import { CheckCircle } from '@mui/icons-material'
import React, { useCallback, useEffect, useState } from 'react'

function SuggestedCat({cat,selectedSuggestions,setSelectedSuggestions}) {
    const[selected,setSelected] = useState(false)
    const selectedItem = useCallback(()=>{
      setSelected(!selected);
      if(!selected){
        setSelectedSuggestions((prev)=>{
          return [...prev,{
            category:cat.category,
            description: cat.description,
            icon:cat.icon,
            categoryId:cat.categoryId,
            createdBy: cat.createdBy,
            banner:cat.banner
          }
          ]
        })
        
      }
      else{
        let newList = selectedSuggestions?.filter((item)=> item.category!==cat.category)
        setSelectedSuggestions(newList)
      
      }
      
    },[selectedSuggestions])
    useEffect(()=>{},[selectedSuggestions])
  return (
    <button className={`flex relative flex-col w-48 h-36 border items-center rounded-md px-5 py-3 hover:scale-105 shadow-md transition-all ${selected && "border-green-600"}`} onClick={selectedItem}>
        {selected && <div className='text-green-600 absolute top-2 flex justify-end w-full'><CheckCircle/> </div>}
        <img src={cat?.icon || "/images/categorybanner.png" } className='w-24 h-20 object-contain'/>
        <div>{cat?.category}</div>
    </button>
  )
}

export default SuggestedCat