import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import ModalOverlay from '../../../components/common/ModalOverlay';
import DivContainer from '../../../ui/DivContainer';
import { ClaimList } from '../ClaimList';

export const ClaimersListModal = ({ openModal, setOpenModal, claimers }) => {

    const appCtx = useSelector((state) => state.app);
    const dark = appCtx.isDarkMode;

    function closeModal() {
        setOpenModal(false);
    }
    return (
        <ModalOverlay
            open={openModal}
            onClose={closeModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"

        >

            <div className="flex flex-col gap-3 w-[400px]">
                <div className="flex justify-end">
                    <button onClick={closeModal}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <div className={`flex items-center flex-col w-96 min-h-[400px] dark-border light-border  p-3`}>
                    {claimers?.length > 0 && (
                        <div className="flex flex-col gap-5">
                            <span className="font-bold">
                                Attendees List :{' '}
                                <span className="font-semibold">Total {claimers.length} Attendees</span>
                            </span>
                            <ClaimList claimers={claimers} dark={dark} />
                        </div>
                    )}
                </div>
            </div>

        </ModalOverlay>
    )
}
