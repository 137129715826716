import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';
import ModalOverlay from '../common/ModalOverlay';
import React from "react"

const CreatePasswordModal = (props) => {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [uploading, setUploading] = useState(false);
    const makeRequest = useAuthorizedHttp();
    const dispatch = useDispatch();

    const handleDoneClick = useCallback(() => {
        if (!password) {
            toast.error("Password can't be empty!");
            return;
        }
        if (password !== confirmPassword) {
            toast.error('Password and confirm password do not match');
            return;
        }

        setUploading(true);
        toast.promise(
            () =>
                makeRequest(
                    {
                        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/password/change`,
                        method: 'POST',
                        data: {
                            password
                        }
                    },
                    () => {
                        dispatch(appActions.togglePasswordCreated(undefined));
                    },
                    (data) => {
                        console.log(data);
                    },
                    () => setUploading(false)
                ),
            {
                pending: 'Updating your profile...',
                success: 'Successfully set your password',
                error: 'Something wrong happened'
            }
        );
    }, [confirmPassword, dispatch, makeRequest, password]);

    const handleCancelClick = useCallback(() => {
        if (props.hideResetPassword) {
            props.hideResetPassword();
        } else {
            dispatch(appActions.togglePasswordCreated(undefined));
        }
    }, [dispatch, props]);

    return (
        <ModalOverlay>
            <div className='px-10 py-10 flex flex-col gap-5'>
            <div className="dark:text-gray-300 text-2xl font-bold text-gray-600 border-b pb-5 border-b-gray-300 dark:border-b-gray-600  ">
                {props.header}
            </div>
            <div className='flex flex-col gap-3'>
            <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Set up your password"
            />
            <Input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Retype your password"
            />
            </div>
            <div className='flex justify-between gap-2'>
            <BlueGradientButton disabled={uploading} onClick={handleCancelClick} className="w-32 py-1 bg-gray-500">
                Cancel
            </BlueGradientButton>
            <BlueGradientButton disabled={uploading} onClick={handleDoneClick} className=" w-32 py-1">
                Done
            </BlueGradientButton>
           
           
            </div>
            </div>
        </ModalOverlay>
    );
};

export default CreatePasswordModal;
