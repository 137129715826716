import React, { useRef } from 'react';
import LandingButton from '../../ui/buttons/LandingButton';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';

function Web3Events() {
    const slider = useRef(null);
    const dispatch = useDispatch();
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        slideMargin: 3,
        autoplaySpeed: 3000
    };
    return (
        <div className="flex flex-col gap-24">
            <div
                className="w-full  h-screen  grid grid-cols-1 md:grid-cols-3 bg-cover bg-no-repeat"
                style={{ backgroundImage: `url(/images/w3e/1.svg)` }}
            >
                <div className='hidden md:block' ></div>
                <div className="flex flex-col justify-evenly px-5  pl-20 md:col-span-2">
                    <div className="flex flex-col justify-center gap-5">
                        <div className="font-semibold text-4xl flex justify-center">Web 3.0 for events</div>

                        <p className="font-semibold text-lg md:text-2xl text-center">
                            Providing a tokenized, verifiable, and social way to issue and share certificates. Host your
                            event, take RSVPs on the blockchain, issue proof of attendance NFTs and manage your
                            community
                        </p>
                        <div className="flex justify-center">
                        <LandingButton onClick={() => dispatch(appActions.setCreateAccountModal(true))}>
                            Get Started
                        </LandingButton>
                    </div>
                    </div>
                   
                </div>
            </div>
            <div className="w-full px-20 flex justify-center">
                <img loading="lazy" src="/images/w3e/2.svg" className="w-[90%] h-full object-contain" />
            </div>
            <div className="flex flex-col items-center gap-5 px-20">
                <span className="font-semibold text-lg md:text-2xl">Use Cases</span>

                <div className="relative w-full px-10 no-dots">
                    <button
                        onClick={() => slider.current.slickPrev()}
                        className="absolute top-1/2 -translate-y-1/2 left-2 md:left-7 text-white z-10"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} fontSize={30} />
                    </button>
                    <button
                        onClick={() => slider.current.slickNext()}
                        className="absolute top-1/2 -translate-y-1/2 right-2 md:right-7 text-white z-10"
                    >
                        <FontAwesomeIcon icon={faChevronRight} fontSize={30} />
                    </button>
                    <Slider ref={slider} {...settings} slideMargin={10}>
                        <div className='p-3'>
                            <img src="/images/w3e/3a.svg" />
                        </div>
                        <div className='p-3'>
                            <img src="/images/w3e/3b.svg" />
                        </div>
                        <div className='p-3'>
                            <img src="/images/w3e/3c.svg" />
                        </div>
                        <div className='p-3'>
                            <img src="/images/w3e/3d.svg" />
                        </div>
                        <div className='p-3'>
                            <img src="/images/w3e/3e.svg" />
                        </div>
                    </Slider>
                </div>
            </div>
            <div className='flex justify-center px-20'>
                <div className="w-full px-10 flex flex-col md:flex-row justify-center gap-10">
                    <div className="flex justify-center">
                        <div className="">
                            <img src="/images/w3e/4.svg" />
                        </div>
                    </div>
                    <div className="flex flex-col items-start gap-10 justify-center">
                        <div className="text-start text-lg ">
                            Give your attendees an NFT in place of a certificate, a distinct, non-transferable unit of data
                            kept in a digital ledger
                        </div>
                        <div className="flex justify-center w-full">
                            <LandingButton className="text-sm md:text-base" onClick={() => window.scroll(0, 0)}>Include your NFT certificates</LandingButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Web3Events;
