import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import bg from '../assets/login/login-bg.jpg';
import useHttp from '../hooks/use-http';
import { isFieldNonEmpty } from '../misc/field-validation';
import BlueGradientButton from '../ui/BlueGradientButton';
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar';
import TextArea from '../ui/TextArea';
import TextField from '../ui/TextField';
import React from "react"

const CreateMarketplace = (props) => {
    const appCtx = useSelector((state) => state.app);
    const marketplaceNameRef = useRef();
    const marketplaceDescriptionRef = useRef();
    const [createDisabled, setCreateDisabled] = useState(false);
    const makeRequest = useHttp();
    const navigate = useNavigate();

    const successCallback = useCallback(
        (data) => {
            navigate('/signup');
        },
        [navigate]
    );

    const errorCallback = useCallback((data) => {
        console.log(data);
    }, []);

    const completeCallback = useCallback((data) => {
        console.log(data);
        setCreateDisabled(false);
    }, []);

    const handleCreateClicked = useCallback(() => {
        marketplaceNameRef.current.touch();
        marketplaceDescriptionRef.current.touch();
        if (
            marketplaceDescriptionRef.current.isValid &&
            marketplaceNameRef.current.isValid &&
            marketplaceDescriptionRef.current.value &&
            marketplaceNameRef.current.value
        ) {
            setCreateDisabled(true);

            toast.promise(
                () =>
                    makeRequest(
                        {
                            data: {
                                name: marketplaceNameRef.current.value,
                                description: marketplaceDescriptionRef.current.value,
                                subdomain: marketplaceNameRef.current.value
                            },
                            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-App-Token': process.env.REACT_APP_APP_TOKEN
                            }
                        },
                        successCallback,
                        errorCallback,
                        completeCallback
                    ),
                {
                    pending: 'Putting your request through...',
                    success: 'Your marketplace has successfully registered!',
                    error: 'Looks like there was a problem!'
                }
            );
        }
    }, [completeCallback, errorCallback, makeRequest, successCallback]);

    return (
        <div className={`${appCtx.isDarkMode && 'dark'}`}>
            <UnauthenticatedNavbar />
            <div className="w-screen bg-no-repeat bg-cover bg-fixed h-screen" style={{ backgroundImage: `url(${bg})` }}>
                <div className="w-screen h-screen bg-gradient-to-r dark:from-zinc-800 dark:via-zinc-800/80 to-transparent from-zinc-700 via-zinc-600/80 p-0 flex justify-center overflow-y-scroll">
                    <div className="my-auto flex flex-col gap-10 mx-auto lg:w-[1100px] text-center md:text-left">
                        <div className="text-slate-100 md:w-[40vw] min-w-[300px] text-xl">
                            Sed eget mattis enim. Phasellus eu commodo augue
                        </div>
                        <div className="text-slate-100 text-5xl font-extrabold">Create your own marketplace.</div>
                        <div className="flex flex-col gap-5">
                            <TextField
                                className="min-w-[320px] w-[40vw] max-w-[560px] mx-auto md:mx-0"
                                type="text"
                                placeholder="Marketplace name"
                                error="Marketplace name can't be empty."
                                ref={marketplaceNameRef}
                                validityFunc={() => isFieldNonEmpty(marketplaceNameRef.current.value)}
                            />
                            <TextArea
                                className="min-w-[320px] w-[40vw] max-w-[560px] mx-auto md:mx-0"
                                placeholder="Marketplace description"
                                error="Marketplace description can't be empty."
                                ref={marketplaceDescriptionRef}
                                validityFunc={() => isFieldNonEmpty(marketplaceDescriptionRef.current.value)}
                            />
                        </div>
                        <BlueGradientButton
                            disabled={createDisabled}
                            className="min-w-[320px] w-[40vw] max-w-[560px]"
                            onClick={handleCreateClicked}
                        >
                            Create
                        </BlueGradientButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateMarketplace;
