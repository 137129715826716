import React, { useCallback, useState } from 'react'
import ModalOverlay from '../common/ModalOverlay'
import useFetchClaimers from '../../hooks/events/useFetchClaimers'
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Cancel } from '@mui/icons-material';

function EditAttendeeModal({name, email, closeModal,fetchClaimers, certId ,id,eventId}) {
    const [editName,setEditName] = useState(name || "");
    const appCtx = useSelector(state => state.app);
    const [editEmail,setEditEmail] = useState(email || "");
    const updateClaimers = useCallback(()=>{
        console.log(certId)
        const data = id? {
            caertId: certId,
            claimed: false,
            email: editEmail,
            eventId: +eventId,
            gasFeeWaived: false,
            id: +id || null,
            name: editName,
            orgId: appCtx.marketplaceId
        } :[
            {
                certId: certId,
                claimed: false,
                email: editEmail,
                eventId: +eventId,
                gasFeeWaived: false,
                id: +id || null,
                name: editName,
                orgId: appCtx.marketplaceId
            }  
        ] 
        

        const config = {
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/cert/nft/claimers`,
            method: id? "put" :"post",
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken,
            },
            data:data
        }

        axios(config)
        .then(res => {
            toast.success("User updated successfully")
            fetchClaimers(certId)
            closeModal()
        })
        .catch(rej => {
            toast.error("User could not be updated")
        })
        .finally(() =>{

        })

    },[certId,editEmail,editName,eventId])
    
  return (
    <ModalOverlay>
        <div className='flex flex-col gap-5 min-w-[300px]'>
            <div className='flex justify-end'><button onClick={closeModal}><Cancel/></button></div>
                <Input 
                placeholder="Name"
                description=" Name"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                />
                <Input 
                placeholder="Email"
                description=" Email"
                value={editEmail}
                onChange={(e) => setEditEmail(e.target.value)}
                />
                <BlueGradientButton onClick={updateClaimers}>Submit</BlueGradientButton>
        </div>
    </ModalOverlay>
  )
}

export default EditAttendeeModal