import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Input from '../Input';
import BlueGradientButton from '../BlueGradientButton';
import axios from 'axios';
import Property from './atoms/Property';

function UpdatePremintedAsset() {
    const makeAuthorizedRequest = useAuthorizedHttp();
    const appCtx = useSelector((state) => state.app);
    const navigate = useNavigate();
    const [assetDetail, setAssetDetails] = useState();
    const [metaData, setMetaData] = useState({
        "Asset Image": "",
        "Asset Name":"",
        "Asset Property":"",
        "Asset Descriptions": "",
    });
    const { id } = useParams();
    useEffect(() => {
        makeAuthorizedRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/premint/nft/${id}`
            },
            (res) => {
                setAssetDetails(res);
                const metaData = (JSON.parse(res.metaData));
                setMetaData({
                    "Asset Descriptions": metaData["Asset Descriptions"],
                    "Asset Image": metaData["Asset Image"],
                    "Asset Property": metaData["Asset Property"],
                    "Asset Name": metaData["Asset Name"]
                })
            },
            (rej) => toast.error('Could not load data please try again later')
        );
    }, [id]);
    const updateAsset = useCallback(()=>{

        let flag = true;
        if(metaData["Asset Image"] ===""){
            flag = false;
            toast.error("Image url can't be empty")
        }

        if(metaData["Asset Name"] ===""){
            flag = false;
            toast.error("Asset name  can't be empty")
        }

        if(flag){

            let config = {
                url:`${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/bulk/premint`,
                method:"post",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': appCtx.authToken,
                },
                data:[
                    {
                        metaData:JSON.stringify(metaData),
                        userId: appCtx.userDetails.userId,
                        marketplaceId: appCtx.marketplaceId,
                        collection: assetDetail?.collection,
                        blockchain: appCtx.blockchain,
                        minted:false,
                        id:+id
                    }
                ]
            }



            axios(config)
            .then(res => {
                toast.success("Asset details upadated successfully")   
                navigate("/nft-launch-pad/premint")
            })
            .catch(rej =>{
                toast.error("We are having trouble updating your asset details .Please try agian Later")
            })


        }
    },[metaData,assetDetail,id])
    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-3">
                <Input
                    description="name"
                    value={metaData['Asset Name']}
                    onChange={(e) => setMetaData({ ...metaData, ['Asset Name']: e.target.value })}
                ></Input>
                <Input
                    description="Image url"
                    value={metaData['Asset Image']}
                    onChange={(e) => setMetaData({ ...metaData, ['Asset Image']: e.target.value })}
                ></Input>
                <Input
                    description="Description"
                    value={metaData['Asset Descriptions']}
                    onChange={(e) => setMetaData({ ...metaData, ['Asset Descriptions']: e.target.value })}
                ></Input>
                <Input description="Collection" disabled={true} value={assetDetail?.collection}></Input>
                <div className="flex flex-col gap-3">
                    <span className="font-semibold text dark:text-gray-400">Properties</span>
                    <div className="flex gap-2">
                        <span className="font-bold">Valid JSON format : </span>
                        <span>{`{"key1" : "value1" , "key2" : "value2" , "key3" : "value3"}`}</span>
                    </div>
                    <div className="flex gap-2 flex-wrap">
                        {metaData['Asset Property'] && (
                            <div>
                                <Property properties={metaData['Asset Property']} />
                            </div>
                        )}
                    </div>
                    <Input
                        value={metaData['Asset Property']}
                        onChange={(e) => setMetaData({ ...metaData, ['Asset Property']: e.target.value })}
                    />
                </div>
                <div className="flex justify-center">
                    <BlueGradientButton onClick={updateAsset} className="px-16">
                        Update
                    </BlueGradientButton>
                </div>
            </div>
        <div className='flex items-center justify-center'>
            <div className='w-3/5 rounded-lg overflow-hidden  '>
                    <img src ={`https://drive.google.com/uc?export=view&id=${metaData['Asset Image']?.split('/')[5]}`}
                     className="object-contain w-full"/>
            </div>
            </div>
        </div>
    );
}

export default UpdatePremintedAsset;
