import { AccountBox, AccountCircle, CopyAll, Launch, RemoveRedEye, Storefront, Wallet } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getImageUrl, nativeToken } from '../../config';

function NavWallet({ marketplace, wallet }) {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('wallet');
    const [walletAddress, setWalletAddress] = useState(wallet?.address);
    const appCtx = useSelector((state) => state.app);

    useEffect(() => {
        setWalletAddress(wallet?.address);
    }, [wallet?.address]);

    return (
        <div className={`flex flex-col gap-4 min-h-[270px] ${appCtx.isDarkMode}`}>
            <div className="flex w-full min-w-[500px]  py-4 justify-between px-3 border-b border-gray-700 shadow-md ">
                <div className="flex gap-2">
                    <Storefront />
                    <div className="font-bold">
                        {appCtx.marketType === 'DAO' ? 'My Landing Page' : 'My Marketplace'}
                    </div>
                </div>
                <div className="flex ">
                    <div className="font-bold text-sm"> Visit Store</div>
                    <button
                        onClick={() => {
                            window.open(`http://${marketplace?.domain}`);
                        }}
                        className="text-blue-600"
                    >
                        <Launch />
                    </button>
                </div>
            </div>

            {wallet ? (
                <div className="flex flex-col">
                    <div className="flex gap-5 px-4 flex-wrap justify-evenly">
                        <div className="flex flex-col grow font-bold">
                            <div className="">Name</div>
                            <div>{marketplace?.name}</div>
                        </div>
                        <div className="flex flex-col grow">
                            <div className="font-bold">Address Associated</div>
                            <div>
                                {`${walletAddress?.slice(0, 6)} .... ${walletAddress?.slice(
                                    walletAddress?.length - 3,
                                    walletAddress?.length
                                )}`}
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(walletAddress);
                                    }}
                                >
                                    <CopyAll fontSize="small" />
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col grow">
                            <div className="font-bold">Status</div>
                            <div className="font-bold">
                                {marketplace?.status === true ? (
                                    <div className="text-green-500">Active</div>
                                ) : (
                                    <div className="text-black">Inactive</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="flex flex-col border border-gray-400 rounded-lg overflow-hidden">
                            <div className="flex flex-col items-center py-3">
                                <div className="font-bold">Account balance</div>
                                <div className="text-xl flex flex-col items-center">
                                    <div className="w-8 h-8 rounded-full overflow-hidden">
                                        <img
                                            src={getImageUrl(wallet?.blockchain)}
                                            className="object-contain"
                                            alt="blockchain_image"
                                        />
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <div className="font-bold">
                                            {appCtx?.walletDetails?.balance} {nativeToken[appCtx?.blockchain]}
                                        </div>{' '}
                                        {appCtx?.walletDetails?.balance < 0.2 && (
                                            <div className="text-red-600 text-xs">Low balance , Please add funds</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {process.env.REACT_APP_ENVIRONMENT == 'prod' ? (
                                <button
                                    onClick={() => {
                                        const url = `https://www.talewallet.com/onramp?address=${appCtx?.walletDetails?.address}`;
                                        window.open(url);
                                    }}
                                    className="bg-secBlue py-3 overflow-hidden text-white"
                                >
                                    Add funds
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        const url =
                                            wallet?.blockchain === 'ALGORAND'
                                                ? `https://dispenser.testnet.aws.algodev.network/?account=${walletAddress}`
                                                : wallet?.blockchain === 'POLYGON'
                                                ? `https://mumbaifaucet.com/`
                                                : wallet?.blockchain === 'BSC'
                                                ? `https://testnet.bnbchain.org/faucet-smart`
                                                : wallet?.blockchain === 'ETHEREUM'
                                                ? `https://goerlifaucet.com/`
                                                : '';
                                        window.open(url);
                                    }}
                                    className="bg-secBlue py-3 overflow-hidden text-white"
                                >
                                    Add funds
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="px-5 py-3">
                    Your marketplace setup is not completed{' '}
                    <button className="text-blue-600" onClick={() => navigate('/home')}>
                        {' '}
                        Click here{' '}
                    </button>{' '}
                    to complete
                </div>
            )}
        </div>
    );
}

export default NavWallet;
