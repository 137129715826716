import React, { useImperativeHandle, useRef, useState } from "react";

const TextArea = React.forwardRef((props, ref) => {
	const [wasTouched, setTouched] = useState(false);
	const [isValid, setValid] = useState(true);
	const textRef = useRef("");
	const [text, setText] = useState("");
	var isInvalid = !isValid && wasTouched;

	const border = {
		right: "r-",
		left: "l-",
		bottom: "b-",
		top: "t-",
	};

	const validityCheck = (value) => {
		if (props.validityFunc(value)) setValid(true);
		else setValid(false);
	};

	const onChange = () => {
		setText(textRef.current.value);
	};
	const onKeyUp = () => {
		validityCheck(text);
	};
	const externalTouch = () => {
		setTouched(true);
		validityCheck(text);
	};

	useImperativeHandle(ref, () => {
		return {
			touch: externalTouch,
			isValid: !isInvalid,
			value: text,
		};
	});

	return (
		<div
			className={`flex flex-col gap-3 transition-all group ease-out duration-300 overflow-hidden ${
				isInvalid ? "h-[300px]" : "h-[270px]"
			}`}
		>
			<textarea
				onFocus={() => setTouched(true)}
				value={text}
				onChange={onChange}
				onKeyUp={onKeyUp}
				ref={textRef}
				rows={10}
				placeholder={props.placeholder}
				className={`${
					props.className
				} dark:hover:bg-gray-600 border-r-xl dark:hover:border-gray-600 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-slate-300 bg-slate-200 text-gray-700 p-3 outline-none rounded-${
					props.border != null ? border[props.border] : ""
				}lg border-2 hover:border-sky-500 dark:focus:border-sky-500 focus:border-sky-500 transition-all ease-in-out duration-300`}
			/>
			<div className="text-rose-500 text-sm">{props.error}</div>
		</div>
	);
});

export default TextArea;
