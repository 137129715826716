import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar';
import Footer from '../components/common/Footer';
import { useSelector } from 'react-redux';
import axios from 'axios';
import parse from 'html-react-parser'
import { toast } from 'react-toastify';
import useFetchBlogPost from '../hooks/blogpost/useFetchBlogPost';
import AssetPulse from '../components/common/AssetPulse';
import useTutorials from '../ui/tutorial/useTutorials';
import { ArrowBack, ArrowLeft, Backspace } from '@mui/icons-material';
import urlForTheString from '../misc/urlForTheString';
// import titleUrlGernerator from '../misc/urlForTheString';

function TutorialDetails() {
    const appCtx = useSelector((state) => state.app);
    const {blogDetail,loading,id} = useFetchBlogPost()
    const {loading:tutorialsLoading,allTutorialsRef} = useTutorials();
    // const {urlForTheString} = titleUrlGernerator();
    useEffect(()=>{
        window.scroll(0,0)
        console.log(window.location)
    },[window.location.pathname])

    return (
        <div className={` landing-bg  min-h-screen ${appCtx.isDarkMode ? 'dark' : ''} flex flex-col justify-between`}>
            <UnauthenticatedNavbar />
            {!loading ? (
                <div className='flex justify-center items-center lg:items-start gap-10 flex-col lg:flex-row-reverse mt-20'>
                <div className="flex  flex-col gap-5 bg-slate-300 text-black px-10 py-5 md:mt-10 rounded-md w-4/5 lg:w-3/5">
                    
                    <div className="flex flex-col gap-10">
                        <div className='flex flex-col gap-5'>
                        <div className=" font-bold text-2xl  ">{blogDetail?.title}</div>
                        <div className='flex gap-3 items-center'>
                            <span className='w-10 h-10'>
                                    <img src="/logo.png"/>
                            </span>
                            <span className='font-bold'>NFTVerse</span>
                        </div>
                        </div>
                      { blogDetail?.summary && <div className="flex flex-col items-start  gap-6  rounded-sm">
                            <span className="font-bold text-xl">summary</span>
                            <div className="">{parse(blogDetail?.summary || '')}</div>
                        </div>}
                        {blogDetail?.coverImage &&<div className=" flex justify-center  w-full">
                            <div className="  w-4/5">
                                <img src={blogDetail?.coverImage || ''} className="w-full max-h-96 object-contain" />
                            </div>
                        </div>}
                        <div className="flex flex-col items-start  gap-6  rounded-sm">
                            {/* <span className="font-bold text-xl">Article :</span> */}
                            <div className="text-ellipsis w-full break-words">{parse(blogDetail?.description || '')}</div>
                        </div>
                    </div>
                </div>
                <div className=" w-4/5  lg:w-1/4  md:mt-10 rounded-md  overflow-hidden">
              {!tutorialsLoading ? (
                  <div className="flex flex-col gap-5">
                    <Link to="/tutorial" className="font-semibold items-center text-xl flex text-white gap-3 no-underline ">
                        <span><ArrowBack/></span>
                        <span>{blogDetail?.category}</span></Link>
                      {/* <span className="font-semibold py-2 text-xl border-b text-slate-50">Other question of the category</span> */}
                      <div className="flex flex-col gap-5">
                          {allTutorialsRef?.current?.map((post, i) => {
                            // const title = post?.title?.split(/[/,&,$,+,:,;,=,?,@,#]/).join("");
                              return (
                                  post.category === blogDetail?.category  &&
                                   (
                                      <Link
                                          to={`/tutorial/${post?.postId}/${urlForTheString(post?.title)}`}
                                          className={`no-underline text-slate-50 hover:text-yellow-400 transition-all border border-gray-600 hover:border-gray-700 p-3 rounded-md ${+id ===post.postId && "text-yellow-300"} `}
                                          key={post.postId}
                                      >
                                          <div className="">
                                              {/* <div className="min-w-[100px] max-w-[100px] ">
                                                  <img src={`${post?.coverImage}`} className="w-full max-h-16" />
                                              </div> */}

                                              <div>{post.title}</div>
                                          </div>
                                      </Link>
                                  )
                              );
                          })}
                      </div>
                  </div>
              ) : (
                  <div>
                      <AssetPulse />
                  </div>
              )}
          </div>
                </div>
            ) : (
                <div className='py-5 mt-20 px-20'>
                    <AssetPulse />
                </div>
            )}
            <Footer />
        </div>
    );
}

export default TutorialDetails;
