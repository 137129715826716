import axios from 'axios'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAuthorizedHttp from '../use-authorized-http'

function useBulkMint() {

    const appCtx = useSelector(state => state.app)
    const navigate = useNavigate();
    const makeRequest = useAuthorizedHttp();
    const bulkMint = useCallback((data)=>{
        
        const config = {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/nft/bulk/mint/byId`,
            method:"post",
            headers: {
                'X-Auth-Token': appCtx.authToken,
                'Content-Type': 'application/json'
            },
            data:data
        }
        axios(config)
        .then((res)=>{ toast.success(res?.data?.message) 
            navigate("/nft-launch-pad/home",{replace:true});
        })
        .catch((rej) => toast.error("Bulk Mint could not be completed"))
    },[])
     
  return {
    bulkMint
  }
}

export default useBulkMint