import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

const useTutorials = () =>{
// const [blogs, setBlogs] = useState([]);
const allTutorialsRef = useRef([]);
const [tutorials,setTutorials] = useState([
    {
        category: 'Algorand Blockchain Network',
        questions: []
    },
    {
        category: 'Get started',
        questions: []
    },
    {
        category: 'Customize Store',
        questions: []
    },
   
    {
        category: 'Manage NFTs',
        questions: []
    },
    {
        category: 'Fungible tokens',
        questions: []
    },
    {
        category: 'Guide for Buyers',
        questions: []
    },
    {
        category: 'Other Store Features',
        questions: []
    }
]);
const [isFound,setIsFound] = useState(true)
const [loading, setLoading] = useState(false);
let definedTutorials = [
    {
        category: 'Algorand Blockchain Network',
        questions: []
    },
    {
        category: 'Get started',
        questions: []
    },
    {
        category: 'Customize Store',
        questions: []
    },
   
    {
        category: 'Manage NFTs',
        questions: []
    },
    {
        category: 'Fungible tokens',
        questions: []
    },
    {
        category: 'Guide for Buyers',
        questions: []
    },
    {
        category: 'Other Store Features',
        questions: []
    }
]


const fillQuestionsToTutorials = useCallback((blogs) => {
    // const newArray = definedTutorials.map((tutorial) => {
    //     // console.log(tutorial.questions)
    //     let sec;
    //     blogs?.map((blog) => {

    //         if (blog?.category === tutorial?.category) {
                
    //             sec =  { category: tutorial.category, questions: [...tutorial?.questions, blog] };
                
    //         }
    //     });
    //     if(sec) return sec
    //     return tutorial
    // });

    blogs?.map((blog) =>{
        definedTutorials =  definedTutorials.map((tutorial)=>{
        if(blog?.category === tutorial?.category) {
                return { category: tutorial.category, questions: [...tutorial?.questions, blog] }
        }
        return tutorial
        })

        setTutorials(definedTutorials)
    })
    
}, []);

const fetchTutorials = useCallback(() => {
    let config = {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/0/blog/post/list?type=TUTORIAL`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'X-App-Token': process.env.REACT_APP_APP_TOKEN

      }
    };
    axios(config)
        .then((response) => {
        //   setBlogs(response.data?.content);
          setIsFound(true)
        // response?.data?.content?.map()
        allTutorialsRef.current = response?.data?.content
        fillQuestionsToTutorials(response.data?.content)

        })
        .catch(()=>{
            setIsFound(false)
        })
        .finally(() => setLoading(false));
});

useEffect(() => {
    fetchTutorials();
}, []);

// const tutorials = [
//     {
//         category: 'Algorand Blockchain Network',
//         questions: ['Steps to create a store on algorand.', 'Content - Set up Header, Pages, Footer.']
//     },
//     {
//         category: 'Get started',
//         questions: [
//             'How to create your NFT store/Marketplace.',
//             'Content - Steps in which we can create a store/marketplace.'
//         ]
//     },
//     {
//         category: 'Customize Store',
//         questions: ['Steps to customize store', 'Content - Set up Header, Pages, Footer.']
//     },
   
//     {
//         category: 'Manage NFTs',
//         questions: [
//             'How to create NFTs & list your created NFTs for sale on the marketplace.',
//             'How to create collections',
//             'How to create and Add new categories for your collections.',
//             'What is NFTLaunchpad?',
//             'How to Create bulk NFTs at a time.',
//             'How to List your created NFTs for sale on the marketplace.'
//         ]
//     },
//     {
//         category: 'Fungible tokens ',
//         questions: ['What is fungible tokens ', 'How you can create your own tokens', 'How to add/remove more tokens.']
//     },
//     {
//         category: 'Guide for Buyers',
//         questions: ['How to buy NFTs from a marketplace.', 'Buy NFTs with Fiat']
//     },
//     {
//         category: 'Other Store Features ',
//         questions: ['How to add a blogspot on your marketplace through dashboards.']
//     }
// ];

    return {
        tutorials,
        isFound,
        loading,
        allTutorialsRef
    }

}
export default useTutorials
