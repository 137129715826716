import React from 'react';
import useFetchPremintData from '../../hooks/events/useFetchPremintData';

function NftCard({ cert, nftHeight, gasFeeWaived, price }) {
    const { premintContent, premintMetaData, fetchPremintData } = useFetchPremintData(cert.premintId);
    return (
        <div className="flex flex-col gap-4  p-3 rounded-md">
            <div className="font-semibold"></div>
            <div className={`flex justify-center  items-center ${nftHeight || 'h-[300px]'}`}>
                {premintContent?.cid && (
                    <img
                        loading="lazy"
                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                        className="max-h-full min-h-[200px] object-contain"
                        alt="Certificate-nft"
                    />
                )}
            </div>
            {price !== undefined && (
                <div className="flex justify-end items-center gap-2">
                    <span className="font-semibold">Price:</span> <span>{price}</span>
                </div>
            )}
            <div className="font-semibold break-words">{premintMetaData?.name}</div>
            <div className="text-sm break-words">{premintMetaData?.description}</div>
            {premintMetaData?.properties && Object.entries(premintMetaData?.properties)?.length > 0 && (
                <div className="flex flex-wrap gap-4">
                    {Object.entries(premintMetaData?.properties).map(([key, val]) => {
                        return (
                            <div className="flex flex-col p-3 rounded shadow-md">
                                <span className="font-semibold">{key}</span>
                                <span className="font-bold">{val}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default NftCard;
