import React, { useEffect, useRef, useState } from 'react';
import KeyValuePair from '../../../components/common/KeyValuePair';
import DivContainer from '../../../ui/DivContainer';
import EventDetailCard from '../../../components/events/EventDetailCard';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Input from '../../../ui/Input';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    AddCircle,
    CloudCircle,
    CloudOutlined,
    FileCopyOutlined,
    InsertDriveFile,
    InsertDriveFileOutlined,
    SelectAll,
    Upload
} from '@mui/icons-material';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import ArrayKeyValue from '../../../components/common/ArrayKeyValue';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';
import CreateCollectionModal from '../../../components/mint-asset/CreateCollectionModal';
import Pdfrender from './Pdfrender';

function CreateNewCertificate() {
    // const [keyValue, setKeyValue] = useState({});
    const [objectProperties, setObjectProperties] = useState({});
    const [kv, setKV] = useState([]);

    const appCtx = useSelector((state) => state.app);
    const fileRef = useRef(null);
    const inputFileRef = useRef(null);
    const previewimageRef = useRef(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [cid, setCid] = useState('');
    const [previewcid, setpreviewCid] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadbutton, setUploadbutton] = useState(true);
    const [previewuploadbutton, setPreviewuploadbutton] = useState(true);

    const [previewuploading, setPreviewuploading] = useState(false);
    const [previewimgloading, setPreviewimgloading] = useState(false);
    const [selectedpreviewfile, setSelectedpreviewfile] = useState(null);

    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [collection, setCollection] = useState([]);
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState('select');
    const [imgLoading, setImgLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const extensionRef = useRef('');

    const navigate = useNavigate();
    console.log('====================================');
    console.log(imgLoading);
    console.log('====================================');
    const uploadNft = useCallback(() => {
        const formData = new FormData();
        formData.append('file', fileRef.current);

        setUploading(true);
        setImgLoading(true);
        // Get the file extension
        const fileExtension = fileRef.current.name.split('.').pop().toLowerCase();
        let url = `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/file/upload?type=image`;

        if (fileExtension === 'pdf' || fileExtension === 'doc' || fileExtension === 'docx') {
            url = `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/file/upload?type=doc`;
        }

        const config = {
            method: 'post',
            url,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            data: formData
        };
        axios(config)
            .then((res) => {
                setUploadbutton(false);
                // console.log(res?.data?.cid);
                if (
                    extensionRef.current === 'pdf' ||
                    extensionRef.current === 'doc' ||
                    extensionRef.current === 'docx'
                ) {
                    setpreviewCid(res?.data?.cid);
                } else {
                    setCid(res?.data?.cid);
                }
            })
            .catch((rej) => {
                if (rej?.response?.data?.status === 500) {
                    if (
                        rej?.response?.data?.message ===
                        'Request processing failed; nested exception is java.lang.Exception: Duplicate file from other marketplace'
                    ) {
                        setOpenWarningModal(true);
                        toast.error('Duplicate file from other marketplace');
                    } else {
                        toast.error('Having trouble uploading your asset');
                    }
                } else {
                    toast.error('Having trouble uploading your asset');
                }
            })
            .finally(() => {
                setUploading(false);
                setImgLoading(false);
            });
    }, []);

    const uploadpreviewnft = useCallback(() => {
        const formData = new FormData();
        formData.append('file', fileRef.current);
        setPreviewuploading(true);
        setPreviewimgloading(true);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/file/upload?type=image`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            data: formData
        };
        axios(config)
            .then((res) => {
                console.log(res?.data?.cid);
                setCid(res?.data?.cid);
            })
            .catch((rej) => {
                if (rej?.response?.data?.status === 500) {
                    if (
                        rej?.response?.data?.message ===
                        'Request processing failed; nested exception is java.lang.Exception: Duplicate file from other marketplace'
                    ) {
                        setOpenWarningModal(true);
                        toast.error('Duplicate file from other marketplace');
                    } else {
                        toast.error('Having trouble uploading your asset');
                    }
                } else {
                    toast.error('Having trouble uploading your asset');
                }
            })
            .finally(() => {
                setPreviewuploading(false);
                setPreviewimgloading(false);
                setPreviewuploadbutton(false);
            });
    }, []);

    const fileChange = useCallback((file) => {
        fileRef.current = file;
        setSelectedFile(file);

        let extension = file.name.split('.');
        extensionRef.current = extension[extension.length - 1];
    }, []);

    const previewfileChange = useCallback((file) => {
        fileRef.current = file;
        setSelectedpreviewfile(file);
    }, []);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let flag = true;
            if (!cid) {
                flag = false;
                toast.error('Upload Asset in order to proceed');
                return;
            }
            if (!name) {
                flag = false;
                toast.error('Please enter the name');
            }
            if (!selectedCollection || selectedCollection === 'select') {
                flag = false;
                toast.error('Select an already created collection or create a new collection to proceed');
            }
            if (flag) {
                setUpdating(true);
                // const collectionConfig = {
                //     url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/save`,
                //     method: 'post',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'X-Auth-Token': appCtx.authToken
                //     },
                //     data: {
                //         name: name?.substring(0, 49)
                //     }
                // };
                // axios(collectionConfig)
                //     .then((response) => {

                let mimeType;
                if (extensionRef.current === 'pdf') {
                    mimeType = 'doc/pdf';
                } else if (extensionRef.current === 'doc') {
                    mimeType = 'doc/doc';
                } else if (extensionRef.current === 'docx') {
                    mimeType = 'doc/docx';
                } else {
                    mimeType = `image/${extensionRef.current}`;
                }

                let mediaUrl;
                if (['pdf', 'doc', 'docx'].includes(extensionRef.current)) {
                    mediaUrl = `ipfs://${previewcid}`;
                } else {
                    mediaUrl = `ipfs://${cid}`;
                }

                const data = {
                    standard:
                        appCtx.blockchain === 'ALGORAND' ? 'ARC69' : appCtx.blockchain === 'BSC' ? 'BEP721' : 'ERC721',
                    name,
                    collection: selectedCollection,
                    marketplaceId: appCtx.marketplaceId,
                    description,
                    properties: objectProperties,
                    mimeType,
                    image: `ipfs://${cid}`,
                    cid: cid,
                    mediaUrl
                };
                const config = {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data
                };

                axios(config)
                    .then((res) => {
                        navigate(
                            `/certificate/submit-certificate/${res?.data?.id}?collectionName=${selectedCollection}`
                        );
                        toast.success('Certificate created successfully');
                    })
                    .catch((rej) => {
                        toast.error(rej?.response?.data?.message?.split(':')[1]);
                    })
                    .finally(() => {
                        setUpdating(false);
                    });
                // })
                // .catch((rej) => {
                //     toast.error('Could not initiate the process.');
                // });
            }
        },
        [
            selectedCollection,
            cid,
            name,
            appCtx.marketplaceId,
            appCtx.authToken,
            appCtx.blockchain,
            description,
            objectProperties,
            navigate
        ]
    );
    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    data.content.forEach((d) => {
                        setCollection((prev) => [
                            ...prev,
                            {
                                id: d.collectionId,
                                text: d.name
                            }
                        ]);
                    });
                }
            ),
        [makeAuthorizedRequest, appCtx.marketplaceId]
    );

    useEffect(() => {
        appCtx.marketplaceId && loadCollections();
    }, [appCtx.marketplaceId, loadCollections]);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    console.log(`${process.env.REACT_APP_GATEWAY_IPFS + '/' + previewcid}`);

    return (
        <div className="flex flex-col gap-5">
            {appCtx.marketplaceId && collectionModalOpen && (
                <CreateCollectionModal
                    marketplaceId={appCtx.marketplaceId}
                    onCreateSuccesssful={() => loadCollections()}
                    onCloseCreateCollection={() => setCollectionModalOpen(false)}
                />
            )}
            <DivContainer>
                <span className="font-bold text-lg">Certificate NFT Details</span>
            </DivContainer>
            <div className="grid grid-cols-5 gap-5">
                <div className="flex flex-col gap-5 col-span-5 md:col-span-3">
                    <EventDetailCard title="Upload Asset">
                        <div className="flex flex-col gap-3 items-center">
                            <input
                                className="hidden"
                                ref={inputFileRef}
                                type="file"
                                accept=".gif, .jpg, .png, .doc, .pdf, .docx, .svg"
                                onChange={(e) => fileChange(e.target.files[0])}
                            />
                            <div
                                onClick={() => inputFileRef.current.click()}
                                className="
                                border-slate-400 w-full
                                flex justify-center
                                gap-2
                                items-center
                                py-5
                                !border-2
                                rounded-md
                            cursor-pointer
                            "
                            >
                                <span className="">
                                    <FontAwesomeIcon icon={faCloudUpload} fontSize={30} />
                                </span>
                                <span>Select File</span>
                            </div>
                            {selectedFile && <span>{selectedFile?.name}</span>}
                            {selectedFile && uploadbutton && (
                                <BlueGradientButton disabled={uploading} className="px-14" onClick={uploadNft}>
                                    {uploading ? 'Uploading .....' : 'Upload'}
                                </BlueGradientButton>
                            )}
                        </div>

                        {/* preview button for doc/pdf  */}
                        {['pdf', 'doc', 'docx'].includes(extensionRef.current) && (
                            <div className="mt-5">
                                <div className="flex flex-col gap-3 items-center">
                                    <input
                                        className="hidden"
                                        ref={previewimageRef}
                                        type="file"
                                        accept=".gif, .jpg, .png, .doc, .pdf, .docx,
                                        .svg"
                                        onChange={(e) => previewfileChange(e.target.files[0])}
                                    />
                                    <div
                                        onClick={() => previewimageRef.current.click()}
                                        className="
                                border-slate-400 w-full
                                flex justify-center
                                gap-2
                                items-center
                                py-5
                                !border-2
                                rounded-md
                            cursor-pointer
                            "
                                    >
                                        <span className="">
                                            <FontAwesomeIcon icon={faCloudUpload} fontSize={30} />
                                        </span>
                                        <span>Select preview image</span>
                                    </div>
                                    {selectedpreviewfile && <span>{selectedpreviewfile?.name}</span>}
                                    {selectedpreviewfile && previewuploadbutton && (
                                        <BlueGradientButton
                                            disabled={previewuploading}
                                            className="px-14"
                                            onClick={uploadpreviewnft}
                                        >
                                            {previewuploading ? 'Uploading .....' : 'Upload'}
                                        </BlueGradientButton>
                                    )}
                                </div>
                            </div>
                        )}
                    </EventDetailCard>
                    <EventDetailCard title="NFT Name">
                        <Input required value={name} placeholder="NFT name" onChange={(e) => setName(e.target.value)} />
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <Input
                            value={description}
                            placeholder="Description"
                            type="textarea"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        {/* <KeyValuePair keyValue={keyValue} setKeyValue={setKeyValue} /> */}
                        <ArrayKeyValue kv={kv} setKV={setKV} setObjectProperties={setObjectProperties} />
                    </EventDetailCard>
                    <div>
                        <EventDetailCard title="Collection">
                            <div className="flex gap-5 w-[100%]">
                                <Select
                                    className="bg-gray-50 outline-none rounded-lg w-[200px]"
                                    sx={{
                                        borderRadius: 2,
                                        height: 40
                                    }}
                                    value={selectedCollection}
                                    onChange={(e) => {
                                        setSelectedCollection(e.target.value);
                                        // sortNfts(e.target.value);
                                    }}
                                >
                                    <MenuItem value="select" key="">
                                        select
                                    </MenuItem>
                                    {collection?.map((collection) => {
                                        return (
                                            <MenuItem value={collection?.text} key={collection?.id}>
                                                {collection?.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <button
                                    onClick={() => {
                                        setCollectionModalOpen(true);
                                    }}
                                    className={
                                        'items-center flex px-5 gap-1 rounded-md py-1 border bg-secBlue text-white'
                                    }
                                    filled
                                >
                                    <AddCircle fontSize="small" /> <div>Create new collection </div>
                                </button>
                            </div>
                        </EventDetailCard>
                    </div>
                </div>

                <div className="flex flex-col gap-5 col-span-5 md:col-span-2">
                    {previewcid && (
                        // <div
                        //     className="cursor-pointer "
                        //     onClick={() => {
                        //         window.open(`${process.env.REACT_APP_GATEWAY_IPFS + '/' + previewcid}`, '_blank');
                        //     }}
                        // >
                        //     {`${process.env.REACT_APP_GATEWAY_IPFS + '/' + previewcid}`}
                        //     &nbsp;
                        // </div>
                        <BlueGradientButton
                            disabled={updating}
                            className="px-5 w-[40%] mx-auto"
                            type="submit"
                            onClick={() =>
                                window.open(`${process.env.REACT_APP_GATEWAY_IPFS + '/' + previewcid}`, '_blank')
                            }
                        >
                            See File
                        </BlueGradientButton>
                    )}
                    {
                        <div className="flex flex-col gap-5 items-center">
                            <div className="flex h-[300px] rounded-md shadow-md w-96 justify-between items-center">
                                {imgLoading ? (
                                    <div className="flex justify-center items-center w-[100%]">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    cid && (
                                        <img
                                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${cid}`}
                                            className="max-h-[300px] min-h-[250px] max-w-[350px] object-contain "
                                            onLoad={() => setImgLoading(false)}
                                            alt="premintnft"
                                        />
                                    )
                                )}
                            </div>
                            <BlueGradientButton
                                disabled={updating}
                                className="px-12"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {updating ? 'Submitting' : 'Submit'}
                            </BlueGradientButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CreateNewCertificate;
