import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import useFetchPremintData from '../../../hooks/events/useFetchPremintData';
import AssetPulse from '../../../components/common/AssetPulse';

function CertificateCard({ event, membership }) {
    const { premintContent, premintMetaData } = useFetchPremintData(event.premintId);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div
            className="flex gap-3 text-slate-700 dark:text-slate-50 flex-col rounded-md dark-border no-underline hover:scale-105 transition-all light-border p-3 shadow-md"
            key={event.eventId}
        >
            {isLoading && <AssetPulse />}
            <div className="rounded-md w-full flex justify-center">
                <img
                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                    className={`h-64 object-contain rounded-md ${isLoading ? 'hidden' : ''}`}
                    alt="premint nft"
                    onLoad={handleImageLoad}
                />
            </div>
            <div
                className="flex justify-center"
                onClick={() => {
                    navigate(
                        `/${membership ? 'membership' : 'certificate'}/details/${event.premintId}/${
                            event.category
                        }?certId=${event.certId}`
                    );
                }}
            >
                <div className="flex border border-gray-100 rounded-lg p-2 cursor-pointer">
                    View {membership ? 'Membership NFT' : 'Certificate'}
                </div>
            </div>
            <div className="break-words max-h-9 text-ellipsis overflow-hidden text-xs font-semibold">
                {event?.description?.length > 100 ? (
                    <span>
                        {parse(event?.description?.slice(0, 100))}{' '}
                        <Link to={`/events/event-details/${event.eventId}`} className="no-underline">
                            ... read more
                        </Link>
                    </span>
                ) : (
                    <span>{parse(event?.description || '')}</span>
                )}
            </div>
        </div>
    );
}

export default CertificateCard;
