import ModalOverlay from '../common/ModalOverlay';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { nanoid } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const TextNFTPopup = (props) => {
    const parsedMetaData = JSON.parse(props.data);
    parsedMetaData.status = 'Submitted';
    const parsedProperties = parsedMetaData.properties;
    delete parsedMetaData.properties;
    const navigate = useNavigate();

    return (
        <ModalOverlay>
            <div className="dark:text-gray-300 text-2xl font-bold text-gray-600 border-b pb-5 border-b-gray-300 dark:border-b-gray-600">
                Your NFT detail
            </div>
            <div className="flex flex-col w-full overflow-hidden text-sm gap-1">
                {Object.keys(parsedMetaData)?.map((k) => (
                    <div className={'flex gap-2'} key={nanoid()}>
                        <div>{k}:</div>
                        {k === 'description' ? (
                            <div className={'ql-editor'} dangerouslySetInnerHTML={{ __html: parsedMetaData[k] }} />
                        ) : (
                            <div>{parsedMetaData[k]}</div>
                        )}
                    </div>
                ))}
                {parsedProperties?.map((k) => (
                    <div key={nanoid()}>
                        {k.key}: {k.value}
                    </div>
                ))}
            </div>
            <BlueGradientButton onClick={() => navigate('/mint')} className={'w-[50%] mx-auto'}>
                Close
            </BlueGradientButton>
        </ModalOverlay>
    );
};

export default TextNFTPopup;
