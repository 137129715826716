import React, { useCallback, useEffect, useRef, useState } from 'react'
import useAuthorizedHttp from '../use-authorized-http';
import { useSelector } from 'react-redux';
import { config } from 'process';
import axios from 'axios';

function usePremint() {
    const makeAuthorizedRequest = useAuthorizedHttp()
    const [premintData,setPremintData] = useState([]);
    const currentPageRef = useRef(0)
    const allAssetsRef = useRef([]);
    const [showMore,setShowMore] = useState(false);
    const appCtx = useSelector(state => state.app)

    const loadPremintAssets = useCallback(()=>{
            config ={
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint?page=${currentPageRef.current}&size=100&sort=id`,
                headers:{
                    'Content-Type': 'application/json',
                    'X-Auth-Token': appCtx.authToken,
                }
            }

            axios(config)
            .then(response =>{
                setShowMore(currentPageRef.current < response.data.totalPages )
                setPremintData(prev => [...prev,...response.data.content])
                
            })

            currentPageRef.current ++
    },[])

    useEffect(()=>{
      loadPremintAssets();
    },[])

    useEffect(()=>{
        allAssetsRef.current = premintData.map(data => data.id);
    },[premintData])
  return (
    {premintData,showMore,loadPremintAssets,allAssetsRef}
  )
}

export default usePremint