import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function useFetchBlogPost() {
    const { id } = useParams();
    const [loading,setLoading] = useState(true);
    const location = useLocation();
    const [showSharePopUp,setShowPopUp] = useState(false)
    const [blogDetail, setBlogDetail] = useState({});
    useEffect(() => {
        if (id) {
            let config = {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/blog/post/${id}/detail`,
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'X-App-Token': process.env.REACT_APP_APP_TOKEN
                }
            };
            axios(config)
                .then((response) => {
                    setBlogDetail(response?.data);
                })
                .catch((rej) => toast.error('post creation failed '))
                .finally(() => setLoading(false))
        }
    }, [id]);

    const sharePost = useCallback(()=>{
        navigator.clipboard.writeText(window.location.href)
        toast.success("post shareable link copied to clipboard ")
    },[])

  return {
        blogDetail,
        loading,
        sharePost,
        showSharePopUp,
        setShowPopUp,
        id
  }
}

export default useFetchBlogPost