import React from 'react'
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar'
import Footer from '../components/common/Footer'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import {Helmet} from "react-helmet";


function BlogPage() {
    const appCtx = useSelector(state =>state.app)
  return (
      <div className={` landing-bg  min-h-screen ${appCtx.isDarkMode ? 'dark' : ''} flex  flex-col justify-between`}>
          <Helmet>
              <title>NFTVerse Blog</title>
              <meta name="description" content="Featuring NFTVerse blog" data-react-helmet="true"/>
          </Helmet>
          <UnauthenticatedNavbar />
          <div className="mt-20  dark:text-slate-200">
              <Outlet />
          </div>
          <Footer />
      </div>
  );
}

export default BlogPage