import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';

import styles from './AbiModal.module.css';

function AbiModal({ openModal, setOpenModal, collection }) {
    function closeModal() {
        setOpenModal(false);
    }

    const parsedABI = JSON.parse(collection?.abi || '{}');

    return (
        <Modal
            isOpen={openModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            ariaHideApp={false}
            className={`${styles.popup} w-[50%] h-[70%] px-10 py-5  overflow-auto`}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.00)'
                }
            }}
        >
            <div className="w-full flex justify-end mr-4 pt-2">
                <button className="font-medium text-2xl flex items-center leading-10 mr-2 mt-2" onClick={closeModal}>
                    X
                </button>
            </div>
            <div className="pb-10 px-10">
                <pre>{JSON.stringify(parsedABI, null, 2)}</pre>
            </div>
        </Modal>
    );
}

export default AbiModal;
