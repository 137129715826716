import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useFetchCertificate from '../../../hooks/events/useFetchCertificate';
import { FilteredCertificate } from './FilteredCertificate';

export const ShowCollectionCertificates = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { certificateList, isFound, loading } = useFetchCertificate(id || '');
    return (
        <>
            <div className='text-[25px] font-bold mb-[40px]'>Certificates</div>
            <FilteredCertificate
                certificateList={certificateList}
                isFound={isFound}
                loading={loading}
                navigate={navigate}
            />
        </>
    )
}
