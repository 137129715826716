import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useFetchCertificate from '../../../hooks/events/useFetchCertificate';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import DivContainer from '../../../ui/DivContainer';
import { FilteredCertificate } from './FilteredCertificate';

export const CertificateHome = () => {
    const navigate = useNavigate();
    const appCtx = useSelector((state) => state.app);
    const [selectedCategory, setSelectedCategory] = useState('');
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [collection, setCollection] = useState([]);
    const { certificateList, isFound, loading } = useFetchCertificate(selectedCategory || '');

    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    data.content.forEach((d) => {
                        setCollection((prev) => [
                            ...prev,
                            {
                                id: d.collectionId,
                                text: d.name
                            }
                        ]);
                    });
                }
            ),
        [makeAuthorizedRequest, appCtx.marketplaceId]
    );
    useEffect(() => {
        appCtx.marketplaceId && loadCollections();
    }, [appCtx.marketplaceId, loadCollections]);
    return (
        <>
            <DivContainer className="flex justify-between items-center  rounded-md mb-[20px]">
                <span className="text-lg font-bold">My Certificates</span>
                <BlueGradientButton onClick={() => { navigate('/certificate/create') }} className="px-5  md:px-8">
                    Create Certificate
                </BlueGradientButton>
            </DivContainer>
            <div className='mb-[20px]'>
                {collection?.length > 0 && (
                    <select
                        className="p-3 rounded-md text-gray-700"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value={''} key={''}>
                            All
                        </option>
                        {collection?.map((collection) => {
                            return (
                                <option value={collection?.text} key={collection.id}>
                                    {collection?.text}
                                </option>
                            );
                        })}
                    </select>
                )}
            </div>
            <FilteredCertificate
                certificateList={certificateList}
                isFound={isFound}
                loading={loading}
                navigate={navigate}
            />
        </>
    )
}
