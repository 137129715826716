import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'
import DivContainer from '../../ui/DivContainer';

export const ClaimList = ({claimers,dark,className}) => {
  return (
    <DivContainer className={`${className?className:'max-h-96 overflow-y-scroll'}`}>
    <Table>
        <TableHead>
            <TableRow>
                <TableCell sx={{ color: dark && 'white' }}>Sr no.</TableCell>
                <TableCell sx={{ color: dark && 'white' }}>Name</TableCell>
                <TableCell sx={{ color: dark && 'white' }}>Email</TableCell>
                <TableCell sx={{ color: dark && 'white' }}>claimed</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {claimers?.map((user, index) => {
                return (
                    <TableRow key={user['Email']}>
                        <TableCell sx={{ color: dark && 'white' }}>
                            {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: dark && 'white' }}>
                            <span>{user.name}</span>
                        </TableCell>
                        <TableCell sx={{ color: dark && 'white', wordBreak: "break-word" }}>
                            {user.email}
                        </TableCell>
                        <TableCell sx={{ color: dark && 'white', wordBreak: "break-word" }}>
                            {user.claimed?'yes':'no'}
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    </Table>
</DivContainer>
  )
}
