import { CloseRounded } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalOverlay from './ModalOverlay';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function SaleFailModal({closeModal,marketplaceId,nftId}) { 
    // const data = {
    //     standard:"arc69",
    //     collection: selectedCollectionItem.text,
    //     name:title,
    //     description,
    //     properties: objectProperties,
    //     mimeType: `${props.type}/${props.extension}`,
    //     image:`ipfs://${displayImageUrl || props.ipfsHash }`,
    //     cid:props.ipfsHash,
    //     media_url:`ipfs://${displayImageUrl}`
    // } 
    const navigate = useNavigate()
    const appCtx = useSelector((state) => state.app);
    const handleRetry = useCallback(()=>{
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/nft/${nftId}/mint/retry`,
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": appCtx.authToken,
            }
        };
        axios(config)
        .then((res)=> {toast.success("Process completed ")
            navigate("/asset")
        })
        .catch((err)=> {toast.error("Could not be completed, Please try again") 
        })
    },[marketplaceId,nftId])
    
    return (
        <ModalOverlay >
            <div className="flex flex-col gap-5 items-center">
                {/* <div className={'flex w-full justify-end'}>
                    <Link to="/asset"  className={'text-rose-500'} >
                        <CloseRounded />
                    </Link>
                </div> */}
                <div className='flex w-full justify-end'><button onClick={()=>closeModal(false)}>X</button></div>
                <div className='flex flex-col items-center gap-3'>
                    <div className="font-bold text-red-500">OOPS!!</div>
                    <div>Your NFT miniting could not be completed</div>
                </div>
                <div className='flex gap-10'>
                    <div className='flex shadow-md flex-col rounded-md overflow-hidden gap-3'>
                        <img src="/images/failedsale.png"  className='w-72 h-48 object-cover'/>
                        <div className='justify-center flex'></div>
                        <button onClick={handleRetry} className='text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4'>Try again</button>
                    </div>
                </div>
            </div>
        </ModalOverlay>
    );
}

export default SaleFailModal;