import { ContactPhone, KeyboardArrowRightRounded, LightMode, LogoutRounded, SettingsRounded } from '@mui/icons-material';
import { useCallback ,useState } from 'react';
import { appActions } from '../../context/app-slice';
import { useDispatch } from 'react-redux';
import ContactUsModal from './ContactUseModal';
import { useSelector } from 'react-redux';
import React from "react"


const NavbarDropdown = (props) => {
    const appCtx = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const changeMode = useCallback(()=>{
        dispatch(appActions.toggleDarkMode());
    },[]
    )
    const openInNewTab = url => {
        window.open(url);
      };
    return (
        <div
            className={`${
                !props.visible && 'hidden'
            } w-[90%] sm:w-[400px] h-fit rounded absolute right-5 top-[150px] sm:top-[75px] dark:bg-gray-700 bg-slate-50 shadow-lg z-50 flex flex-col`}
        >
            <button
                onClick={() => openInNewTab('https://developer.onnftverse.com/')}
                className={
                    'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
                }
            >
                <div className={'flex gap-5'}>
                    <SettingsRounded />
                    <div>Developer Doc</div>
                </div>
                <div>
                    <KeyboardArrowRightRounded />
                </div>
            </button>
            <button
                onClick={() => openInNewTab('https://developer.onnftverse.com/')}
                className={
                    'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
                }
            >
                <div className={'flex gap-5'}>
                    <SettingsRounded />
                    <div>API Keys</div>
                </div>
                <div>
                    <KeyboardArrowRightRounded />
                </div>
            </button>
            <button
                onClick={props.showResetPassword}
                className={
                    'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
                }
            >
                <div className={'flex gap-5'}>
                    <SettingsRounded />
                    <div>Reset Password</div>
                </div>
                <div>
                    <KeyboardArrowRightRounded />
                </div>
            </button>
            <button
                onClick={props.showUpdateProfile}
                className={
                    'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
                }
            >
                <div className={'flex gap-5'}>
                    <SettingsRounded />
                    <div>Update Profile</div>
                </div>
                <div>
                    <KeyboardArrowRightRounded />
                </div>
            </button>
            
            <button 
            className={
                'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
            }
             onClick={changeMode}>
                <div className='flex gap-5'>
                <LightMode/>
                <div>Switch to {appCtx.isDarkMode ? <>light</> :<>dark</>} mode</div>
                </div>
            </button>
            <button 
            className={
                'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
            }
             onClick={ () =>props.setContactUsVisible(true) }>
                <div className='flex gap-5'>
                    <ContactPhone/>
                    <div>Contact US</div>

                </div>
            </button>
            <button
                onClick={props.onLogoutClick}
                className={
                    'text-left border-b p-4 dark:border-gray-600 border-gray-200 flex justify-between hover:bg-gray-200 dark:hover:bg-gray-600 transition-all ease-out duration-300'
                }
            >
                <div className={'flex gap-5'}>
                    <LogoutRounded />
                    <div>Log Out</div>
                </div>
                <div>
                    <KeyboardArrowRightRounded />
                </div>
            </button>
            
        </div>
    );
};

export default NavbarDropdown;
