import ModalOverlay from '../common/ModalOverlay';
import Input from '../../ui/Input';
import { useCallback, useEffect, useState } from 'react';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { CloseRounded } from '@mui/icons-material';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { appActions } from '../../context/app-slice';

const UpdateProfileModal = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const userId = useSelector((state) => state.app.userDetails.userId);
    const dispatch = useDispatch();

    const makeAuthorizedRequest = useAuthorizedHttp();

    useEffect(() => {
        setLoading(true);
        makeAuthorizedRequest(
            {
                url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/info/${userId}`
            },
            (data) => {
                data.email && setEmail(data.email);
                data.mobile && setMobile(data.mobile);
                data.firstName && setFirstName(data.firstName);
                data.lastName && setLastName(data.lastName);
            },
            () => {},
            () => setLoading(false)
        );
    }, [makeAuthorizedRequest, userId]);

    const handleUpdateClick = useCallback(() => {
        toast.promise(
            () =>
                makeAuthorizedRequest(
                    {
                        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/profile/update`,
                        method: 'put',
                        data: {
                            firstName,
                            lastName,
                            email,
                            mobile,
                            userId
                        }
                    },
                    (data) => {
                        dispatch(
                            appActions.updateUserDetails({
                                name: firstName + ' ' + lastName,
                                email: data.email
                            })
                        );
                        props.hideUpdateProfile();
                    },
                    () => {},
                    () => {
                        setLoading(false);
                    }
                ),
            {
                pending: 'Updating your account...',
                success: 'Successfully updated your account!',
                error: "Couldn't update your account"
            }
        );
    }, [dispatch, email, firstName, lastName, makeAuthorizedRequest, mobile, props, userId]);

    return (
        <ModalOverlay>
            <div className={'flex justify-between border-b pb-5 border-b-gray-300 dark:border-b-gray-600'}>
                <div className="dark:text-gray-300 text-2xl font-bold text-gray-600">Update your profile</div>
                <button className={'text-rose-500'} onClick={props.hideUpdateProfile}>
                    <CloseRounded />
                </button>
            </div>
            <Input
                value={firstName}
                disabled={loading}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="First name"
            />
            <Input
                disabled={loading}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Last name"
            />
            <Input
                disabled={loading}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                type="text"
                placeholder="Phone number"
            />
            <Input
                disabled={loading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Email"
            />
            <BlueGradientButton disabled={loading} onClick={handleUpdateClick} className={'w-full'}>
                Update
            </BlueGradientButton>
        </ModalOverlay>
    );
};

export default UpdateProfileModal;
