import { FiberManualRecord } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import urlForTheString from '../../misc/urlForTheString'

function TutorialCard({tutorial}) {
  return (
    <div className='flex flex-col  p-5 rounded-md border shadow-md gap-5 bg-white'>
        <div className='font-bold text-2xl'>
                {tutorial?.category}
        </div>
        {
            tutorial?.questions?.map((question ,i )=>{
                return(
                  <div className='flex gap-2 text-sm' key={i}>
                        <span>
                        <FiberManualRecord fontSize='small'/>
                        </span>
                        <Link to={`${question?.postId}/${urlForTheString(question?.title)}`} className=' text-start text-black no-underline hover:underline transition-all'>
                            {
                                question.title
                            }
                        </Link>
                    </div>
                )
            })
        }
        
    </div>
  )
}

export default TutorialCard