import { MoreVert } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Popover } from 'react-tiny-popover';
import React, { useCallback, useState } from 'react';
import { Button } from 'react-scroll';
import BlueGradientButton from '../../BlueGradientButton';
import { useNavigate } from 'react-router-dom';
import Property from '../atoms/Property';

function PremintDataContainer({ metaData, collection, id,allAssetsRef ,options ,index,setOptions }) {
    const [openPopover, setOpenPopver] = useState(false);
    const navigate = useNavigate();

    const handleOptionChange = (event) => {
        const updatedOptions = [...options];
        if (event.target.checked) {
          updatedOptions.push(+event.target.value);
        } else {
          updatedOptions.splice(updatedOptions.indexOf(+event.target.value), 1);
        }
        setOptions(updatedOptions);
      }

    return (
        <TableRow className="flex justify-between p-4 items-center rounded-md border">
            <TableCell>
                <div className="flex gap-2 items-center">
                    <input
                        value={id}
                        checked={options.includes(id)}
                        onChange={handleOptionChange}
                        className="h-5 w-5 cursor-pointer"
                        type="checkbox"
                    />
                    <span>{index + 1}</span>
                </div>
            </TableCell>
            <TableCell>
                <div className="w-16 h-16 rounded-md overflow-hidden">
                    <img
                        src={`https://drive.google.com/uc?export=view&id=${metaData['Asset Image']?.split('/')[5]}`}
                        className="object-contain"
                    />
                </div>
            </TableCell>
            <TableCell className="font-bold">{metaData['Asset Name']}</TableCell>
            <TableCell className="max-w-md">{metaData['Asset Descriptions']}</TableCell>
            <TableCell>
                <div className="w-32  break-words"><Property properties = {((metaData['Asset Property']))} /></div>
            </TableCell>
            <TableCell>{collection}</TableCell>
            <TableCell>
                <Popover
                    isOpen={openPopover}
                    onClickOutside={() => setOpenPopver(!openPopover)}
                    positions={['left']}
                    content={
                        <div className="flex flex-col px-3 shadow-md rounded-lg py-3 gap-3 bg-white">
                            <BlueGradientButton
                                className="py-0 px-8"
                                onClick={() => navigate(`/nft-launch-pad/update-premint/${id}`)}
                            >
                                edit
                            </BlueGradientButton>
                            <BlueGradientButton className="py-0">delete</BlueGradientButton>
                        </div>
                    }
                >
                    <button onClick={() => setOpenPopver(!openPopover)}>
                        <MoreVert />
                    </button>
                </Popover>
            </TableCell>
        </TableRow>
    );
}

export default PremintDataContainer;
