import React, { useEffect, useState, useCallback } from 'react';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TuneIcon from '@mui/icons-material/Tune';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import AssetPulse from '../components/common/AssetPulse';
import CollectionItem from '../components/collection-item/CollectionItem';
import CreateCollectionModal from '../components/mint-asset/CreateCollectionModal';
import { Link, useNavigate } from 'react-router-dom';

function MyCollections() {
    const navigate = useNavigate();
    const makeRequest = useAuthorizedHttp();
    const [collection, setCollection] = useState([]);
    const [loading, setLoading] = useState(true);
    const [marketplaceDomain, setMarketplaceDomain] = useState();
    const [marketplaceId, setMarketplaceId] = useState();
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);
    const [collectionCalculation, setCollectionCalculation] = useState(false);
    const [iscollectionnull, setiscollectionnull] = useState(false);

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
                setMarketplaceDomain(data[0].domain);
            },
            () => {},
            () => {
                setLoading(false);
            }
        );
    }, [makeRequest]);

    useEffect(() => {
        marketplaceId &&
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/list`
                },
                (data) => {
                    // setCollection([{ collectionId: 0.5, name: 'All' }]);
                    setCollection([]);
                    setCollection((prev) => [...prev, ...data.content]);
                }
            );
    }, [makeAuthorizedRequest, marketplaceId]);

    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    setCollection((prev) => [...prev, ...data.content]);
                }
            ),
        [makeAuthorizedRequest, marketplaceId]
    );

    useEffect(() => {
        marketplaceId && loadCollections();
    }, [loadCollections, makeAuthorizedRequest, marketplaceId]);

    useEffect(() => {
        if (!loading) {
            setCollectionCalculation(true);
            collection.length === 0 ? setiscollectionnull(true) : setiscollectionnull(false);
            setCollectionCalculation(false);
        }
    }, [collection]);

    return (
        <div className="flex flex-col gap-14">
            {marketplaceId && collectionModalOpen && (
                <CreateCollectionModal
                    marketplaceId={marketplaceId}
                    onCreateSuccesssful={() => loadCollections()}
                    onCloseCreateCollection={() => setCollectionModalOpen(false)}
                />
            )}
            <div className="flex justify-between items-center">
                <div className="font-bold text-lg md:text-2xl">My Collection</div>
                {/* <button disabled={true}><SwapVertIcon/>  Sort</button>
                <button disabled={true}> <TuneIcon/>  Filter</button> */}
                <button
                    className=" px-3 md:px-12 bg-secBlue py-2 md:py-4 rounded-md text-white"
                    onClick={() => setCollectionModalOpen(true)}
                >
                    {' '}
                    Create Collecton
                </button>
            </div>
            <div>
                {loading && (
                    <div className="grid grid-cols-4 gap-5">
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                    </div>
                )}
                {!loading && collection.length != 0 && (
                    <div className="flex flex-start gap-8 flex-wrap">
                        {collection.map((item) => {
                            return (
                                <CollectionItem collection={item} key={item?.collectionId} domain={marketplaceDomain} />
                            );
                        })}
                    </div>
                )}
                {!loading && !collectionCalculation && iscollectionnull && (
                    <div>Hi, you don't have any collection to show , Create collection to get it featured here.</div>
                )}
            </div>
        </div>
    );
}

export default MyCollections;
