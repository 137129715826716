import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser'

function EventCard({ event }) {

    return (
        <Link to={`/events/event-details/${event.eventId}`} className="flex gap-3 text-slate-700 dark:text-slate-50 flex-col rounded-md dark-border no-underline  hover:scale-105 transition-all light-border p-3 shadow-md" key={event.eventId}>
            <div className='rounded-md w-full flex justify-center '>
            <img src={event?.coverImage} className="h-64 object-contain rounded-md" />
            </div>
            
            <div className='flex justify-between'>
            <span className='font-bold'>{event?.eventName || event?.category}</span>
            {/* <Link to={`/event-details/${event.eventId}`}>View</Link> */}
            </div>
            
            <div className="break-words max-h-9 text-ellipsis overflow-hidden text-xs font-semibold">
            { event?.description?.length > 100 ? <span>{parse(event?.description?.slice(0,100))} <Link to={`/events/event-details/${event.eventId}`} className="no-underline">... read more</Link></span> : <span>{parse(event?.description || "")}</span> }
      </div>
      <div className="flex justify-between text-xs font-bold">
          <span>
            Start: {new Date(event?.startDate).toDateString()}
          </span>
          <span>
            End:  {new Date(event?.endDate).toDateString()}
          </span>
      </div>
        </Link>
    );
}

export default EventCard;
