import React, { useState, useEffect, useCallback } from 'react';
import CreateCategoryModal from '../components/common/CreateCategoryModal';
import BlueGradientButton from '../ui/BlueGradientButton';
import CategoryItem from '../ui/CategoryItem';
import SuggestedCat from '../ui/SuggestedCat';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { Category } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import AssetPulse from '../components/common/AssetPulse';
import axios from 'axios';
import { toast } from 'react-toastify';
import Input from '../ui/Input';

function MyCategories() {
    const [openCatModal, setOpenCatModal] = useState(false);
    const [categories, setCatrgories] = useState([]);
    const [suggestedCats, setSuggestedCats] = useState([]);
    const [marketplaceId, setMarketplaceId] = useState(null);
    const appCtx = useSelector((state) => state.app);
    const makeAuthorizedRequest = useAuthorizedHttp();
    const makeRequest = useAuthorizedHttp();
    const [searchKey, setSearchKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedSuggestions, setSelectedSuggestions] = useState([]);

    useEffect(() => {
        setLoading(true);
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
                setLoading(false);
            },
            () => {},
            () => {}
        );
    }, [makeRequest]);

    useEffect(() => {
        setLoading(true);
        marketplaceId &&
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${marketplaceId}/category/list`
                },
                (data) => {
                    // setCollection([{ collectionId: 0.5, name: 'All' }]);
                    setCatrgories([]);
                    setCatrgories((prev) => [...prev, ...data.content]);
                    setLoading(false);
                },
                ()=>{}
            );
    }, [makeAuthorizedRequest, marketplaceId]);
    const loadSuggestions = useCallback(()=>{
        // setLoading(true);
        marketplaceId &&
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/category/master/list`
                },
                (data) => {
                    setSuggestedCats([]);
                    setSuggestedCats((prev) => [...prev, ...data.content.splice(0, 10)]);
                },
                ()=>{}
            );
    },[makeAuthorizedRequest,loading])
    useEffect(() => {
        if (categories?.length === 0) {
           loadSuggestions();
        }
    }, [ categories]);

    const loadCategories = useCallback(() => {
        setLoading(true);
        makeAuthorizedRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${marketplaceId}/category/list`
            },
            (data) => {
                setCatrgories([]);
                setCatrgories((prev) => [...prev, ...data.content]);
                setLoading(false);
            },
            ()=>{}
        );
    }, [makeAuthorizedRequest, marketplaceId]);

    const searchCategory = useCallback(
        (e) => {
            setSearchKey(e.target.value);
        },
        [searchKey]
    );
    useEffect(() => {
        if (searchKey !== '' && searchKey?.length > 2) {
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/category/${searchKey}/master/search`
                },
                (data) => {
                    
                    setSuggestedCats([]);
                    setSuggestedCats((prev) => [...prev, ...data.content]);
                
                },
                ()=>{}
            );
        }
        else{
                loadSuggestions()
        }
    }, [searchKey]);

    const addSuggestedCategories = useCallback(() => {

                let config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/category/save`,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken,
                        'X-App-Token': process.env.REACT_APP_APP_TOKEN
                    },
                    data: selectedSuggestions?.map((item) => {
                        return { categoryId: item.categoryId, marketplaceId };
                    })
                };
                axios(config)
                    .then((res) => {
                        loadCategories();
                        toast.success('Category added successfully');
                    })
                    .catch((err) => {
                        toast.error('Could not Completed');
                    });
           

           
    }, [selectedSuggestions]);

    useEffect(() => {
        marketplaceId && loadCategories();
    }, [loadCategories, makeAuthorizedRequest, marketplaceId]);

    return !loading ? (
        <div className="flex flex-col gap-5">
            {categories?.length > 0 ? (
                <>
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-lg md:text-2xl">Category</div>
                        <BlueGradientButton onClick={() => setOpenCatModal(true)} className="py-2  md:px-12">
                            Add Category
                        </BlueGradientButton>
                    </div>
                    <div></div>
                    <div className="flex justify-center md:justify-start gap-[5%] gap-y-14 flex-wrap ">
                        {/* <CategoryItem />
                      <CategoryItem />
                      <CategoryItem />
                      <CategoryItem />
                      <CategoryItem />
                      <CategoryItem />
                      <CategoryItem /> */}
                        {categories?.map((category) => {
                            return (
                                <CategoryItem
                                    category={category?.categoryMaster}
                                    marketplaceId={category?.marketplaceId}
                                    loadCategories={loadCategories}
                                />
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="flex flex-col items-center gap-8">
                    <div className="font-semibold">
                        Hi! You don't have any category right now, you can select the category from the suggetsed domain
                        or can add new category to your marketplace
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex justify-center text-lg font-bold">Suggested Categories</div>
                        <div className="flex justify-center">

                            <Input type="text" value={searchKey} onChange={(e) => searchCategory(e)} placeholder="Search category here..." />
                        </div>
                        <div className="flex justify-start flex-wrap gap-8">
                            {console.log(selectedSuggestions)}
                            {
                            suggestedCats[0] !=="" ?
                              suggestedCats?.map((cat) => {
                                return (
                                    <SuggestedCat
                                        cat={cat}
                                        key={cat.categoryId}
                                        selectedSuggestions={selectedSuggestions}
                                        setSelectedSuggestions={setSelectedSuggestions}
                                    />
                                );
                            }):<div>Nothing found</div>}
                        </div>
                        <div className="flex items-center gap-4  justify-center">
                            <div>Your favourite category is missing? No worries. Add it from here.</div>
                            <BlueGradientButton className="py-1" onClick={() => setOpenCatModal(true)}>
                                Add
                            </BlueGradientButton>
                        </div>
                        <div className="flex justify-center">
                            <BlueGradientButton
                                className="px-12"
                                disabled={selectedSuggestions?.length === 0}
                                onClick={addSuggestedCategories}
                            >
                                Save
                            </BlueGradientButton>
                        </div>
                    </div>
                </div>
            )}

            {openCatModal && (
                <CreateCategoryModal
                    setOpenCatModal={setOpenCatModal}
                    marketplaceId={marketplaceId}
                    searchCategory={searchCategory}
                    loadCategories={loadCategories}
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                    suggestedCats={suggestedCats}
                />
            )}
        </div>
    ) : (
        <div className="grid grid-cols-4 gap-10 col-span-1 md:col-span-2 lg:col-span-4">
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
        </div>
    );
}

export default MyCategories;
