/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useRef, useState } from 'react';
import { isEmailValid, isPhoneValid } from '../../misc/field-validation';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../../assets/landing/createbg.svg';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useHttp from '../../hooks/use-http';
import { toast } from 'react-toastify';
import Input from '../../ui/Input';
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';
import React from "react"
import LandingButton from '../../ui/buttons/LandingButton';
import ModalOverlay from '../common/ModalOverlay';
import { Cancel } from '@mui/icons-material';

const CreateAccountModal = ({}) => {
    const [uploading, setUploading] = useState(false);
    const [marketplace, setMarketplace] = useState("");
    const [cred, setCred] = useState("");
    const marketplaceRef = useRef("");
    const emailRef = useRef("");
    const [searchParams, setSearchParams] = useSearchParams()
    const [marketplaceSuccessMessage, setMarketplaceSuccessMessage] = useState('');
    // console.log(searchParams.get("email"))
    // console.log(searchParams.get("marketplace"))

    const navigate = useNavigate();
    const makeRequest = useHttp();

    const dispatch = useDispatch();
    
    const handleCreateClicked = useCallback(() => {
        let flag = true;
        let data = null;
        if (!marketplace && !marketplaceRef.current) {
            flag = false;
            toast.error("Marketplace name can't be empty!");
        }
        if (isEmailValid(cred || emailRef.current)) {
            data = {
                email: cred ||emailRef.current,
                marketplace:marketplace || marketplaceRef.current
            };
        } else if (isPhoneValid(cred)) {
            data = {
                mobile: cred,
                marketplace
            };
        } else {
            flag = false;
            toast.error('Either mobile number or email needed');
        }
        if (flag) {
            dispatch(appActions.setMarketplaceEmail(cred))
            setUploading(true);
            toast.promise(
                () =>
                    makeRequest(
                        {
                            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/save`,
                            method: 'POST',
                            headers: {
                                'X-App-Token': process.env.REACT_APP_APP_TOKEN,
                                'Content-Type': 'application/json'
                            },
                            data
                        },
                        (data) => {
                            if (data.authToken) {
                                if(process.env.REACT_APP_ENVIRONMENT === "prod"){
                                    window.location.replace(`https://testnet.onnftverse.com/redirect/?email=${cred}&marketplace=${marketplace}`)
                                }
                                else{
                                setMarketplaceSuccessMessage('Successfully created your marketplace!');
                                dispatch(appActions.updateUserDetails({ userId: data.userId }));
                                dispatch(appActions.updateAuthToken(data.authToken));
                                dispatch(appActions.toggleNewSignup());
                                dispatch(appActions.togglePasswordCreated());
                                dispatch(appActions.login());
                                navigate("/")
                                }
                                
                                
                            } else {
                                setMarketplaceSuccessMessage('Account already exists. Please login.');
                                navigate('/login');
                            }
                        },
                        (data) => {
                            console.log(data);
                        },
                        () => {
                            setUploading(false);
                        }
                    ),
                {
                    pending: 'Creating your marketplace',
                    success: marketplaceSuccessMessage,
                    error: 'Something went wrong!'
                }
            );
        }
    }, [cred, dispatch, makeRequest, marketplace, marketplaceSuccessMessage, navigate]);

    useEffect(()=>{
        if(searchParams.get("email") && searchParams.get("marketplace")){
            setCred(searchParams.get("email"))
            setMarketplace(searchParams.get("marketplace"))
            emailRef.current = searchParams.get("email")
            marketplaceRef.current = searchParams.get("marketplace")
            handleCreateClicked();

        }
    },[searchParams])

    return (
        <ModalOverlay innerClass="!p-0 !rounded-md !border-sky-600  !overflow-y-hidden">
        <div
            className=" bg-fixed bg-cover bg-no-repeat text-slate-200 flex flex-col p-2 md:p-5 justify-center"
            style={{ backgroundImage: `url(${bg})` }}
        >
            <div className='flex justify-end'>
            <button onClick={() =>dispatch(appActions.setCreateAccountModal(false))}><Cancel/></button>
            </div>
            
            
                {/* <img src="/images/ringlight.png" alt="" className='absolute h-[50%] w-[100%]'/> */}
                
                    <div className='flex justify-center '>
                        <div className="flex flex-col gap-5">
                            <div className="text-slate-100 text-xl md:text-2xl font-bold">Create your Web 3.0 app</div>
                            <Input
                                disabled={uploading}
                                type="text"
                                className="bg-gradient-to-t placeholder:text-white from-[#925BEC] to-[#5514DF]"
                                placeholder="Web3 app name"
                                value={marketplace}
                                onChange={(e) => setMarketplace(e.target.value)}
                            />
                            <Input
                                disabled={uploading}
                                placeholder="Email"
                                className="bg-gradient-to-t placeholder:text-white from-[#925BEC] to-[#5514DF]"
                                type="text"
                                value={cred}
                                onChange={(e) => setCred(e.target.value)}
                            />
                            <div className='flex justify-center'>
                            <LandingButton disabled={uploading} className="px-28" onClick={handleCreateClicked}>
                                Create
                            </LandingButton>
                            </div>
                            <div className='flex justify-center'>
                                <span>Already have account ? <button className='text-blue-600' onClick={() =>{
                                    dispatch(appActions.setCreateAccountModal(false))
                                    navigate("/login")
                                }}>Login here</button></span>/.
                            </div>
                        </div>
                    </div>
               
        </div>
        </ModalOverlay>
    );
};

export default CreateAccountModal;
