import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { homeActions } from '../context/home-slice';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import getBestSize from '../misc/size-determination';
import ThreeDRenderer from '../components/common/ThreeDRenderer';
import React from "react"
import AssetInfo from '../ui/asset-detail/AssetInfo';
import Input from '../ui/Input';
import BlueGradientButton from '../ui/BlueGradientButton';

const AssetDetail = () => {
    const dispatch = useDispatch();
    const [metaData,setMetData] = useState(null);
    const [content,setContent] = useState(null);
    const [type,setType] = useState("");
    const navigate = useNavigate();
    const appCtx = useSelector(state => state.app)
    // const [asset, setAsset] = useState();
    // const type = new URL(window.location.href).searchParams.get('type');
    // const s3url = new URL(window.location.href).searchParams.get('s3url');
    // const title = new URL(window.location.href).searchParams.get('title');
    const [asset, setAsset] = useState();
    // const type = new URL(window.location.href).searchParams.get('type');
    const s3url = new URL(window.location.href).searchParams.get('s3url');
    const title = new URL(window.location.href).searchParams.get('title');
    const extension = new URL(window.location.href).searchParams.get('extension');


    const makeRequest = useAuthorizedHttp();

    const { nftId } = useParams();

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/nft/${nftId}/detail`
            },
            (res) => {
                console.log(res)
                setContent(res);
                setMetData(JSON.parse(res?.metaData));
                setType(JSON.parse(res?.metaData)?.mime_type?.split("/")[0])
            }
        );
    }, [nftId, makeRequest]);

    dispatch(homeActions.updateSelectedSidebarOption('My Assets'));

    return (
        <div className="flex flex-col gap-10">
            <div className="font-bold text-2xl">NFT details</div>
            <div className="flex gap-10">
                <span>NFT Interface</span>
                <span>{appCtx?.blockchain === 'ALGORAND' && 'ARC-0069'}</span>
            </div>
            <div className="flex flex-col-reverse md:flex-row w-full justify-between gap-y-6">
                <div className="flex flex-col w-full md:w-2/5 gap-7">
                    <AssetInfo
                        propName1="Asset Name"
                        val1={metaData?.name}
                        propName2="Collection"
                        val2={metaData?.collection}
                    />
                   
                  {metaData?.description &&  <div className="flex flex-col gap-3">
                        <span className="font-bold">Description</span>
                        <span className='p-3 border dark:dark-border light-border rounded-md'>{metaData?.description}</span>
                    </div>}
                    <div className='flex flex-col gap-2'>
                        <span className='font-bold'>Properties</span>
                        <div className='flex gap-3 flex-wrap gap-y-4'>
                        {metaData?.properties && Object?.keys(metaData?.properties).map(function (key) {
                            return <div value={key} className="flex flex-col gap-2 p-2 border dark:dark-border light-border rounded-md">
                            <span>{key}</span>
                              <span>{metaData?.properties[key]}</span>  
                            </div>;
                        })}
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        <span className="font-bold">Hash code</span>
                        {/* <input disabled={true} className="border p-2 rounded-md">{metaData?.media_url?.split('/')[2]}</input> */}
                        <Input
                        disabled={true}
                        value={metaData?.media_url?.split('/')[2] ? metaData?.media_url?.split('/')[2] :""}
                        />

                    </div>
                </div>

                <div className="flex flex-col w-full  md:w-1/2 items-center gap-4">
                    <div className="flex w-full justify-center">
                        {type === 'image' ? (
                            <img
                                className="w-full md:w-3/5 border rounded dark:border-gray-500 object-cover"
                                src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image?.split('/')[2]}`}
                                alt="NFT"
                            />
                        ) : type === 'video' ? (
                            <video
                                controls
                                autoPlay
                                className="w-full md:w-3/5 border rounded dark:border-gray-500 object-cover"
                                src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split('/')[2]}`}
                            />
                        ) :   ( metaData?.mime_type?.split('/')[0] === 'threeD' && metaData?.media_url === metaData?.image) ? (
                                // <ThreeDRenderer
                                //     className={`w-4/5  rounded object-contain`}
                                //     src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split('/')[2]}`}
                                // />
                                <ThreeDRenderer
                                    className={`h-[350px] border rounded dark:border-gray-500 object-cover`}
                                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split("/")[2]}`}
                                    type={metaData?.mime_type?.split("/")[1]}
                                />
                            ) : (
                        
                                <img
                                    className="w-4/5 object-contain"
                                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image?.split("/")[2]}`}
                                    alt="NFT"
                                />
                        )}
                    </div>
                    <div className='flex gap-4 '>
                        <BlueGradientButton
                        onClick={()=> navigate(`/salenft/${nftId}`) }
                        >
                            List for sale
                        </BlueGradientButton>
                    </div>
                </div>
            </div>
        </div>
        // <>
        //     <div className="text-2xl font-semibold mb-10">Your Asset</div>
        //     {asset && (
        //         <div className="flex flex-col justify-between border rounded dark:border-gray-500 hover:scale-105 transition-all ease-out duration-300 w-[50%] h-[500px] mx-auto">
        //             <div className="h-fit p-2 flex flex-col gap-2">
        //                 {type === 'image' ? (
        //                     <img
        //                         className="h-[350px] border rounded dark:border-gray-500 object-cover"
        //                         src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image?.split("/")[2]}`}
        //                         alt="NFT"
        //                     />
        //                 ) : type === 'video' ? (
        //                     <video
        //                         controls
        //                         autoPlay
        //                         className="h-[350px] border rounded dark:border-gray-500 object-cover"
        //                         src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split("/")[2]}`}
        //                     />
        //                 ) : (
        //                     <ThreeDRenderer
        //                         className={`h-[350px] border rounded dark:border-gray-500 object-cover`}
        //                         src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image?.split("/")[2]}`}
        //                     />
        //                 )}
        //                 <div className="flex overflow-hidden break-all text-sm">Hash: {metaData?.media_url?.split("/")[2]}</div>
        //                 <div className="flex overflow-hidden break-all text-sm">Name: {title}</div>
        //                 <div className="flex overflow-hidden break-all text-sm gap-2">
        //                     Status:{' '}
        //                     <div
        //                         className={`
        // 				${asset.status === 'SEALED' && `text-green-500`} ${asset.status === 'FAILED' && `text-rose-500`}`}
        //                     >
        //                         {asset.status}
        //                     </div>
        //                 </div>
        //                 <div>Size: {getBestSize(asset.pinSize)}</div>
        //             </div>
        //         </div>
        //     )}
        // </>
    );
};

export default AssetDetail;
