import ModalOverlay from '../common/ModalOverlay';
import { CloseRounded } from '@mui/icons-material';
import React from "react"
import InfoModal from '../common/InfoModal';

const ContactUsModal = (props) => {
    return (
        <ModalOverlay className={'text-xl'}>
            <div className="flex flex-col ">
                <div className='flex justify-end'>
                    <button onClick={props.onClose}>
                        <CloseRounded />
                    </button>
                </div>

                <InfoModal />
            </div>
        </ModalOverlay>
    );
};

export default ContactUsModal;
