import getBestSize from '../../misc/size-determination';
import { getAssetType } from '../../misc/asset-type';
import { Link, useNavigate } from 'react-router-dom';
import ThreeDRenderer from './ThreeDRenderer';
import { useCallback, useState,useEffect } from 'react';
import ThreeDViewOverlay from './ThreeDViewOverlay';
import React from "react"

const AssetItem = (props) => {
    const navigate = useNavigate();
    const assetType = getAssetType(props.asset.s3url);
    const [threeDViewOpen, setThree3ViewOpen] = useState(false);
    
    const toggleThreeDView = useCallback(() => {
        setThree3ViewOpen(!threeDViewOpen);
    }, [threeDViewOpen]);

    
       const extension =props?.asset?.s3url?.split(".")[props?.asset?.s3url?.split(".")?.length -1]

   
    
    

    return (
        <>
            {threeDViewOpen && (
                <ThreeDViewOverlay
                    url={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${props.asset.s3url}`}
                    onClose={toggleThreeDView}
                    type={extension}
                />
            )}
            <div className="flex flex-col justify-between border rounded dark:border-gray-500 hover:scale-105 transition-all ease-out duration-300">
                <div className="h-fit p-2 flex flex-col gap-2">
                    {assetType === 'image' ? (
                        <img
                            className="h-[200px] border rounded dark:border-gray-500 object-cover"
                            src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${props.asset.s3url}`}
                            alt="NFT"
                        />
                    ) : assetType === 'video' ? (
                        <video
                            controls
                            autoPlay ={false}
                            className="h-[200px] border rounded dark:border-gray-500 object-cover"
                            src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${props.asset.s3url}`}
                        />
                    ) : (
                        <button onClick={toggleThreeDView}>
                            <ThreeDRenderer
                                className={`h-[200px] border rounded dark:border-gray-500`}
                                src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${props.asset.s3url}`}
                                type={extension}
                            />
                        </button>
                    )}
                    {/* <div className='flex justify-between'>
                        <div className='flex flex-col'>
                            <span>Name</span>
                            <span className='font-bold'>{props.asset.name}</span>   
                        </div>
                        <div className='flex flex-col'>
                            <span>Collection</span>
                            <span className='font-bold'>{props.asset.collectionName}</span>   
                        </div>
                    </div> */}
                    <div className='flex justify-between'>
                        {/* <div className='flex flex-col'>
                            <span>Price</span>
                            <span className='font-bold'>{props.asset.price}</span>   
                        </div> */}
                        <div className='flex  gap-5 items-center px-1'>
                            <span className='font-bold'>Status :</span>
                            <span  className={` font-bold text-sm
						${props.asset.status === 'SEALED' && `text-green-500`} ${props.asset.status === 'SUBMITTED' && `text-gray-400`}`}>{props.asset.status===null?<div className='text-gray-400 font-bold '>UPLOADED</div>: props.asset.status}</span>   
                        </div>
                    </div>
                    {assetType ==="threeD" && <button onClick={toggleThreeDView} ><div className='flex justify-start text-secPurple'>View 3D</div></button>}
                    {props.asset.status === 'FAILED' || props.asset.status === null && extension   &&<Link to=
                                              {`/mint?ipfsHash=${props.asset?.ipfsHash}&s3url=${
                                                props.asset?.s3url
                                              }&type=${getAssetType(props.asset?.s3url)}&assetId=${props.asset?.assetId}&extension=${extension}`} className="no-underline">Retry</Link>
                                         
                                      } 

                    {/* <div className="flex overflow-hidden break-all text-sm">Hash: {props.asset.ipfsHash}</div>
                    <div className="flex overflow-hidden break-all text-sm gap-2">
                        Status:
                        <div
                            className={`
						${props.asset.status === 'SEALED' && `text-green-500`} ${props.asset.status === 'FAILED' && `text-rose-500`}`}
                        >
                            {props.asset.status}
                        </div>
                    </div>
                    <div>{getBestSize(props.asset.pinSize)}</div> */}
                </div>
                {/* <div>
                    {props.asset.minted ?  (
                        <button disabled={props.asset.status !== "SEALED"} className={`bg-green-500 text-slate-50 p-3 w-full rounded-b cursor-default ${props.asset.status !== "SEALED" && "bg-gray-500"}`}>
                            View NFT
                        </button>)
                    : (
                        <button
                            className="bg-secBlue text-slate-50 p-3 w-full rounded-b"
                            onClick={() =>
                                navigate(
                                    `/mint?ipfsHash=${props.asset.ipfsHash}&s3url=${
                                        props.asset.s3url
                                    }&type=${getAssetType(props.asset.s3url)}`
                                )
                            }
                        >
                            Mint NFT
                        </button>
                    )}
                </div> */}
            </div>
        </>
    );
};

export default AssetItem;
