import React from 'react'
import CreateAccountModal from '../components/landing/CreateAccountModal'

function Redirect() {
  return (
    <div className='landing-bg h-screen w-full'>
        <CreateAccountModal />
    </div>
  )
}

export default Redirect