import React from 'react'
import { useNavigate } from 'react-router-dom';
import AssetPulse from '../../../components/common/AssetPulse';
import ErrorDisplay from '../../../components/common/ErrorDisplay';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import DivContainer from '../../../ui/DivContainer';
import EmptyContent from '../../../ui/EmptyContent';
import CertificateCard from './CertificateCard';

export const FilteredCertificate = ({loading,isFound,certificateList,navigate,membership}) => {
  return (
    <>
    {loading ? (
                <div className="flex flex-col gap-8">
                    {/* <Skeleton sx={{ width: '100%' }} variant="rectangular" height={50} /> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                    </div>
                </div>
            ) : isFound ? (
                <>
                    {certificateList?.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
                            {certificateList?.map((event) => {
                                return <CertificateCard event={event} key={event.eventId} membership={membership}/>;
                            })}
                        </div>
                    ) : (
                        <DivContainer className="py-5">
                            <div className="flex flex-col gap-5 justify-between items-center">
                                <EmptyContent message="You don't have any previously created Certificates for this Category. Create one to get it appeared here" />

                                <BlueGradientButton
                                    onClick={() => membership? navigate('/membership/create'):navigate('/certificate/create')}
                                    className="px-5  md:px-8"
                                >
                                    Create {membership?'Membership NFT':'Certificate'}
                                </BlueGradientButton>
                            </div>
                        </DivContainer>
                    )}
                </>
            ) : (
                <ErrorDisplay />
            )}
    </>
  )
}
