import Input from '../ui/Input';
import React, { useCallback, useEffect, useRef } from 'react'
import BlueGradientButton from '../ui/BlueGradientButton';
import { CloudUpload, Info, Refresh } from '@mui/icons-material';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import useCreateToken from '../hooks/token/useCreateToken';
import useRechargeWallet from '../hooks/wallet/useRechargeWallet';
import useLoadWallet from '../hooks/wallet/useLoadWallet';

function CreateToken() {
    const appCtx = useSelector(state => state.app)
    const {
        iconImage,
        blockchains,
        selectIconImage,
        selectedBlockChain,
        setSelectedBlockchain,
        fileRef,
        iconImageUrl,
        setTokeName,
        tokwnSymbol,
        initialSupply,
        initialSupplyHandler,
        decimal,
        decimalHandler,
        confirmTokenDetails,
        tokenName,
        setTokenSymbol,
        marketplaceAddress,
        reserveAccount,
        setReserveAccount
    } = useCreateToken();
    const { rechargeWallet} = useRechargeWallet();
    const {loadWallet} = useLoadWallet();
    return (
        <div className="flex flex-col gap-10">
            <div className="font-bold text-2xl ">Create Token</div>
            <div className="flex justify-between flex-col md:flex-row">
                <div className="w-full md:w-[45%] flex flex-col items-center gap-6">
                    <div className="flex flex-col gap-4 w-full items-center">
                        <div className="rounded-lg w-11/12 h-60 overflow-hidden shadow-md">
                            <img src={iconImageUrl} className="w-full h-full object-contain" />
                        </div>
                        <div className="font-semibold text-sm">Token icon preview</div>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                        <span className="font-bold text-lg">Select blockchain</span>
                        <div className="flex flex-wrap justify-between gap-y-3 w-full">
                            {/* <BlueGradientButton className="w-2/5"  onClick={()=> blockchainRef.current="algorand"}>Algorand</BlueGradientButton>
                          <BlueGradientButton className="w-2/5">Flow</BlueGradientButton>
                          <BlueGradientButton className="w-2/5">Etherum</BlueGradientButton>
                          <BlueGradientButton className="w-2/5">Solana</BlueGradientButton> */}
                            {blockchains.map((blockchain) => {
                                return (
                                    <BlueGradientButton
                                        className={`w-2/5 ${selectedBlockChain === blockchain.blockchain && '!bg-secPurple'
                                            }`}
                                        key={blockchain.blockchain}
                                        onClick={() => {
                                            setSelectedBlockchain(blockchain.blockchain);
                                            console.log(selectedBlockChain === blockchain.blockchain);
                                        }}
                                    >
                                        {blockchain.name}
                                    </BlueGradientButton>
                                );
                            })}
                        </div>
                    </div>

                </div>
                <div className="w-full md:w-[45%] flex flex-col gap-6">
                    <div className="flex items-center md:items-start flex-col gap-2 relative">
                        <div className="font-bold">Token icon</div>
                        <div>
                            <button
                                onClick={() => fileRef.current.click()}
                                className="h-28 w-28 rounded-full border opacity-0 flex justify-center items-center flex-col text-white  overflow-hidden absolute hover:opacity-60 bg-[rgba(0,0,0,1)]"
                            >
                                <span>
                                    <CloudUpload fontSize="large" />
                                </span>
                                <span className="font-bold">upload</span>
                            </button>
                            <div className="h-28 w-28 rounded-full border  overflow-hidden flex flex-col justify-center items-center">
                                {!iconImage && (
                                    <>
                                        <span>
                                            <CloudUpload fontSize="large" />
                                        </span>
                                        <span className="font-bold">upload</span>
                                    </>
                                )}
                                {iconImageUrl && (
                                    <img src={iconImageUrl} className="h-full w-full rounded-full object-cover"></img>
                                )}
                            </div>
                        </div>
                        <input
                            ref={fileRef}
                            onChange={(e) => selectIconImage(e.target.files[0])}
                            className="hidden"
                            type="file"
                        />
                    </div>
                    <Input
                        description={'Token name'}
                        placeholder={'Token name'}
                        type={'text'}
                        value={tokenName}
                        onChange={(e) => setTokeName(e.target.value)}
                    />
                    <Input
                        value={tokwnSymbol}
                        onChange={(e) => setTokenSymbol(e.target.value)}
                        description={'Token symbol'}
                        placeholder={'Token symbol'}
                        type={'text'}
                    />
                    <div className="flex flex-col justify-between w-full">
                        <Input
                        value={`${marketplaceAddress}`}
                            description={'Manager Account'}
                            placeholder={``}
                            disabled={true}
                            type={'text'}
                        />
                        <Input
                            description={'Reserve Account'}
                            value={reserveAccount}
                            onChange={(e)=>{setReserveAccount(e.target.value)}}
                            placeholder={'Reserve Account'}
                            type={'text'}
                        />
                       
                    </div>
                    <div className="flex flex-col md:flex-row justify-between w-full">
                        <Input
                            value={initialSupply}
                            onChange={initialSupplyHandler}
                            description={'Initial supply'}
                            placeholder={'Initial supply'}
                            type={'text'}
                        />
                        <Input
                            description={'Decimals'}
                            value={decimal}
                            onChange={decimalHandler}
                            placeholder={'Decimals'}
                            type={'text'}
                        />
                       
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-3">
                {appCtx.walletDetails?.balance < 0.001 && (
                    <div className="text-red-400">Wallet balance less than 0.001 algo please <button className='text-blue-600'  onClick={rechargeWallet}>recharge</button> the wallet . <button className='text-black dark:text-white' onClick={loadWallet}><Refresh/></button></div>
                )}
                <div>
                    <BlueGradientButton
                        disabled={appCtx.walletDetails?.balance < 0.001}
                        className="px-5 md:px-32 rounded-full"
                        onClick={confirmTokenDetails}
                    >
                        Create token
                    </BlueGradientButton>
                </div>


            </div>
        </div>
    );
}

export default CreateToken