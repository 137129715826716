import React, { useState } from 'react';
import DivContainer from '../../ui/DivContainer';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useNavigate, useParams } from 'react-router-dom';
import UploadAttendeesCSV from '../../pages/events-page/UploadAttendeesCSV';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';

function MinAttendeesList({ claimers, fetchClaimers, certId }) {
    const { id } = useParams();
    console.log(claimers);
    const appCtx = useSelector((state) => state.app);
    const navigate = useNavigate();
    const dark = appCtx.isDarkMode;
    const [showUploadModal, setShowUploadModal] = useState(false);
    return (
        <div className="flex flex-col gap-2">
            <span className="font-bold">Attendees list</span>
            {claimers.length > 0 ? (
                <div className="flex flex-col gap-3">
                    <DivContainer className="max-h-96 overflow-y-scroll ">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: dark && 'white' }}>Sr no.</TableCell>
                                    <TableCell sx={{ color: dark && 'white' }}>Name</TableCell>
                                    <TableCell sx={{ color: dark && 'white' }}>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {claimers?.map((user, index) => {
                                    return (
                                        <TableRow key={user?.email}>
                                            <TableCell sx={{ color: dark && 'white' }}>{index + 1}</TableCell>
                                            <TableCell sx={{ color: dark && 'white' }}>{user.name}</TableCell>
                                            <TableCell sx={{ color: dark && 'white' }}>{user.email}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </DivContainer>
                    <div className="flex justify-center">
                        <BlueGradientButton onClick={() => navigate(`/events/edit-attendees/${certId}`)}>Edit List</BlueGradientButton>
                    </div>
                </div>
            ) : (
                <DivContainer className="flex flex-col items-center gap-3">
                    <span className="font-semibold text-sm">You haven't uploaded the attendees list </span>
                    <BlueGradientButton onClick={() => setShowUploadModal(true)}>Upload new</BlueGradientButton>
                    {showUploadModal && (
                        <UploadAttendeesCSV
                            showUploadModal={showUploadModal}
                            certId = {certId}
                            closeModal={() => setShowUploadModal(false)}
                            fetchClaimers={fetchClaimers}
                        />
                    )}
                </DivContainer>
            )}
        </div>
    );
}

export default MinAttendeesList;
