import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import CreateNftTutorial from '../../pages/CreateNftTutorial';
import AssetItem from './AssetItem';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AssetPulse from './AssetPulse';
import { useLocation } from 'react-router-dom';
import React from "react"

const AssetDisplay = (props) => {
    const [assets, setAssets] = useState([]);
    const [offset, setOffset] = useState(0);
    const location = useLocation()
    const [maxAssets, setMaxAssets] = useState(20000);
    const makeRequest = useAuthorizedHttp();
    const authToken = useSelector((state) => state.app.authToken);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/assets?page=${offset}&size=8`,
                headers: {
                    'X-Auth-Token': authToken
                }
            },
            (data) => {
                if (data.totalElements !== 0) {
                    if (props.onlyNonMinted) setAssets(data.content.filter((i) => !i.minted));
                    else setAssets(data.content);
                    let t = Math.floor(data.totalElements / 8);
                    setMaxAssets(t);
                }
                setLoading(false);
            },
            () => {
                toast.error('Something went wrong');
            },
            () => {}
        );
    }, [authToken, makeRequest, offset, props.marketplaceId, props.onlyNonMinted]);

    const handleLeftClicked = useCallback(() => {
        setAssets([]);
        setOffset((prev) => prev - 1);
    }, []);

    const handleRightClicked = useCallback(() => {
        setAssets([]);
        setOffset((prev) => prev + 1);
    }, []);

    return (
        <div>
            {!props.showUploadSection && (
                <div>
                    <div className={`${props.className} grid grid-cols-1 gap-y-10`}>
                    {(location.pathname.startsWith("/asset")) && <div className='font-bold text-2xl'>My Assets</div>}
                     {  assets.length !== 0 &&  <div className="flex justify-start gap-[8%] relative text-base">
                            {/* <select  className='bg-inherit'>
                                <option className=''>Category</option>
                                <option>All</option>
                                <option>Arts</option>
                                <option>Music</option>
                                <option>Sports</option>
                                <option>Others</option>
                            </select> */}
                            {/* <select className='bg-inherit'>
                                <option>Satus</option>
                                <option>Sealed</option>
                                <option>Submitted</option>
                            </select> */}
                            {/* <select className='bg-inherit'>
                                <option>Sort</option>
                                <option>Price: Low to High</option>
                                <option>Recently added</option>
                                <option>last 3 days</option>
                                <option>last 7 days</option>
                                <option>last 15 days</option>
                            </select> */}
                           {(location.pathname.startsWith("/asset")) && <button
                                className="bg-secBlue px-10 rounded-md py-3 absolute right-1 bottom-6 text-white flex items-center gap-2"
                                onClick={() => props.setShowUploadSection(true)}
                            >    <CloudUploadOutlinedIcon/>
                                <div>Upload Asset</div>
                            </button>}
                        </div>}
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-5">
                            {loading && (
                                <div className="grid grid-cols-4 gap-10 col-span-1 md:col-span-2 lg:col-span-4">
                                    <AssetPulse />
                                    <AssetPulse />
                                    <AssetPulse />
                                    <AssetPulse />
                                    <AssetPulse />
                                    <AssetPulse />
                                </div>
                            )}
                            {assets.length !== 0 &&
                                assets.map((asset) => <AssetItem key={asset.assetId} asset={asset} />)}
                        </div>
                        {assets.length !== 0 && (
                            <div className="flex flex-row mx-auto">
                                <button
                                    disabled={offset === 0}
                                    onClick={handleLeftClicked}
                                    className="disabled:bg-gray-500 dark:disabled:bg-zinc-600 rounded-l-xl w-[130px] bg-sky-500 text-slate-50 flex items-center gap-3 p-3 justify-center"
                                >
                                    <KeyboardArrowLeftRounded />
                                    Previous
                                </button>
                                <div className="bg-gray-100 dark:bg-zinc-700 w-[60px] h-[50px] flex justify-center items-center text-xl">
                                    {offset + 1}
                                </div>
                                <button
                                    disabled={offset === maxAssets}
                                    onClick={handleRightClicked}
                                    className="disabled:bg-gray-500 dark:disabled:bg-zinc-600 rounded-r-xl bg-sky-500 text-slate-50 flex items-center gap-3 p-3 w-[130px] justify-center"
                                >
                                    Next
                                    <KeyboardArrowRightRounded />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div>
                {assets.length === 0 && !loading && (
                    <div>
                        <CreateNftTutorial
                            showUploadSection={props.showUploadSection}
                            setShowUploadSection={props.setShowUploadSection}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssetDisplay;
