import axios from 'axios';
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function useVerifyAttendee() {
    const appCtx = useSelector(state => state.app)
    const [success,setSuccess] = useState(false)
    const verifyAttendee = useCallback((eventId,id)=>{

        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/event/attendee/verify`,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                'X-App-Token': process.env.REACT_APP_APP_TOKEN
            },
            data: {
                category: 'string',
                eventId: eventId,
                id: id,
                orgId: appCtx?.marketplaceId
            }
        };

        axios(config)
        .then(res =>{
            setSuccess(true)
        }).catch(rej =>{
            setSuccess(false)
            toast.error("Could not verify user(s)")
        })

    },[])
  return {
        verifyAttendee,
        success,
        setSuccess
  }
}

export default useVerifyAttendee