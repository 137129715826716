import { useCallback, useEffect, useRef, useState } from 'react';
import Input from '../ui/Input';
import BlueGradientButton from '../ui/BlueGradientButton';
import BlueButton from '../ui/BlueButton';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import EditPages from '../components/marketplace-settings/EditPages';
import axios from 'axios';
import PreviewContainer from "../ui/PreviewContainer"
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import EditPagesContainer from '../components/marketplace-settings/EditPagesContainer';
import React from "react"

const EditMarketplaceHomepage = (props) => {
    const appCtx = useSelector(state => state.app)
    const [banner, setBanner] = useState();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [buttonLabel, setButtonLabel] = useState('explore');
    const [buttonURL, setButtonURL] = useState("/explore");
    const [uploading, setUploading] = useState(false);
    const [logo, setLogo] = useState("")

    const url = new URL(window.location.href);
    const marketplaceId = appCtx.marketplaceId;
    const domain = appCtx.marketplace.domain;

    const fileRef = useRef();

    const makeRequest = useAuthorizedHttp();

    const navigate = useNavigate();

    

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${domain}/page/home/detail`
            },
            (data) => {

                data.bannerImage && setBanner(data?.bannerImage)
                data.title && setTitle(data.title);
                data.logo && setLogo(data.logo)
                data.description && setDescription(data.description);
                data.buttonLabel && setButtonLabel(data.buttonLabel);
                data.buttonLink && setButtonURL(data.buttonLink);
            },
            (data) => console.log(data),
            () => { }
        );

    }, [domain, makeRequest]);



    const uploadBanner = useCallback((file)=>{
        setUploading(true);
        const data = new FormData();
        data.append('file', file);

        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${marketplaceId}/web2/file/upload?type=marketplace-banner`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            data: data
        };
        axios(config)
        .then((res) =>{
            setUploading(false)
            setBanner(res.data.fileUrl)
            toast.success("banner updated successfully")
        }
        )
        .catch((rej) =>{
            setUploading(false)
            toast.error("banner upload failed")
        })
    },[uploading])


    // useEffect(() => {
    //     if (!banner) return;

    //     const preview = URL.createObjectURL(banner);
    //     setBannerPreview(preview);
    //     return () => URL.revokeObjectURL(preview);
    // }, [banner]);

    const handleSaveClick = () => {
        let flag = true;
        if (!banner) {
            flag = false;
            toast.error("Banner can't be empty!");
        }
        if (!title) {
            flag = false;
            toast.error("Title can't be empty!");
        }
        if (!description) {
            flag = false;
            toast.error("Description can't be empty!");
        }
        if (!buttonLabel && appCtx.marketType !== 'DAO') {
            flag = false;
            toast.error("Button label can't be empty!");
        }
        if (!buttonURL && appCtx.marketType !== 'DAO') {
            flag = false;
            toast.error("Button URL can't be empty!");
        }

        if (flag) {
            const marketplace = {
                description,
                title,
                buttonLabel,
                buttonLink: buttonURL,
                marketplaceId,
                page: 'home',
                bannerImage: banner
            };

            makeRequest(
                {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                    method: 'POST',
                    data: marketplace
                },
                (data) => toast.success("Home page updated successfully"),
                (data) => console.log(data),
                () => {
                    setUploading(false);
                }
            );
        }
    };

    return (
        <>
            {/* <div className="text-2xl font-bold mx-auto mb-10">Edit your marketplace Home</div> */}
            <EditPagesContainer>
                <div className="grid grid-cols-6 gap-10 ">
                    <div className="col-span-2 flex flex-col gap-3  h-[60vh] edit-scrollbar p-2">
                        {/* <button className="text-sky-500 text-left" onClick={() => navigate('/marketplace')}>
                        <KeyboardArrowLeftRounded />
                        Back
                    </button> */}

                        <Input

                            disabled={uploading}
                            type="text"
                            placeholder="Enter an appropriate title"
                            description="Title"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                        <Input
                            disabled={uploading}
                            type="textarea"
                            placeholder="Enter an appropriate description"
                            description="Description"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        />
                        <div className="grid gap-1">
                            <label className="font-bold  dark:text-gray-400">Select banner</label>
                            <button
                                className=" py-4 bg-[#EDEDED] rounded-lg text-black  dark:text-gray-400 dark:bg-darkPrimary border flex items-center justify-center gap-4  "
                                disabled={uploading}
                                onClick={() => fileRef.current.click()}
                            >   <img src="/icons/brows.svg" alt="" className='w-8 h-8' />
                                Browse or Drag & Drop
                            </button>
                            <input
                                hidden={true}
                                ref={fileRef}
                                
                                type="file"
                                accept='image/*'
                                onChange={(e) => uploadBanner(e.target.files[0])}
                            />
                        </div>
                        {appCtx.marketType !== 'DAO' &&
                            <>
                                <Input
                                    disabled={uploading}
                                    type="text"
                                    placeholder="Explore"
                                    description="Button label"
                                    maxLength={16}
                                    onChange={(e) => setButtonLabel(e.target.value)}
                                    value={buttonLabel}
                                />
                                {
                                   buttonLabel.length >= 16 && <span className='text-sm flex justify-end text-red-400'>Character limit: 16</span> 
                                }
                                <Input
                                    disabled={uploading}
                                    type="text"
                                    placeholder="/explore"
                                    description="Button link"
                                    onChange={(e) => setButtonURL(e.target.value)}
                                    value={buttonURL}
                                />
                            </>
                        }


                        {/* <BlueGradientButton
                        disabled={uploading}
                        className="w-full"
                        onClick={() => navigate('/marketplace')}
                    >
                        Discard changes
                    </BlueGradientButton> */}
                    </div>
                    <div className='col-span-4 flex flex-col items-end'>
                        {/* <div className=" rounded-lg  flex flex-col shadow-prevBox bg-inherit dark:text-white h-[60vh] dark:border-darkBorder border-white border-[20px] dark:border-darkSecondary">
                        <div className="flex justify-around px-5 py-10">
                            <img className="h-8 w-8 rounded-full object-cover" src={logo} alt="logo" />  
                            <button className="text-xl font-light">{marketplaceName}</button>
                            <input className="outline-none dark:bg-darkBorder rounded-xl bg-[#D6D6D6]" type="text" />
                            <button className="">Explore</button>
                            <div className="flex gap-5 items-center">
                               
                                <button className="w-5 h-6">
                                    <AccountBalanceWalletIcon />
                                </button>
                                <AccountCircleIcon/>
                            </div>
                        </div>
                        <div className=" h-full px-20 gap-10">
                            <div className="flex flex-col items-start justify-center gap-5 ">
                                <div className="text-4xl font-bold">{title}</div>
                                <div className="text-2xl">{description}</div>
                                <button
                                    className="w-[200px] dark:bg-white bg-black  text-white dark:text-black py-3 font-bold rounded-md"
                                    onClick={() => window.open('https://' + buttonURL)}
                                >
                                    {buttonLabel}
                                </button>
                            </div>
                            <div className="flex items-center justify-end">
                                {!bannerPreview && <div className="text-2xl">Select your banner image</div>}
                                {bannerPreview && (
                                    <img
                                        alt="Banner"
                                        src={bannerPreview}
                                        className="object-cover rounded-lg max-h-[250px]"
                                    />
                                )}
                            </div>
                        </div>
                    </div> */}
                        <PreviewContainer>
                            <div className="flex h-full w-full px-10 gap-10 justify-between">
                                <div className="flex flex-col items-start justify-center gap-5 ">
                                    <div className="text-xl font-bold">{title || "Title"}</div>
                                    <div className="break-words" >{description || "Description"}</div>
                                    {appCtx.marketType !== 'DAO' &&
                                        <button
                                            className="w-[200px] dark:bg-white bg-black  text-white dark:text-black py-3 font-bold rounded-md"
                                            onClick={() => window.open('https://' + buttonURL)}
                                        >
                                            {buttonLabel || "Explore"}
                                        </button>
                                    }
                                </div>
                                <div className="w-1/2 h-60 flex items-center justify-end">
                                    {!banner && <div className="text-2xl">Select your banner image</div>}
                                    {banner && (
                                        <img
                                            alt="Banner"
                                            src={banner}
                                            className="object-contain rounded-lg max-h-full  w-full"
                                        />
                                    )}
                                </div>
                            </div>
                        </PreviewContainer>
                        <BlueGradientButton disabled={uploading} className="py-2 mt-3 w-48" onClick={handleSaveClick}>
                            Save
                        </BlueGradientButton>
                    </div>
                </div>
            </EditPagesContainer>
        </>
    );
};

export default EditMarketplaceHomepage;
