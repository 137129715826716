import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

function useRechargeWallet() {
    const  from = `https://dispenser.testnet.aws.algodev.network/?account=`
    const appCtx = useSelector(state => state.app)
    const rechargeWallet = useCallback(()=>{
        window.open(`${from}${appCtx.walletDetails.address}`)
    },[appCtx])
  return {
    rechargeWallet
  }
}

export default useRechargeWallet