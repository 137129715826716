import React, { useEffect, useState } from 'react'
import LaunchPadUpload from '../ui/launchpad/templates/LaunchPadUpload';
import LaunchPadPreview from '../ui/launchpad/templates/LaunchPadPreview';
import { useNavigate, useParams } from 'react-router-dom';
import PremintDataContainer from '../ui/launchpad/templates/PremintDataContainer';
import usePremint from '../hooks/launchpad/usePremint';
import BlueGradientButton from '../ui/BlueGradientButton';

function NftLaunchPad() {
    const [assets, setAssetsArray] = useState([]);
    const [errorArray,setErrorArray] = useState([]);
    const navigate = useNavigate();
    const [selectedCollection,setSelectedCollection] = useState("");
    // const [premintData,setPremintData] = useState([])
    const {premintData,showMore,loadPremintAssets} = usePremint();
  return (
 <div className="flex flex-col gap-10">
          <div className="flex justify-between items-center ">
              <span className="font-bold text-2xl">NFT launchpad</span>
              <div className='flex gap-4'>
                <BlueGradientButton
                onClick={() =>navigate('/nft-launch-pad/nft')}
                >
                    View NFTs
                </BlueGradientButton>
              {premintData?.length > 0 &&<div>
                    <BlueGradientButton
                    onClick={() =>navigate('/nft-launch-pad/premint')}
                    >
                        Visit preminted assets
                    </BlueGradientButton>
              </div>}
              </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-10">
              <LaunchPadUpload setAssetsArray={setAssetsArray} setErrorArray ={setErrorArray} selectedCollection = {selectedCollection} setSelectedCollection = {setSelectedCollection}/>

              <LaunchPadPreview assets = {assets} errorArray= {errorArray} selectedCollection={selectedCollection} />
          </div>
      </div>
      
  );
}

export default NftLaunchPad