import React, { useState } from 'react'
import DivContainer from '../../ui/DivContainer';
import { useParams } from 'react-router-dom';
import EditAttendeeModal from './EditAttendeeModal';
import { Edit } from '@mui/icons-material';

function Claimers({claimer,fetchClaimers,i}){
    const [openEditModal,setOpenEditModal] = useState(false);
    const {cid} = useParams()
    return  <DivContainer key={claimer.id}>
    <div className='grid grid-cols-12'>
        <span className='col-span-2'>{i+1}</span>
        <span className='break-words col-span-4'>{claimer.name}</span>
        <span className='break-words col-span-4'>{claimer.email}</span>
        <span className={`${claimer?.claimed ? "text-green-600":"dark:text-white text-black"} col-span-2`}>{claimer?.claimed ? "Claimed":"Yet to claim"}</span>
        {/* <button onClick={() => setOpenEditModal(true)}><Edit/></button> */}
        {openEditModal && <EditAttendeeModal name={claimer.name} email={claimer.email} closeModal={()=>setOpenEditModal(false)} fetchClaimers={fetchClaimers} cid={cid} id={claimer.id} eventId={claimer.eventId}/>}
    </div>
    
</DivContainer>
}

export default Claimers