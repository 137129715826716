import React, { useCallback, useState } from 'react';
import ModalOverlay from '../common/ModalOverlay';
import useFetchClaimers from '../../hooks/events/useFetchClaimers';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Cancel } from '@mui/icons-material';

function CreateCategoryModal({ closeModal,id,fetchCategories }) {
    const [editName, setEditName] = useState('');
    const appCtx = useSelector((state) => state.app);
    const createCategory = useCallback(() => {
        if(!editName){
            toast.error("Category can't be empty")
            return
        }
        const data = {
            category: editName,
            id: null,
            orgId: appCtx.marketplaceId
        };

        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/category`,
            method: id ? 'put' : 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
            data: data
        };

        axios(config)
            .then((res) => {
                toast.success('Category created successfully');
                closeModal();
                fetchCategories();
            })
            .catch((rej) => {
                toast.error('category could not be updated');
            })
            .finally(() => {
                
            });
    }, [editName]);

    return (
        <ModalOverlay>
            <div className="flex flex-col gap-5 min-w-[300px]">
                <div className="flex justify-end">
                    <button onClick={closeModal}>
                        <Cancel />
                    </button>
                </div>
                <Input
                    placeholder="Category"
                    description="Category"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                />
                <BlueGradientButton onClick={createCategory}>Submit</BlueGradientButton>
            </div>
        </ModalOverlay>
    );
}

export default CreateCategoryModal;
