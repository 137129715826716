import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { appActions } from '../../context/app-slice';

function useFetchTokenDetails() {
    const [showManageTokenOption, setShowManageTokenOption] = useState(false);
    const location = useLocation();
    const [showManageTokenSettings,setShowManageTokenSettings] = useState(false)
    
    const dispatch = useDispatch()
    const [content,setContent] = useState(null)
    const appCtx = useSelector(state => state.app)
        const fetchToken = useCallback(()=>{
            let config = {
                method:"get",
                headers:{
                    'Content-Type': 'application/json',
                    'X-Auth-Token': appCtx.authToken,
                },
                url:`${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/fungible/token/list`
            }
            axios(config)
            .then((res)=>{
                console.log(res?.data[0])
                dispatch(appActions.setTokenDetails((res?.data[0])))
            })
            .catch((rej) =>{
                console.log(rej)
                toast.error("error accoured")
            })
        },[])
    useEffect(()=>{

        fetchToken()
    
    },[location])
  return {
      setShowManageTokenOption,
      showManageTokenOption,
      showManageTokenSettings,
      setShowManageTokenSettings,
      fetchToken
  };
}

export default useFetchTokenDetails