import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AssetPulse from '../../../components/common/AssetPulse';
import useFetchCertificate from '../../../hooks/events/useFetchCertificate';
import useFetchClaimers from '../../../hooks/events/useFetchClaimers';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import DivContainer from '../../../ui/DivContainer';
import EmptyContent from '../../../ui/EmptyContent';
import Input from '../../../ui/Input';
import { FilteredCertificate } from '../certificate/FilteredCertificate';
import { ClaimList } from '../ClaimList';

export const MembershipList = () => {
    const navigate = useNavigate();
    const appCtx = useSelector((state) => state.app);
    const [selected, setSelected] = useState(false);

    const makeAuthorizedRequest = useAuthorizedHttp();
    const { fetchClaimers, claimers, setClaimers, loading } = useFetchClaimers();

    useEffect(() => {
        fetchClaimers('0');
    }, []);

    if (loading) {
        return (
            <DivContainer>
                <AssetPulse />
            </DivContainer>
        );
    }

    return (
        <>
            <DivContainer className="flex justify-between items-center  rounded-md mb-[20px]">
                <span
                    className="text-lg font-bold"
                    onClick={() => {
                        setSelected(false);
                        // navigate('/membership/create')
                    }}
                >
                    Members
                </span>
                <BlueGradientButton
                    onClick={() => {
                        setSelected(true);
                        navigate('/membership/my-membership');
                    }}
                    className="px-5  md:px-8"
                >
                    Membership NFT
                </BlueGradientButton>
            </DivContainer>
            {/* <div className='mb-[20px] w-[150px]'>
                    <Input value="Membership NFT" className={`!bg-white !text-black`}/>
            </div> */}
            {claimers?.length > 0 ? (
                <ClaimList
                    claimers={claimers}
                    dark={appCtx.isDarkMode}
                    membership={true}
                    className="w-[100%] !overflow-inherit"
                />
            ) : (
                <DivContainer>
                    <div className="flex flex-col items-center justify-center gap-5 h-[200px] text-2xl font-bold">
                        DAO has no members
                    </div>
                </DivContainer>
            )}
        </>
    );
};
