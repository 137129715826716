import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useTransition } from "react";
import { CircularProgress } from "@mui/material";

const Model = ({ src, gltf1,scale }) => {



  return (
    <>
      {gltf1?.scene && <>
        <directionalLight position={[10, 10, 2]} intensity={10} />
        <primitive object={gltf1.scene} scale={1}/>
      </>
      }
    </>
  );
};

export default function Forgltf({ src,scale }) {
  const [isPending, startTransition] = useTransition();
  const [gltf, setGltf] = useState();
  // const [error,setError] = useState(false);
  // let gltf1 = '';
  let error = false
  try {
    var gltf1 = useGLTF(src);
  }
  catch {
    error = true;
    // alert('if you are uploading a threeD with multiple textures and bin file , please convert all of them into a single file and reupload')
  }
  // useGLTF.preload(src);

  useEffect(() => {
    try {
      startTransition(() =>
        void new GLTFLoader().load(src, setGltf), [src]
      )
    }
    catch {
      toast('error')
    }
  }, [src])

  // useLoader(GLTFLoader, "./gift.glb");
  return (
    !error &&
    isPending?
    <div className="w-[100%] h-[100%]">
      <CircularProgress />
    </div>
    :
    <div style={{ position: "relative", width: "100%",height:"100%" }} className=''>
      <Suspense fallback={null}>
        <Canvas 
        // camera={{ fov: 35, zoom: 6, near: 1, far: 1000 }}
        >
          <Suspense fallback={null}>
            <mesh scale={scale?scale:100}>
              <Model src={src} gltf1={gltf} scale={scale}/>
              <OrbitControls />
            </mesh>
            {/* <Environment preset="dawn" background /> */}
          </Suspense>
        </Canvas>
      </Suspense>
    </div>
  );
}