import React, { useCallback, useEffect, useState } from 'react';
import useFetchEvents from '../../hooks/events/useFetchEvents';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useFetchOrgCertificates from '../../hooks/events/useFetchOrgCertificates';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import EmptyContent from '../../ui/EmptyContent';
import DivContainer from '../../ui/DivContainer';
import BlueGradientButton from '../../ui/BlueGradientButton';
import AssetPulse from '../../components/common/AssetPulse';

function ClaimersList() {
    const { eventList, isFound, loading } = useFetchEvents();
    const navigate = useNavigate();
    const { certId } = useParams();
    const { eventCert, fetchOrgCets } = useFetchOrgCertificates();
    const [selectedEvent, setSelectedEvent] = useState(0);
    const handleEventChange = useCallback(
        (e) => {
            console.log(typeof e.target.value);
            setSelectedEvent(+e.target.value);
            if (+e.target.value === 0) {
                navigate('/events/claimers-list');
            } else {
                fetchOrgCets(e.target.value);
            }
        },
        [selectedEvent]
    );
    useEffect(() => {
        if (!certId && eventList?.length > 0) {
            setSelectedEvent(eventList?.eventId)
            fetchOrgCets(eventList[0]?.eventId)
        }
    }, [certId]);

    // useEffect(() => {
    //     if (selectedEvent === 0) {
    //         navigate('/events/attendees-list');
    //     }
    // }, [selectedEvent]);

    useEffect(()=>{
        if(eventList?.length > 0){
            setSelectedEvent(eventList?.eventId)
            fetchOrgCets(eventList[0]?.eventId)
        }
    },[eventList])
    useEffect(() => {
        if (eventCert) {
            navigate(`${eventCert?.certId}`);
        }else{
            navigate(`0`)
        }
    }, [eventCert]);
    return !loading ? isFound ? (
        eventList?.length > 0 ? (
            <div className="flex flex-col gap-10">
                <div>
                    <select
                        className="p-3 rounded-md shadow-md text-gray-700"
                        value={selectedEvent}
                        onChange={handleEventChange}
                    >
                        {/* <option value={0}>Select Event </option> */}
                        {eventList?.map((event) => {
                            return (
                                <option key={event?.eventId} value={event?.eventId}>
                                    {event?.eventName}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {!certId && (
                    <div className="font-bold text-2xl text-center">
                        <EmptyContent message="No certificates found for the event" />
                    </div>
                )}
                <Outlet />
            </div>
        ) : (
            <DivContainer>
                <div className="flex flex-col items-center gap-5">
                    <EmptyContent
                        message="You haven't created any events or uploaded
                        the attendee list create one first to get it appear here."
                    />
                    <BlueGradientButton onClick={() => navigate('/events/create-event')}>
                        Create event
                    </BlueGradientButton>
                </div>
            </DivContainer>
        )
    ) : (
        <ErrorDisplay />
    ):<AssetPulse/>
}

export default ClaimersList;
