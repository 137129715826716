import React, { useCallback, useEffect, useState } from 'react'
import TutorialCard from './TutorialCard'
import useTutorials from "./useTutorials"
import axios from 'axios'
import AssetPulse from '../../components/common/AssetPulse'

function TutorialsSection() {
    const {
      tutorials,
      isFound,
      loading
  } = useTutorials()
// const [tutorials, setTutorials] = useState([]);
// const [isFound,setIsFound] = useState(true)
// const [loading, setLoading] = useState(false);

// const fetchTutorials = useCallback(() => {
//     let config = {
//         url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/0/blog/post/list?type=TUTORIAL`,
//         method: 'get',
//         headers: {
//           'Content-Type': 'application/json',
//           'X-App-Token': 'E9FE46D9-FC53-480F-9DC6-D26A7DE233A0'

//       }
//     };
//     axios(config)
//         .then((response) => {
//           setTutorials(response.data?.content);
//           setIsFound(true)

//         })
//         .catch(()=>{
//             setIsFound(false)
//         })
//         .finally(() => setLoading(false));
// });

// useEffect(() => {
//     fetchTutorials();
// }, []);
  return !loading ? (
      isFound ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10 md:px-20 py-10">
              {tutorials?.map((tutorial, i) => (
                  <TutorialCard tutorial={tutorial} key={i} />
              ))}
          </div>
      ) : (
          <div>
              <div className='text-center py-10 text-3xl text-white'>Nothing found</div>
          </div>
      )
  ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  px-20 py-10">
          <AssetPulse />
          <AssetPulse />
          <AssetPulse />
          <AssetPulse />
          <AssetPulse />
          <AssetPulse />
      </div>
  );
}

export default TutorialsSection