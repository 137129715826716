import React from 'react'

function EventDetailCard({title,...props}) {
  return (
    <div className='flex flex-col gap-3'>
        {title &&<span className='font-bold'>
            {title}
        </span>}
        <span>
            {props.children}
        </span>
    </div>
  )
}

export default EventDetailCard