import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CollectionItem from '../../../components/collection-item/CollectionItem';
import AssetPulse from '../../../components/common/AssetPulse';
import CreateCollectionModal from '../../../components/mint-asset/CreateCollectionModal';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';

function Collections() {
    const navigate = useNavigate();
    const makeRequest = useAuthorizedHttp();
    const [collection, setCollection] = useState([]);
    const [iscollectionnull, setiscollectionnull] = useState(false);

    const [loading, setLoading] = useState(true);
    const [marketplaceDomain, setMarketplaceDomain] = useState();
    const [marketplaceId, setMarketplaceId] = useState();
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
                setMarketplaceDomain(data[0].domain);
            },
            () => {},
            () => {
                setLoading(false);
            }
        );
    }, [makeRequest]);

    useEffect(() => {
        marketplaceId &&
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/list`
                },
                (data) => {
                    // setCollection([{ collectionId: 0.5, name: 'All' }]);
                    setCollection([]);
                    setCollection((prev) => [...prev, ...data.content]);
                    setiscollectionnull(data.content.length === 0);
                }
            );
    }, [makeAuthorizedRequest, marketplaceId]);

    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    setCollection((prev) => [...prev, ...data.content]);
                }
            ),
        [makeAuthorizedRequest, marketplaceId]
    );

    useEffect(() => {
        marketplaceId && loadCollections();
    }, [loadCollections, makeAuthorizedRequest, marketplaceId]);
    return (
        <div className="flex flex-col gap-14">
            {marketplaceId && collectionModalOpen && (
                <CreateCollectionModal
                    marketplaceId={marketplaceId}
                    onCreateSuccesssful={() => loadCollections()}
                    onCloseCreateCollection={() => setCollectionModalOpen(false)}
                />
            )}
            <div className="flex justify-between items-center">
                <div>
                    <div className="font-bold text-lg md:text-2xl">My Collection</div>
                    <div className="text-sm">* Click on any collection to view certificate issued under it</div>
                </div>
                {/* <button disabled={true}><SwapVertIcon/>  Sort</button>
                <button disabled={true}> <TuneIcon/>  Filter</button> */}
                <button
                    className=" px-3 md:px-12 bg-secBlue py-2 md:py-4 rounded-md text-white"
                    onClick={() => setCollectionModalOpen(true)}
                >
                    {' '}
                    Create Collecton
                </button>
            </div>

            <div>
                {loading && (
                    <div className="grid grid-cols-4 gap-5">
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                    </div>
                )}
                {!loading && collection.length !== 0 && (
                    <div className="grid grid-cols-3 gap-8 flex-wrap">
                        {collection.map((item) => {
                            return (
                                <div
                                    className="w-[100%] cursor-pointer"
                                    // onClick={() => {
                                    //     navigate(`/certificate/collections/filtered-certificate/${item.name}`);
                                    // }}
                                >
                                    <CollectionItem
                                        collection={item}
                                        key={item?.collectionId}
                                        domain={marketplaceDomain}
                                        certificate={true}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
                {!loading && collection.length === 0 && iscollectionnull === true && (
                    <div>Hi, you don't have any collection to show , Create collection to get it featured here.</div>
                )}
            </div>
        </div>
    );
}

export default Collections;
