import React from 'react'
import UnauthenticatedNavbar from '../../components/navbar/UnauthenticatedNavbar'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Footer from '../../components/common/Footer'
import CreateAccountModal from '../../components/landing/CreateAccountModal'

function About() {
    const appCtx = useSelector(state => state.app)
  return (
    <div className={` landing-bg ${appCtx.isDarkMode ? "dark" : ""} flex flex-col text-slate-100 justify-between min-h-screen`}>
        <UnauthenticatedNavbar/>
        <div className='mt-[80px]'>
            <Outlet/>
        </div>
        <Footer />
        {appCtx?.openCreateAccountModal && <CreateAccountModal />}

    </div>
  )
}

export default About