import React from 'react'
import { useParams } from 'react-router-dom'

function CollectionNfts() {
    const {collectionId} = useParams();
  return (
    <div>

    </div>
  )
}

export default CollectionNfts