import { useEffect, useState } from 'react';
import BlueGradientButton from '../ui/BlueGradientButton';
import { useNavigate } from 'react-router-dom';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { toast } from 'react-toastify';
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import RichTextEditor from '../components/common/RichTextEditor';
import EditPagesContainer from '../components/marketplace-settings/EditPagesContainer';
import PreviewContainer from '../ui/PreviewContainer';
import SuggestionTemplateContainer from '../ui/SuggestionTemplateContainer';
import React from "react"
import { useSelector } from 'react-redux';

const EditMarketplacePrivacy = (props) => {
    const appCtx = useSelector(state => state.app)
    const [privacy, setPrivacy] = useState('');
    const [uploading, setUploading] = useState(false);

    const url = new URL(window.location.href);
    const marketplaceId = appCtx.marketplaceId;
    const domain = appCtx.marketplace.domain;
    const isEventPage = appCtx.marketType ==="DAO";

    const navigate = useNavigate();

    const makeRequest = useAuthorizedHttp();

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${domain}/page/privacy-policy/detail`
            },
            (data) => {
                setPrivacy(data.description ? data.description : '');
            },
            (data) => console.log(data),
            () => {}
        );
    }, [domain, makeRequest]);

    const handleSaveClick = () => {
        if (!privacy) {
            toast.error("Privacy can't be empty");
            return;
        }
        setUploading(true);

        const data = {
            description: privacy,
            marketplaceId,
            page: 'privacy-policy'
        };

        toast.promise(
            () =>
                makeRequest(
                    {
                        data,
                        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                        method: 'POST'
                    },
                    (data) => {},
                    (data) => {
                        console.log(data);
                    },
                    () => {
                        setUploading(false);
                    }
                ),
            {
                pending: 'Updating your marketplace settings...',
                success: 'Settings updated successfully!',
                error: 'Uh-oh! Something went wrong'
            }
        );
    };

    return (
        <>
            {/* <div className="text-2xl font-bold mx-auto mb-10">Edit your marketplace Privacy Policy</div> */}
            <EditPagesContainer>
                <div className="grid grid-cols-5  gap-10">
                    <div className="col-span-2 flex flex-col gap-5 items-center">
                        {/* <button className="text-sky-500 text-left" onClick={() => navigate('/marketplace')}>
                        <KeyboardArrowLeftRounded />
                        Back
                    </button> */}
                        <div className={'text-gray-800 h-[300px] mb-10'}>
                            <RichTextEditor value={privacy} onChange={(e) => setPrivacy(e)} />
                        </div>
                        <BlueGradientButton disabled={uploading} className="px-24" onClick={handleSaveClick}>
                            Save
                        </BlueGradientButton>
                        {/* <BlueGradientButton
                        disabled={uploading}
                        className="w-full"
                        onClick={() => navigate('/marketplace')}
                    >
                        Discard changes
                    </BlueGradientButton> */}
                    </div>
                    {/* <div className="col-span-2 rounded-lg  flex flex-col gap-10 items-start p-20 bg-prevBg shadow-prevBox text-white border-white border-[20px] dark:border-darkSecondary">
                    <div className="text-2xl font-bold">Privacy Policy</div>
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: privacy }} />
                </div> */}
                    <PreviewContainer page="Privacy Policy">
                        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: privacy }} />
                    </PreviewContainer>
                </div>
                <div className="mt-7 flex flex-col">
                    <div className="text-2xl font-bold">Privacy template</div>
                    <SuggestionTemplateContainer setter={setPrivacy}>
                    {appCtx.marketType ==="DAO" ?`
                    Our page only collects data that is necessary to provide its services and stores in a safe and secure
                     environment in an anonymized way. We always respect our user privacy and never share any details with
                      any third party systems. Our policy advocates that we do not use cookies or any other tracking technologies
                       which is against our own principles. We only allow connections that is HTTPs this ensures that no one can
                        come between you and our platform, or can listen our private conversation or steal users' private and
                         confidential data. We do not save any transaction related data like your card number/Wallet address etc.
                          Your all transactions are safe and secure with us and your product's ownership & rarity is never compromised.
                    `
                    :`Our marketplace only collects data that is necessary to provide its services and stores in a
                        safe and secure environment in an anonymized way. We always respect our user privacy and never
                        share any details with any third party systems. Our policy advocates that we do not use cookies
                        or any other tracking technologies which is against our own principles. We only allow
                        connections that is HTTPs this ensures that no one can come between you and our platform, or can
                        listen our private conversation or steal users' private and confidential data. We do not save
                        any transaction related data like your card number/Wallet address etc. Your all transactions are
                        safe and secure with us and your product's ownership & rarity is never compromised.`}
                    </SuggestionTemplateContainer>
                </div>
            </EditPagesContainer>
        </>
    );
};

export default EditMarketplacePrivacy;
