import React from 'react'

function ErrorDisplay() {
  return (
    <div className='flex justify-center'>
        <img src="/images/error-message.svg" className='w-96 h-96 object-contain'/>
    </div>
  )
}

export default ErrorDisplay