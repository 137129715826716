import { AccountBalanceWallet, AccountCircle } from '@mui/icons-material';
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { ConfigureEvent } from './ConfigureEvent';
import { ConfigureHeader } from './ConfigureHeader';

export const Configure = () => {
    const navigate = useNavigate();
    const [section, setSection] = useState('header');

    return (
        <>
            {/* <div className='mb-[50px]'>
                <button className={`h-[50px] w-[200px] rounded-xl ${section === 'header' ? 'bg-secPurple' : 'bg-gray-500'}`}
                    onClick={() => {
                        setSection('header')
                        // navigate('/configure/header')
                    }}
                >
                    Header Section</button>
                <button className={`ml-[20px] h-[50px] w-[200px] rounded-xl ${section === 'event' ? 'bg-secPurple' : 'bg-gray-500'}`}
                    onClick={() => {
                        setSection('event')
                        // navigate('/configure/event')
                    }}
                >
                    Event Section</button>
            </div> */}
            {/* {section === 'header' ?
                <ConfigureHeader />
                : */}
                <ConfigureEvent />
            {/* } */}
        </>
    )
}
