import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EventDetailCard from '../../../components/events/EventDetailCard';
import DivContainer from '../../../ui/DivContainer';
import Input from '../../../ui/Input';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import useFetchCertCategory from '../../../hooks/events/useFetchCertCategory';
import CreateCategoryModal from '../../../components/events/CreateCategoryModal';
import useFetchPremintData from '../../../hooks/events/useFetchPremintData';
import { CircularProgress } from '@mui/material';
import UploadAttendeesCSV from '../UploadAttendeesCSV';
import useFetchClaimers from '../../../hooks/events/useFetchClaimers';
import { CopyAll, DescriptionOutlined } from '@mui/icons-material';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';
import EditAttendeeModal from '../../../components/events/EditAttendeeModal';
import { NotifyClaimers } from '../certificate/NotifyClaimers';
import { ClaimersListModal } from '../certificate/CaimersListModal';
import AssetPulse from '../../../components/common/AssetPulse';

function MembershipDetails() {
    const { premintId, category } = useParams();
    const makeRequest = useAuthorizedHttp();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState('');
    const { fetchCategories, categories } = useFetchCertCategory();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openListModal, setOpenListModal] = useState(false);

    const [openNotifyModal, setOpenNotifyModal] = useState(false);
    const [marketplaceDomain, setMarketplaceDomain] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const appCtx = useSelector((state) => state.app);
    const { premintContent, premintMetaData } = useFetchPremintData(premintId);
    const searchParams = new URLSearchParams(window.location.search);
    const { fetchClaimers, claimers, setClaimers } = useFetchClaimers();
    useEffect(() => {
        if (categories?.length > 0) {
            setSelectedCategory(categories[0]?.category);
        }
    }, [categories]);
    useEffect(() => {
        fetchClaimers(searchParams.get('certId'));
    }, []);
    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceDomain(data[0].domain);
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);

    const handleUploadNewClaimers = useCallback(() => {
        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/cert/nft/claimers`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
            data: []
        };

        axios(config)
            .then((res) => {
                // toast.success('List uploaded successfully');
                setClaimers([]);
            })
            .catch((rej) => {
                // toast.error('List could not be uploaded');
            })
            .finally(() => {});
    }, []);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    console.log('loggint premint content', premintContent);
    console.log('loggint  premintMetaData', premintMetaData);

    return (
        <div className="flex flex-col gap-8">
            <div className="grid grid-cols-1 gap-10 lg:mx-[5rem]">
                <div className="flex flex-col gap-5">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                        {isLoading && <AssetPulse />}
                        <img
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                            className={`w-[100%] .max-h-[400px] .min-h-[300px] object-contain border rounded-lg p-20 ${
                                isLoading ? 'hidden' : ''
                            }`}
                            alt="premint nft"
                            onLoad={handleImageLoad}
                        />
                        <div className="flex justify-center items-center">
                            {!claimers?.length ? (
                                <DivContainer className="flex flex-col items-center gap-3">
                                    <span className="font-semibold text-sm">
                                        You can restrict who can claim these NFTs by adding their email ids
                                    </span>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 w-[100%] gap-5">
                                        <BlueGradientButton onClick={() => setShowUploadModal(true)}>
                                            Add Bulk Email
                                        </BlueGradientButton>
                                        {showUploadModal && (
                                            <UploadAttendeesCSV
                                                showUploadModal={showUploadModal}
                                                certId={searchParams.get('certId')}
                                                closeModal={() => setShowUploadModal(false)}
                                                fetchClaimers={fetchClaimers}
                                                claimers={claimers}
                                            />
                                        )}
                                        <BlueGradientButton
                                            onClick={() => {
                                                setOpenEditModal(true);
                                            }}
                                        >
                                            Add email
                                        </BlueGradientButton>
                                    </div>
                                    <div className="flex gap-5 cursor-pointer">
                                        <div
                                            onClick={() => {
                                                window.open(
                                                    `http://${marketplaceDomain}/certificate/${searchParams.get(
                                                        'certId'
                                                    )}/nft/${premintId}/claim`,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            {`http://${marketplaceDomain}/certificate/${searchParams.get(
                                                'certId'
                                            )}/nft/${premintId}/claim`}
                                            &nbsp;
                                        </div>
                                        <CopyAll
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `http://${marketplaceDomain}/certificate/${searchParams.get(
                                                        'certId'
                                                    )}/nft/${premintId}/claim`
                                                );
                                            }}
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </DivContainer>
                            ) : (
                                <div className="flex flex-col gap-5">
                                    <div className="border rounded-lg p-2 flex justify-center items-center w-[100%]">
                                        <DescriptionOutlined />
                                        File uploaded
                                    </div>
                                    <div className="grid grid-cols-3 gap-2">
                                        {/* <BlueGradientButton onClick={() => { setOpenNotifyModal(true) }} className='bg-yellow-600 p-0'>Notify members</BlueGradientButton> */}
                                        <BlueGradientButton
                                            onClick={() => {
                                                handleUploadNewClaimers();
                                            }}
                                            className="p-0"
                                        >
                                            upload new
                                        </BlueGradientButton>
                                        <BlueGradientButton onClick={() => setOpenListModal(true)}>
                                            View List
                                        </BlueGradientButton>
                                        <BlueGradientButton
                                            onClick={() => {
                                                setOpenEditModal(true);
                                            }}
                                        >
                                            Add email
                                        </BlueGradientButton>
                                    </div>
                                    <div className="flex gap-5 cursor-pointer">
                                        <div
                                            onClick={() => {
                                                window.open(
                                                    `http://${marketplaceDomain}/certificate/${searchParams.get(
                                                        'certId'
                                                    )}/nft/${premintId}/claim`,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            {`http://${marketplaceDomain}/certificate/${searchParams.get(
                                                'certId'
                                            )}/nft/${premintId}/claim`}
                                            &nbsp;
                                        </div>
                                        <CopyAll
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `http://${marketplaceDomain}/certificate/${searchParams.get(
                                                        'certId'
                                                    )}/nft/${premintId}/claim`
                                                );
                                            }}
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <EventDetailCard title="Name">
                        <DivContainer className="break-words">{premintMetaData?.name}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <DivContainer className="break-words">{premintMetaData?.description}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Category">
                        <DivContainer className="break-words">{category}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        <DivContainer className="flex gap-2 flex-wrap">
                            {Object.keys(premintMetaData?.properties || {})?.length ? (
                                <>
                                    {Object.entries(premintMetaData?.properties)?.map(([key, value]) => {
                                        return (
                                            <DivContainer className="flex flex-col" key={key}>
                                                <span>{key}</span>
                                                <span>{value}</span>
                                            </DivContainer>
                                        );
                                    })}
                                </>
                            ) : (
                                <div>No properties to display</div>
                            )}
                        </DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Price">
                        <DivContainer className="break-words">{premintContent?.price}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Price unit">
                        <DivContainer className="break-words">{premintContent?.priceUnit}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Gas Fee Waived">
                        <DivContainer className="break-words">
                            {premintContent?.gasFeeWaived === true ? 'Yes' : 'no'}
                        </DivContainer>
                    </EventDetailCard>
                </div>
            </div>
            {openEditModal && (
                <EditAttendeeModal
                    closeModal={() => setOpenEditModal(false)}
                    fetchClaimers={fetchClaimers}
                    certId={searchParams.get('certId')}
                    eventId={claimers[0]?.eventId}
                />
            )}
            {openNotifyModal && (
                <NotifyClaimers
                    openModal={openNotifyModal}
                    setOpenModal={setOpenNotifyModal}
                    claimers={claimers}
                    link={`http://${marketplaceDomain}/certificate/${searchParams.get(
                        'certId'
                    )}/nft/${premintId}/claim`}
                    desc={`
            To claim your Membership NFT, please visit our website <u>${`http://${marketplaceDomain}/certificate/${searchParams.get(
                'certId'
            )}/nft/${premintId}/claim`}</u> and verify your email address. Once you have completed this step, you will be able to claim your Membership NFT.
        <br><br>
        &nbsp;&nbsp;&nbsp;&nbsp;If you have any questions or need assistance with the process, please feel free to contact us at ${
            appCtx.marketplaceEmail
        }.
        <br><br>
            &nbsp;&nbsp;&nbsp;&nbsp;Thank you for your dedication and contributions to ${
                appCtx.marketplace?.name
            }. We look forward to celebrating your achievements with you.
        <br><br>
        <b>Best regards,</b><br>
        Team ${appCtx.marketplace?.name}
        `}
                    sub={`Membership Nft`}
                />
            )}
            {openListModal && (
                <ClaimersListModal
                    openModal={openListModal}
                    setOpenModal={setOpenListModal}
                    claimers={claimers}
                    link={`http://${marketplaceDomain}/certificate/${searchParams.get(
                        'certId'
                    )}/nft/${premintId}/claim`}
                />
            )}
        </div>
    );
}

export default MembershipDetails;
