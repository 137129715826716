import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import MyMarketplaces from './pages/MyMarketplaces';
import MyAssets from './pages/MyAssets';
import AllNFT from './pages/AllNFTs';
import Landing from './pages/Landing';
import Login from './pages/Login';
import CreateMarketplace from './pages/CreateMarketplace';
import Signup from './pages/Signup';
import MintNFT from './pages/MintNFT';
import EditMarketplaceHomepage from './pages/EditMarketplaceHomepage';
import EditMarketplaceAbout from './pages/EditMarketplaceAbout';
import EditMarketplacePrivacy from './pages/EditMarketplacePrivacy';
import EditMarketplaceTOS from './pages/EditMarketplaceTOS';
import EditMarketplaceFAQ from './pages/EditMarketplaceFAQ';
import CryptoNews from './pages/CryptoNews';
import ResetPassword from './pages/ResetPassword';
import AboutUs from './pages/AboutUs';
import 'react-quill/dist/quill.snow.css';
import AuthorizedPageCard from './components/common/AuthorizedPageCard';
import AssetDetail from './pages/AssetDetail';
import UploadLogo from './components/marketplace-settings/UploadLogo';
import EditMarketPlaceFooter from './pages/EditMarketPlaceFooter';
import MyCollections from './pages/MyCollections';
import CryptoWallet from './pages/CryptoWallet';
import SaleNft from './pages/SaleNft';
import CollectionNfts from './pages/CollectionNfts';
import { Privacy, TermsAndCondition } from './pages/TermsAndCondition';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { FAQ } from './pages/FAQ';
import MyCategories from './pages/MyCategories';
import React from "react"
import FungibleToken from './pages/FungibleToken';
import CreateToken from './pages/CreateToken';
import ManageToken from './pages/ManageToken';
import ConfigureToken from './pages/ConfigureToken';
import TokenDetails from './pages/TokenDetails';
import useFetchTokenDetails from './hooks/token/useFetchTokenDetails';
import { Configure } from './EventPage/Configure/Configure';
import { ConfigureHeader } from './EventPage/Configure/ConfigureHeader';
import { ConfigureEvent } from './EventPage/Configure/ConfigureEvent';
import { UploadEmail } from './EventPage/UploadEmail';
import { UploadNft } from './EventPage/UploadNft';
import QrCodeGenerator from './EventPage/QrCodeGenerator/QrCodeGenerator';
import Tutorial from './pages/Tutorial';
import NftLaunchPad from './pages/NftLaunchPad';
import Premint from './ui/launchpad/Premint';
import UpdatePremintedAsset from './ui/launchpad/UpdatePremintedAsset';
import TutorialDetails from './pages/TutorialDetails';
import BlogPage from './pages/BlogPage';
import BlogPosts from './ui/blogpost/BlogPosts';
import BlogPost from './ui/blogpost/BlogPost';
import Events from './pages/events-page/Events';
import MyEvents from './pages/events-page/MyEvents';
import CreateEvent from './pages/events-page/CreateEvent';
import AttendeesList from './pages/events-page/AttendeesList';
import POANft from './pages/events-page/POANft';
import EditEvent from './pages/events-page/EditEvent';
import UploadAttendeesCSV from './pages/events-page/UploadAttendeesCSV';
import EventDetails from './pages/events-page/EventDetails';
import CreateRSVP from './pages/events-page/CreateRSVP';
import CreateTicket from './pages/events-page/CreateTicket';
import EditAttendees from './pages/events-page/EditAttendees';
import CreateCertificate from './pages/events-page/CreateCertificate';
import ClaimersList from './pages/events-page/ClaimersList';
import Redirect from './pages/Redirect';
import Web3Events from './pages/landing-details/Web3Events';
import About from './pages/landing-details/About';
import GameDevelopers from './pages/landing-details/GameDevelopers';
import MarketplaceOrStorefront from './pages/landing-details/MarketplaceOrStorefront';
import DOALaunchpad from './pages/landing-details/DAOLaunchpad';
import DAOLaunchpad from './pages/landing-details/DAOLaunchpad';
import Web3Solution from './pages/landing-details/Web3Solution';
import { HomeCertificate } from './EventPage/certificate/HomeCertificate';
import CreateNewCertificate from './pages/events-page/certificate/CreateNewCertificate';
import SubmitCertificate from './pages/events-page/certificate/SubmitCertificate';
import { CertificateHome } from './pages/events-page/certificate/CertificateHome';
import CertificateDetails from './pages/events-page/certificate/CertificateDetails';
import ContainerCertificates from './pages/events-page/certificate/containerCertificate';
import Collections from './pages/events-page/certificate/Collections';
import { ShowCollectionCertificates } from './pages/events-page/certificate/ShowCollectionCertificates';
import ContainerMembershipCertificates from './pages/events-page/membership/ContainerMembershipCertificates';
import { MembershipHome } from './pages/events-page/membership/MembershipHome';
import CreateNewMembershipCertificate from './pages/events-page/membership/CreateNewMembershipCertificate';
import SubmitMembership from './pages/events-page/membership/SubmitMembership';
import MembershipDetails from './pages/events-page/membership/MembershipDetails';
import { MembershipList } from './pages/events-page/membership/MembershipList';
import Web3Signup from './pages/web3Signup';

function App() {
    const appCtx = useSelector((state) => state.app);
    const helmetContex = {}

    return (

        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme={appCtx.isDarkMode ? 'dark' : 'light'}
                toastStyle={
                    appCtx.isDarkMode
                        ? { backgroundColor: '#27272A', color: '#E2E8F0' }
                        : { backgroundColor: '#F8FAFC', color: '#1F2937' }
                }
            />
            <BrowserRouter>
                <Routes>
                    {/* Public routes */}
                    <Route path="/" element={appCtx.isLoggedIn ? <AuthorizedPageCard /> :<Login />}>
                        <Route path="home" element={<Home />} />
                        <Route path="marketplace">
                            <Route path="pages">
                                <Route path="edit-home-page" element={<EditMarketplaceHomepage />} />
                                <Route path="" element={<Navigate to="edit-home-page" />} />
                                <Route path="edit-privacy-page" element={<EditMarketplacePrivacy />} />
                                <Route path="edit-faq-page" element={<EditMarketplaceFAQ />} />
                                <Route path="edit-tos-page" element={<EditMarketplaceTOS />} />
                                <Route path="edit-about-page" element={<EditMarketplaceAbout />} />
                            </Route>
                            <Route path="" element={<Navigate to="update-header" />}></Route>
                            <Route path="update-header">
                                <Route path="" element={<UploadLogo />} />
                                <Route path=":target" element={<UploadLogo />} />
                            </Route>

                            <Route path="edit-footer-page" element={<EditMarketPlaceFooter />} />
                        </Route>
                        <Route path="asset" element={<MyAssets />}>
                            <Route path=":nftId" element={<AssetDetail />} />
                        </Route>
                        <Route path="fungibletoken" element={<FungibleToken />}>
                            {/* <Route  path="" element={<Navigate to="create"/>}/> */}
                            <Route path="manage" element={<ManageToken />}>
                                {!appCtx.tokenDetails && <Route path="create" element={<CreateToken />} />}
                                <Route path="configure-token" element={<ConfigureToken />} />
                                <Route path="token-details" element={<TokenDetails />} />
                            </Route>
                        </Route>
                        <Route path="mint" element={<MintNFT />} />
                        <Route path="nft" element={<AllNFT />} />
                        <Route path="salenft/:nftId" element={<SaleNft />} />
                        <Route path="collections" element={<MyCollections />}></Route>
                        <Route path="categories" element={<MyCategories />} />
                        <Route path="collections/:collectionId" element={<CollectionNfts />} />

                        <Route path="news" element={<CryptoNews />} />
                        <Route path="wallet" element={<CryptoWallet />} />
                        <Route path="nft-launch-pad" >
                            <Route path="home" element={<NftLaunchPad />} />
                            <Route path="" element={<Navigate to="home" />} />
                            <Route path="premint" element={<Premint />} />
                            <Route path="update-premint/:id" element={<UpdatePremintedAsset />} />
                            <Route path="nft" element={<AllNFT />} />
                        </Route>
                        {/* Event page */}

                        <Route path="configure" element={<Configure />}>
                            <Route path="header" element={<ConfigureHeader />} />
                            <Route path="event" element={<ConfigureEvent />} />
                        </Route>

                        <Route path="uploadnft" element={<UploadNft />} />
                        <Route path="uploademail" element={<UploadEmail />} />
                        <Route path="/qrcodegenerator" element={<QrCodeGenerator />} />


                        <Route path='events' element={<Events />}>
                            <Route path='' element={<Navigate to="my-event" />} />
                            <Route path='my-event' element={<MyEvents />} >
                            </Route>
                            <Route path='event-details/:id' element={<EventDetails />}></Route>
                            <Route path="edit-event/:id" element={<EditEvent />} />
                            <Route path='create-event' element={<CreateEvent />} />
                            <Route path='claimers-list' element={<ClaimersList />} >
                                <Route path=":certId" element={<EditAttendees />} />
                            </Route>
                            <Route path='attendees-list' element={<AttendeesList />} >
                                <Route path=":eventId" element={<EditAttendees />} />
                            </Route>
                            <Route path="proof-of-attendence" element={<POANft />}>
                            </Route>
                            <Route path="create-rsvp/:id" element={<CreateRSVP />} />
                            <Route path="create-poa/:id" element={<CreateRSVP />} />
                            <Route path="create-ticket/:id/:premintId" element={<CreateTicket />} />
                            <Route path="edit-ticket/:id/:premintId/:ticketId" element={<CreateTicket />} />
                            <Route path="create-certificate/:id/:premintId" element={<CreateCertificate />} />
                            <Route path="edit-certificate/:id/:premintId/:certId" element={<CreateCertificate />} />
                            <Route path="edit-attendees/:certId" element={<EditAttendees />} />

                        </Route>
                        <Route path="certificate" element={<ContainerCertificates />} >
                            <Route path='' element={<Navigate to="my-certificate" />} />
                            <Route path="my-certificate" element={<CertificateHome />} />
                            <Route path="create" element={<CreateNewCertificate />} />
                            <Route path="collections" element={<Collections />} />
                            <Route path="collections/filtered-certificate/:id" element={<ShowCollectionCertificates />} />
                            <Route path="submit-certificate/:premintId" element={<SubmitCertificate />} />
                            <Route path="details/:premintId/:category" element={<CertificateDetails />} />
                        </Route>
                        <Route path="membership" element={<ContainerMembershipCertificates />} >
                            <Route path='' element={<Navigate to="list" />} />
                            <Route path='list' element={<MembershipList />} />
                            <Route path="my-membership" element={<MembershipHome />} />
                            <Route path="create" element={<CreateNewMembershipCertificate />} />
                            <Route path="collections" element={<Collections />} />
                            {/*  */}
                            <Route path="collections/filtered-certificate/:id" element={<ShowCollectionCertificates />} />
                            {/*  */}
                            <Route path="submit-membership/:premintId" element={<SubmitMembership />} />
                            <Route path="details/:premintId/:category" element={<MembershipDetails />} />
                        </Route>
                    </Route>



                    <Route path="/about-us" element={appCtx.isLoggedIn ? <Navigate to="/home" /> : <AboutUs />} />
                    <Route
                        path="/termsandcondition"
                        element={appCtx.isLoggedIn ? <Navigate to="/home" /> : <TermsAndCondition />}
                    />
                    <Route path="/privacy" element={appCtx.isLoggedIn ? <Navigate to="/home" /> : <PrivacyPolicy />} />
                    <Route path="/faq" element={appCtx.isLoggedIn ? <Navigate to="/home" /> : <FAQ />} />

                    <Route
                        path="/"
                        element={appCtx.isLoggedIn ? <Navigate to="/home" replace={true} /> : <Login />}
                    />
                    <Route path="/signup" element={<Web3Signup />} />
                    <Route path="/redirect" element={<Redirect />} />
                    <Route path='about' element={<About />} >
                        <Route path="web-3-events" element={<Web3Events />} />
                        <Route path="nft-launchpad-for-game-developers" element={<GameDevelopers />} />
                        <Route path="nft-marketplace-or-storefront" element={<MarketplaceOrStorefront />} />
                        <Route path="dao-launchpad" element={<DAOLaunchpad />} />
                        <Route path="web3-solution" element={<Web3Solution />} />
                    </Route>

                    <Route path="/create-marketplace" element={<CreateMarketplace />} />
                    <Route path="/tutorial" >
                        <Route path='' element={<Tutorial />} />
                        <Route path=":isided/:title" element={<TutorialDetails />} />
                    </Route>
                    <Route path='/blog' element={<BlogPage />}>
                        <Route path="" element={<BlogPosts />}  >
                        </Route>
                        <Route path="post/:id/:title" element={<BlogPost />} />
                    </Route>
                    <Route
                        path="/reset-password"
                        element={appCtx.isLoggedIn ? <Navigate to="/home" replace={true} /> : <ResetPassword />}
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>

    );
}

export default App;
