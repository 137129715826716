import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

function useFetchEventTicket() {
    const {id} = useParams();
    const appCtx = useSelector(state => state.app)
    const [eventTickets,setEventTickets] = useState([]);

    const fetchEventTicket = useCallback(()=>{
        const config ={
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/event/${id}/nft/ticket`,
            method:"get",
                headers: {
                    'X-Auth-Token': appCtx.authToken
                }
        }

        axios(config)
        .then(res => setEventTickets(res.data))
        .catch(rej => toast.error("Could not fetch the event tickets"))
    },[id])

    useEffect(() => {
        if(id){
            fetchEventTicket();
        }
    },[id])

  return {
    eventTickets

  }
}

export default useFetchEventTicket