import { useCallback } from 'react';
import React from "react"

const Select = (props) => {
    const handleChange = useCallback(
        (e) => {
            props.onChange({
                id: e.target.value,
                text: e.target.selectedOptions[0].text
            });
        },
        [props]
    );

    return (
        <div className={`flex  w-full gap-1 ${props.containerClassName}`}>
            <label className="text-lg font-semibold">{props.header}</label>
            <label className="text-sm">{props.description}</label>
            <select
                onChange={handleChange}
                disabled={props.disabled}
                value={props.value && props.value.id}
                className={`disabled:text-gray-500 w-52 border-black dark:disabled:text-gray-400 border-2 rounded-lg dark:text-gray-100 dark:bg-gray-700 dark:border-gray-700 bg-slate-100 hover:bg-slate-200 disabled:cursor-not-allowed disabled:hover:bg-slate-100 dark:hover:bg-gray-600 dark:disabled:hover:bg-zinc-700 transition-all ease-out duration-300 p-3 dark:focus:border-sky-500 focus:border-sky-600 outline-none ${props.className}`}
            >
                {props.data &&
                    props.data.length !== 0 &&
                    props.data.map((o) => (
                        <option key={o.id ||o.collectionId} value={o.id||o.collectionId}>
                            {o.text || o.name}
                        </option>
                    ))}
            </select>
        </div>
    );
};

export default Select;
