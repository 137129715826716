import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { appActions } from '../../context/app-slice';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useSelector } from 'react-redux';
import React from 'react';
import { getImageUrl, nativeToken } from '../../config';

const FinishAccountSetup = (props) => {
    const [flowButtonEnabled, setFlowButtonEnabled] = useState(true);
    const makeRequest = useAuthorizedHttp();
    const dispatch = useDispatch();
    const [selectedMarketType, setSelectedMarketType] = useState('');
    const [marketplaceId, setMarketplaceId] = useState();
    const appCtx = useSelector((state) => state.app);

    const setAppType = useCallback(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                method: 'PUT',
                data: {
                    marketplaceId: appCtx.marketplaceId,
                    appType: selectedMarketType
                }
            },
            (data) => dispatch(appActions.setMarketType(data.appType)),
            (data) => console.log(data),
            (rej) => {
                console.log(rej);
            }
        );
    }, [selectedMarketType, appCtx.marketplaceId]);

    useEffect(() => {
        if (selectedMarketType !== '') {
            setAppType();
        }
    }, [selectedMarketType]);

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
                dispatch(appActions.setMarketplaceId(data[0].marketplaceId));
                dispatch(appActions.setMarketplace(data[0]));

                dispatch(appActions.setDomain(data[0].domain));
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);
    // useEffect(()=>{
    // 	console.log(selectedMarketType)
    // 	// localStorage.setItem("markettype",selectedMarketType)
    // 	dispatch(appActions.setMarketType(selectedMarketType))
    // },[selectedMarketType])
    const setMarketType = (marketType) => {
        setSelectedMarketType(marketType);

        console.log(selectedMarketType);
    };
    const handleClick = useCallback(
        (blockchain) => {
            dispatch(appActions.setBlockChain(blockchain));
            // localStorage.setItem("blockchain",blockchain)
            let data;
            data = { blockchain: blockchain, marketplaceId: marketplaceId };

            setFlowButtonEnabled(false);
            toast.promise(
                () =>
                    makeRequest(
                        {
                            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/wallet/setup`,
                            method: 'POST',
                            headers: {
                                'X-App-Token': process.env.REACT_APP_APP_TOKEN,
                                'Content-Type': 'application/json'
                            },
                            data
                        },
                        (data) => {
                            dispatch(appActions.toggleNewSignup());
                            console.log('hello data', data);
                            dispatch(appActions.setWalletDetails(data));
                        },
                        (data) => {
                            console.log(data);
                        },
                        () => {
                            setFlowButtonEnabled(true);
                        }
                    ),
                {
                    pending: 'Updating your marketplace...',
                    success: 'Successfully updated your marketplace!',
                    error: 'Looks like there was a problem!'
                }
            );
        },
        [dispatch, makeRequest, marketplaceId]
    );

    return (
        <div className="">
            {appCtx.marketType !== null && (
                <div className="rounded-xl flex flex-col gap-10">
                    <h1 className="text-4xl font-bold">
                        Finish setting up your{' '}
                        {appCtx.marketType === 'MARKETPLACE'
                            ? 'marketplace'
                            : appCtx.marketType === 'NFT_STORE_FRONT'
                            ? 'Store front'
                            : appCtx.marketType === 'DAO'
                            ? 'Event page'
                            : 'Organization'}
                    </h1>
                    <h3 className="text-xl font-light">Select your preferred blockchain network</h3>
                    {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 text-slate-50 "> */}
                    {/* <div className="bg-gradient-to-br grid grid-rows-2 gap-5 bg-violet-600 rounded-xl px-14 py-10 shadow-2xl shadow-violet-600/50 hover:scale-[1.05] transition-all ease-out duration-300">
					<div className="flex flex-col gap3">
					<div className="text-xs flex justify-center">Recommended</div>
					<h2 className="text-xl"><div className="text-xl">Create your account on Algorand blockchain </div></h2>
					</div>
					<button
						disabled={!flowButtonEnabled || !marketplaceId}
						onClick={() => handleClick("ALGORAND")}
						className="disabled:bg-gray-500 h-12 disabled:text-slate-50 disabled:hover:shadow-none bg-slate-50 text-violet-600 rounded-lg p-3 hover:shadow-slate-100/40 hover:shadow-lg transition-all ease-out duration-300"
					>
						Create
					</button>
				</div> */}
                    {/* <div className="bg-gradient-to-br grid grid-rows-2 gap-5 bg-rose-600 rounded-xl px-14 py-10 shadow-2xl shadow-rose-600/50 hover:scale-[1.05] transition-all ease-out duration-300">
					<h2 className="text-xl">Create your account on Flow blockchain</h2>
					<button
						disabled={!flowButtonEnabled || !marketplaceId}
						onClick={() => handleClick("FLOW")}
						className="disabled:bg-gray-500 h-12 disabled:text-slate-50 disabled:hover:shadow-none bg-slate-50 text-rose-600 rounded-lg p-3 hover:shadow-slate-100/40 hover:shadow-lg transition-all ease-out duration-300"
					>
						Create
					</button>
				</div> */}

                    {/* </div> */}
                    <div className=" w-[60%] mx-auto flex items-center justify-center gap-20  mt-12 flex-wrap">
                        {/* <div className="flex  flex-col rounded-md overflow-hidden shadow-md gap-3 hover:transform hover:scale-105 transition-all flex-wrap">
                            <img src="/images/algorand.png" className="w-72 h-48 object-contain" />
                            <div className="justify-center flex">Create your account on Algorand blockchain </div>
                            <button
                                disabled={!flowButtonEnabled || !marketplaceId}
                                onClick={() => handleClick('ALGORAND')}
                                className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                            >
                                Create
                            </button>
                        </div> */}

                        {Object.keys(nativeToken).map((key) => (
                            <div
                                className="w-[40%] flex  flex-col rounded-md overflow-hidden shadow-md gap-3 hover:transform hover:scale-105 transition-all flex-wrap"
                                key={key}
                            >
                                <img src={getImageUrl(key)} alt="blockchain_image" className="w-60 h-60 mx-auto" />
                                <div className="justify-center flex">Create your account on {key} blockchain </div>
                                <button
                                    disabled={!flowButtonEnabled || !marketplaceId}
                                    onClick={() => handleClick(key)}
                                    className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                >
                                    Create
                                </button>
                            </div>
                        ))}

                        {/*<div className='flex  flex-col rounded-md overflow-hidden shadow-md gap-3 hover:transform hover:scale-105 transition-all'>*/}
                        {/*	<img src="/images/polygon.png"  className='w-72 h-48 object-contain'/>*/}
                        {/*	<div className='justify-center flex'>Create your account on Polygon </div>*/}
                        {/*	<button*/}
                        {/*		disabled={!flowButtonEnabled || !marketplaceId}*/}
                        {/*		onClick={()=>handleClick("ALGORAND")} className='text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4'>Create</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )}
            {appCtx.marketType === null && (
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-20 lg:px-20">
                    {/* <div className="flex items-center justify-center gap-10 mt-12 flex-wrap"> */}
                    <div className="flex  flex-col gap-2  rounded-md overflow-hidden shadow-md justify-between  hover:transform hover:scale-105 transition-all">
                        <img src="/images/market1.png" className="w-72 h-48 object-cover" />
                        <div className="justify-center text-sm flex px-2">
                            NFT marketplaces are public platforms where NFTs can be stored, displayed, traded and can be
                            created by the users.
                        </div>
                        <button
                            onClick={() => setSelectedMarketType('MARKETPLACE')}
                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                        >
                            Create marketplace
                        </button>
                    </div>
                    <div className="flex  flex-col gap-2  rounded-md overflow-hidden shadow-md justify-between  hover:transform hover:scale-105 transition-all">
                        <img src="/images/market2.png" className="w-72 h-48 object-cover" />
                        <div className="justify-center text-sm flex px-2">
                            An NFT storefront,is an individual store from which your users can purchase digital assets.
                            With an NFT Storefront you can really personalize the entire user experience of your
                            customers.
                        </div>
                        <button
                            onClick={() => setSelectedMarketType('NFT_STORE_FRONT')}
                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                        >
                            Create store front
                        </button>
                    </div>

                    <div className="flex  flex-col gap-2 rounded-md overflow-hidden shadow-md justify-between  hover:transform hover:scale-105 transition-all">
                        <img src="/images/tut3.png" className="w-72 h-48 object-cover" />
                        <div className="justify-center flex text-sm px-2">
                            A decentralised autonomous organisation (DAO) is an Internet community owned & operated by
                            its members on blockchain technology. It uses smart contracts,that establish the group's
                            rules and executes decisions.
                        </div>
                        <button
                            onClick={() => setSelectedMarketType('DAO')}
                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                        >
                            Create DAO
                        </button>
                    </div>
                    {/* <div className='flex  flex-col rounded-md overflow-hidden shadow-md gap-3  hover:transform hover:scale-105 transition-all'>
				<img src="/images/tut2.png"  className='w-72 h-48 object-cover'/>
				<div className='justify-center flex'>Setup Organisation page</div>
				<button onClick={()=>setSelectedMarketType("ORGANISATION")}  className='text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4'>Create</button>
			</div> */}
                </div>
            )}
        </div>
    );
};

export default FinishAccountSetup;
