import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function useFetchPremintData(premintId) {
    const appCtx = useSelector(state => state.app)
    const [premintContent, setPremintContent] = useState({});
    const [premintMetaData, setPremintMetaData] = useState('');
    const fetchPremintData = useCallback((argPreMintId) => {
        const config = {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/premint/nft/${premintId || argPreMintId}`,
            method: 'get',
            headers: {
                'X-Auth-Token': appCtx.authToken
            }
        };
        try {
            axios(config)
                .then((res) => {
                    setPremintContent(res?.data);
                    setPremintMetaData(JSON.parse(res?.data?.metaData));
                })
                .catch((rej) => toast.error('Failed loading data'));
        } catch (error) {
            toast.error('Facing issue');
        }
    }, [premintId]);

    useEffect(() => {
        if (premintId) {
            fetchPremintData();
        }
    }, [premintId]);
  return {
    premintContent,
    premintMetaData,
    fetchPremintData
  }
}

export default useFetchPremintData