import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function useFetchOrgCertificates() {

    const {id} = useParams();
    const appCtx = useSelector(state => state.app)
    const [eventCert,setEventCert] = useState(null);
    const [eventCerts,setEventCerts] = useState([]);
    const [certLoading,setCertLoading] = useState(true)

    const fetchOrgCets = useCallback((eventId)=>{
        const config ={
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx.marketplaceId}/cert/nft/list?eventId=${id || eventId}&size=100`,
            method:"get",
                headers: {
                    'X-Auth-Token': appCtx.authToken
                }
        }

        axios(config)
        .then(res => {
            setEventCert(res?.data?.content[0])
            setEventCerts(res?.data?.content)
        })
        .catch(rej => toast.error("Could not fetch the organization certificates"))
        .finally(() => setCertLoading(false))
    },[id])

    useEffect(() => {
        if(id){
            fetchOrgCets();
        }else{
                
        }
    },[id])

  return {
    eventCert,
    fetchOrgCets,
    certLoading,
    eventCerts

  }
  
}

export default useFetchOrgCertificates