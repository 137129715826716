import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingManager } from 'three';

function useFetchClaimers() {
    const appCtx = useSelector((state) => state.app);
    const [loading,setLoading] = useState(false);
    const [claimers, setClaimers] = useState([]);
    const fetchClaimers = useCallback((certId) => {
        setLoading(true)
        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx.marketplaceId}/cert/nft/claimers/${certId}?size=100`,
            method: 'get',
            headers: {
                'X-Auth-Token': appCtx.authToken
            }
        };
        axios(config)
            .then((res) => {
                console.log(res);
                setClaimers(res?.data?.content);
            })
            .catch((rej) => console.log(rej))
            .finally(()=>{
                setLoading(false)
            })
    }, []);

    return {
        fetchClaimers,
        claimers,
        loading,
        setClaimers
    };
}

export default useFetchClaimers;
