import React from 'react'
import useFetchBlogPost from '../../hooks/blogpost/useFetchBlogPost';
import { useSelector } from 'react-redux';
import AssetPulse from '../../components/common/AssetPulse';
import { ArrowBack, CopyAll, Facebook, FiberManualRecord, Instagram, Share, Twitter } from '@mui/icons-material';
import parse from 'html-react-parser'
import { Popover } from 'react-tiny-popover';
import useFetchAllBlogs from '../../hooks/blogpost/useFetchAllBlogs';
import { Link } from 'react-router-dom';

import urlForTheString from '../../misc/urlForTheString';

function BlogPost() {
    const appCtx = useSelector((state) => state.app);
    const { blogDetail, loading, sharePost, showSharePopUp, setShowPopUp,id } = useFetchBlogPost();
    const { loading: postsLoading, posts, isFound, titleUrlRef } = useFetchAllBlogs();
    console.log(id)
  return <> 
  
    
    
  {!loading ? (
    <>
    {/* <Helmet> */}
    {/* <title>{`Blog - ${blogDetail?.title}`}</title>‍
        <meta name="description" content={blogDetail?.title}></meta>
        <meta property="og:url" content={window.location.href} /> */}
        {/* {blogDetail?.summary &&<meta property="og:description" content ={ parse(blogDetail?.summary) } />} */}
        {/* <meta property="og:image" content={blogDetail?.coverImage} />
        <meta property="og:site_name" content={`NFTVerse  blog - ${blogDetail?.title}`} /> */}
        {/* <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="blog" />
    </Helmet> */}
    
    <div className='flex flex-col gap-5'>
        <Link to = "/blog" className='flex gap-3 px-7 md:px-14 text-white no-underline' >
            <ArrowBack/>
            <span>Back</span>
            </Link>
      <div className="flex justify-center items-center lg:items-start gap-10 flex-col lg:flex-row ">
          <div className="flex flex-col gap-5 bg-slate-300 text-black px-10 py-5 md:mt-10 rounded-md w-4/5 lg:w-1/2">
              <div className="flex items-start flex-col gap-5 w-full">
                  <span className="font-bold text-3xl md:text-3xl">{blogDetail?.title}</span>
                  <div className="flex justify-between w-full items-center">
                      <span className="font-semibold text-xl">{blogDetail?.category}</span>
                      <Popover
                          isOpen={showSharePopUp}
                          positions={['left']}
                          padding={20}
                          reposition={false}
                          onClickOutside={() => setShowPopUp(false)}
                          content={({ position }) => (
                              <div className="bg-slate-100 p-3 rounded-md flex-col items-center  gap-10">
                                  <div className="flex justify-center font-bold">Copy shareable link</div>
                                  <div className='flex gap-3 cursor-pointer p-3 rounded-md hover:bg-slate-200' onClick={sharePost}>
                                      <div className="font-semibold text-sm max-w-xs overflow-hidden text-ellipsis">{window.location.href}</div>
                                      <span >
                                          <CopyAll />
                                      </span>
                                  </div>
                              </div>
                          )}
                      >
                          <button onClick={() => setShowPopUp(!showSharePopUp)}>
                              <Share />
                          </button>
                      </Popover>
                  </div>
              </div>
              <div className="fixed w-12 h-60  flex flex-col justify-evenly items-center left-0 top-1/2 -translate-y-1/2">
                  <div
                      className="w-auto cursor-pointer"
                      onClick={() => {
                          window.open('https://discord.gg/7GaSxew48h', '_blank');
                      }}
                  >
                      <img
                          src="/images/DiscordFilled.png"
                          className="w-[80%]"
                          alt="logo"
                          style={{ color: '#0C0C0C' }}
                      />
                  </div>
                  <div
                      className="w-auto cursor-pointer"
                      onClick={() => {
                          window.open('https://www.facebook.com/onnftverse', '_blank');
                      }}
                  >
                      <img src="/images/Facebook.svg" alt="logo" className="w-[80%]" style={{ color: '#0C0C0C' }} />
                  </div>
                  <div
                      className="w-auto cursor-pointer"
                      onClick={() => {
                          window.open('https://twitter.com/onnftverse?s=20&t=d9xAcEQONstJWodcG-8sUA', '_blank');
                      }}
                  >
                      <img
                          src="/images/TwitterFilled.png"
                          alt="logo"
                          className="w-[80%]"
                          style={{ color: '#0C0C0C' }}
                      />
                  </div>
                  <div
                      className="w-auto cursor-pointer"
                      onClick={() => {
                          window.open('https://www.linkedin.com/company/nftverse/', '_blank');
                      }}
                  >
                      <img src="/images/Linkedin.svg" alt="logo" className="w-[80%]" style={{ color: '#0C0C0C' }} />
                  </div>
                  <div
                      className="w-auto cursor-pointer"
                      onClick={() => {
                          window.open('https://www.instagram.com/_nftverse/', '_blank');
                      }}
                  >
                      <img src="/images/Instagram.svg" alt="logo" className="w-[80%]" style={{ color: '#0C0C0C' }} />
                  </div>
              </div>
              <div className=" py-10 flex flex-col gap-5 w-full">
                  { blogDetail?.coverImage &&<div className="flex justify-center">
                      <div className="w-full flex justify-center">
                          <img src={blogDetail?.coverImage} className=" w-full object-contain max-h-[440px]" />
                      </div>
                  </div>}
                  <div className="w-full">
                      <div className="text-ellipsis w-full break-words">{parse(blogDetail?.description || '')}</div>
                  </div>
              </div>
          </div>
          <div className=" w-4/5  lg:w-1/3  mt-5 rounded-md  overflow-hidden">
              {!postsLoading ? (
                  <div className="flex flex-col gap-5">
                      <span className="font-semibold py-2 text-xl border-b ">Suggested posts</span>
                      <div className="flex flex-col gap-5">
                          {posts?.map((post, i) => {
                              return (
                                  post.postId !== +id &&
                                  i < 5 && (
                                      <Link
                                          to={`/blog/post/${post.postId}/${urlForTheString(post?.title)}`}
                                          className=" no-underline text-slate-50 hover:text-blue-700  transition-all border border-gray-600 hover:border-gray-700 p-3 rounded-md"
                                          key={post.postId}
                                      >
                                          <div className="flex gap-5">
                                              <div className="min-w-[100px] max-w-[100px] ">
                                                  <img src={`${post?.coverImage}`} className="w-full max-h-16" />
                                              </div>

                                              <div>{post.title}</div>
                                          </div>
                                      </Link>
                                  )
                              );
                          })}
                      </div>
                  </div>
              ) : (
                  <div>
                      <AssetPulse />
                  </div>
              )}
          </div>
      </div>
      </div></>
  ) : (
      <div className="px-5 md:px-20">
          <AssetPulse />
      </div>
  )}
  </>
}

export default BlogPost