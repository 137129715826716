import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function useFetchCertificate(selectedCategory) {
    const appCtx = useSelector(state => state.app)
    const [certificateList,setCertificateList] = useState([]);
    const [loading,setLoading] = useState(true);
    const [isFound,setIsFound] = useState(true);

    const fetchEvents = useCallback(()=>{
        setLoading(true);
        
        let url1 =`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx?.marketplaceId}/cert/nft/list?size=100&category=${selectedCategory}`
        const config = {
            url: selectedCategory?url1:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx?.marketplaceId}/cert/nft/list?size=100`,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
        };

        axios(config)
        .then((res) =>{
            setCertificateList(res?.data?.content)
        })
        .catch((rej) =>{
            setIsFound(false);
        })
        .finally(()=>{
            setLoading(false);
        })

    },[selectedCategory])

    useEffect(()=>{
        fetchEvents();
    },[selectedCategory])
  return {
    certificateList,
    isFound,
    loading
  }
}

export default useFetchCertificate