import React, { useEffect, useState } from 'react';
import useFetchClaimers from '../../hooks/events/useFetchClaimers';
import { useParams } from 'react-router-dom';
import DivContainer from '../../ui/DivContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Edit } from '@mui/icons-material';
import EditAttendeeModal from '../../components/events/EditAttendeeModal';
import Claimers from '../../components/events/Claimers';
import BlueButton from '../../ui/BlueButton';
import BlueGradientButton from '../../ui/BlueGradientButton';
import EmptyContent from '../../ui/EmptyContent';

function EditAttendees() {
    const { certId } = useParams();
    const [openEditModal, setOpenEditModal] = useState(false);

    const { fetchClaimers, claimers, loading } = useFetchClaimers();
    useEffect(() => {
        if (certId && fetchClaimers) {
            fetchClaimers(certId);
        }
    }, [certId, fetchClaimers]);
    return (
      certId !== "0" ? <div className="flex flex-col gap-10">
            <DivContainer>
                <div className="flex justify-between items-center">
                    <span className="font-bold text-lg">Attendees list</span>
                    <BlueGradientButton onClick={() => setOpenEditModal(true)}>Add Attendee</BlueGradientButton>
                </div>
            </DivContainer>
            <DivContainer>
                <div className="grid grid-cols-12 font-bold min-w-[450px]">
                    <span className='col-span-2'>Sr.</span>
                    <span className='col-span-4'>Name</span>
                    <span className='col-span-4'>Email</span>
                    <span className='col-span-2'>Status</span>
                </div>
            </DivContainer>
            {!loading ? (
                claimers?.length > 0 ? (
                    <DivContainer className="min-w-[450px]">
                        <div className="flex flex-col gap-3 min-w-[400px]  ">
                            {claimers?.map((claimer, i) => {
                                return (
                                    <Claimers i={i} key={claimer.id} claimer={claimer} fetchClaimers={fetchClaimers} />
                                );
                            })}
                        </div>
                    </DivContainer>
                ) : (
                    <div className="font-bold text-3xl text-center">
                        <EmptyContent message="No attendee has been added yet!!" />

                        {/* TODO: add image */}
                    </div>
                )
            ) : (
                <></>
            )}
            {openEditModal && (
                <EditAttendeeModal
                    closeModal={() => setOpenEditModal(false)}
                    fetchClaimers={fetchClaimers}
                    certId={certId}
                    eventId={claimers[0]?.eventId}
                />
            )}
        </div>:<EmptyContent message="No certificate found ,In-order to add attendee you need to create certificate for the event" />
    );
}

export default EditAttendees;
