import React from 'react'
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';
import Working from '../../components/landing/Working';
import LandingButton from '../../ui/buttons/LandingButton';

function DAOLaunchpad() {
  const dispatch = useDispatch();
  return (
      <div className="flex flex-col gap-24">
          <div
              className="grid bg-cover grid-cols-1 md:grid-cols-2  h-screen bg-no-repeat"
              style={{ backgroundImage: `url(/images/dao/1.svg)` }}
          >
              <div className="justify-start flex">
                  <div className="flex flex-col justify-center  gap-5 w-4/5 pl-20">
                      <div className="font-semibold text-4xl">Your DAO launchpad</div>
                      <div className="">
                      Launch and manage your decentralized autonomous organization, issue Web 3.0 membership through your own crypto token and NFTs.
                      </div>
                      <div className="flex justify-start">
                          <LandingButton onClick={() => dispatch(appActions.setCreateAccountModal(true))}>
                              Get Started
                          </LandingButton>
                      </div>
                  </div>
              </div>
          </div>
          <div className="flex flex-col gap-10 px-20">
              <div className="text-center text-semibold text-4xl">Use Cases</div>
              <div className=" px-10 grid grid-cols-1 md:grid-cols-4 min-h-0">
                <div className='flex justify-center'>
                 <img src="/images/dao/2a.svg" />
                </div>
                <div className='flex justify-center '>
                 <img src="/images/dao/2b.svg" />
                </div>
                <div className='flex justify-center'>
                 <img src="/images/dao/2c.svg" />
                </div>
                <div className='flex justify-center  '>
                 <img src="/images/dao/2d.svg" />
                </div>
                  
              </div>
          </div>
          <Working />
      </div>
  );
}

export default DAOLaunchpad