import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useHttp from '../use-http';
import { appActions } from '../../context/app-slice';

function useLoadWallet() {
    const appCtx =useSelector(state => state.app)
    const dispatch = useDispatch()
    const makeRequest = useHttp()
    const loadWallet = useCallback(()=>{
    
        if((appCtx.marketplaceId && appCtx.blockchain!=="" )|| !appCtx.newSignup){
            if(appCtx.marketplaceId){
		makeRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/wallet/balance?blockchain=${appCtx?.blockchain}`,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': appCtx.authToken
                }
            },
            (data) => {
                dispatch(appActions.setWalletDetails(data));
                // dispatch(appActions.setBlockChain(data.blockchain))
                return data;
            },
            (data) => {},
            () => {}
        );
            }
        }
	},[])
  return {
    loadWallet
  }
}

export default useLoadWallet