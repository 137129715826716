import { CopyAll, MoreHoriz, Refresh, Settings } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { fontWeight } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import { toast } from 'react-toastify';
import useFetchTokenDetails from '../hooks/token/useFetchTokenDetails';
import BlueButton from '../ui/BlueButton';
import BlueGradientButton from '../ui/BlueGradientButton';

function ManageToken() {
    const navigate = useNavigate();
    const location = useLocation();
    const appCtx = useSelector((state) => state.app);
    const dark = appCtx.isDarkMode;
    const { setShowManageTokenOption, showManageTokenOption,fetchToken, showManageTokenSettings, setShowManageTokenSettings } =
        useFetchTokenDetails();
    const { tokenName, tokenIcon, tokenShortName,blockchainFtId, totalBalanceLeft, totalSupply, status } = appCtx.tokenDetails || {};

    return location.pathname === '/fungibletoken/manage' ? (
        <div className="flex flex-col gap-10">
            <div className="font-bold text-2xl">Manage Token</div>
            <>
              
                {appCtx.tokenDetails && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        Token
                                    </TableCell>
                                    <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        Symbol
                                    </TableCell>
                                    <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        Icon
                                    </TableCell>
                                    <TableCell align="center" sx={{ color: dark ? 'white' : 'black' }}>
                                        Total supply
                                    </TableCell>
                                     <TableCell align="center" sx={{ color: dark ? 'white' : 'black' }}>
                                        Asset Id
                                    </TableCell>
                                    {/* <TableCell align="center">Owners</TableCell> */}
                                    <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        Token address
                                    </TableCell>
                                    {/* <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        Actions
                                    </TableCell> */}
                                    <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        Settings
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell 
                                     onClick={(e) => {
                                        navigate('configure-token');
                                    }}
                                    sx={{ color: dark ? 'white' : 'black' ,cursor:"pointer" }} align="center" >
                                        {tokenName}
                                    </TableCell>
                                    <TableCell 
                                     onClick={(e) => {
                                        navigate('configure-token');
                                    }}
                                    sx={{ color: dark ? 'white' : 'black' ,cursor:"pointer" }} align="center" >
                                        {tokenShortName}
                                    </TableCell>
                                    <TableCell
                                         onClick={(e) => {
                                            navigate('configure-token');
                                        }}
                                        sx={{ color: dark ? 'white' : 'black' }}
                                        align="center"
                                        className="relative cursor-pointer"
                                    >
                                        <div className="w-12 h-12 rounded-full overflow-hidden absolute left-1/2 top-2 -translate-x-1/2">
                                            <img src={tokenIcon} className="h-full w-full object-cover" />
                                        </div>
                                    </TableCell>
                                    
                                    <TableCell sx={{ color: dark ? 'white' : 'black' ,cursor:"pointer"}} align="center"
                                     onClick={(e) => {
                                        navigate('configure-token');
                                    }}
                                    >
                                        {totalSupply}
                                    </TableCell>
                                     <TableCell
                                         onClick={(e) => {
                                            navigate('configure-token');
                                        }}
                                        sx={{ color: dark ? 'white' : 'black' }}
                                        align="center"
                                        className="relative cursor-pointer"
                                    >
                                        <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(blockchainFtId){
                                                        window.open(`${process.env.REACT_APP_GATEWAY_ALGO}/asset/${blockchainFtId}`)

                                                    }
                                                }}
                                                className="font-bold "
                                            >
                                                {blockchainFtId || "-"}
                                            </button>
                                    </TableCell>
                                    <TableCell sx={{ color: dark ? 'white' : 'black' }} align="center">
                                        <>
                                            <span>
                                                {`${appCtx.walletDetails?.address?.slice(
                                                    0,
                                                    6
                                                )} .... ${appCtx.walletDetails?.address?.slice(
                                                    appCtx.walletDetails?.address?.length - 3,
                                                    appCtx.walletDetails?.address?.length
                                                )}`}
                                            </span>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigator.clipboard.writeText(appCtx.walletDetails?.address);
                                                }}
                                                className="font-bold "
                                            >
                                                <CopyAll />
                                            </button>
                                        </>
                                    </TableCell>
                                    <TableCell align="center" sx={{ color: dark ? 'white' : 'black' }}>
                                    {appCtx.tokenDetails?.transactionId ? (
                                        <BlueGradientButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(
                                                    `${process.env.REACT_APP_GATEWAY_ALGO}/tx/${appCtx.tokenDetails?.transactionId}`,
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                );
                                            }}
                                        >
                                            view on chain
                                        </BlueGradientButton>
                                    ) :
                                    <BlueGradientButton onClick={(e) =>
                                    {   e.stopPropagation()
                                        fetchToken();
                                    }
                                    } className="flex items-center">
                                            <span>View on chain</span>
                                            <span><Refresh/></span>
                                    </BlueGradientButton>
                                    }
                                    {/* <Popover
                                            isOpen={showManageTokenSettings}
                                            positions={['bottom', 'right']}
                                            padding={20}
                                            reposition={false}
                                            onClickOutside={() => setShowManageTokenSettings(false)}
                                            content={({ position }) => (
                                               <div
                                    className="shadow-md  py-4 px-6  rounded-md flex flex-col gap-2 "
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate('token-details');
                                    }}
                                >
                                    {appCtx.tokenDetails?.transactionId ? (
                                        <BlueGradientButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(
                                                    `${process.env.REACT_APP_GATEWAY_ALGO}/tx/${appCtx.tokenDetails?.transactionId}`,
                                                    '_blank',
                                                    'noopener,noreferrer'
                                                );
                                            }}
                                        >
                                            view on chain
                                        </BlueGradientButton>
                                    ) :
                                    <BlueGradientButton onClick={(e) =>
                                    {   e.stopPropagation()
                                        fetchToken();
                                    }
                                    } className="flex items-center">
                                            <span>View on chain</span>
                                            <span><Refresh/></span>
                                    </BlueGradientButton>
                                    }
                                    <BlueGradientButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigator.clipboard.writeText(appCtx.walletDetails?.address);
                                        }}
                                    >
                                        Copy address
                                    </BlueGradientButton>
                                </div>
                                            )}
                                        >
                                            <button
                                                className=""
                                                onClick={() => setShowManageTokenSettings(!showManageTokenSettings)}
                                            >
                                                <MoreHoriz/>
                                            </button>
                                        </Popover> */}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </>
            {!appCtx.tokenDetails && (
                <div className="flex flex-col gap-16 items-center">
                    <div className="font-bold text-lg">You haven't created any tokens yet. Create one to get it appear here .</div>
                    <div className="flex">
                        <BlueGradientButton className="px-5 md:px-14" onClick={() => navigate('create')}>Create token</BlueGradientButton>
                    </div>
                </div>
            )}
        </div>
    ) : (
        <Outlet />
    );
}

export default ManageToken;
