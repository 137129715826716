import React, { useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Carousel from 'react-material-ui-carousel';
import CarouselItem from './CarouselItem';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import NextArrow from './cars-components/NextArrow';
import PrevArrow from './cars-components/PrevArrow';
import Dot from './cars-components/Dot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function LandingCarousels() {
    const appCtx = useSelector((state) => state.app);
    const slider = useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const items = [
        {
            // img:`${process.env.REACT_APP_ASSET}1.svg`,
            img: `/images/c2/1.svg`,
            heading: 'Web 3.0 Solution for Brands',
            detail: 'Tokenize your digital assets and leverage the power of Web 3.0 from your existing apps and websites',
            key: 1,
            target: "/about/web3-solution"
        },
        {
            // img:`${process.env.REACT_APP_ASSET}2.svg`,
            img: `/images/c2/2.svg`,
            heading: 'NFT launchpad for Game Developers',
            detail: 'Launch and integrate the NFT marketplace into your game. Allow your users to purchase NFT without the need to leave your game',
            key: 2,
            target: '/about/nft-launchpad-for-game-developers'
        },
        {
            // img:`${process.env.REACT_APP_ASSET}3.png`,
            img: `/images/c2/3.png`,
            heading: 'Personal NFT storefront for influencers and celebrities',
            detail: 'Mint your videos, images, and art as NFT and sell them to your fans. Create loyalty program for NFT holders',
            key: 3,
            target: '/about/nft-marketplace-or-storefront'
        },
        {
            // img:`${process.env.REACT_APP_ASSET}4.svg`,
            img: `/images/c2/4.svg`,
            heading: ' Your DAO launchpad',
            detail: 'Launch and manage your decentralized autonomous organization, issue Web 3.0 membership through your own crypto token and NFTs',
            key: 4,
            target: '/about/dao-launchpad'
        },
        {
            // img:`${process.env.REACT_APP_ASSET}5.svg`,
            img: `/images/c2/5.svg`,
            heading: 'Web 3.0 for events',
            detail: 'Host your event, take RSVPs on the blockchain, issue proof of attendance NFTs and manage your community',
            key: 5,
            target: '/about/web-3-events'
        }
    ];
    return (
        <div className="mt-[80px]  ">
            {/* <Carousel  className='MuiCarousel-animationNone' > */}
            <div className="relative">
                <button
                    onClick={() => slider.current.slickPrev()}
                    className="absolute top-1/2 -translate-y-1/2 left-2 md:left-7 text-white z-10"
                >
                    <FontAwesomeIcon icon={faChevronLeft} fontSize={30} />
                </button>
                <button
                    onClick={() => slider.current.slickNext()}
                    className="absolute top-1/2 -translate-y-1/2 right-2 md:right-7 text-white z-10"
                >
                    <FontAwesomeIcon icon={faChevronRight} fontSize={30} />
                </button>
                <Slider ref={slider} {...settings}>
                    {items.map((item) => 
                        
                            <CarouselItem
                                img={item.img}
                                key={item.key}
                                heading={item.heading}
                                detail={item.detail}
                                target={item.target}
                            />
                        
                    )}
                </Slider>
            </div>
        </div>
    );
}

export default LandingCarousels;
