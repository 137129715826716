import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BlueGradientButton from '../ui/BlueGradientButton';
import Input from '../ui/Input';

function ConfigureToken() {
    const appCtx = useSelector(state => state.app)
    const {tokenName,tokenIcon,tokenShortName,totalBalanceLeft,totalSupply,status} = appCtx.tokenDetails || {}
    const navigate = useNavigate();
  return (
      <div className="flex flex-col  gap-8">
          <div className="font-bold text-2xl">Manage token</div>
          <div className="flex flex-col md:flex-row">
              <div className="flex w-full md:w-1/2 md:p-10 justify-center">
                  <div className="shadow-md w-4/5 md:w-3/5 aspect-square rounded-md overflow-hidden border">
                      <img src={tokenIcon} className="h-full w-full object-contain" />
                  </div>
              </div>
              <div className="flex w-full md:w-1/2 md:p-10">
                <div className='flex flex-col gap-4 w-full'>
                    <Input
                    value={appCtx.tokenDetails.tokenName}
                    disabled={true}
                     description="Token name"
                     placeholder="token name"
                     />
                     <Input
                    value={appCtx.tokenDetails.tokenShortName}
                    disabled={true}
                     description="Token short name"
                     placeholder="token name"
                     />
                      <Input
                      disabled={true}
                      value={appCtx.walletDetails.address}
                     description="Token contact address"
                     placeholder="token contact address"
                     />

                     {/* <BlueGradientButton
                     onClick={() => navigate("/fungibletoken/manage/token-details")}
                     >Continue</BlueGradientButton> */}
                </div>
                </div>
          </div>
          <div className='flex justify-center items-center gap-4'>
                <div className='font-bold'>
                Manage all activites of your token in your Web 2 Applications by integrating  our specially curated APIs and SDKs
                </div>
                <BlueGradientButton 
                 onClick={() =>
                    window.open(
                        `https://developer.onnftverse.com/`,
                        '_blank',
                        'noopener,noreferrer'
                    )
                }
                >
                    Learn more
                </BlueGradientButton>
          </div>
      </div>
  );
}

export default ConfigureToken