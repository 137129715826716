import { DeleteForever } from '@mui/icons-material';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import { toast } from 'react-toastify';
import BlueGradientButton from './BlueGradientButton';

function CategoryItem({ category, marketplaceId, loadCategories }) {
    const [disabled, setDisabled] = useState(false);
    const [openDeletePop,setOpenDeletePop] = useState(false)
    const appCtx = useSelector((state) => state.app);
    const deletCat = useCallback(() => {
        setDisabled(true);
        let config = {
            method: 'delete',
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${marketplaceId}/category/${category?.categoryId}/delete`,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken,
                'X-App-Token': process.env.REACT_APP_APP_TOKEN
            }
        };
        axios(config)
            .then((res) => {
                console.log('res', res);
                loadCategories();
                setDisabled(false);
                toast.success('Category deleted successfull');
            })
            .catch((err) => {
                setDisabled(false);
                toast.error('Category could not be deleted');
            });
    }, [category]);
    return (
        <div className=" md:w-2/5 lg:w-[30%] flex flex-col gap-3 items-center hover:scale-105 transition-all rounded-lg shadow-md h-80 py-3">
            <div className="w-11/12 h-60  overflow-hidden rounded-md">
                <img src={category?.banner || "/images/categorybanner.png"} className="object-contain h-full w-full " />
            </div>
            <div className="flex w-full justify-between px-5 ">
                <div className="font-bold ">{category?.category}</div>
                {/* <Popover
                    isOpen={openDeletePop}
                    className="z-10"
                    positions={['top', 'right']}
                    padding={20}
                    reposition={false}
                    onClickOutside={() => setOpenDeletePop(false)}
                    content={() => (
                        <div className="flex flex-col px-10 py-10 rounded-md bg-white mr-[250px] mb-4 gap-5">
                            <div className="font-bold">Delete this category </div>
                            <div className='flex justify-between gap-4'>
                                <BlueGradientButton disabled={disabled} onClick={deletCat} className="py-2">Confirm</BlueGradientButton>
                                <BlueGradientButton onClick={()=> setOpenDeletePop(false)} className="bg-gray-600">Cancel</BlueGradientButton>
                            </div>
                        </div>
                    )}
                >
                    <button disabled={disabled} onClick={() => setOpenDeletePop(!openDeletePop)}>
                        <DeleteForever />
                    </button>
                </Popover> */}
            </div>
            {/* <div className='flex w-full flex-col items-end pr-5 relative bottom-8'>
                <div className='font-semibold ' >3.7</div>
                <div className='text-sm'>No of Items</div>
        </div> */}
        </div>
    );
}

export default CategoryItem;
