import React, { useCallback, useEffect, useState } from 'react';
import useFetchEvents from '../../hooks/events/useFetchEvents';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchOrgCertificates from '../../hooks/events/useFetchOrgCertificates';
import useFetchEventAttendees from '../../hooks/events/useFetchEventAttendees';
import AssetPulse from '../../components/common/AssetPulse';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import AttendeeContainer from '../../components/events/AttendeeContainer';
import EmptyContent from '../../ui/EmptyContent';
import BlueGradientButton from '../../ui/BlueGradientButton';
import useVerifyAttendee from '../../hooks/events/useVerifyAttendee';

function AttendeesList() {
    const { eventList, isFound, loading } = useFetchEvents();
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(0);
    const { verifyAttendee, success ,setSuccess} = useVerifyAttendee();
    const { loading: attendeesLoading, isFound: attendeesFound, attendees, fetchAttendees } = useFetchEventAttendees();

    
    const handleEventChange = useCallback(
        (e) => {
            setSelectedEvent(+e.target.value);
            fetchAttendees(e.target.value)
        },
        [selectedEvent]
    );
    useEffect(() => {
        if (eventList?.length > 0) {
            setSelectedEvent(eventList[0]?.eventId);
            fetchAttendees(eventList[0]?.eventId)
        }
    }, [eventList]);
    useEffect(()=>{
        if(success){
          setSuccess(false)
          fetchAttendees(selectedEvent)
        }
    },[success])
    return loading ? (
        <div className="h-10 border bg-gray-400/30 animate-pulse rounded-md"></div>
    ) : isFound ? (
        <div className="flex flex-col gap-8">
            <div className='flex justify-between items-center'>
                <select
                    className="p-3 rounded-md text-gray-500"
                    value={selectedEvent}
                    onChange={handleEventChange}
                >
                    {eventList?.map((event) => {
                        return <option value={event?.eventId}>{event?.eventName}</option>;
                    })}
                </select>
               {attendees?.length > 0 && <BlueGradientButton className="md:px-10" onClick={() => verifyAttendee(selectedEvent,0)}>Verify all</BlueGradientButton>}
            </div>
            {attendeesLoading ?  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10'>
                      <AssetPulse/>
                      <AssetPulse/>
                      <AssetPulse/>
                      <AssetPulse/>
                      <AssetPulse/>
                      <AssetPulse/>
            </div> :attendeesFound ? attendees?.length > 0 ?<div className='flex flex-col gap-3'>
              <div className='grid grid-cols-12 font-semibold'>
                <div className='col-span-1'>No.</div>
                <div className='col-span-3'>Name</div>
                <div className='col-span-3'>Email</div>
                <div className='col-span-3'>Status</div>
                <div className='col-span-2'>Action</div>
              </div>
               {
                  attendees.map((attendee, index) =>{
                    return <AttendeeContainer attendee={attendee} key={attendee.id} index = {index} fetchAttendees={fetchAttendees}/>
                  })
              }
              
            </div>:<EmptyContent  message={"No attendee has been found"} />:<ErrorDisplay />}
        </div>
    ):<ErrorDisplay/>;
}

export default AttendeesList;
