import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AssetPulse from '../../../components/common/AssetPulse';
import useFetchCertificate from '../../../hooks/events/useFetchCertificate';
import useFetchClaimers from '../../../hooks/events/useFetchClaimers';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import DivContainer from '../../../ui/DivContainer';
import EmptyContent from '../../../ui/EmptyContent';
import Input from '../../../ui/Input';
import { FilteredCertificate } from '../certificate/FilteredCertificate';
import { ClaimList } from '../ClaimList';

export const MembershipHome = () => {
    const navigate = useNavigate();
    const appCtx = useSelector((state) => state.app);
    const [selectedCategory, setSelectedCategory] = useState('Membership NFT');
    const [selected, setSelected] = useState(false);

    const makeAuthorizedRequest = useAuthorizedHttp();
    const { certificateList, isFound, loading } = useFetchCertificate('Membership NFT');

    return (
        <>
            <DivContainer className="flex justify-between items-center  rounded-md mb-[20px]">
                <span
                    className="text-lg font-bold"
                    onClick={() => {
                        setSelected(false);
                        navigate('/membership/create');
                    }}
                ></span>
                <BlueGradientButton
                    onClick={() => {
                        setSelected(true);
                        // navigate('/membership/create')
                    }}
                    className="px-5  md:px-8"
                >
                    Membership NFT
                </BlueGradientButton>
            </DivContainer>
            {/* <div className='mb-[20px] w-[150px]'>
                    <Input value="Membership NFT" className={`!bg-white !text-black`}/>
            </div> */}

            {loading ? (
                <AssetPulse />
            ) : (
                <>
                    {certificateList?.length > 0 ? (
                        <FilteredCertificate
                            certificateList={certificateList}
                            isFound={isFound}
                            loading={loading}
                            navigate={navigate}
                            membership={true}
                        />
                    ) : (
                        <DivContainer>
                            <div className="flex flex-col items-center gap-5">
                                <EmptyContent message="No membership NFT found" />
                                <BlueGradientButton
                                    onClick={() => {
                                        navigate('/membership/create');
                                    }}
                                    className="px-5  md:px-8"
                                >
                                    Create membership NFT
                                </BlueGradientButton>
                            </div>
                        </DivContainer>
                    )}
                </>
            )}
        </>
    );
};
