import React from 'react';

export const EventPageNavbarItem = () => {
    return (
        <div className="flex justify-evenly w-1/2 ">
            <div>Home</div>
            <div>About us</div>
            <div>Events</div>
        </div>
    );
};
