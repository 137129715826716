import NewsDisplay from '../components/crypto-news/NewsDisplay';
import CoinDisplay from '../components/crypto-news/CoinDisplay';
import React from "react"

const CryptoNews = (props) => {
    return (
        <>
            <CoinDisplay />
            <NewsDisplay />
        </>
    );
};

export default CryptoNews;
