import React from 'react';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useNavigate } from 'react-router-dom';
import useFetchEvents from '../../hooks/events/useFetchEvents';
import DivContainer from '../../ui/DivContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from '@mui/material';
import AssetPulse from '../../components/common/AssetPulse';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import EventCard from '../../components/events/EventCard';
import EmptyContent from '../../ui/EmptyContent';

function MyEvents(props) {
    const navigate = useNavigate();

    const { eventList, isFound, loading } = useFetchEvents();
    return (
        <div className="flex flex-col gap-3">
            <DivContainer className="flex justify-between items-center  rounded-md">
                <span className="text-lg font-bold">My Events</span>
                <BlueGradientButton onClick={() => navigate('/events/create-event')} className="px-5  md:px-8">
                    Create Event
                </BlueGradientButton>
            </DivContainer>
            {/* <DivContainer className="rounded-md py-4 flex justify-start gap-5 items-center">
                <FontAwesomeIcon icon={faSliders} />
                <span>Hosted Events</span>
            </DivContainer> */}
            {loading ? (
                <div className="flex flex-col gap-8">
                    {/* <Skeleton sx={{ width: '100%' }} variant="rectangular" height={50} /> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
                        <AssetPulse />
                        <AssetPulse />
                        <AssetPulse />
                    </div>
                </div>
            ) : isFound ? (
                <>
                    {eventList?.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
                            {eventList?.map((event) => {
                                return <EventCard event={event} key={event.eventId} />;
                            })}
                        </div>
                    ) : (
                        <DivContainer className="py-5">
                            <div className="flex flex-col gap-5 justify-between items-center">
                                <EmptyContent message="You don't have any previously created events. Create one to get it appeared here" />

                                <BlueGradientButton
                                    onClick={() => navigate('/events/create-event')}
                                    className="px-5  md:px-8"
                                >
                                    Create Event
                                </BlueGradientButton>
                            </div>
                        </DivContainer>
                    )}
                </>
            ) : (
                <ErrorDisplay />
            )}
        </div>
    );
}

export default MyEvents;
