import React from 'react';

function EmptyContent({ message }) {
    return (
        <div className="flex flex-col items-center gap-3 ">
            <div className="h-[40vh]">
                <img src="/images/empty-content.png" className="h-full object-contain"  alt="empty-content"/>
            </div>
            <div className='break-words text-center'>
                {message}
            </div>
        </div>
    );
}

export default EmptyContent;
