import { CheckCircleRounded, DiscountRounded, Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import discord from "../../assets/footer/discord.png";
import facebook from "../../assets/footer/facebook.png";
import instagram from "../../assets/footer/instagram.png";
import linkedin from "../../assets/footer/linkedin.png";
import pinterest from "../../assets/footer/pinterest.png";
import telegram from "../../assets/footer/telegram.png";
import twitter from "../../assets/footer/twitter.png";
import youtube from "../../assets/footer/youtube.png";
import useHttp from "../../hooks/use-http";
import { isEmailValid, isPhoneValid } from "../../misc/field-validation";
import BlueGradientButton from "../../ui/BlueGradientButton";
import LandingButton from "../../ui/buttons/LandingButton";
import Input from "../../ui/Input";

const Footer = () => {
	const [hasRegistered, setRegistered] = useState(false);
	const [notifyMeButtonDisabled, setNotifyMeButtonDisabled] = useState(false);
	const [cred, setCred] = useState();
	const navigate = useNavigate();
	const makeRequest = useHttp();

	const openInNewTab = url => {
		window.open(url);
	};

	const successCallback = (data) => {
		
		setRegistered(true);
	};

	const errorCallback = (data) => {
		
	};

	const completeCallback = (data) => {
		
		setNotifyMeButtonDisabled(false);
	};

	const handleNotifyMeClicked = () => {
		let userData = null;
		if (isEmailValid(cred)) {
			userData = {
				email: cred,
			};
		} else if (isPhoneValid(cred)) {
			userData = {
				phone: cred,
			};
		} else {
			toast.error("Email/phone number expected!");
			return;
		}

		setNotifyMeButtonDisabled(true);

		toast.promise(
			() =>
				makeRequest(
					{
						data: userData,
						url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/contactus`,
						method: "POST",
						headers: {
							"X-App-Token": process.env.REACT_APP_APP_TOKEN,
							"Content-Type": "application/json",
						},
					},
					successCallback,
					errorCallback,
					completeCallback
				),
			{
				pending: "Registering you...",
				success: "You have successfully registered!",
				error: "Looks like there was a problem!",
			}
		);
	};

	return (
        <div className="landing-bg px-10 gap-9 flex flex-col  py-8  ">
            <div className="flex justify-between flex-col md:flex-row gap-5">
                <div className="h-fit  text-gray-50 gap-6 lg:gap-16 flex flex-col  items-center md:items-start">
                    <div className={`${hasRegistered ? 'flex' : 'hidden'} justify-center text-green-600`}>
                        <CheckCircleRounded />
                        <span className="ml-3">Your response has been noted. We will contact you shortly!</span>
                    </div>
                    <div className="flex flex-col gap-3">
                        {/* <div className="text-lg">Stay Connected</div> */}
                        <div className={`${hasRegistered ? 'hidden' : 'flex'} items-center justify-center`}>
                            <Input
                                placeholder="Enter number or email"
                                border="left"
                                className="sm:w-[200px] lg:w-[400px] h-[52px] sm:h-10 rounded-r-none w-[100%]"
                                value={cred}
                                type="text"
                                onChange={(e) => setCred(e.target.value)}
                            />
                            {/* <BlueGradientButton
                                className="  mt-2 py-3 text-[10px] md:text-base  md:py-[8px] rounded-r-md"
                                border="right"
                                disabled={notifyMeButtonDisabled}
                                onClick={handleNotifyMeClicked}
                            >
                                Notify Me!
                            </BlueGradientButton> */}
                            <LandingButton
                                className="  mt-2 py-5 text-[10px] px-2 md:px-8 md:text-base  md:py-[8px] rounded-l-none"
                                disabled={notifyMeButtonDisabled}
                                onClick={handleNotifyMeClicked}
                            >
                                Notify Me!
                            </LandingButton>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-auto items-center gap-4 md:gap-8 text-white">
                    <div className="font-bold text-xl">Join Our Community</div>
                    <div className="flex  justify-between w-auto">
                        <div
                            className="w-auto cursor-pointer"
                            onClick={() => {
                                window.open('https://discord.gg/7GaSxew48h', '_blank');
                            }}
                        >
                            <img
                                src="/images/DiscordFilled.png"
                                className="w-[80%]"
                                alt="logo"
                                style={{ color: '#0C0C0C' }}
                            />
                        </div>
                        <div
                            className="w-auto cursor-pointer"
                            onClick={() => {
                                window.open('https://www.facebook.com/onnftverse', '_blank');
                            }}
                        >
                            <img
                                src="/images/Facebook.svg"
                                alt="logo"
                                className="w-[80%]"
                                style={{ color: '#0C0C0C' }}
                            />
                        </div>
                        <div
                            className="w-auto cursor-pointer"
                            onClick={() => {
                                window.open('https://twitter.com/onnftverse?s=20&t=d9xAcEQONstJWodcG-8sUA', '_blank');
                            }}
                        >
                            <img
                                src="/images/TwitterFilled.png"
                                alt="logo"
                                className="w-[80%]"
                                style={{ color: '#0C0C0C' }}
                            />
                        </div>
                        <div
                            className="w-auto cursor-pointer"
                            onClick={() => {
                                window.open('https://www.linkedin.com/company/nftverse/', '_blank');
                            }}
                        >
                            <img
                                src="/images/Linkedin.svg"
                                alt="logo"
                                className="w-[80%]"
                                style={{ color: '#0C0C0C' }}
                            />
                        </div>
                        <div
                            className="w-auto cursor-pointer"
                            onClick={() => {
                                window.open('https://www.instagram.com/_nftverse/', '_blank');
                            }}
                        >
                            <img
                                src="/images/Instagram.svg"
                                alt="logo"
                                className="w-[80%]"
                                style={{ color: '#0C0C0C' }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-between flex-col-reverse gap-y-5 md:flex-row">
                <div className=" flex gap-3 items-center justify-center">
                    <img
                        src="/logo.png"
                        alt="logo"
                        className="object-contain w-16 h-16 cursor-pointer"
                        onClick={() => {
                            navigate('/');
                        }}
                    />
                    <div className="text-white font-bold text-lg">NFTVerse</div>
                </div>
                <div className="flex  w-full flex-col md:flex-row md:w-auto text-white text-lg gap-6 items-center">
                    {/* <div>Aboutus</div> */}
                    <NavLink to="/about-us" className={`no-underline text-white`}>
                        About us
                    </NavLink>
                    <button onClick={() => openInNewTab('https://developer.onnftverse.com/')}>Api Docs</button>
                    <NavLink to="/privacy" className={`no-underline text-white`}>
                        Privacy
                    </NavLink>
                    <NavLink to="/faq" className={`no-underline text-white`}>
                        FAQ
                    </NavLink>
                    <NavLink to="/termsandcondition" className={`no-underline text-white`}>
                        Terms & Conditions
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default Footer;


