import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar';
import Footer from '../components/common/Footer';
import AboutUsCard from '../components/common/AboutUsCard';
import { useEffect, useState } from 'react';
import React from "react"
import {Helmet} from "react-helmet";

const AboutUs = () => {
    const [ourTeamData, setOurTeamData] = useState([
        {
            image: '/images/neel.jpg',
            name: 'Neel Kamal ',
            designation: 'Founder and CTO',
            linkedIn: 'https://www.linkedin.com/in/neel-kamal-b4067425/',
            description: 'Neel comes with 9 years of industry experience in startups like LeadSquared, Cropin Technology (Principal Engineer), and Eloelo (VP of Engineering). Most recently, he worked with Joyride games - a web3 gaming company that was an entry point to web3 for him. A problem solver by heart, Neel loves building products that scale to the masses.            ',
            employee: false
        },
        {
            image: '/images/c1.jpg',
            name: 'Chandrashekhar Shaw',
            designation: 'Co-Founder',
            linkedIn: 'https://www.linkedin.com/in/chandrashekhar-shaw/',
            description: 'Chandrashekhar is a principal software developer at Yahoo. Chandrashekhar has 10 years of experience in building highly scalable products for 1 billion users. His expertise includes increasing scale, reducing latency, improving operability, and raising customer satisfaction while delivering quality in engineering best practices.',
            employee: false,
        },
        {
            image: '/images/p.jpg',
            name: 'Peeyush Singh',
            designation: 'Co-Founder',
            linkedIn: 'https://www.linkedin.com/in/peeyush-90071222/',
            description: 'Peeyush has a wide experience of working with fashion retail and supply chain companies. He is the VP of Engineering at Triveni IT & helping KiksAR (a startup in the field of Augmented Reality and computer vision) to acquire brands like Signet Jewellers, Polo Ralph Lauren, Amazon, Walmart in the field of 3D technology, Virtual Try On, Metaverse            ',
            employee: false,
        },
        {
            image: "/images/suraj.jpg",
            name: "Suraj Yadav",
            linkedIn: "https://www.linkedin.com/in/suraj-yadav-569991173",
            designation: "Associate Product Manager",
            employee: true,
        },
        {
            image: "/images/sahil.png",
            name: "Sahil Deshmukh",
            linkedIn: " https://www.linkedin.com/in/sahil-deshmukh-216a4b1a6",
            designation: "Associate Product Manager",
            employee: true,
        },
        {
            image: "/images/samardip.jpg",
            name: "Samardip Mandal",
            linkedIn: "https://www.linkedin.com/in/samardipmandal/",
            designation: "Associate Software Engineer",
            employee: true,
        },
        {
            image: "/images/ashutosh.jpg",
            name: "Ashutosh Singh",
            linkedIn: "https://www.linkedin.com/in/ashutosh-singh-63323a205/",
            designation: "Associate Software Engineer",
            employee: true,
        },
        {
            image: "/images/monisha.jpg",
            name: "Monisha Judith",
            linkedIn: "https://www.linkedin.com/in/monisha-judith-b37454242/",
            designation: "Associate UI/UX Designer",
            employee: true,
        },
    ]);
    const [investorsAndAdvisorData, setInvestorsAndAdvisorData] = useState([
        {
            name: 'God Samardip',
            image: '/images/flow.png'
        },
    ])
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
        <Helmet>
            <title>title</title>
            <link rel="icon" href={"path"}/>
            <meta name="description" content={"description"}/>
            <meta name="keywords" content={"keyword"}/>
            <meta property="og:title" content={"og:title"}/>
            <meta property="og:description" content={"og:description"}/>
        </Helmet>
        <div className="dark landing-bg flex -col  h-full text-slate-50">
            <UnauthenticatedNavbar />
            <div className="dark landing-bg .bg-zinc-800  w-full h-full text-slate-50">
                <div className="flex flex-col mt-20 gap-10 ">
                    <div className="flex flex-col px-10 py-5 gap-6 md:gap-0">
                        <div className=" flex text-3xl font-bold justify-center ">
                            <div className="border-b-[10px] border-secPurple">About US</div>
                        </div>
                        <div className="flex justify-center lg:justify-between items-center md:mt-[50px] flex-wrap-reverse gap-4 sm:gap-0">
                            <div className='flex w-full  md:w-[48%] justify-center'>
                                <div className=" md:text-lg lg:ml-[23%] text-justify">
                                    NFTVerse is a white-label SaaS-based Web 3.0 platform to bridge the gap between Web 2.0 and Web 3.0. NFTVerse allows enterprises, brands, celebrities, influencers, and creators to launch their own fully customizable web 3.0 apps, including NFT Marketplace, Crypto Wallets, and Crypto Tokens, by integrating with their existing web2 apps and websites.
                                    <br/>
                                    <br/>
                                    We are supporting FLOW and ALGORAND. Ethereum, Solana, and Polygon are in our pipeline.
                                    <br />
                                </div>
                            </div>
                            <div className="flex w-full sm:w-[50%] md:w-1/2 justify-center">
                                <img src="/images/aboutuspage.jpg" className="object-contain w-[100%] sm:w-[92%] lg:w-[70%]" alt="about-us banner" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-col px-10 py-5 gap-5" id="chain-support">
                        <div className=" flex text-3xl font-bold justify-center ">
                            <div className="border-b-[10px] border-secPurple">Chains Supporting</div>
                        </div>
                        <div className="flex md:text-lg justify-center">
                            we are supporting FLOW and ALGORAND. Ethereum, Solana, Polygon are in our pipeline.
                        </div>
                        <div className="flex justify-between items-center bg-secPurple p-10">
                            <div className="flex w-1/2 justify-center">
                                <img src="/images/flow.png" className="object-contain w-3/5" alt="about-us banner" />
                            </div>
                            <div className="flex w-2/5 md:text-3xl ">Explore the Power of FLOW Blockchain through NFTVerse.</div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-10 px-10 py-5 gap-5 w-full">
                        <div className="flex justify-between items-center bg-secPurple p-10">
                            <div className="flex w-2/5 md:text-3xl ">
                                Explore the Power of Algorand Blockchain through NFTVerse
                            </div>
                            <div className="flex w-1/2 justify-center">
                                <img
                                    src="/images/algorand.png"
                                    className="object-contain w-3/5"
                                    alt="about-us banner"
                                />
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="flex flex-col px-10 py-5 gap-5" id="team">
                        <div className=" flex text-3xl font-bold justify-center ">
                            <div className="border-b-[10px] border-secPurple">Our Team</div>
                        </div>
                        <div className='flex  justify-center items-center' >
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-[100%] lg:m-[50px] justify-center items-center mt-[20px] md:px-10 flex-wrap gap-10 gap-y-12 " >
                                {
                                    ourTeamData.map((teamData) => {
                                        return (
                                            <>
                                                <AboutUsCard
                                                    image={teamData.image}
                                                    name={teamData.name}
                                                    designation={teamData.designation}
                                                    linkedIn={teamData.linkedIn}
                                                    employee={teamData.employee}
                                                >
                                                    {teamData.description}
                                                </AboutUsCard>
                                            </>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        {/* <div className=" flex text-3xl font-bold justify-center ">
                            <div className="border-b-[10px] border-secPurple">Investors & Advisors</div>
                        </div>
                        <div className="grid grid-cols-4 justify-center items-center px-10 flex-wrap gap-[10%] gap-y-12 ml-[91px] mr-[91px] mt-[50px] mb-[50px]">
                            {investorsAndAdvisorData.map((data) => {
                                return (<>
                                    <div className='flex flex-col justify-center items-center'>
                                        <div className='w-[100%]'>
                                            <img className='w-[100%]' src={data.image} alt="logo" />
                                        </div>
                                        <div className='mt-[15px] text-[30px]'>{data.name}</div>
                                    </div>
                                </>)
                            })}
                        </div> */}

                    </div>
                </div>
                <Footer />
            </div>
        </div>
        </>
    );
};

export default AboutUs;
