import { useDispatch, useSelector } from 'react-redux';
import FinishAccountSetup from '../components/home/FinishAccountSetup';
import CreatePasswordModal from '../components/navbar/CreatePasswordModal';
import BlueButton from '../ui/BlueButton';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import React from 'react';
import { appActions } from '../context/app-slice';
import axios from 'axios';
import { persistor } from '../context/store';

const Home = (props) => {
    const appCtx = useSelector((state) => state.app);
    const navigator = useNavigate();
    const [marketplace, setMarketplace] = useState({});
    const makeRequest = useAuthorizedHttp();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        let config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken,
                'X-App-Token': process.env.REACT_APP_APP_TOKEN
            }
        };
        axios(config)
            .then((res) => {
                setMarketplace(res?.data[0]?.domain);
                dispatch(appActions.setMarketType(res?.data[0]?.appType));
                dispatch(appActions.setMarketplaceId(res?.data[0]?.marketplaceId));
                makeRequest(
                    {
                        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${res?.data[0]?.marketplaceId}/wallet/balance?blockchain=${appCtx?.blockchain}`
                    },
                    (data) => {
                        dispatch(appActions.setBlockChain(data.blockchain));
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            })
            .catch((err) => {
                if (err?.code === 'ERR_BAD_REQUEST') {
                    localStorage.removeItem('display');
                    localStorage.removeItem('contact');
                    localStorage.clear();
                    persistor.purge().then(() => (window.location.href = '/'));
                }
            });
        // makeRequest(
        //     {
        //         url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`,
        //         headers: {
        //             "Content-Type": "application/json",
        //             "X-Auth-Token": 'appCtx.authToken',
        //             "X-App-Token": 'process.env.REACT_APP_APP_TOKEN'
        //         },
        //     },
        //     (data) => {

        //     },
        //     (error) => {
        //         console.log(error);
        //     }
        // );
    }, [dispatch, makeRequest]);
    console.log('appctx', appCtx);
    

    return (
        <>
            {!appCtx.passwordCreated && <CreatePasswordModal header={'Set up your password'} />}
            <div className="h-fit w-full gap-20">
                <div className="mx-auto text-2xl mb-8 font-bold ">Welcome to NFTVerse </div>
                {appCtx.newSignup && <FinishAccountSetup />}
                <div className="flex flex-col gap-3">
                    {/* <BlueButton
                        onClick={() =>
                            navigator(
                                `/marketplace/edit-home-page?marketplaceId=${marketplace?.marketplaceId}&marketplaceName=${marketplace?.name}&domain=${marketplace?.domain}`
                            )
                        }
                    >
                        configure your marketplace
                    </BlueButton> */}
                    {/* <span className="mx-auto">Welcome {appCtx.userDetails.name}!</span>
                    <span className="mx-auto">You are logged in as: {appCtx.userDetails.email}</span> */}
                    {appCtx.newSignup || (
                        <div className="flex flex-wrap gap-[10%] gap-y-10 justify-center">
                            {appCtx.marketType !== 'DAO' ? (
                                <>
                                    <div className="flex  flex-col w-96 rounded-md overflow-hidden bg-white shadow-md gap-3  justify-between   hover:transform hover:scale-105 transition-all">
                                        <img src="/images/tut1.png" className="w-full h-48 object-cover" />
                                        <div className="justify-center flex text-black p-4">
                                            Edit the marketplace the way you want with our given customize marketplace
                                            features.
                                        </div>
                                        <button
                                            onClick={() => navigate(`/marketplace/update-header`)}
                                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                        >
                                            Customize Your MarketPlace
                                        </button>
                                    </div>
                                    {/*<div className="flex  flex-col w-96 rounded-md bg-white  overflow-hidden shadow-md gap-3    justify-between  hover:transform hover:scale-105 transition-all">*/}
                                    {/*    <img src="/images/tut2.png" className="w-full h-48 object-cover" />*/}
                                    {/*    <div className="justify-center flex text-black p-4">*/}
                                    {/*        Set up your wallet for performing NFTs related transactions on our platform*/}
                                    {/*    </div>*/}
                                    {/*    <button*/}
                                    {/*        onClick={() => navigate('/wallet')}*/}
                                    {/*        className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"*/}
                                    {/*    >*/}
                                    {/*        Setup Your Wallet*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                    <div className="flex  flex-col w-96 rounded-md bg-white  overflow-hidden shadow-md gap-3  justify-between  hover:transform hover:scale-105 transition-all">
                                        <img src="/images/tut3.png" className="w-full h-48 object-cover" />
                                        <div className="justify-center flex text-black p-4">
                                            Create your first NFT of Artwork, games, music, video or anything else.
                                        </div>
                                        <button
                                            onClick={() => navigate(`/asset?type=${'upload'}`)}
                                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                        >
                                            Create Your First NFT
                                        </button>
                                    </div>
                                    <div className="flex  flex-col w-96 rounded-md bg-white overflow-hidden shadow-md gap-3 justify-between  hover:transform hover:scale-105 transition-all">
                                        <img src="/images/tut4.png" className="w-full h-48 object-cover" />
                                        <div className="justify-center flex text-black p-4">
                                            Put your first NFT on Sale on your marketplace and earn good.
                                        </div>
                                        <button
                                            onClick={() => navigate(`/asset?type=${'upload'}`)}
                                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                        >
                                            List it For Sale
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex  flex-col w-96 rounded-md overflow-hidden bg-white shadow-md gap-3  justify-between   hover:transform hover:scale-105 transition-all">
                                        <img src="/images/configure.svg" className="w-full h-48 " />
                                        {/* <div className="justify-center flex text-black p-4 font-bold">
                                            Configure Event Page
                                        </div> */}
                                        <button
                                            onClick={() => navigate(`/marketplace/update-header`)}
                                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                        >
                                            Configure organization Page
                                        </button>
                                    </div>
                                    <div className="flex  flex-col w-96 rounded-md overflow-hidden bg-white shadow-md gap-3  justify-between   hover:transform hover:scale-105 transition-all">
                                        <img src="/images/uploadnft.svg" className="w-full h-48 " />
                                        {/* <div className="justify-center flex text-black p-4 font-bold">
                                            Upload your NFTs
                                        </div> */}
                                        <button
                                            onClick={() => navigate(`/events/create-event`)}
                                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                        >
                                            Create Event
                                        </button>
                                    </div>
                                    <div className="flex  flex-col w-96 rounded-md overflow-hidden bg-white shadow-md gap-3  justify-between   hover:transform hover:scale-105 transition-all">
                                        <img src="/images/submitemail.svg" className="w-full h-48 " />

                                        <button
                                            onClick={() => navigate(`/certificate/create`)}
                                            className="text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4"
                                        >
                                            Create certificates
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
