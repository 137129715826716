import React from 'react'
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';
import Working from '../../components/landing/Working';
import LandingButton from '../../ui/buttons/LandingButton';

function Web3Solution() {
    const dispatch = useDispatch();
    const useCases = [{
        src: '/images/w3s/i1.svg',
        title: 'WEB 3.0 APIs and SDKs  ',
        desc: 'Add nft support in your web 2.0 apps and website using our easy to use SDKs and APIs',
    },
    {
        src: '/images/w3s/i2.svg',
        title: 'Customisable Smart Contracts',
        desc: 'Variety of ready to deploy Smart Contracts to  chose from which can be further customized for your use case',
    },
    {
        src: '/images/w3s/i3.svg',
        title: 'Metaverse',
        desc: 'Showcase your products in a whole immersive way by incorporating metaverse support into your product.        ',
    },
    {
        src: '/images/w3s/i4.svg',
        title: 'Whitelabel NFT marketplace /storefront',
        desc: 'Start  minting your NFTs by launching a fully customizable marketplace/store which supports Multiple blockchain.',
    },
    {
        src: '/images/w3s/i5.svg',
        title: 'Crypto Token',
        desc: 'Launch your own brand token or crypto token that helps to attract and retain customers more efficiently, by creating deep incentives to strengthen loyalty.',
    },
    {
        src: '/images/w3s/i6.svg',
        title: 'Whitelabel Crypto Wallet',
        desc: 'Create your own crypto wallet for your brand to provide hassle free entry to your product. ',
    }]
    return (
        <div className="flex flex-col gap-24">
            <div
                className="grid bg-cover grid-cols-1 md:grid-cols-2  h-screen bg-no-repeat"
                style={{ backgroundImage: `url(/images/w3s/1.svg)` }}
            >
                <div className="justify-start flex">
                    <div className="flex flex-col justify-center  gap-5 w-4/5 pl-20">
                        <div className="font-semibold text-4xl">Web 3.0 solution for brands</div>
                        <div className="text-2xl">
                            Are you a web 2 company looking for web 3 solutions to be integrated in your product. Then you are at right place.                        </div>
                        <div className="flex justify-start">
                            <LandingButton onClick={() => dispatch(appActions.setCreateAccountModal(true))}>
                                Get Started
                            </LandingButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-10 px-20">
                <div className="text-center text-semibold text-4xl">Use Cases</div>
                <div className=" px-10 grid grid-cols-1 md:grid-cols-3 min-h-0 gap-10">
                    {/* <div className='flex justify-center'>
                        <img src="/images/w3s/2a.svg" />
                    </div>
                    <div className='flex justify-center '>
                        <img src="/images/w3s/2b.svg" />
                    </div>
                    <div className='flex justify-center'>
                        <img src="/images/w3s/2c.svg" />
                    </div>
                    <div className='flex justify-center  '>
                        <img src="/images/w3s/2d.svg" />
                    </div>
                    <div className='flex justify-center  '>
                        <img src="/images/w3s/2d.svg" />
                    </div> */}
                    {
                        useCases?.map((cases) => {
                            return (
                                <>
                                    <div className='flex flex-col items-center justify-center gap-5'>
                                        <img src={cases.src} className="h-[300px]"/>
                                        <div className='font-bold text-xl text-center'>{cases.title}</div>
                                        <div className='text-center text-sm'>{cases?.desc}</div>
                                    </div>
                                </>
                            )
                        })
                    }


                </div>
            </div>
            <Working />
        </div>
    );
}

export default Web3Solution