import { Download, LocationOn } from '@mui/icons-material';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ThreeDRenderer from '../components/common/ThreeDRenderer';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import Papa from "papaparse";

export const UploadEmail = () => {
    const makeRequest = useAuthorizedHttp();
    const fileRef = useRef();
    const bannerFileRef = useRef();
    const [csvEmailFile, setCsvEmailFile] = useState(null);
    const [nftPreview, setNftPreview] = useState();
    const [uploadingBanner, setUploadingBanner] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [showUploadBannerButton, setShowUploadBannerButton] = useState(true);
    const [emailArray, setEmailArray] = useState('');
    const asset = appCtx.nftData;

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/marketplace/${appCtx.marketplaceId}/nft/claimers/${appCtx.nftData?.ipfsHash}`,
            },
            (data) => {
                let emailString = '';
                data?.content.map((data) => {
                    emailString = [...emailString, data.email]
                })
                console.log(emailString);
                setEmailArray(emailString);
                setCsvEmailFile({ name: 'CSV Uploaded' })
            },
        );
    }, [appCtx.marketplaceId, appCtx.nftData, makeRequest])

    const handleEmailData = (e) => {
        e.preventDefault();
        let responseBody = [];
        try {
            emailArray?.map((email) => {
                const uploadData = {
                    email: email,
                    cid: appCtx.nftData.ipfsHash,
                    marketplaceId: appCtx.marketplaceId
                }
                responseBody = [...responseBody, uploadData];
            })
            toast.promise(
                () =>
                    makeRequest(
                        {
                            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/nft/claimers`,
                            method: 'POST',
                            data: responseBody
                        },
                        (data) => {
                        },
                        (data) => console.log(data),
                    ),
                {
                    pending: 'uploading CSV File',
                    success: 'Email uploaded successfully !!!',
                    error: 'Something went wrong !!!'
                }
            );
        }
        catch {
            toast('Upload Email of the given format !!!')
        }
    };

    const handleUploadCsv = useCallback((file) => {

        if (file) {
            // if (file.size > 4 * 1024 * 1024) {
            //     toast.error('File size exceeds 3mb!');
            //     return false;
            // }
            console.log(file);
            setCsvEmailFile(file);
            const objectUrl = URL.createObjectURL(file);
            setNftPreview(objectUrl);
            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });
                const parsedData = csv?.data;
                const columns = Object.keys(parsedData[0]);
                console.log(parsedData);
                let dataArray = [];
                parsedData.map((data) => {
                    dataArray = [...dataArray, data.Email];
                })
                setEmailArray(dataArray);
            };
            reader.readAsText(file);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const downloadParaphrase = async () => {

        // console.log("keys", keys);
        const element = document.createElement("a");
        // const file = new Blob(csvEmailFile, { type: 'csv/plain' });
        try {
            element.href = URL.createObjectURL(csvEmailFile);
            element.download = csvEmailFile?.name;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        catch {
            let string = 'Email\n';
            emailArray.map((email) => {
                string = string + email + '\n'
            })
            console.log(string);
            var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' });
            console.log(blob);
            element.href = URL.createObjectURL(blob);
            element.download = 'emailList';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
    }
    const downloadTemplate = async (e) => {
        e.preventDefault();
        const element = document.createElement("a");
        let string = '';
        // emailArray.map((email)=>{
        //     string=string+email+';'
        // })
        string = string + 'Email\nsample1@gmil.com\nsample2@gmail.com';
        console.log(string);
        var blob = new Blob([string], { type: 'text/csv;charset=utf-8;' });
        console.log(blob);
        element.href = URL.createObjectURL(blob);
        element.download = 'emailList';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    return (
        <>
            <div className='flex justify-between'>
                <div>
                    <div className="flex flex-col gap-3">
                        <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Upload Email List</div>
                        <button
                            onClick={() => bannerFileRef.current.click()}
                            className="group bg-[#EAEAEA] dark:bg-gray-700 dark:bg-zing-700  w-[350px] h-[100px] rounded-lg"
                        >
                            <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-700 items-center flex justify-center h-full rounded-lg transition-all ease-out duration-300 text-white">
                                {csvEmailFile === null ? "Browse for CSV File" : `${csvEmailFile?.name?.substring(0, 15)}`}
                            </div>
                            {/* {nftPreview && (
                                <img
                                    src={nftPreview}
                                    alt="NFT"
                                    className="object-cover relative bottom-[100px]  w-[350px] h-[100px] z-10  overflow-hidden rounded-lg"
                                />
                            )} */}
                        </button>

                        {/* {nftPreview && <div className='flex justify-center'>{!uploadingBanner && showUploadBannerButton && <button
                            disabled={!csvEmailFile || uploadingBanner}
                            onClick={handleUploadBanner}
                            // onClick={() => fileRef.current.click()}
                            className="group px-20 bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12  text-white"
                        >
                            Confirm
                        </button>}</div>} */}
                        <input
                            hidden={true}
                            type="file"
                            ref={bannerFileRef}
                            accept=".csv"
                            onChange={(e) => {
                                handleUploadCsv(e.target.files[0])
                                setShowUploadBannerButton(true)
                            }}
                        />
                    </div>
                    {csvEmailFile &&
                        <div className='w-[350px] flex justify-end mt-[30px]'>
                            <div className='h-[40px] w-[100%] p-[10px] bg-gray-700 flex justify-center items-center rounded-lg cursor-pointer' onClick={() => downloadParaphrase()}>
                                Download uploaded CSV <Download />
                            </div>
                        </div>
                    }
                    <div className='w-[350px] flex justify-end mt-[30px]'>
                        <div className='h-[40px] w-[100%] p-[10px] bg-gray-700 flex justify-center items-center rounded-lg cursor-pointer' onClick={(e) => downloadTemplate(e)}>
                            Download Template File <Download />
                        </div>
                    </div>
                    <div className="flex justify-center gap-32 flex-wrap items-center mt-[20px]">
                        {/* {confirm && <div className="text-green-500">Marketplace header details updated successfully </div>} */}
                        {csvEmailFile && (
                            <Link
                                // to='/uploademail'
                                className=" no-underline bg-secBlue py-3 px-12 rounded-md text-white"
                                onClick={(e) => handleEmailData(e)}
                            >
                                Save
                            </Link>
                        )}
                    </div>
                </div>
                <div className='w-[100%] flex flex-col items-center'>
                    NFT Preview
                    <div className='w-[50%] mb-[30px] border-[10px] border-gray-600'>
                        {asset?.assetId !== "" ? (

                            <div className="flex flex-col gap-[20vh] ">
                                {asset?.assetType === 'image' ? (
                                    <div className="shadow-md p-4 rounded-md">
                                        {asset.s3url !== null ?
                                            <img
                                                src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                                className="w-[100%] object-contain"
                                                alt='logo'
                                            />
                                            :
                                            <img
                                                src={`${process.env.REACT_APP_GATEWAY_IPFS}/${asset?.ipfsHash}`}
                                                className="w-[100%] object-contain"
                                                alt='logo'
                                            />
                                        }
                                    </div>
                                ) : asset?.assetType === 'video' ? (
                                    <video
                                        controls
                                        autoPlay
                                        className="w-[100%] shadow-lg rounded-lg object-contain"
                                        src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                    />
                                ) : (
                                    <ThreeDRenderer
                                        className={`w-[100%] shadow-lg rounded-lg object-contain`}
                                        src={!asset?.s3url ? `${process.env.REACT_APP_GATEWAY_IPFS}/${asset?.ipfsHash}` : `${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                        type={asset?.extension}
                                    />
                                )}
                            </div>

                        )
                            :
                            <div className="w-[100%] h-[200px] flex justify-center items-center text-[20px] bg-gray-700">
                                NFT Preview
                            </div>

                        }
                    </div>
                </div>
            </div>

        </>
    )
}
