import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

function useFetchCertCategory() {
    const appCtx = useSelector(state => state.app)
    const [categories,setCategories]=useState([])

    const fetchCategories = useCallback(()=>{
            const config ={
                url :`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx.marketplaceId}/cert/category`,
                method:"get",
                headers:{
                    "X-Auth-Token":appCtx.authToken
                }
            }
            axios(config)
            .then((res) => {
                setCategories(res?.data)
            } )
            .catch(rej =>{
                toast.error("Could not load the categories")
            })
    },[])

    useEffect(()=>{
        fetchCategories();
    },[])
  return {
    fetchCategories,
    categories
  }
}

export default useFetchCertCategory