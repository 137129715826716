import { LocationOn } from '@mui/icons-material';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { appActions } from '../../context/app-slice';
import useAuthorizedHttp from '../../hooks/use-authorized-http';

export const ConfigureHeader = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const makeRequest = useAuthorizedHttp();
    const fileRef = useRef();
    const bannerFileRef = useRef();
    const [collectionLogo, setCollectionLogo] = useState();
    const [logoPreview, setLogoPreview] = useState(null);
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [collectionBanner, setCollectionBanner] = useState();
    const [bannerPreview, setBannerPreview] = useState();
    const [uploadingBanner, setUploadingBanner] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [logoUrl, setLogoUrl] = useState("");
    const [bannerUrl, setBannerUrl] = useState("");
    const [confirm, setConfirm] = useState(false)
    const makeAuthorizedRequest = useAuthorizedHttp()
    const [showUploadLogoButton, setShowUploadLogoButton] = useState(true);
    const [showUploadBannerButton, setShowUploadBannerButton] = useState(true);
    const [section, setSection] = useState('header');
   
    const dispatch = useDispatch();
    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`,
                method: "get",
            },
            (data) => {
                setLogoPreview(data[0].logo);
                setBannerPreview(data[0].backgroundImage);
                dispatch(appActions.setDomain(data[0].domain));
            },
        );
    }, [makeRequest])

    const handleLogoChange = useCallback((file) => {

        if (file) {
            // if (file.size > 3 * 1024 * 1024) {
            //     toast.error('File size exceeds 3mb!');
            //     return false;
            // }
            setCollectionLogo(file);
            const objectUrl = URL.createObjectURL(file);
            setLogoPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUpload = () => {
        console.log(collectionLogo);
        let flag = true;
        if (!collectionLogo) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            setUploadingLogo(true);

            const data = new FormData();
            data.append("file", collectionLogo);

            let config = {
                method: "post",
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-logo`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Auth-Token": appCtx.authToken,
                },
                data: data,
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            setLogoUrl(response.data.fileUrl)
                            const marketplace = {
                                marketplaceId: appCtx.marketplaceId,
                                logo: response.data?.fileUrl,
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                                    method: "PUT",
                                    data: marketplace,
                                },
                                (data) => console.log(data),
                                (data) => console.log(data),
                                () => {
                                    setUploadingLogo(false);
                                    setShowUploadLogoButton(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error("Uploading logo failed!");
                            setUploadingLogo(false);
                        }),
                {
                    pending: "Updating your marketplace settings...",
                    success: "Settings updated successfully!",
                    error: "Uh-oh! Something went wrong",
                }
            );
        }
    };
    const handleBannerChange = useCallback((file) => {

        if (file) {
            // if (file.size > 4 * 1024 * 1024) {
            //     toast.error('File size exceeds 3mb!');
            //     return false;
            // }
            setCollectionBanner(file);
            const objectUrl = URL.createObjectURL(file);
            setBannerPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUploadBanner = () => {
        console.log(collectionBanner);
        let flag = true;
        if (!collectionBanner) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            // setUploadingLogo(true);

            const data = new FormData();
            data.append("file", collectionBanner);

            let config = {
                method: "post",
                url: `${process.env.REACT_APP_FILE_UPLOAD }/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-banner`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-Auth-Token": appCtx.authToken,
                },
                data: data,
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            setBannerUrl(response.data.fileUrl)
                            const marketplace = {
                                marketplaceId: appCtx.marketplaceId,
                                backgroundImage: response.data?.fileUrl,
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                                    method: "PUT",
                                    data: marketplace,
                                },
                                (data) => {
                                    setConfirm(true);
                                    // setLogoPreview(data.logo)
                                },
                                () => {
                                    setUploadingBanner(false);
                                    setShowUploadBannerButton(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error("Uploading logo failed!");
                            setUploadingBanner(false);
                        }),
                {
                    pending: "Updating your marketplace settings...",
                    success: "Settings updated successfully!",
                    error: "Uh-oh! Something went wrong",
                }
            );
        }
    }

    return (
        <div>
            <>
                <div className='flex flex-col md:flex-row'>
                    <div>
                        <div className="flex flex-col gap-3 p-3">
                            <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Logo image</div>
                            <div className="flex  gap-3 items-center ">

                                <div className='group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700 rounded-full w-[120px] h-[120px] cursor-pointer' onClick={() => fileRef.current.click()}>
                                    <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center w-[120px] h-[120px] rounded-full transition-all ease-out duration-300">
                                        Browse for logo
                                    </div>
                                    {logoPreview &&
                                        <img
                                            src={`${logoPreview} `}
                                            alt="upload"
                                            className="w-[120px] h-[120px] rounded-full relative bottom-[120px]  overflow-hidden object-cover cursor-pointer"
                                        />
                                    }
                                </div>

                                <div className="flex  flex-col gap-2 text-[8px]">
                                    <span>Maximum Size : 3MB </span>
                                    <span>Supported Format : JPG,PNG,SVG,GIF</span>
                                    <span>Recommended Size : 400 X 400 px</span>
                                </div>
                                <div className="flex flex-col gap-5 justify-center">
                                    {logoPreview && showUploadLogoButton && <button
                                        disabled={!collectionLogo || uploadingLogo}
                                        onClick={handleUpload}
                                        // onClick={() => fileRef.current.click()}
                                        className={`group bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12 px-8 text-white ${uploadingLogo && "bg-gray-700"}`}
                                    >
                                        Confirm
                                    </button>}

                                </div>

                            </div>
                            <input
                                hidden={true}
                                type="file"
                                ref={fileRef}
                                onChange={(e) => {
                                    handleLogoChange(e.target.files[0])
                                    setShowUploadLogoButton(true)
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Banner image</div>
                            <button
                                onClick={() => bannerFileRef.current.click()}
                                className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700  w-[350px] h-[100px] rounded-lg"
                            >
                                <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center h-full rounded-lg transition-all ease-out duration-300">
                                    Browse for banner
                                </div>
                                {bannerPreview && (
                                    <img
                                        src={bannerPreview}
                                        alt="Banner"
                                        className="object-cover relative bottom-[100px]  w-[350px] h-[100px] z-10  overflow-hidden rounded-lg"
                                    />
                                )}
                            </button>
                            {bannerPreview && <div className='flex justify-center'>{!uploadingBanner && showUploadBannerButton && <button
                                disabled={!collectionBanner || uploadingBanner}
                                onClick={handleUploadBanner}
                                // onClick={() => fileRef.current.click()}
                                className="group px-20 bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12  text-white"
                            >
                                Confirm
                            </button>}</div>}
                            <input
                                hidden={true}
                                type="file"
                                ref={bannerFileRef}
                                onChange={(e) => {
                                    handleBannerChange(e.target.files[0])
                                    setShowUploadBannerButton(true)
                                }}
                            />
                        </div>
                       

                    </div>
                    <div className="lg:ml-[50px] mt-[50px] sm:mt-[0px] rounded-lg w-[306%] sm:w-[121%] mr-[10px] flex flex-col shadow-prevBox dark:bg-inherit dark:border-darkBorder  min-h-[60vh] xl:w-3/4  border-white border-[20px] dark:border-darkSecondary ">
                        <div className="flex flex-col justify-between items-start pl-[1rem] pr-[1rem] xl:px-10 py-4 gap-5">
                            <div className='w-[100px] h-[100px]'>
                                {logoPreview ? (
                                    <div className='flex justify-start'>

                                        <img
                                            src={logoPreview}
                                            alt="LOGO"
                                            className="object-cover  w-[50px] h-[50px] z-10 rounded-full"
                                        />
                                        {/* <button className="text-xl font-light ml-[5px]">{marketplace?.name}</button> */}
                                    </div>
                                ) :
                                    <div className="w-[100%] h-[100%] flex justify-center items-center text-sm rounded-full bg-gray-500">
                                        Logo Preview
                                    </div>
                                }
                            </div>
                            {/* <button className="text-xl font-light ">{marketplaceName}</button> */}
                            {/* <input
                            className="outline-none rounded-xl py-1 px-2 dark:bg-darkBorder bg-[#D6D6D6]"
                            type="text"
                        />
                        <div className="flex gap-5 items-center">
                            <button className="">Explore</button>
							{menuItems?.map((item)=>{
							return	<button>{item.itemName}</button>
							}
							)}
                            <AccountBalanceWallet />
                            <AccountCircle />
                        </div> */}
                            {bannerPreview ? (
                                <img
                                    src={bannerPreview}
                                    alt="BANNERLOGO"
                                    className="object-cover  w-[100%] h-[100%]"
                                />
                            )
                                :
                                <div className="w-[100%] h-[300px] flex justify-center items-center text-[20px] bg-gray-500">
                                    Banner Logo Preview
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-32 flex-wrap items-center mt-[20px]">
                    {/* {confirm && <div className="text-green-500">Marketplace header details updated successfully </div>} */}
                    {confirm && (
                        <div
                            className=" no-underline bg-secBlue py-3 px-12 rounded-md text-white cursor-pointer"
                            onClick={() => { setSection('event') }}
                        >
                            Next : Event Page
                        </div>
                    )}
                </div>
            </>
        </div>
    )
}
