import {
    Code,
    CreateNewFolder,
    DarkModeRounded,
    DashboardRounded,
    Email,
    KeyboardArrowRightRounded,
    LightModeRounded,
    ManageAccounts,
    Newspaper,
    Outbound,
    PermDataSettingOutlined,
    PolylineRounded,
    RocketLaunch,
    StorefrontRounded,
    Token,
    TokenRounded,
    Upload,
    WebAssetRounded
} from '@mui/icons-material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import PagesIcon from '@mui/icons-material/Pages';
import GridViewIcon from '@mui/icons-material/GridView';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import MyMarketplaces from '../../pages/MyMarketplaces';
import EditPagesContainer from '../marketplace-settings/EditPagesContainer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CategoryIcon from '@mui/icons-material/Category';
import SidebarItem from './SidebarItem';
import DescriptionIcon from '@mui/icons-material/Description';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import WalletIcon from '@mui/icons-material/Wallet';
import React from 'react';
import { EventPageSideBar } from './EventPageSideBar';

const expandButtonClasses = {
    entering: 'rotate-180',
    entered: 'rotate-180',
    exiting: 'rotate-0',
    exited: 'rotate-0'
};

const expandedSidebarClasses = {
    entering: 'w-[100vw] md:w-[350px]',
    entered: 'w-[100vw] md:w-[350px]',
    exiting: 'w-[75px]',
    exited: 'w-[75px]'
};

const Sidebar = () => {
    const [marketplace, setMarketplace] = useState(null);
    const location = useLocation();
    const makeRequest = useAuthorizedHttp();
    const [highLightButton, setHighLightButton] = useState('');
    const [currentPage, setCurrentPage] = useState('');
    useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location]);
    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplace(data[0]);
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);

    const navigate = useNavigate();
    const [expandMarketplaceCustomization, setExpandMarketplaceCustomization] = useState(false);
    const [expandNfts, setExpandNfts] = useState(false);
    const [expandToken, setExpandToken] = useState(false);
    const [isExpanded, setExpanded] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const nftLocations = ['/asset', '/mint', '/nft', '/collections', '/categories'];

    return (
        <Transition in={isExpanded} timeout={500}>
            {(state) => (
                <div
                    className={`${expandedSidebarClasses[state]} pt-[70px] z-20 md:relative fixed overflow-x-hidden h-[100vh] dark:bg-gray-700 bg-white  flex flex-col transition-all ease-out duration-500 shadow-sideBar`}
                >
                    <div className="flex flex-row gap-5  p-5 items-center">
                        <div className="justify-self-end">
                            <Transition in={isExpanded} timeout={500}>
                                {(state) => (
                                    <button
                                        className={`${expandButtonClasses[state]} transition-all ease-in-out duration-500`}
                                        onClick={() => {
                                            setExpanded(!isExpanded);
                                        }}
                                    >
                                        <KeyboardArrowRightRounded fontSize="large" />
                                    </button>
                                )}
                            </Transition>
                        </div>
                        <div className="text-xl font-light min-w-[200px]">Your statistics</div>
                    </div>
                    {appCtx.marketType !== 'DAO' ? (
                        <>
                            <SidebarItem
                                icon={
                                    <div className="flex flex-col items-center">
                                        <DashboardRounded fontSize="large" />
                                        {!isExpanded && <div className="text-[10px]">Dashboard</div>}
                                    </div>
                                }
                                navigate="/home"
                            >
                                Dashboard
                            </SidebarItem>
                            <SidebarItem
                                icon={
                                    <div className="flex flex-col items-center">
                                        <AcUnitIcon fontSize="large" />
                                        {!isExpanded && <div className="text-[10px]">NFT</div>}
                                    </div>
                                }
                                navigate="/asset"
                                className={`${
                                    (highLightButton === 'mintnft' || highLightButton === 'nft') &&
                                    'bg-secPurple text-slate-100'
                                }`}
                                navto={['/collections', '/mint', '/nft', '/categories']}
                            >
                                <div className="flex gap-2">
                                    <span>NFTs</span>
                                    {!nftLocations.includes(currentPage) ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                </div>
                            </SidebarItem>
                            {isExpanded && nftLocations.includes(currentPage) && (
                                <div className="bg-inherit p-3 flex flex-col items-start pl-16 gap-3">
                                    <NavLink
                                        to="/asset"
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <WebAssetRounded />
                                            <div>My Asset</div>
                                        </div>
                                    </NavLink>
                                    <NavLink
                                        to="/mint"
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <PolylineRounded />
                                            <div>Mint NFTs</div>
                                        </div>
                                    </NavLink>
                                    <NavLink
                                        to="/nft"
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <TokenRounded />
                                            <div>My NFTs</div>
                                        </div>
                                    </NavLink>
                                    <NavLink
                                        to="/collections"
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <GridViewIcon />
                                            <div>My Collections</div>
                                        </div>
                                    </NavLink>
                                    <NavLink
                                        to="/categories"
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <CategoryIcon />
                                            <div>My Categories</div>
                                        </div>
                                    </NavLink>
                                </div>
                            )}
                            <SidebarItem
                                icon={
                                    <div className="flex flex-col">
                                        <StorefrontRounded fontSize="large" />
                                        {!isExpanded && (
                                            <div className="text-[10px]">
                                                {appCtx.marketType
                                                    ? appCtx.marketType === 'MARKETPLACE'
                                                        ? 'Marketplace'
                                                        : 'Store front'
                                                    : 'Marketplace'}
                                            </div>
                                        )}
                                    </div>
                                }
                                navigate={'/marketplace'}
                                // navto={[
                                //     `/marketplace`,
                                //     `/marketplace/pages/edit-home-page`,
                                //     `/marketplace/edit-footer-page`,
                                //     `/marketplace/pages/edit-about-page`,
                                //     `/marketplace/pages/edit-privacy-page`,
                                //     `/marketplace/pages/edit-faq-page`,
                                //     `/marketplace/pages/edit-tos-page`
                                // ]}
                            >
                                <div>
                                    <span className="">
                                        {appCtx.marketType
                                            ? appCtx.marketType === 'MARKETPLACE'
                                                ? 'Marketplace'
                                                : 'Store front'
                                            : 'Marketplace'}
                                    </span>
                                    {currentPage?.split('/')[1] !== 'marketplace' ? (
                                        <ExpandMoreIcon />
                                    ) : (
                                        <ExpandLessIcon />
                                    )}
                                </div>
                            </SidebarItem>
                            {isExpanded && currentPage?.split('/')[1] === 'marketplace' && (
                                <div className="bg-inherit p-3 flex flex-col items-start pl-16 gap-3">
                                    <NavLink
                                        to="/marketplace/update-header"
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <CallToActionOutlinedIcon className="transform rotate-180" />

                                            <div>Header</div>
                                        </div>
                                    </NavLink>
                                    <NavLink
                                        to={`/marketplace/pages`}
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <DescriptionIcon />
                                            <div>Pages</div>
                                        </div>
                                    </NavLink>

                                    <NavLink
                                        to={`/marketplace/edit-footer-page?marketplaceId=${marketplace?.marketplaceId}&marketplaceName=${marketplace?.name}&domain=${marketplace?.domain}`}
                                        className={({ isActive }) =>
                                            isActive ? 'text-secPurple no-underline' : 'no-underline text-inherit'
                                        }
                                    >
                                        <div className="flex gap-1 items-center">
                                            <CallToActionOutlinedIcon />
                                            <div>Footer</div>
                                        </div>
                                    </NavLink>
                                    <button
                                        className={`flex gap-3`}
                                        onClick={() => {
                                            window.open(`http://${marketplace?.domain}`);
                                            setHighLightButton('domain');
                                        }}
                                    >
                                        <Outbound />
                                        <div>Visit Marketplace</div>
                                    </button>
                                </div>
                            )}

                            <SidebarItem
                                icon={
                                    <div className="flex flex-col items-center">
                                        <RocketLaunch fontSize="large" />
                                        {!isExpanded && <div className="text-[10px]">launchpad</div>}
                                    </div>
                                }
                                navigate="/nft-launch-pad"
                            >
                                <div className="flex gap-2">
                                    <span>NFT launchpad</span>
                                </div>
                            </SidebarItem>

                            {/* <SidebarItem
                                icon={
                                    <div className="flex flex-col items-center">
                                        <Token fontSize="large" />
                                        {!isExpanded && <div className="text-[10px]">Token</div>}
                                    </div>
                                }
                                navigate="/fungibletoken/manage"
                            >
                                <div className="flex gap-2">
                                    <span>Fungible Token</span>
                                </div>
                            </SidebarItem> */}
                            {/* 
                            <SidebarItem
                                icon={
                                    <div className="flex flex-col items-center">
                                        <WalletIcon fontSize="large" />
                                        {!isExpanded && <div className="text-[10px]">Wallet</div>}
                                    </div>
                                }
                                navigate="/wallet"
                            >
                                Crypto Wallet
                            </SidebarItem> */}
                            <SidebarItem
                                icon={
                                    <div className="flex flex-col items-center">
                                        <Newspaper fontSize="large" />
                                        {!isExpanded && <div className="text-[10px]">NEWS</div>}
                                    </div>
                                }
                                navigate="/news"
                            >
                                Crypto News
                            </SidebarItem>
                        </>
                    ) : (
                        <EventPageSideBar
                            isExpanded={isExpanded}
                            setExpandMarketplaceCustomization={setExpandMarketplaceCustomization}
                            setExpandNfts={setExpandNfts}
                            expandNfts={expandNfts}
                            setHighLightButton={setHighLightButton}
                            expandMarketplaceCustomization={expandMarketplaceCustomization}
                            marketplace={marketplace}
                            appCtx={appCtx}
                        />
                    )}
                </div>
            )}
        </Transition>
    );
};

export default Sidebar;
