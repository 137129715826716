import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import login from '../assets/login/login-bg.jpg';
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar';
import useHttp from '../hooks/use-http';
import BlueGradientButton from '../ui/BlueGradientButton';
import Input from '../ui/Input';
import React from "react"

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [otpSentOnce, setOtpSentOnce] = useState(false);
    const [resendOtp, setResendOtp] = useState(30);
    const [userData, setUserData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        otp: '',
        authToken: ''
    });
    const makeRequest = useHttp();
    const navigate = useNavigate();

    const disableResendOtp = useCallback(() => {
        setResendOtp(30);
        const interval = setInterval(() => {
            setResendOtp((prev) => {
                if (prev === 0) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleVerifyOtpClicked = useCallback(() => {
        if (!userData.email) {
            toast.error('Please enter a valid email');
            return;
        }

        if (!userData.otp) {
            toast.error('Please enter a valid OTP');
            return;
        }
        setLoading(true);
        toast.promise(
            () =>
                makeRequest(
                    {
                        method: 'POST',
                        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/verify?type=reset`,
                        data: {
                            otp: userData.otp,
                            email: userData.email
                        },
                        headers: {
                            'X-App-Token': process.env.REACT_APP_APP_TOKEN,
                            'Content-Type': 'application/json'
                        }
                    },
                    (data) =>
                        setUserData((prev) => ({
                            ...prev,
                            authToken: data.authToken
                        })),
                    (error) => console.log(error),
                    () => setLoading(false)
                ),
            {
                pending: 'Verifying OTP...',
                success: 'OTP verified successfully',
                error: 'Failed to verify OTP'
            }
        );
    }, [makeRequest, userData.email, userData.otp]);

    const handleSendOtpClicked = useCallback(() => {
        if (!userData.email) {
            toast.error('Please enter your email');
            return;
        }
        setLoading(true);
        toast.promise(
            () =>
                makeRequest(
                    {
                        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?type=reset`,
                        data: { email: userData.email },
                        method: 'post',
                        headers: {
                            'X-App-Token': process.env.REACT_APP_APP_TOKEN,
                            'Content-Type': 'application/json'
                        }
                    },
                    () => {
                        setOtpSentOnce(true);
                        disableResendOtp();
                    },
                    (data) => console.log(data),
                    () => setLoading(false)
                ),
            {
                pending: 'Sending OTP...',
                success: 'OTP sent successfully!',
                error: 'Error sending OTP!'
            }
        );
    }, [disableResendOtp, makeRequest, userData.email]);

    const handleResetClick = useCallback(() => {
        if (!userData.password) {
            toast.error('Please enter a valid password');
            return;
        }
        if (!userData.confirmPassword) {
            toast.error('Please enter a valid confirm password');
            return;
        }
        if (userData.password !== userData.confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }
        setLoading(true);
        toast.promise(
            () =>
                makeRequest(
                    {
                        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/password/change`,
                        method: 'POST',
                        data: { password: userData.password },
                        headers: {
                            'X-Auth-Token': userData.authToken,
                            'Content-Type': 'application/json'
                        }
                    },
                    () => {
                        navigate('/login');
                    },
                    (data) => {
                        console.log(data);
                    },
                    () => setLoading(false)
                ),
            {
                pending: 'Updating your profile...',
                success: 'Successfully set your password',
                error: 'Something wrong happened'
            }
        );
    }, [makeRequest, navigate, userData.authToken, userData.confirmPassword, userData.password]);

    const handleInputChange = useCallback((e) => {
        setUserData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }, []);

    return (
        <div className='dark'>
        <UnauthenticatedNavbar/>
        <div className="h-screen w-screen bg-cover bg-no-repeat" style={{ backgroundImage: `url(${login})` }}>
            <div className="min-w-screen min-h-screen bg-gradient-to-r from-zinc-800 via-zinc-800/80 to-zinc-600/0 p-0 md:p-20 flex overflow-y-scroll">
                <div className="my-auto flex flex-col text-left justify-center items-center gap-10 mx-auto lg:w-[1100px]">
                    <div className="text-slate-100 text-left text-3xl font-extrabold">Reset your password</div>
                    <div className="dark grid gap-5 min-w-[320px] w-[40vw] max-w-[560px]">
                        {!otpSentOnce && (
                            <Input
                                value={userData.email}
                                type="email"
                                name={'email'}
                                header="Email"
                                onChange={handleInputChange}
                                description="We will send an otp to this email"
                                placeholder="Email"
                            />
                        )}
                        {otpSentOnce && !userData.authToken && (
                            <Input
                                value={userData.otp}
                                type="number"
                                name={'otp'}
                                header="OTP"
                                onChange={handleInputChange}
                                description="Enter the OTP we sent you"
                                placeholder="OTP"
                            />
                        )}
                        {userData.authToken && (
                            <>
                                <Input
                                    value={userData.password}
                                    type="password"
                                    name={'password'}
                                    header="Password"
                                    onChange={handleInputChange}
                                    description="Enter your new password"
                                    placeholder="********"
                                />
                                <Input
                                    value={userData.confirmPassword}
                                    type="password"
                                    name={'confirmPassword'}
                                    header="Confirm Password"
                                    onChange={handleInputChange}
                                    description="Confirm your new password"
                                    placeholder="********"
                                />
                            </>
                        )}
                        {!otpSentOnce && (
                            <BlueGradientButton disabled={loading} onClick={handleSendOtpClicked} className="w-full">
                                Send OTP
                            </BlueGradientButton>
                        )}
                        {otpSentOnce && !userData.authToken && (
                            <BlueGradientButton disabled={loading} onClick={handleVerifyOtpClicked} className="w-full">
                                Verify OTP
                            </BlueGradientButton>
                        )}
                        {userData.authToken && (
                            <BlueGradientButton disabled={loading} onClick={handleResetClick} className="w-full">
                                Reset Password
                            </BlueGradientButton>
                        )}
                        {otpSentOnce && !userData.authToken && (
                            <button
                                onClick={handleSendOtpClicked}
                                disabled={resendOtp !== 0}
                                className="text-slate-300 text-md text-left"
                            >
                                {resendOtp === 0 ? 'Resend OTP' : `Resend OTP in ${resendOtp}s`}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default ResetPassword;
