import React from 'react'
import CreateEvent from './CreateEvent'
import DivContainer from '../../ui/DivContainer'
import BlueGradientButton from '../../ui/BlueGradientButton';
import MinAttendeesList from '../../components/events/MinAttendeesList';
import { useParams } from 'react-router-dom';

function EditEvent() {
    const {id} = useParams();

  return (
      <div className="flex flex-col">
          <DivContainer className="flex justify-between items-center">
              <div className="font-bold text-lg">Event Details</div>
              <div className="flex gap-3">
                  {/* <BlueGradientButton>Upload Attendes List</BlueGradientButton>
                  <BlueGradientButton>Create POA NFTs</BlueGradientButton> */}
              </div>
          </DivContainer>
          <CreateEvent id ={id}/>
          <div className='flex flex-col gap-4'>
                <span></span>
          </div>
      </div>
  );
}

export default EditEvent