import React from "react"

const convertMarketCap = (marketCap) => {
	const m = Number(marketCap);
	if (marketCap % 1000000000 > 0) return Number(m / 1000000000).toFixed(1) + "B";
	if (marketCap % 1000000 > 0) return Number(m / 1000000).toFixed(1) + "M";
	if (marketCap % 1000 > 0) return Number(m / 1000).toFixed(1) + "K";
	return m;
};

const getChangeColor = (change) => {
	const c = Number(change);
	if (c < 0) return "text-rose-500";
	return "text-green-500";
};

const CoinItem = (props) => {
	const coin = props.coin;

	return (
		<button className="flex items-center flex-row justify-between rounded-lg p-4 hover:bg-slate-200 dark:hover:bg-zinc-700/50 transition-all ease-out duration-200">
			<div className="flex flex-row gap-14">
				<div className="flex flex-row gap-5 items-center w-[20%]">
					<div className={`text-lg font-light`}>{coin.rank}</div>
					<img width={40} src={coin.iconUrl} alt="icon" />
				</div>
				<div className="grid  justify-items-start">
					<div className="text-lg font-semibold">{coin.name}</div>
					<div className="flex gap-2">
						<div className=" text-sm text-gray-400">Price: </div>
						<span className="text-left text-sm text-gray-400">{Number(coin.price).toFixed(2)}</span>
					</div>
				</div>
			</div>
			<div className="grid justify-end justify-items-end">
				<div className="flex gap-3">
					<div className=" text-sm text-gray-400">Market cap: </div>
					<div>{convertMarketCap(coin.marketCap)}</div>
				</div>
				<div className="flex flex-row gap-3">
					<div className=" text-sm text-gray-400">Change: </div>
					<div className={`${getChangeColor(coin.change)}`}>{coin.change}</div>
				</div>
			</div>
		</button>
	);
};

export default CoinItem;
