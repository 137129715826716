import React from 'react';
import BlueButton from '../ui/BlueButton';
import { useLocation, useNavigate } from 'react-router-dom';

function CreateNftTutorial({setShowUploadSection, showUploadSection}) {
    const navigate = useNavigate();
    const location = useLocation();
    return (
         !showUploadSection &&   <div className="flex flex-col gap-3 items-center md:-mt-8">
            <div className="text-xl font-bold"> Hi welcome</div>
            <div className="">
            You don't have any assets to show here. In the meantime,<br></br> you can watch our tutorial to learn how to make NFTs and earn money from them.
            </div>
            <div className="flex flex-col items-center gap-2">
                <div className='md:border-[20px] border-white  rounded-xl'>
                    <video controls  className="object-cover  md:w-[600px] md:h-[350px] rounded-xl overflow-hidden" src="https://nftverse-dashboard-assets.s3.ap-south-1.amazonaws.com/Mint+1.mp4" />
                </div>
              {(location.pathname.startsWith("/asset"))  && <button className='px-6 md:px-24 py-3 bg-secBlue text-white rounded-md' 
                onClick={()=>{setShowUploadSection && setShowUploadSection(true)
                    navigate("/asset")
                }}
                >
                    Upload Asset
                </button>}
            </div>
        </div>
    );
}

export default CreateNftTutorial;
