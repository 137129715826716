import { nanoid } from '@reduxjs/toolkit';
import ThreeDRenderer from '../common/ThreeDRenderer';
import { useCallback, useState } from 'react';
import ThreeDViewOverlay from '../common/ThreeDViewOverlay';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';

const NFTItem = (props) => {
    let parsedMetaData = '';
    try {
        parsedMetaData = props.nft?.metaData && JSON?.parse(props.nft?.metaData);
    } catch {}
    const [type, extension] = parsedMetaData?.mime_type?.split('/') || [];
    const parsedProperties = parsedMetaData?.properties;
    parsedMetaData?.collectionId && props.collection && (parsedMetaData.colection = props.collection?.name);
    delete parsedMetaData.collectionId;
    const navigate = useNavigate();
    delete parsedMetaData.properties;
    const [show3D, setShow3D] = useState(false);
    console.log(parsedMetaData);
    const toggle3D = useCallback(() => {
        setShow3D(!show3D);
    }, [show3D]);

    return (
        <>
            {show3D && (
                <ThreeDViewOverlay
                    onClose={toggle3D}
                    url={`${process.env.REACT_APP_GATEWAY_IPFS}/${parsedMetaData?.media_url?.split('/')[2]}`}
                    type={extension}
                />
            )}
            <div className="flex bg-gray-100 flex-col justify-between border rounded dark:border-gray-500 hover:scale-105 transition-all ease-out duration-300">
                <div className="h-fit flex flex-col ">
                    {type === 'text' ? (
                        <div className={'h-[20px] p-10 dark:bg-neutral-800 flex items-center justify-center'}>
                            No image or video found for this NFT
                        </div>
                    ) : type === 'image' ? (
                        <img
                            className=" object-contain h-[200px] w-full dark:bg-neutral-800"
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${parsedMetaData?.image?.split('/')[2]}`}
                            alt="NFT"
                        />
                    ) : type === 'video' ? (
                        <video
                            controls
                            autoPlay={false}
                            className=" object-contain h-[200px] w-full dark:bg-neutral-800"
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${parsedMetaData?.media_url?.split('/')[2]} `}
                        />
                    ) : // console.log(extension,parsedMetaData)
                    parsedMetaData?.image ? (
                        <img
                            className=" object-contain h-[200px] w-full dark:bg-neutral-800"
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${parsedMetaData?.image?.split('/')[2]}`}
                            alt="NFT"
                        />
                    ) : (
                        //   <></>
                        <ThreeDRenderer
                            className={`h-[200px] border rounded dark:border-gray-500 dark:bg-neutral-800`}
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${parsedMetaData?.media_url?.split('/')[2]}`}
                            type={extension}
                        />
                    )}
                    <div className=" p-2 bg-gray-50 text-gray-700">
                        <div className="flex  flex-col gap-3 w-full overflow-hidden text-sm flex-wrap">
                            <div className="flex justify-between">
                                <div className="font-bold">Name</div>
                                <div>{parsedMetaData?.name || parsedMetaData?.title}</div>
                            </div>
                            <div className="flex justify-between">
                                <div className="font-bold">Price</div>
                                <div>{props.nft?.price}</div>
                            </div>
                            <div className="flex justify-between">
                                <div className="font-bold">Status</div>
                                <div
                                    className={`${
                                        props.nft?.status === 'SEALED' ? 'text-green-600' : 'text-gray-500'
                                    } font-bold`}
                                >
                                    {props.nft?.status}
                                </div>
                                {props.nft?.status === 'FAILED' && (
                                    <Link
                                        to={`/mint?ipfsHash=${parsedMetaData?.media_url?.split('/')[2]}&type=${
                                            parsedMetaData.mime_type?.split('/')[0]
                                        }&s3url=${'retry'}&extension=${parsedMetaData.mime_type?.split('/')[1]}&nftId=${
                                            props.nft.nftId
                                        }`}
                                        className="no-underline"
                                    >
                                        Retry
                                    </Link>
                                )}
                            </div>
                            <div className="flex justify-between">
                                <div className="font-bold">Collection</div>
                                <div>{parsedMetaData?.collection}</div>
                            </div>
                            <div className="flex justify-between">
                                <>
                                    <div
                                        className="text-secBlue cursor-pointer"
                                        onClick={() =>
                                            window.open(
                                                `${process.env.REACT_APP_GATEWAY_IPFS}/${
                                                    parsedMetaData?.media_url?.split('/')[2] ||
                                                    parsedMetaData?.image?.split('/')[2]
                                                }`,
                                                '_blank',
                                                'noopener,noreferrer'
                                            )
                                        }
                                    >
                                        View on IPFS
                                    </div>
                                </>
                                <>
                                    <div
                                        className="text-secBlue cursor-pointer"
                                        onClick={() => {
                                            const blockchain = props?.nft?.blockchain;
                                            const transactionId = props?.nft?.transactionId;
                                            console.log('blockchain', blockchain);
                                            console.log('transactionId', transactionId);

                                            const blockchainLink =
                                                blockchain === 'FLOW'
                                                    ? `${process.env.REACT_APP_GATEWAY_FLOWSCAN}${transactionId}`
                                                    : blockchain === 'ALGORAND'
                                                    ? `${process.env.REACT_APP_GATEWAY_ALGO}/tx/${transactionId}`
                                                    : blockchain === 'ETHEREUM'
                                                    ? `${process.env.REACT_APP_GATEWAY_ETHEREUM}/tx/${transactionId}`
                                                    : blockchain === 'POLYGON'
                                                    ? `${process.env.REACT_APP_GATEWAY_POLYGON}/tx/${transactionId}`
                                                    : blockchain === 'BSC'
                                                    ? `${process.env.REACT_APP_GATEWAY_BSC}/address/${transactionId}`
                                                    : '';

                                            if (blockchainLink !== '') {
                                                window.open(blockchainLink);
                                            }
                                        }}
                                    >
                                        View on Chain
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {props.nft?.listed === true ? (
                    <div className="flex">
                        {props.domain && (
                            <button
                                onClick={() => {
                                    window.open('http://' + props.domain + '/nft/' + props.nft.nftId);
                                }}
                                className="bg-green-500 text-slate-50 p-3 w-full rounded-b"
                            >
                                View NFT
                            </button>
                        )}
                        {parsedMetaData.mime_type?.split('/')[0] === 'threeD' && (
                            <button className="bg-secBlue text-slate-50 p-3 w-full rounded-b" onClick={toggle3D}>
                                View 3D
                            </button>
                        )}
                    </div>
                ) : (
                    <button
                        onClick={() => {
                            navigate(`/salenft/${props.nft?.nftId}`);
                        }}
                        className="bg-blue-500 text-slate-50 p-3 w-full rounded-b"
                    >
                        List for sale
                    </button>
                )}
            </div>
        </>
    );
};

export default NFTItem;
