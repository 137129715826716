import axios from 'axios';
import { config } from 'process';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BlueGradientButton from '../../BlueGradientButton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Property from '../atoms/Property';

function LaunchPadPreview({ assets , errorArray,selectedCollection,setPremintData}) {
  // const [errorValue,setErrorValue] = useState("");
  const navigate = useNavigate();
//   const [savedPremint,setSavedPremint] = useState(false)

  const appCtx = useSelector(state => state.app)
       
      const saveAssets = useCallback(()=>{
        let flag = true;
        

        if(selectedCollection ===""){
          flag = false;
          toast.error("Your Collection list is empty")
        }

        if(flag){
        if(assets.length > 0){
          const data = assets?.map(asset => {return  {
            metaData:JSON.stringify(asset),
            userId: appCtx.userDetails.userId,
            marketplaceId: appCtx.marketplaceId,
            collection: selectedCollection,
            blockchain: appCtx.blockchain,
            minted:false,
           
          }} )
          config ={
            url :`${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/bulk/premint`,
            method:"post",
            headers:{
                'Content-Type': 'application/json',
                "X-Auth-Token": appCtx.authToken
            },

            data:JSON.stringify(data)
          }

          axios(config).
          then((res) => {
            // setPremintData(res?.data)
            // setSavedPremint(true)
            navigate('/nft-launch-pad/premint')
          }).
          catch((err) => toast.error("Failed saving the data"))
        }
      }
      },[assets,appCtx,selectedCollection])

      // useEffect(()=>{
      //   setSavedPremint(false)
      // },[assets])

    return (
        assets?.length > 0 &&  (errorArray?.length ===0 ? (
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-5   max-h-80 overflow-y-scroll">
                    {assets?.map((asset, i) => {
                        const imageSrc = asset['Asset Image'].split('/')[5];
                        return (
                            <div className="flex justify-between items-center border py-2 rounded-md" key={i}>
                                <div className="p-3">
                                    {
                                        <img
                                            src={`https://drive.google.com/uc?export=view&id=${imageSrc}`}
                                            className="h-16 object-contain"
                                        />
                                    }
                                </div>
                                <div>{asset['Asset Name']}</div>
                                <div>{asset['Asset Descriptions']}</div>
                                <div className="w-32  break-words"><Property properties = {((asset['Asset Property']))} /></div>
                            </div>
                        );
                    })}
                </div>
               
                    <div className="flex justify-center">
                        <BlueGradientButton className="px-24 " onClick={saveAssets}>
                            Save
                        </BlueGradientButton>
                    </div>
              
            </div>
        )
        :
        <div className='flex flex-col gap-3'>
          <div className='font-semibold'>You have following errors</div>
          <div className='flex justify-between px-4'>
            <span className='font-bold'>
               Line
            </span>
            <span className='font-bold'>
              Error
            </span>
          </div>
          {
            errorArray?.map((error)=>{
              return <div className='flex justify-between shadow-md py-3 px-4 rounded-md' >
                <span>{error?.line}</span>

                <span className='text-red-600'>{error?.issue} </span>
              </div>
            })
          }
        </div>
    ));
}

export default LaunchPadPreview;
