import React, { useEffect } from 'react'
import Footer from '../components/common/Footer'
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar'

export const PrivacyPolicy = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className="dark landing-bg .bg-zinc-800 flex -col  h-full text-slate-50">
            <UnauthenticatedNavbar />
            <div className="dark landing-bg .bg-zinc-800  w-full h-full text-slate-50">
                <div className="flex flex-col mt-20 gap-10 ">
                    <div className=" flex text-3xl font-bold justify-center ">
                        <div className="border-b-[15px] border-secPurple text-3xl h-[80px] mt-[20px]">Privacy Policy</div>
                    </div>
                    <div className='mx-8 lg:ml-[132px] lg:mr-[132px] mb-[80px] text-[18px] text-justify'>
                        <div className='flex flex-col-reverse lg:flex-row'>
                            <div className='w-[100%] mr-[20px]'>
                                <div className='font-bold mt-[20px]'>Overview</div>
                                <div className='font-medium mt-[40px] mb-[50px]'>
                                    At NextTales Inc, NFTVerse is committed to protecting your privacy. We have created this privacy policy to describe to you our practices regarding the data we collect, use and share with our partners' websites, applications and any other services associated with us. “NFT” here in this privacy policy means “Non-Fungible Tokens” or a digital asset created on the blockchain. The data collected by us can be divided into the following two categories.
                                </div>
                                <div className='font-bold mt-[40px] mb-[50px]'>
                                    Information provided by the user
                                </div>
                                <div className='font-bold mt-[20px]'>Information You Provide Us.</div>
                                <div className='m-[36px]'>
                                    <div >1.When you use our Service, update your account profile, or contact us, we may collect Personal Data from you, such as email address, first and last name, user name, and other information you provide. We also collect your blockchain address, which may become associated with Personal Data when you use our Service.</div>
                                </div>
                            </div>
                            <div className='h-[500px] lg:w-[1000px]'>
                                <img src="/images/privacypolicy.png" alt="privacyLogo" className='w-[100%] h-[100%]' />
                            </div>
                        </div>
                        <div>
                            <div className='m-[36px]'>
                                2.If you provide us with feedback or contact us, we will collect your name and contact information, as well as any other content included in the message.
                            </div>
                            <div className='m-[36px]'>
                                3.We may also collect Personal Data at other points in our Service where you voluntarily provide it or where we state that Personal Data is being collected.
                            </div>
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>
                            Information Collected via Technology <br/>As of now, we do not collect user’s equipment, browsing behaviour, and patterns.  However, we do keep log files and cookies, as described below.
                        </div>
                        <div>
                            <div className='m-[36px]'>1. <spam className="font-bold">Log Files.</spam><br /> As is true of most websites and applications, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider (“ISP”), referring/exit pages, operating system etc. For example, some of the information may be collected so that when you visit the Service, it will recognize you and the information can be used to personalize your experience.
                            </div>
                            <div className='m-[36px]'>2. <spam className="font-bold">Cookies.</spam> Like many online services, we use cookies to collect information. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to analyse how users interact with our Service, make improvements to our product quality, and provide users with a more personalised experience.
                            </div>
                        </div>
                        <div className='font-bold mt-[20px]'>How Do We Use Your Data? </div>
                        <div>We process your Personal Data to run our business, provide the Service, personalize your experience on the Service, and improve the Service. Specifically, we use your Personal Data to:
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>
                            <p className='m-[36px]'>1. Facilitate the creation of and secure your account;</p>
                            <p className='m-[36px]'>2. Identify you as a user in our system;</p>
                            <p className='m-[36px]'>3. Provide you with our Service, helping you view, explore, and create NFTs, create & Customise Marketplace  using our tools , list NFTs for sale and, at, connect directly with others to purchase, sell, NFTs on public blockchains;
                            </p>
                            <p className='m-[36px]'>4. Improve the administration of our Service and quality of experience when you interact with our Services.
                            </p>
                            <p className='m-[36px]'>5. Provide customer support and respond to your requests and inquiries;</p>
                            <p className='m-[36px]'>6. Detect, prevent, and address fraud, violations of our terms or policies, and/or other harmful or unlawful activity;
                            </p>
                            <p className='m-[36px]'>7. display your NFTs currently or previously accessible in your third-party wallet, and next to NFTs on which you have interacted
                            </p>
                            <p className='m-[36px]'>8. Send you notifications, such as security, support, and maintenance advisories;
                            </p>
                            <p className='m-[36px]'>9. Send you notifications related to action on nfts including notifications of offers on your NFTs.
                            </p>
                            <p className='m-[36px]'>10. Comply with applicable laws, cooperate with investigations by law enforcement or other authorities of suspected violations of law, and/or to pursue or defend against legal threats and/or claims.
                            </p>
                        </div>
                        <div className='font-bold mt-[20px]'>
                            Data Sharing
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>
                            <spam className="font-bold">Third Party Service Providers.</spam><br/>We may share your Data with third-party service providers to provide technical services; conduct quality assurance testing; analyse how our Service is used; prevent, detect, and respond to unauthorised activities; provide customer support etc. We may share some or all of your Personal Data in connection with or during the negotiation of any merger, acquisition, dissolution or proceeding involving the sale, transfer, or all or a portion of our business or assets.
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>We may share some or all of your Personal Data in connection with or during negotiation of any merger, acquisition, or dissolution or proceeding involving sale, transfer, all or a portion of our business or assets.</div>
                        <div className='font-medium mt-[40px] mb-[50px]'>
                            <spam className="font-bold">Legal Rights.</spam> Regardless of any choices you make regarding your Personal Data NFTales Inc (NFTVerse) may disclose Personal Data if it believes in good faith that such disclosure is necessary and is confined in legal investigation
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>
                            <spam className="font-bold">Third Party Wallets</spam><br/> To use our service you might use third party wallets (we currently support metamask, Blocto wallets only) which helps you engage in transactions on blockchains.
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>
                        <spam className="font-bold">Email Communications.</spam><br/> We may periodically send you newsletters and/or emails that directly promote the use of our Service or third parties’ goods and services. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving these communications from us.
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'><spam className="font-bold">Data Access & Control</spam><br/> You can view,access,edit or delete your data for certain services that you wish to not continue with, through our page.
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'><spam className="font-bold">Notwithstanding the above,</spam><br/> we cannot edit or delete any information that is stored on a blockchain, for example the Flow blockchain, as we do not have custody or control over </div>
                        <div className='font-medium mt-[40px] mb-[50px]'><spam className="font-bold">Data Protection</spam><br/> We care about the security information of our users & use various infrastructure to store and preserve it using technical and physical mediums however no security system is impenetrable so do ours as well. In case of any security breach we will take necessary steps in accordance with the laws and policies and will investigate it and notify the users about the same.
                        </div>
                        <div className='font-medium mt-[40px] mb-[50px]'>You are responsible for the security of your digital wallet, and we urge you to take steps to ensure it is and remains secure.</div>
                        <div className='font-medium mt-[40px] mb-[50px]'>If you have any concerns ,complaints or questions regarding the privacy policy, data usage, data collections please contact us - at this address and using this email.</div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
