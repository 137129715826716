import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EventDetailCard from '../../components/events/EventDetailCard';
import DivContainer from '../../ui/DivContainer';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import useFetchPremintData from '../../hooks/events/useFetchPremintData';
import { CircularProgress } from '@mui/material';
import { nativeToken } from '../../config';

function CreateTicket() {
    const { id, premintId, ticketId } = useParams();
    // const [premintContent, setPremintContent] = useState({});
    // const [premintMetaData, setPremintMetaData] = useState('');
    const [ticktName, setTicketName] = useState('');
    const [selectedFee, setSelectedFee] = useState('subsidized');
    const [price, setPrice] = useState(0);
    const [updating, setUpdating] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [priceUnit, setPriceUnit] = useState(nativeToken[appCtx?.blockchain]);
    const navigate = useNavigate();

    const { premintContent, premintMetaData } = useFetchPremintData(premintId);

    const submitForm = useCallback(
        (e) => {
            e.preventDefault();
            console.log(price, priceUnit, selectedFee, ticktName, premintContent?.cid);
            let flag = true;
            if (isNaN(+price) || +price < 0) {
                flag = false;
                toast.error('enter a valid price');
            }
            if (!premintContent?.cid) {
                flag = false;
                toast.error('Having trouble with asset');
            }

            if (flag) {
                setUpdating(true);
                const data = {
                    cid: premintContent?.cid,
                    claimed: false,
                    eventId: +id,
                    premintId: +premintId,
                    gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                    orgId: appCtx.marketplaceId,
                    price: +price,
                    priceUnit: priceUnit,
                    ticketId: +ticketId || null,
                    ticketName: ticktName
                };
                const config = {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/event/nft/ticket`,
                    method: ticketId ? 'put' : 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data
                };

                axios(config)
                    .then((res) => {
                        toast.success('Event Ticket created successfully');
                        navigate(`/events/event-details/${id}`);
                    })
                    .catch((rej) => toast.error('Colud not create event ticket'))
                    .finally(() => setUpdating(false));

                // updating

                const data2 = {
                    gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                    id: +premintId,
                    price: +price,
                    priceUnit: priceUnit
                };
                const config2 = {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint`,
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data2
                };

                axios(config2)
                    .then((res) => {
                        console.log('update successfull:', res.data);
                    })
                    .catch((err) => {
                        console.log('Error updating:', err);
                    });
            }
        },
        [price, priceUnit, selectedFee, ticktName, premintContent, premintId, id, ticketId]
    );

    return (
        <div className="flex flex-col gap-8">
            <DivContainer>
                <span className="text-lg font-bold">Create Ticket</span>
            </DivContainer>
            <div className="grid grid-cols-2 gap-10">
                <div className="flex flex-col gap-5">
                    <div className="flex justify-center">
                        <img
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                            className="max-w-full max-h-[400px] min-h-[300px] object-contain"
                            alt="Ticket-nft"
                        />
                    </div>
                    <EventDetailCard title="Name">
                        <DivContainer className="break-words">{premintMetaData?.name}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <DivContainer className="break-words">{premintMetaData?.description}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        <DivContainer className="flex gap-2 flex-wrap">
                            {premintMetaData?.properties &&
                                Object.entries(premintMetaData?.properties).map(([key, value]) => {
                                    return (
                                        <DivContainer className="flex flex-col" key={key}>
                                            <span>{key}</span>
                                            <span>{value}</span>
                                        </DivContainer>
                                    );
                                })}
                        </DivContainer>
                    </EventDetailCard>
                </div>
                <form onSubmit={submitForm} className="flex flex-col gap-5">
                    <EventDetailCard title="Ticket Tier">
                        <Input
                            required
                            placeholder="Ticket tier"
                            value={ticktName}
                            onChange={(e) => setTicketName(e.target.value)}
                        />
                    </EventDetailCard>
                    <EventDetailCard title="Gas Fees">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="subsidized"
                                    checked={selectedFee === 'subsidized'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Subsidized</span>
                            </div>
                            {/* <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="charged"
                                    checked={selectedFee === 'charged'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Charge from the guest</span>
                            </div> */}
                        </div>
                    </EventDetailCard>
                    <EventDetailCard title="Price">
                        <div className="flex items-center gap-10">
                            <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
                            <select
                                className="px-4 py-2 rounded-md text-gray-700"
                                value={priceUnit}
                                onChange={(e) => setPriceUnit(e.target.value)}
                            >
                                <option value={nativeToken[appCtx?.blockchain]}>
                                    {nativeToken[appCtx?.blockchain]}
                                </option>
                                <option value="usd">USD</option>
                            </select>
                        </div>
                    </EventDetailCard>
                    <BlueGradientButton disabled={updating} type="submit">
                        {updating ? <CircularProgress size={20} /> : 'Submit'}
                    </BlueGradientButton>
                </form>
            </div>
        </div>
    );
}

export default CreateTicket;
