import { Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

function AboutUsCard(props) {
  return (
    <div className={`flex flex-col '} p-5 bg-white rounded-md gap-5 text-black relative hover:transform hover:scale-105 transition-all`}>
        <div className=' rounded-full flex justify-center overflow-hidden'>
                <img src={props.image} alt="profile-pic" className='object-contain h-36 w-36 rounded-full' />
        </div>
        <div className='flex flex-col items-center gap-2'>
                <div className='font-bold text-lg'>{props.name}</div>
                <div className='font-semibold'>{props.designation}</div>
        </div>
        <div className='flex wordwrap text-[13px] lg:text-[17px] text-justify font-normal'>
            {props.children}
        </div>
        <div className={`flex justify-center   ${props.employee ?'bottom-8':'bottom-16'} `}>
            <div  onClick={()=>window.open(props.linkedIn)}><LinkedIn fontSize='large'/></div>
        </div>
    </div>
  )
}

export default AboutUsCard