import React from 'react'
import { useSelector } from 'react-redux'

function TokenDetails() {
    const appCtx = useSelector(state => state.app)
    const {tokenName,tokenIcon,tokenShortName,totalBalanceLeft,totalSupply,status} = appCtx.tokenDetails || {}
  return (
    <div className='flex flex-col gap-10'>
        <div className='font-bold text-2xl'>
                Manage token
        </div>
        <div className='flex justify-between items-center'>
            <div className='flex gap-8 text-lg font-bold'>
                  <span>{tokenName}</span>
                  <span>({tokenShortName})</span>
            </div>
            <div className='rounded-full w-10 h-10 overflow-hidden'>
                  <img src={tokenIcon} className='h-full w-full object-contain'/>
            </div>
        </div>
        <div className='font-bold text-lg'>Token details</div>
        <div className='flex gap-3 items-center'>
            <div>Total token owners</div>
            <div>:</div>
            <div>XYZ</div>
        </div>
        <div className='flex gap-3 items-center'>
            <div>Token supply</div>
            <div>:</div>
            <div>{totalSupply}</div>
        </div>
        <div className='flex gap-3 items-center'>
            <div>Circulating supply</div>
            <div>:</div>
            <div>800000</div>
        </div>
        <div className='flex gap-3 items-center'>
            <div>Token creator address</div>
            <div>:</div>
            <div>XYZ</div>
        </div>
      
    </div>
  )
}

export default TokenDetails