import React, { useEffect, useState } from 'react';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { TextField } from '@mui/material';
import Select from '../../ui/Select';
import Input from '../../ui/Input';
import CreateEventForm from '../../components/events/CreateEventForm';
import { useLocation } from 'react-router-dom';
import MinAttendeesList from '../../components/events/MinAttendeesList';

function CreateEvent({id}) {
    const [hideCreateElements,setHideCreateElements] = useState(false);    
    const [selectedFilePreview,setSelectedFilePreview] = useState(null);
    const location = useLocation();
    useEffect(()=>{
        setHideCreateElements(location.pathname.includes("edit-event"))
    },[location])
    
    return (
        <div className="flex flex-col gap-10">
            <div className="flex justify-start">
                {!hideCreateElements && <span className="text-lg font-bold">Event Details</span>}
            </div>
            <div className="flex flex-col-reverse gap-10 md:flex-row ">
                <div className=" flex flex-col gap-5 w-full md:w-3/5 px-5 md:px-10">
                    
                    <CreateEventForm setSelectedFilePreview={setSelectedFilePreview} selectedFilePreview={selectedFilePreview} hideCreateElements={hideCreateElements} id={id}/>
                    
                </div>
                <div className=" flex gap-10 flex-col items-center sticky top-4 w-full md:w-2/5">
                    <div className="shadow-lg  flex justify-center items-center ">
                        {selectedFilePreview &&<img src={selectedFilePreview || ""} className="object-contain h-80 w-full" alt="preview" />}
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateEvent;
