import { ArrowBack, ArrowLeft } from '@mui/icons-material';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/common/Footer';
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar';
import TutorialsSection from '../ui/tutorial/TutorialsSection';

function Tutorial() {
    const appCtx = useSelector((state) => state.app);
  return (
    
    <div className={` landing-bg  min-h-screen ${appCtx.isDarkMode ? "dark" : ""} flex flex-col justify-between`}>
			<UnauthenticatedNavbar />
            <div className='flex flex-col gap-3  '>
                <div className='mt-20'>
                    <Link to="/" className='flex text-xl gap-2 text-slate-300 md:px-20' >
                        <span>
                            <ArrowBack/>
                        </span>
                        <span>
                            Tutorial
                        </span>
                    </Link>
                </div>
                <div>
                <TutorialsSection/>
                </div>
                
            </div>
			<Footer />
		</div>
  )
}

export default Tutorial