import { useCallback, useEffect, useRef, useState } from 'react';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import {
    AddCircle,
    AddRounded,
    DeleteRounded,
    KeyboardArrowLeftRounded,
    Refresh,
    RefreshOutlined
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import useHttp from '../../hooks/use-http';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import BlueButton from '../../ui/BlueButton';
import Select from '../../ui/Select';
import RichTextEditor from '../common/RichTextEditor';
import TextNFTPopup from './TextNFTPopup';
import ThreeDRenderer from '../common/ThreeDRenderer';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import axios from 'axios';
import RetryMint from '../../ui/RetryMint';
import { appActions } from '../../context/app-slice';
const MintAsset = (props) => {
    const [title, setTitle] = useState('');
    const appCtx = useSelector((state) => state.app);

    const [description, setDescription] = useState('');
    const [uploading, setUploading] = useState(false);
    const [content, setContent] = useState(null);
    const [metaData, setMetData] = useState(null);
    const dispatch = useDispatch();
    // const [blockchain,setBlockchain] = useState();
    const [text, setText] = useState();
    const [refresh, setRefresh] = useState(true);
    const [selectedCollectionItem, setSelectedCollectionItem] = useState(props.collection[0]);
    const [textNFTData, setTextNFTData] = useState(null);
    const [collections, setCollections] = useState([]);
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [displayImage, setDispalyImage] = useState();
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [preview, setPreview] = useState();
    const fileRef = useRef();
    const [objectProperties, setObjectProperties] = useState({});
    const [kv, setKV] = useState([]);
    const [displayImageUrl, setDisplayImageUrl] = useState('');

    // const walletBalance = appCtx.walletDetails?.balance;
    useEffect(() => {
        setSelectedCollectionItem(props.collection[0] || collections[0]);

        makeAuthorizedRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/collection/list`
            },
            (data) => {
                setCollections(data.content);
            }
        );
    }, [props.collection, refresh, props.marketplaceId]);

    useEffect(() => {
        setSelectedCollectionItem(props.collection[0] || collections[0]);
    }, [props.collection, refresh, props.marketplaceId, collections]);

    const navigate = useNavigate();

    const makeRequest = useHttp();

    const handleRemoveClick = useCallback(
        (index) => {
            const copyArray = kv.filter((k) => k !== index);
            setKV(copyArray);
        },
        [kv]
    );

    const handleKVChange = useCallback(
        (e, index) => {
            const { name, value } = e.target;
            const list = [...kv];
            list[index][name] = value;
            setKV(list);
        },
        [kv]
    );
    useEffect(() => {
        let object = kv.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
        setObjectProperties(object);
    }, [kv]);
    const handleLogoChange = useCallback((file) => {
        if (file) {
            setDispalyImage(file);
            const objectUrl = URL.createObjectURL(file);
            setPreview(objectUrl);
            setShowUploadButton(true);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    useEffect(() => {
        if (props.nftId) {
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/nft/${props.nftId}/detail`
                },
                (res) => {
                    setContent(res);
                    setMetData(JSON.parse(res?.metaData));
                }
            );
        }
    }, []);
    const handleUpload = () => {
        let flag = true;
        if (!displayImage) {
            toast.error("Display Image can't be empty");
            flag = false;
        }

        if (flag) {
            const data = new FormData();
            data.append('file', displayImage);
            setUploading(true);
            setShowUploadButton(false);

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${props.marketplaceId}/ipfs/upload?type=image`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: data
            };

            toast.promise(
                () =>
                    axios(config)
                        .then((res) => {
                            setDisplayImageUrl(res?.data?.ipfsHash || res?.data?.cid);

                            toast.success('display Image set Successfully');
                        })
                        .catch(function (error) {
                            toast.error('Uploading display image failed!');
                            setUploading(false);
                            setShowUploadButton(true);
                        }),
                {
                    pending: 'Updating your marketplace settings...',
                    success: 'Settings updated successfully!',
                    error: 'Uh-oh! Something went wrong'
                }
            );
        }
    };

    const loadWallet = useCallback(() => {
        if ((appCtx.marketplaceId && appCtx.blockchain !== '') || !appCtx.newSignup) {
            if (appCtx.marketplaceId) {
                makeRequest(
                    {
                        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/wallet/balance?blockchain=${appCtx?.blockchain}`,
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Auth-Token': appCtx.authToken
                        }
                    },
                    (data) => {
                        dispatch(appActions.setWalletDetails(data));
                        // dispatch(appActions.setBlockChain(data.blockchain))
                        return data;
                    },
                    (data) => {},
                    () => {}
                );
            }
        }
    }, []);
    const handleSale = useCallback(
        (mintingType) => {
            const blockchain = appCtx.blockchain;
            let flag = true;
            if (!selectedCollectionItem && !props.nftId) {
                toast.error("Collection can't be empty");
                flag = false;
            }
            if (!title && !props.nftId) {
                toast.error("Name can't be empty");
                flag = false;
            }

            if (props.type === 'text' && !text) {
                toast.error('The nft text cant be empty!');
                flag = false;
            }

            if (flag) {
                const data = {
                    standard:
                        appCtx.blockchain === 'ALGORAND' ? 'ARC69' : appCtx.blockchain === 'BSC' ? 'BEP721' : 'ERC721',
                    collection: selectedCollectionItem.text,
                    name: title,
                    description: description,
                    properties: objectProperties,
                    mimeType: `${props.type}/${props.extension}`,
                    image: `ipfs://${displayImageUrl || props.ipfsHash}`,
                    cid: props.ipfsHash,
                    mediaUrl: `ipfs://${props.ipfsHash}`
                };

                props.type === 'text' && (data.description = text);
                const config = {
                    url: props.nftId
                        ? `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/nft/${props.nftId}/mint/retry`
                        : props.type === 'text'
                        ? `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/blockchain/${blockchain}/text/nft/mint`
                        : `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/blockchain/${blockchain}/nft/mint`,

                    method: 'POST',
                    data,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    }
                };

                toast.promise(
                    () =>
                        // makeRequest(
                        //     {
                        //         url: props.nftId ?     `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/nft/${props.nftId}/mint/retry` :
                        //             props.type === 'text'
                        //                 ? `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/blockchain/${blockchain}/text/nft/mint`
                        //                 : `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/blockchain/${blockchain}/nft/mint`,
                        //         data,
                        //         method: 'POST',
                        //         headers: {
                        //             'Content-Type': 'application/json',
                        //             'X-Auth-Token': appCtx.authToken,

                        //         }
                        //     },
                        //     (res) => {
                        //         props.type === 'text'
                        //             ? setTextNFTData(JSON.stringify(data))
                        //             :
                        //             mintingType ==="sale"?
                        //             navigate(
                        //                 `/salenft/${res?.nftId}`
                        //             ) : navigate(`/asset/${res?.nftId}`)
                        //     },
                        //     () => {},
                        //     () => {}
                        // ),
                        axios(config)
                            .then((res) => {
                                toast.success('Minting request submitted');

                                props.type === 'text'
                                    ? setTextNFTData(JSON.stringify(data))
                                    : mintingType === 'sale'
                                    ? navigate(`/salenft/${res?.data?.nftId}`)
                                    : navigate(`/asset/${res?.data?.nftId}`);
                            })
                            .catch((rej) => {
                                if (rej?.response?.data?.status === 500) {
                                    toast.error(rej?.response?.data?.message?.split(':')[1]);
                                } else {
                                    toast.error('Could not process the request!!');
                                }
                            }),
                    {
                        pending: 'Updating your NFT...'
                        // success: 'Minting request submitted',
                        // error: 'Something went wrong!'
                    }
                );
            }
        },
        [
            selectedCollectionItem,
            title,
            description,
            props.type,
            props.ipfsHash,
            props.marketplaceId,
            props.s3url,
            text,
            kv,
            makeRequest,
            appCtx.authToken,
            navigate,
            displayImageUrl,
            objectProperties
        ]
    );

    return (
        <>
            {textNFTData && <TextNFTPopup data={textNFTData} />}

            <div className="flex flex-col gap-10 pb-2 relative">
                <div className="flex md:flex-row flex-col gap-20 ">
                    <div className="grid gap-5 md:order-1 order-2 w-full">
                        <div className="flex  items-center justify-between">
                            <div className="text-xl flex items-center font-semibold  ">NFT Details</div>

                            <div className="flex flex-col w-56   justify-between items-center border border-gray-700 p-3 rounded-md">
                                <div className="">
                                    {appCtx.blockchain === 'ALGORAND'
                                        ? 'ARC69'
                                        : appCtx.blockchain === 'BSC'
                                        ? 'BEP721'
                                        : 'ERC721 '}
                                </div>
                            </div>
                        </div>
                        {
                            <div className="flex flex-col gap-3">
                                <div className="flex flex-col justify-between">
                                    <div className="font-bold ">Name</div>
                                    <Input
                                        maxLength={32}
                                        type="text"
                                        disabled={!!props.nftId}
                                        className="border border-gray-700 px-4 py-2 rounded-md"
                                        value={props.nftId ? metaData?.name : title}
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                        }}
                                    />
                                    <div
                                        className={`text-[12px] flex justify-end ${
                                            title?.length === 32 && 'text-red-700 italic '
                                        }`}
                                    >
                                        Character limit: 32
                                    </div>
                                </div>
                                <div className="flex flex-col justify-between gap-3 ">
                                    <div className="font-bold">
                                        {props.type === 'text' ? <>Title</> : <>Description</>}
                                    </div>
                                    <Input
                                        disabled={!!props.nftId}
                                        value={props.nftId ? metaData?.description : description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        type="text"
                                        className="border border-gray-700 px-4 py-5 rounded-md dark:text-black"
                                    />
                                </div>
                                {props.type !== 'text' ? (
                                    ''
                                ) : (
                                    <RichTextEditor
                                        header={'Describe your nft'}
                                        value={text}
                                        onChange={setText}
                                        placeholder={'My cool nft description'}
                                    />
                                )}

                                <div className="flex flex-col gap-5">
                                    <div
                                        className={`flex gap-5 ${
                                            collections?.length === 0 ? 'items-center' : 'items-center'
                                        } `}
                                    >
                                        {collections?.length === 0 ? (
                                            <div
                                                className={
                                                    'text-gray-700 dark:text-gray-300 text-lg border border-gray-700 rounded-md p-3'
                                                }
                                            >
                                                You dont have any collections yet. Create one.
                                            </div>
                                        ) : (
                                            <Select
                                                disabled={!!props.nftId}
                                                containerClassName="flex-col"
                                                className=" py-2 w-60"
                                                onChange={(d) => setSelectedCollectionItem(d)}
                                                value={props.nftId ? metaData?.collection : selectedCollectionItem}
                                                data={collections}
                                                header={'Choose collection'}
                                            />
                                        )}
                                        <button onClick={() => setRefresh(!refresh)}>
                                            <Refresh />
                                        </button>
                                        <button
                                            onClick={props.onOpenCreateCollection}
                                            className={
                                                'items-center flex px-5 gap-1 rounded-md py-1 border bg-secBlue text-white'
                                            }
                                            filled
                                        >
                                            <AddCircle fontSize="small" /> <div>Add </div>
                                        </button>
                                    </div>
                                    {/* <Select className="px-9" onChange value={''} data={''} header={'Choose Category'} /> */}
                                </div>
                                {kv.map((x, i) => (
                                    <div className="flex flex-row gap-5 items-between justify-between">
                                        <Input
                                            onChange={(e) => handleKVChange(e, i)}
                                            name="name"
                                            disabled={!!props.nftId}
                                            placeholder="Property"
                                            type="text"
                                            value={x.name}
                                        />
                                        <Input
                                            onChange={(e) => handleKVChange(e, i)}
                                            name="value"
                                            disabled={!!props.nftId}
                                            placeholder="Value"
                                            type="text"
                                            value={x.value}
                                        />
                                        <button
                                            onClick={() => handleRemoveClick(x)}
                                            className="bg-gray-100 hover:bg-gray-200 transition-all dark:bg-gray-700 dark:hover:bg-gray-600 ease-out duration-300 p-3 rounded mt-2"
                                        >
                                            <DeleteRounded />
                                        </button>
                                    </div>
                                ))}

                                <BlueGradientButton
                                    className="w-fit"
                                    onClick={() => setKV((prev) => [...prev, { name: '', value: '' }])}
                                >
                                    Add property
                                </BlueGradientButton>
                                {props.type === 'threeD' && (
                                    <div className="flex gap-3 flex-col">
                                        <div className="font-bold">Display Image</div>
                                        <div className="flex gap-3">
                                            <div className=" flex items-center">
                                                <button
                                                    className="bg-secBlue py-3 px-6 rounded-md text-white"
                                                    onClick={() => fileRef.current.click()}
                                                >
                                                    Browse
                                                </button>
                                            </div>
                                            <input
                                                disabled={!!props.nftId}
                                                type="file"
                                                ref={fileRef}
                                                hidden={true}
                                                onChange={(e) => handleLogoChange(e.target.files[0])}
                                            />
                                            {preview && (
                                                <div className="flex  gap-3 justify-center">
                                                    <div className="shadow-sm w-[30vw] h-[30vh]">
                                                        <img src={preview} className="w-full h-full object-cover" />
                                                    </div>
                                                    <div className=" flex items-center">
                                                        {showUploadButton && (
                                                            <button
                                                                className="bg-secBlue py-3 px-2 rounded-md text-white"
                                                                onClick={handleUpload}
                                                            >
                                                                Confirm
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        }

                        {appCtx?.walletDetails?.balance < 0.001 && (
                            <div className=" flex text-sm justify-start p-3 rounded-md">
                                <span className="text-[#ffc107]">Insufficient wallet balance . Please</span>
                                <button
                                    onClick={() => {
                                        const url = `https://dispenser.testnet.aws.algodev.network/?account=${appCtx?.walletDetails?.address}`;

                                        window.open(url);
                                    }}
                                    className="px-2 overflow-hidden text-blue-500"
                                >
                                    recharge
                                </button>
                                <span className="text-[#ffc107]"> your wallet to avoid service disruption .</span>
                                <button onClick={loadWallet}>
                                    <RefreshOutlined />
                                </button>
                            </div>
                        )}
                        <div className="flex gap-16">
                            <Link
                                className={'font-bold text-black no-underline flex items-center dark:text-white'}
                                to="/mint"
                            >
                                Cancel
                            </Link>
                            <button
                                disabled={appCtx?.walletDetails?.balance < 0.001}
                                className={`px-24 border-secBlue border rounded-md py-3 font-bold ${
                                    appCtx?.walletDetails?.balance < 0.001 && 'bg-gray-500 border-none'
                                }`}
                                onClick={() => handleSale('mint')}
                            >
                                Mint
                            </button>
                            <button
                                disabled={appCtx?.walletDetails?.balance < 0.001}
                                className={` px-10 text-white border-secBlue border rounded-md py-3 font-bold bg-secBlue ${
                                    appCtx?.walletDetails?.balance < 0.001 && 'bg-gray-500 border-none'
                                }`}
                                onClick={() => handleSale('sale')}
                            >
                                Mint & Sale
                            </button>
                        </div>

                        {/* <BlueGradientButton onClick={handleDoneClicked} className="w-full">
                            Submit
                        </BlueGradientButton> */}
                    </div>
                    <div className="w-full flex flex-col  gap-3 md:w-[50%] md:order-2 order-1 max-h-96">
                        <div className="text-xl font-semibold mb-10 ">Preview of your NFT</div>
                        {props.type === 'text' ? (
                            <div className={'ql-editor'} dangerouslySetInnerHTML={{ __html: text }} />
                        ) : props.type === 'image' ? (
                            <img
                                className="w-full shadow-lg rounded-lg object-contain h-full"
                                src={`${process.env.REACT_APP_GATEWAY_IPFS}/${props?.ipfsHash}`}
                                alt="NFT"
                            />
                        ) : props?.type === 'video' ? (
                            <video
                                controls
                                autoPlay
                                className="w-full shadow-lg rounded-lg object-contain h-full"
                                src={`${process.env.REACT_APP_GATEWAY_IPFS}/${props?.ipfsHash}`}
                            />
                        ) : (
                            <ThreeDRenderer
                                className={`w-full shadow-lg rounded-lg object-contain h-full`}
                                src={`${process.env.REACT_APP_GATEWAY_IPFS}/${props?.ipfsHash}`}
                                // src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${props.s3url}`}
                                type={props.extension}
                            />
                        )}

                        {props.type !== 'text' && (
                            <Input
                                header="Your ipfs hash"
                                disabled={true}
                                type="text"
                                className="text-sm"
                                value={props.ipfsHash}
                            />
                        )}
                        {props.type !== 'text' && (
                            <button
                                className=" bg-secBlue rounded-md text-white py-3 "
                                onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_GATEWAY_IPFS}/${props.ipfsHash}`,
                                        '_blank',
                                        'noopener,noreferrer'
                                    )
                                }
                            >
                                View ipfs hash
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MintAsset;
