import { Cancel, CancelOutlined, DeleteRounded } from '@mui/icons-material';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalOverlay from '../components/common/ModalOverlay';
import ThreeDRenderer from '../components/common/ThreeDRenderer';
import { appActions } from '../context/app-slice';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import useHttp from '../hooks/use-http';
import BlueGradientButton from '../ui/BlueGradientButton';
import CustomButton from '../ui/CustomButton';
import Input from '../ui/Input';

export const UploadNft = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [uploadFile, setUploadFile] = useState();
    const [uploaded, setUploaded] = useState('0%');
    const [uploadFinished, setUploadFinished] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [fileType, setFileType] = useState('');
    const fileRef = useRef();
    const appCtx = useSelector((state) => state.app);
    const [nftExtension, setExtension] = useState('');
    const [asset, setAsset] = useState(appCtx.nftData || null);
    const location = useLocation();
    const [showUploadSection, setShowUploadSection] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [mintNft, setMintNft] = useState(false);
    const navigate = useNavigate();
    const type = new URL(window.location.href).searchParams.get('type');
    const marketplaceId = appCtx.marketplaceId;
    const dispatch = useDispatch();
    const makeRequest = useAuthorizedHttp();
    const [kv, setKV] = useState([]);
    const [collectionName, setCollectionName] = useState('');
    const [objectProperties, setObjectProperties] = useState({});

    const imageExtensions = ['jpg', 'jpeg', 'gif', 'tiff', 'psd', 'raw', 'png', 'svg'];
    const videoExtensions = ['mp4', 'mov', 'wmv', 'avi', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'mpeg-2', 'webm'];
    const threeDExtensions = ['fbx', 'obj', 'gltf', 'glb'];
    const maxFileSize = 1024 * 1024 * 1024;

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${appCtx.domain}/page/home/detail`
            },
            (data) => {
                data.buttonLabel && setButtonLabel(data.buttonLabel);
            },
            (data) => console.log(data),
            () => {}
        );
    }, [makeRequest]);
    const premint = () => {
        if (marketplaceId && appCtx.blockchain) {
            makeRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint`
                },
                (data) => {
                    dispatch(appActions.setNftList(data.content));
                    const metaData = JSON.parse(data.content[data.content?.length - 1].metaData);
                    const nftDataObj = {
                        ipfsHash: metaData.image?.split('/')[2],
                        assetId: appCtx.assetId || null,
                        s3url: appCtx.s3url || null,
                        assetType: metaData.mime_type?.split('/')[0],
                        extension: metaData.mime_type?.split('/')[1]
                    };
                    console.log(nftDataObj);
                    dispatch(appActions.setNftData(nftDataObj));
                    setObjectProperties(metaData.properties);
                    let kvArray = [];
                    console.log(metaData.properties);
                    for (const key in metaData.properties) {
                        const obj = {
                            name: key,
                            value: metaData.properties[key]
                        };
                        kvArray = [...kvArray, obj];
                    }
                    console.log(kvArray);
                    setKV(kvArray);
                    setDescription(metaData.description);
                    setName(metaData.name);
                    console.log(metaData);
                },
                (data) => {
                    console.log(data);
                },
                () => {
                    setLoading(false);
                }
            );
        }
    };
    useEffect(() => {
        premint();
    }, []);

    const submitForm = useCallback(async () => {
        var data = new FormData();
        data.append('file', uploadFile);
        console.log(uploadFile, data);
        var reader = new FileReader();
        reader.onload = function (e) {
            // var image = document.createElement('img');
            // image.src = e.target.result;
            // document.body.appendChild(image);
            setImageSrc(e.target.result);
            // dispatch(appActions.setImageSrc(e.target.result));
        };
        reader.readAsDataURL(uploadFile);
        // setUploading(true);
        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${marketplaceId}/file/upload?type=${fileType}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            onUploadProgress: (progressEvent) => {
                setUploaded(`${Number((progressEvent.loaded / uploadFile.size) * 100).toFixed(0)}%`);
            },
            data: data
        };
        if (uploadFile) {
            // axios(config)
            //     .then((res) => {

            //     })
            //     .catch((rej) => {

            //     })

            toast.promise(
                () =>
                    axios(config)
                        .then(function (res) {
                            // setUploading(false);
                            // setAsset(response.data);
                            // setUploadFinished(true);
                            // resolve();
                            console.log(res);
                            setUploading(false);
                            setAsset(res.data);

                            dispatch(
                                appActions.setNftData({
                                    ipfsHash: res.data.ipfsHash,
                                    s3url: res.data.s3url,
                                    assetId: res.data.assetId,
                                    assetType: res.data.type,
                                    extension: nftExtension
                                })
                            );
                            setUploadFinished(true);
                            uploadFinished();
                            toast.success('Your asset was uploaded successfully!');
                        })
                        .catch(function (rej) {
                            if (rej.response.status === 500) {
                                setUploading(false);
                                setUploadFinished(true);
                                setShowModal(true);
                                toast.error('There was an error while uploading your asset!');
                            }
                        }),
                {
                    pending: 'Starting upload...'
                    // success: 'Your asset was uploaded successfully!',
                    // error: 'There was an error while uploading your asset!'
                }
            );
        }
    });
    const unselectFile = () => {
        setAsset(null);
        setUploadFile(null);
        setImageSrc(null);
        dispatch(appActions.setImageSrc(null));
    };
    useEffect(() => {
        // dispatch(appActions.setMintNft(false));
    }, [dispatch, appCtx.imageSrc]);
    const stageForUpload = useCallback((file) => {
        setAsset(null);
        if (!uploading) {
            setUploaded('0%');
            setUploadFile(file);
            setUploadFinished(false);
        } else {
            toast.error('Another upload still in progress!');
        }
    }, []);

    const isFileValid = useCallback((file) => {
        if (file.size > maxFileSize) {
            toast.error('File size exceeds 500mb!');
            return false;
        }
        let extension = file.name.split('.');
        extension = extension[extension.length - 1];
        console.log(extension);
        setExtension(extension);
        if (imageExtensions.includes(extension)) {
            setFileType('image');
            return true;
        } else if (videoExtensions.includes(extension)) {
            setFileType('video');
            return true;
        } else if (threeDExtensions.includes(extension)) {
            setFileType('threeD');
            return true;
        } else {
            return null;
        }
    }, []);

    const handleFileDropped = useCallback(
        (e) => {
            e.preventDefault();
            const f = e.dataTransfer.files[0];

            if (isFileValid(f)) stageForUpload(f);
        },
        [isFileValid, stageForUpload]
    );

    const handleFileInputChange = useCallback(
        (e) => {
            const f = e.target.files[0];
            if (isFileValid(f)) stageForUpload(f);
        },
        [isFileValid, stageForUpload]
    );

    const handleRemoveClick = useCallback(
        (index) => {
            console.log(kv, index);
            const propertyArray = kv.filter((property, i) => {
                return i !== index;
            });
            setKV(propertyArray);
            console.log(kv, index);
        },
        [kv]
    );

    const handleKVChange = useCallback(
        (e, index) => {
            const { name, value } = e.target;
            const list = [...kv];
            list[index][name] = value;
            setKV(list);
        },
        [kv]
    );
    useEffect(() => {
        let object = kv.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
        setObjectProperties(object);
        console.log(objectProperties);
    }, [kv]);
    const handleDoneClicked = useCallback(
        (buttonName, name) => {
            const blockchain = appCtx.blockchain;
            let flag = true;
            let myCollectionName = '';
            makeRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/save`,
                    method: 'post',
                    data: {
                        name: 'eventPage'
                    }
                },
                (data) => {
                    // setCollectionName(data.name);
                    myCollectionName = data.name;
                    if (flag) {
                        const data = {
                            standard:
                                appCtx.blockchain === 'ALGORAND'
                                    ? 'ARC69'
                                    : appCtx.blockchain === 'BSC'
                                    ? 'BEP721'
                                    : 'ERC721',
                            name,
                            collection: myCollectionName,
                            marketplaceId: appCtx.marketplaceId,
                            description,
                            properties: objectProperties,
                            mimeType: `${asset?.type || asset?.assetType}/${nftExtension}`,
                            image: `ipfs://${asset?.ipfsHash}`,
                            cid: asset?.ipfsHash,
                            media_url: `ipfs://${asset?.ipfsHash}`
                        };
                        asset?.type || asset?.assetType !== 'text'
                            ? (data.ipfsHash = asset?.ipfsHash)
                            : (data.description = description);
                        toast.promise(
                            () =>
                                makeRequest(
                                    {
                                        url:
                                            (asset?.type || asset?.assetType) === 'text'
                                                ? `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${blockchain}/text/nft/premint`
                                                : `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${blockchain}/nft/premint`,
                                        data,
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'X-Auth-Token': appCtx.authToken
                                        }
                                    },
                                    (res) => {
                                        console.log(res);
                                        premint();
                                        // (asset?.type|| asset?.assetType) === 'text'
                                        //     ? setTextNFTData(JSON.stringify(data))
                                        //     :
                                        //     navigate(`/asset/${props.ipfsHash}?name=${name}&s3url=${props.s3url}&type=${props.type}`)
                                    },
                                    () => {},
                                    () => {}
                                ),
                            {
                                pending: 'Updating your NFT...',
                                success: 'nft request submitted',
                                error: 'Something went wrong!'
                            }
                        );
                        console.log(buttonName);
                        const marketplace = {
                            // description,
                            // name,
                            buttonLabel: buttonLabel,
                            // buttonLink: buttonURL,
                            marketplaceId: appCtx.marketplaceId,
                            page: 'home'
                        };
                        makeRequest(
                            {
                                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                                method: 'POST',
                                data: marketplace
                            },
                            (data) => console.log(data),
                            (data) => console.log(data),
                            () => {
                                // setUploading(false);
                            }
                        );
                    }
                },
                () => {},
                () => setLoading(false)
            );
            // if(!collectionName && collectionName===''){
            //     flag=false
            // }
        },
        [
            appCtx.authToken,
            appCtx.blockchain,
            appCtx.marketplaceId,
            asset,
            description,
            dispatch,
            makeRequest,
            nftExtension,
            objectProperties
        ]
    );
    return (
        <div>
            <div className="flex flex-col md:flex-row">
                <div className="flex flex-col gap-3 w-[30%]">
                    <div className="w-full flex flex-col gap-5">
                        <div className="font-semibold">Upload asset (Image , Video ,3D fbx supported )</div>
                        <button
                            draggable={true}
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleFileDropped}
                            onClick={() => fileRef.current.click()}
                            onDrag={() => fileRef.current.drag()}
                            className="w-full border-2 border-dashed rounded h-[200px] items-center justify-center flex dark:bg-gray-700 bg-gray-100/70 dark:hover:bg-sky-700/50 hover:bg-sky-100/50 transition-all ease-out duration-300"
                        >
                            <div>Drag and drop images here or browse...</div>
                            <input
                                draggable={true}
                                type="file"
                                className="hidden"
                                onChange={handleFileInputChange}
                                ref={fileRef}
                            />
                        </button>
                        {uploadFile && (
                            <div className="flex flex-col w-full">
                                <div className="flex justify-between rounded-t  p-4">
                                    <div className="">{`${uploadFile?.name?.substring(
                                        0,
                                        10
                                    )}...${uploadFile?.name?.substring(
                                        uploadFile?.name?.length - 5,
                                        uploadFile?.name?.length
                                    )}`}</div>
                                    <CustomButton onClick={unselectFile}>
                                        <Cancel />
                                    </CustomButton>
                                </div>
                                <div className="flex flex-row">
                                    <div className="h-2 bg-green-500 rounded-bl" style={{ width: uploaded }}></div>
                                    <div className="h-2 bg-gray-200 dark:bg-gray-700 grow rounded-br"></div>
                                </div>
                            </div>
                        )}
                        <div className="flex justify-center ">
                            <CustomButton
                                disabled={!uploadFile || uploading}
                                className={`px-28 w-[100%] border-gray-600`}
                                onClick={() => submitForm()}
                            >
                                Upload
                            </CustomButton>
                        </div>
                        {showModal && (
                            <div>
                                <ModalOverlay>
                                    <div className="flex flex-col gap-5 py-6 relative">
                                        <div
                                            className="flex justify-end absolute right-2 -top-3 cursor-pointer"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <CancelOutlined />
                                        </div>
                                        <div className="flex w-96 flex-col">
                                            This uploaded asset is owned & listed on marketplace for sale by someone
                                            else if you think its not you please do Raise a Dispute, we will help you
                                            out finding the appropriate solution.
                                            <span className="font-bold">Send an email to info@onnftverse.com</span>
                                        </div>
                                    </div>
                                </ModalOverlay>
                            </div>
                        )}
                    </div>

                    {/* <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Upload NFTs</div>
                    <button
                        onClick={() => bannerFileRef.current.click()}
                        className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700  w-[350px] h-[100px] rounded-lg"
                    >
                        <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center h-full rounded-lg transition-all ease-out duration-300">
                            Browse for NFTs
                        </div>
                        {nftPreview && (
                            <img
                                src={nftPreview}
                                alt="NFT"
                                className="object-cover relative bottom-[100px]  w-[350px] h-[100px] z-10  overflow-hidden rounded-lg"
                            />
                        )}
                    </button>
                    {nftPreview && <div className='flex justify-center'>{!uploadingBanner && showUploadBannerButton && <button
                        disabled={!collectionBanner || uploadingBanner}
                        onClick={handleUploadBanner}
                        // onClick={() => fileRef.current.click()}
                        className="group px-20 bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12  text-white"
                    >
                        Confirm
                    </button>}</div>}
                    <input
                        hidden={true}
                        type="file"
                        ref={bannerFileRef}
                        onChange={(e) => {
                            handleBannerChange(e.target.files[0])
                            setShowUploadBannerButton(true)
                        }}
                    /> */}
                    <div className="text-lg font-bold dark:text-gray-400 text-gray-700 mt-[20px]">NFT Name</div>
                    <Input
                        className="w-[100%] h-[40px] rounded-lg px-[10px] text-black"
                        value={name}
                        placeholder="Enter Nft name"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <div className="text-lg font-bold dark:text-gray-400 text-gray-700 mt-[20px]">NFT Description</div>
                    <Input
                        className="bg-gray-400 rounded-lg w-[350px] px-[10px]"
                        placeholder="Enter NFT Description"
                        value={description?.substring(0, 250)}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                    ></Input>
                    <div className="text-right">
                        {description?.length > 250 ? 250 : description.length}/{250}
                    </div>
                    {kv.map((x, i) => (
                        <div className="flex flex-row gap-5 items-between justify-between">
                            <Input
                                onChange={(e) => handleKVChange(e, i)}
                                name="name"
                                placeholder="Property"
                                type="text"
                                value={x.name}
                            />
                            <Input
                                onChange={(e) => handleKVChange(e, i)}
                                name="value"
                                placeholder="Value"
                                type="text"
                                value={x.value}
                            />
                            <button
                                onClick={() => handleRemoveClick(i)}
                                className="bg-gray-100 hover:bg-gray-200 transition-all dark:bg-gray-700 dark:hover:bg-gray-600 ease-out duration-300 p-3 rounded mt-2"
                            >
                                <DeleteRounded />
                            </button>
                        </div>
                    ))}

                    <BlueGradientButton
                        className="w-fit"
                        onClick={() => setKV((prev) => [...prev, { name: '', value: '' }])}
                    >
                        Add property
                    </BlueGradientButton>
                    {/* <div className="text-lg font-bold dark:text-gray-400 text-gray-700 mt-[20px]">Button Label Name</div>
                    <input className='w-[100%] h-[40px] rounded-lg px-[10px] text-black' value={buttonLabel?.substring(0, 30)} placeholder='Enter button label name' onChange={(e) => { setButtonLabel(e.target.value) }} /> */}
                </div>
                <div className="lg:ml-[50px] mt-[50px] sm:mt-[0px] rounded-lg w-[306%] sm:w-[121%] mr-[10px] flex flex-col items-center shadow-prevBox dark:bg-inherit dark:border-darkBorder h-[100%] xl:w-3/4  border-white border-[20px] dark:border-darkSecondary ">
                    <div className="w-[100%] flex flex-row-reverse justify-between items-center pl-[1rem] pr-[1rem] xl:px-10 py-4 gap-5">
                        <div className="flex flex-col">
                            {name !== '' ? (
                                <div
                                    className="w-[360px] min-h-[20px] flex justify-start items-center text-[15px]"
                                    style={{ overflowWrap: 'anywhere' }}
                                >
                                    {name}
                                </div>
                            ) : (
                                <div className="w-[360px] mb-[10px] min-h-[20px] flex justify-center items-center text-[20px] bg-gray-700">
                                    NFT Name Preview
                                </div>
                            )}
                            <div className="w-[360px] min-h-[20px]" style={{ overflowWrap: 'break-word' }}>
                                {description !== '' ? (
                                    <div
                                        className="w-[360px] min-h-[20px] flex justify-start items-center text-[15px]"
                                        style={{ overflowWrap: 'anywhere' }}
                                    >
                                        {description?.substring(0, 250)}
                                    </div>
                                ) : (
                                    <div className="w-[360px] mb-[10px] min-h-[100px] flex justify-center items-center text-[20px] bg-gray-700">
                                        NFT Description Preview
                                    </div>
                                )}
                            </div>
                            <div className="grid grid-cols-3 gap-2">
                                {kv?.length === 0 ? (
                                    <>
                                        <div className="flex flex-col gap-3 bg-gray-700 border border-gray-600 rounded-2xl items-center p-3 leading-[10px]">
                                            <span className="text-base text-white ">{'Key1'}</span>
                                            <span className="text-lg font-bold text-white">{'Value1'}</span>
                                            {/* <span className='text-slate-400 '>{props.percentage}% have this trait</span> */}
                                        </div>
                                        <div className="flex flex-col gap-3 bg-gray-700 border border-gray-600 rounded-2xl items-center p-3 leading-[10px]">
                                            <span className="text-base text-white ">{'Key2'}</span>
                                            <span className="text-lg font-bold text-white">{'Value2'}</span>
                                            {/* <span className='text-slate-400 '>{props.percentage}% have this trait</span> */}
                                        </div>
                                        <div className="flex flex-col gap-3 bg-gray-700 border border-gray-600 rounded-2xl items-center p-3 leading-[10px]">
                                            <span className="text-base text-white ">{'Key3'}</span>
                                            <span className="text-lg font-bold text-white">{'Value3'}</span>
                                            {/* <span className='text-slate-400 '>{props.percentage}% have this trait</span> */}
                                        </div>
                                    </>
                                ) : (
                                    kv?.map((item) => {
                                        return (
                                            <>
                                                <div className="flex flex-col gap-3 bg-gray-700 border border-gray-600 rounded-2xl items-center p-3 leading-[10px]">
                                                    <span className="text-base text-white ">{item.name}</span>
                                                    <span className="text-lg font-bold text-white">{item.value}</span>
                                                    {/* <span className='text-slate-400 '>{props.percentage}% have this trait</span> */}
                                                </div>
                                            </>
                                        );
                                    })
                                )}
                            </div>
                            {/* {buttonLabel ? ( */}
                            {/* <BlueGradientButton className='bg-gray-700 w-[100%] h-[40px] rounded-full'>{buttonLabel?.substring(0, 30)}</BlueGradientButton>
                            )
                                : */}
                            <BlueGradientButton className=" w-[100%] h-[40px] mt-[20px] rounded-full flex justify-center items-center">
                                Claim Nft
                            </BlueGradientButton>
                            {/* } */}
                        </div>
                        <div className="w-[300px] flex flex-col items-center justify-center">
                            <div className="w-[100%] mb-[30px] border-[10px] border-gray-600">
                                {asset && asset?.type !== '' ? (
                                    <div className="flex flex-col gap-[20vh] ">
                                        {asset?.type === 'image' || asset?.assetType === 'image' ? (
                                            <div className="shadow-md p-4 rounded-md">
                                                {asset.ipfsHash ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${asset?.ipfsHash}`}
                                                        className="w-[100%] object-contain"
                                                        alt="logo"
                                                    />
                                                ) : (
                                                    <img
                                                        src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                                        className="w-[100%] object-contain"
                                                        alt="logo"
                                                    />
                                                )}
                                            </div>
                                        ) : asset.type === 'video' || asset?.assetType === 'video' ? (
                                            <video
                                                controls
                                                autoPlay
                                                className="w-[100%] shadow-lg rounded-lg object-contain"
                                                src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                            />
                                        ) : (
                                            <ThreeDRenderer
                                                className={`w-[100%] shadow-lg rounded-lg object-contain`}
                                                src={
                                                    !asset?.s3url
                                                        ? `${process.env.REACT_APP_GATEWAY_IPFS}/${asset?.ipfsHash}`
                                                        : `${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`
                                                }
                                                type={nftExtension}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="w-[100%] h-[200px] flex justify-center items-center text-[20px] bg-gray-700">
                                        NFT Preview
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-32 flex-wrap items-center mt-[20px]">
                {/* {confirm && <div className="text-green-500">Marketplace header details updated successfully </div>} */}
                {description && name && (
                    <BlueGradientButton
                        // to='/uploademail'
                        className=" no-underline bg-secBlue py-3 px-12 rounded-md text-white"
                        onClick={() => handleDoneClicked(buttonLabel, name)}
                    >
                        Save
                    </BlueGradientButton>
                )}
            </div>
        </div>
    );
};
