import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ModalOverlay from '../../../components/common/ModalOverlay';
import RichTextEditor from '../../../components/common/RichTextEditor';
import EventDetailCard from '../../../components/events/EventDetailCard';
import Input from '../../../ui/Input';

export const NotifyClaimers = ({ openModal, setOpenModal, claimers, link,desc,sub }) => {
    function closeModal() {
        setOpenModal(false);
    }
    const appCtx = useSelector((state) => state.app)
    const [subject, setSubject] = useState(sub);
    const [description, setDescription] = useState(desc);
    const [loading, setLoading] = useState(false);

    const handleEmail = () => {
        setLoading(true)
        let claimersEmailArray = [];
        claimers?.map((user) => {
            claimersEmailArray = [...claimersEmailArray, user?.email]
        })
        const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
        let config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/bulk/email/send`,
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": appCtx.authToken,
                "X-App-Token": process.env.REACT_APP_APP_TOKEN
            },
            data: {
                "subject": subject,
                "body": JSON.stringify(description).replace(regex, '<br>'),
                "email": claimersEmailArray
            }
        }
        axios(config)
            .then((res) => {
                setLoading(false)
                toast.success('email send successfully!!!')
                closeModal();
            })
            .catch((error) => {
                setLoading(false)
                toast.error('something went wrong')
            })
    }
    return (
        <ModalOverlay
            open={openModal}
            onClose={closeModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"

        >

            <div className="flex flex-col gap-3 w-[400px]">
                <div className="flex justify-end">
                    <button onClick={closeModal}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <EventDetailCard title="Subject">
                    <Input
                        value={subject}
                        placeholder="Enter email subject"
                        type="textarea"
                        className='text-white'
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </EventDetailCard>
                <EventDetailCard title="Description">
                    <div className={'text-gray-800 h-[300px] mb-10'}>
                        <RichTextEditor value={description} onChange={(e) => setDescription(e)} />
                    </div>
                    {/* <Input
                        value={description}
                        placeholder="Enter email description"
                        type="textarea"
                        onChange={(e) => setDescription(e.target.value)}
                        className="h-[300px]"
                    /> */}
                </EventDetailCard>
                <div className='flex justify-center'>
                    <button onClick={() => { handleEmail() }} className=' rounded-lg p-2 bg-blue-700'>Send email&nbsp;{loading && <CircularProgress size={20} />}</button>
                </div>
            </div>

        </ModalOverlay>
    )
}
