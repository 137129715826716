import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function useCreateEvent({ setSelectedFilePreview, selectedFilePreview, hideCreateElements }) {
    const appCtx = useSelector((state) => state.app);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [eventLocation,setEventLocation] = useState("");
    const [eventVenue,setEventVenue] = useState("");
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedEventType, setSelectedEventType] = useState('Please select event type');
    const [updating,setUpdating] = useState(false);
    const {id} = useParams();

    const inputBannerRef = useRef(null);
    const formRef = useRef(null);
    const navigate = useNavigate();
    const submitForm = useCallback(
        (e) => {
            
            e.preventDefault();

            const flag = true;
            const startTimeMili = new Date(startTime).getTime()
            const endtTimeMili = new Date(endTime).getTime();
            const currentTimeMili = new Date().getTime();

            if(startTimeMili < currentTimeMili){
                toast.error("Event Start time cant be before the current time")
                flag = false
            }
            if(startTimeMili >= endtTimeMili){
                toast.error("Event end time cant be at or before  the  event Start time ")
                flag=false;
            }
            

            if(flag){
                setUpdating(true)
            const method = id ?   'put':"post";
            const eventId = id ? id : null;
            const config={
                url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/event`,
                data:{
                "contact": `${email},${phone}`,
                "coverImage": selectedFilePreview,
                "description": description,
                "endDate": endTime,
                "endTime": endTime?.split("T")[1],
                 "eventId": eventId,
                "eventName": title,
                // "icon": "string",
                "mapLocation": eventLocation,
                "orgId": appCtx.marketplaceId,
                "startDate": startTime,
                "startTime": startTime?.split("T")[1],
                "userId": appCtx?.marketplac?.userId,
                "venue": eventVenue
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': appCtx.authToken,
                },
                method:method
              }

              axios(config)
              .then( res => {
                navigate(`/events/event-details/${res?.data?.eventId}`)
                toast.success("Saved successfull")
              })
              .catch(
                rej =>{
                    console.log(rej)
                    toast.error(rej)
                }
              ).finally(()=>{
                setUpdating(false)
              })
            }
              

        },
        [title, description, startTime, endTime, email, phone, selectedFilePreview, hideCreateElements,eventVenue]
    );
    const getTimeString = useCallback((d,time)=>{
        const date =new Date(d);
        const zeroNeeded = date.getMonth()<10
        const zeroNeededInDate = date.getDate() < 10;
        const result =  `${date.getFullYear()}-${zeroNeeded ? "0" :""}${date.getMonth() +1}-${zeroNeededInDate?"0":""}${date.getDate()}T${time}`
        console.log(result)
        return result
    },[])
    const fetchEventDetails = useCallback(()=>{
        const config={
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/event/${id}/detail`,
            method:"get",
            headers:{
                'X-Auth-Token': appCtx.authToken
            }
        }
        axios(config)
        .then(res =>{
            setEmail(res?.data?.contact?.split(",")[0]);
            setPhone(res?.data?.contact?.split(",")[1]);
            setSelectedFilePreview(res?.data?.coverImage)
            setEndTime(getTimeString(res?.data?.endDate,res?.data?.endTime));
            setStartTime(getTimeString(res?.data?.startDate,res?.data?.startTime))
            setDescription(res?.data?.description)
            setEventLocation(res?.data?.mapLocation)
            setEventVenue(res?.data?.venue)
            setTitle(res?.data?.eventName)
        })
       },[])

    useEffect(()=>{
        console.log(id)
        if(id){
            fetchEventDetails()
        }
    },[id])


    const handleFileChange = useCallback(
        (e) => {
            setSelectedFile(e.target.files[0]);
            const imageData = new FormData();
            imageData.append('file', e.target.files[0]);
            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=event-banner`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: imageData
            };
            axios(config)
                .then((res) => setSelectedFilePreview(res?.data?.fileUrl))
                .catch((rej) => toast.error('Failed Uploading Banner'));
        },
        [selectedFile]
    );
    return {
        submitForm,
        formRef,
        selectedEventType,
        setSelectedEventType,
        inputBannerRef,
        handleFileChange,
        selectedFile,
        setTitle,
        setDescription,
        setEmail,
        setStartTime,
        setEndTime,
        setEventLocation,
        setPhone,
        setEventVenue,
        title,
        description,
        eventLocation,
        startTime,
        endTime,
        email,
        phone,
        eventVenue,
        updating
    };
}

export default useCreateEvent;
