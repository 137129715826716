import { NavLink, useNavigate } from 'react-router-dom';
import React from 'react';

const EditPages = () => {
    return (
        <div className="flex shadow-md rounded-md dark:bg-darkSecondary  ">
            <div className="flex  w-full no-underline text-inherit">
                <NavLink
                    to="/marketplace/pages/edit-home-page"
                    className={({ isActive }) =>
                        `${isActive && 'bg-secPurple text-white  '} py-2 rounded-md dark:text-white text-gray-700  text-center transition-all no-underline w-1/5`
                    }
                >
                    Home page
                </NavLink>
                <NavLink
                    to="/marketplace/pages/edit-about-page"
                    className={({ isActive }) =>
                        `${isActive && 'bg-secPurple text-white'} py-2 rounded-md dark:text-white text-gray-700 text-center transition-all  no-underline w-1/5`
                    }
                >
                    About
                </NavLink>
                <NavLink
                    to="/marketplace/pages/edit-privacy-page"
                    className={({ isActive }) =>
                        `${isActive && 'bg-secPurple text-white'} py-2 rounded-md dark:text-white text-gray-700 text-center transition-all  no-underline w-1/5`
                    }
                >
                    Privacy
                </NavLink>
                <NavLink
                    to="/marketplace/pages/edit-faq-page"
                    className={({ isActive }) =>
                        `${isActive && 'bg-secPurple text-white'} py-2 rounded-md dark:text-white text-gray-700 text-center transition-all no-underline w-1/5`
                    }
                >
                    FAQ
                </NavLink>
                <NavLink
                    to="/marketplace/pages/edit-tos-page"
                    className={({ isActive }) =>
                        `${isActive && 'bg-secPurple text-white'} py-2 rounded-md dark:text-white text-gray-700 text-center w-1/5 transition-all no-underline`
                    }
                >
                    Terms & Conditions
                </NavLink>
            </div>
        </div>
    );
};

export default EditPages;
