import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EventDetailCard from '../../../components/events/EventDetailCard';
import DivContainer from '../../../ui/DivContainer';
import Input from '../../../ui/Input';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import useFetchCertCategory from '../../../hooks/events/useFetchCertCategory';
import CreateCategoryModal from '../../../components/events/CreateCategoryModal';
import useFetchPremintData from '../../../hooks/events/useFetchPremintData';
import { CircularProgress } from '@mui/material';
import UploadAttendeesCSV from '../UploadAttendeesCSV';
import useFetchClaimers from '../../../hooks/events/useFetchClaimers';

function SubmitCertificate() {
    const { id, premintId } = useParams();

    const [selectedFee, setSelectedFee] = useState('subsidized');
    const [isLoading, setIsLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const navigate = useNavigate();
    const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);
    const { fetchCategories, categories } = useFetchCertCategory();
    const params = new URLSearchParams(window.location.search);
    const [selectedCategory, setSelectedCategory] = useState(params.get('collectionName'));
    const appCtx = useSelector((state) => state.app);
    const { premintContent, premintMetaData } = useFetchPremintData(premintId);
    let certId = premintMetaData?.certId;
    console.log(certId);

    const createCategory = useCallback(() => {
        const data = {
            category: params.get('collectionName'),
            id: null,
            orgId: appCtx.marketplaceId
        };

        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/category`,
            method: id ? 'put' : 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
            data: data
        };

        axios(config)
            .then((res) => {})
            .catch((rej) => {
                toast.error('submiting certificate failed');
            })
            .finally(() => {});
    }, [appCtx.authToken, appCtx.marketplaceId, id, params]);

    const submitForm = useCallback(
        (e) => {
            e.preventDefault();

            let flag = true;
            // if (!selectedCategory) {
            //     flag = false;
            //     toast.warning('please create category to proceed');
            // }

            if (flag) {
                setUpdating(true);
                const data = {
                    category: params.get('collectionName'),
                    certId: certId ? +certId : null,
                    // eventId: +id,
                    gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                    orgId: appCtx.marketplaceId,
                    premintId: +premintId
                };

                const config = {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/nft`,
                    method: certId ? 'put' : 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data
                };

                axios(config)
                    .then((res) => {
                        createCategory();
                        toast.success('Details submitted successfully !!');
                        navigate(
                            `/certificate/details/${premintId}/${params.get('collectionName')}?certId=${
                                res.data?.certId
                            }`
                        );
                    })
                    .catch((rej) => toast.error('Colud submit details'))
                    .finally(() => setUpdating(false));
            }
        },
        [selectedCategory, selectedFee, premintId, certId]
    );

    // const fetchCertificateDetails = useCallback(() => {
    //     const config = {
    //         url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/${certId}/nft/detail`,
    //         method: "get",
    //         headers: {
    //             "X-Auth-Token": appCtx.authToken
    //         }
    //     }

    //     axios(config)
    //         .then((res) => {
    //             setSelectedCategory(res?.data?.category)
    //             setSelectedFee(() => {
    //                 return res?.data?.gasFeeWaived ? "subsidized" : "charged"
    //             })
    //         })
    //         .catch(() => {
    //             toast.error("Could not fetch the certificate details")
    //         })

    // }, [certId])

    // useEffect(() => {
    //     if (certId) {
    //         fetchCertificateDetails()
    //     }
    // }, [certId])

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className="flex flex-col gap-8">
            <DivContainer>
                <span className="text-lg font-bold"> {certId ? 'Edit' : 'Create'} Certificate</span>
            </DivContainer>
            <div className="grid grid-cols-2 gap-10">
                <div className="flex flex-col gap-5">
                    <div className="flex justify-center">
                        <img
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                            className={`max-w-full max-h-[400px] min-h-[300px] object-contain ${
                                isLoading ? 'hidden' : ''
                            } `}
                            alt="premint nft"
                            onLoad={handleImageLoad}
                        />
                    </div>
                    <EventDetailCard title="Name">
                        <DivContainer className="break-words">{premintMetaData?.name}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <DivContainer className="break-words">{premintMetaData?.description}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        <DivContainer className="flex gap-2 flex-wrap">
                            {Object.keys(premintMetaData?.properties || {})?.length ? (
                                <>
                                    {Object.entries(premintMetaData?.properties)?.map(([key, value]) => {
                                        return (
                                            <DivContainer className="flex flex-col" key={key}>
                                                <span>{key}</span>
                                                <span>{value}</span>
                                            </DivContainer>
                                        );
                                    })}
                                </>
                            ) : (
                                <div>No properties to display</div>
                            )}
                        </DivContainer>
                    </EventDetailCard>
                </div>
                <div className="flex flex-col gap-10">
                    <EventDetailCard title="Category">
                        <div className="flex gap-3 items-center">
                            <Input value={`${params.get('collectionName')}`} />
                        </div>
                    </EventDetailCard>
                    <EventDetailCard title="Gas Fees">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="subsidized"
                                    checked={selectedFee === 'subsidized'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Subsidized by Organisation</span>
                            </div>
                            {/* <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="charged"
                                    checked={selectedFee === 'charged'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Charge from the guest</span>
                            </div> */}
                        </div>
                    </EventDetailCard>
                    <BlueGradientButton disabled={updating} onClick={submitForm} className="w-[300px]">
                        {updating ? <CircularProgress size={20} /> : 'Submit'}{' '}
                    </BlueGradientButton>
                </div>
            </div>
            {openCreateCategoryModal && (
                <CreateCategoryModal
                    closeModal={() => setOpenCreateCategoryModal(false)}
                    fetchCategories={fetchCategories}
                />
            )}
        </div>
    );
}

export default SubmitCertificate;
