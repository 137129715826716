import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { homeActions } from '../context/home-slice';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import getBestSize from '../misc/size-determination';
import ThreeDRenderer from '../components/common/ThreeDRenderer';
import Input from '../ui/Input';
import PutOnSaleModal from '../components/common/PutOnSaleModal';
import { toast } from 'react-toastify';
import SaleFailModal from '../components/common/SaleFailModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AssetPulse from '../components/common/AssetPulse';
import { useSelector } from 'react-redux';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { nativeToken } from '../config';

function SaleNft() {
    const dispatch = useDispatch();
    const appCtx = useSelector((state) => state.app);
    const [price, setPrice] = useState('');
    const [content, setContent] = useState(null);
    const [openSaleModal, setOpenSaleModal] = useState(false);
    const [openFailedSaleModal, setOpenFailedSaleModal] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [marketplace, setMarketplace] = useState({});
    const makeRequest = useAuthorizedHttp();
    const [disable, setDisable] = useState(false);
    const [marketplaceId, setMarketplaceId] = useState();
    const [marketplaceDomain, setMarketplaceDomain] = useState('');
    const { nftId } = useParams();
    const [metaData, setMetData] = useState(null);
    const [saleResult, setSaleResult] = useState(null);
    const [priceUnit, setPriceUnit] = useState(nativeToken[appCtx?.blockchain]);
    const [arrayOfObj, setArrAyOfObj] = useState();

    useEffect(() => {
        if (metaData) {
            setArrAyOfObj(
                metaData?.properties && Object?.entries(metaData?.properties)?.map((e) => ({ [e[0]]: e[1] }))
            );
        }
    }, [metaData]);

    const saleHandler = useCallback(() => {
        let flag = false;
        let data = null;
        if (!price) {
            flag = true;
            toast.error('price not mentioned');
        }
        if (isNaN(parseInt(price)) || price < 0) {
            flag = true;
            toast.error('please enter a valid price ');
        }

        if (isNaN(parseInt(quantity))) {
            flag = true;
            toast.error('please enter a valid quantity ');
        }
        if (!marketplaceId) {
            flag = true;
            toast.error('price not mentioned');
        }
        if (!flag) {
            data = {
                price: price,
                priceUnit: priceUnit,
                nftId: nftId,
                marketplaceId: marketplaceId
            };
        }
        if (!flag) {
            setDisable(true);
            toast.promise(
                () =>
                    makeRequest(
                        {
                            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/nft/sale`,
                            method: 'POST',
                            headers: {
                                'X-App-Token': process.env.REACT_APP_APP_TOKEN,
                                'Content-Type': 'application/json'
                            },
                            data
                        },
                        (data) => {
                            // setSaleResult(data)
                            // if(data.status  ==="FAILED"){

                            //     setOpenFailedSaleModal(true)
                            // }
                            // else{
                            //     setOpenSaleModal(true)

                            // }
                            setOpenSaleModal(true);
                            console.log(data);
                        },
                        () => {}
                    ),
                {
                    pending: 'sale in progress',
                    error: 'Something went wrong!'
                }
            );
        }
    });

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                console.log(data[0].marketplaceId);
                setMarketplace(data[0]);
                setMarketplaceId(data[0].marketplaceId);
                setMarketplaceDomain(data[0].domain);
                if (marketplaceId && nftId) {
                    getData();
                }
            }
        );
        const getData = () => {
            makeRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/nft/${nftId}/detail`
                },
                (res) => {
                    console.log(res);
                    setContent(res);
                    setMetData(JSON.parse(res?.metaData));
                }
            );
        };
    }, [makeRequest, marketplaceId, nftId]);

    // const [type,extenstion] = metaData?.mime_type && metaData?.mime_type?.split("/")

    return (
        <div className="flex flex-col gap-3">
            <div className="font-bold text-2xl">Set Price</div>
            <div className="flex gap-10">
                <div className="flex flex-col w-1/2 gap-3">
                    <div className="flex flex-col w-4/5  items-center gap-3">
                        <div className="p-3 w-full h-[300px]  rounded=md border border-gray-500 flex justify-center">
                            {!metaData && (
                                <div className="w-full h-[250px] overflow-hidden">
                                    <AssetPulse />
                                </div>
                            )}
                            {metaData?.mime_type?.split('/')[0] === 'image' ? (
                                <img
                                    className="w-4/5 object-contain"
                                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                                        metaData?.media_url?.split('/')[2] || metaData?.image?.split('/')[2]
                                    }`}
                                    alt="NFT"
                                />
                            ) : metaData?.mime_type?.split('/')[0] === 'video' ? (
                                <video
                                    controls
                                    autoPlay
                                    className="h-full border rounded dark:border-gray-500 object-cover"
                                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split('/')[2]}`}
                                />
                            ) : metaData?.mime_type?.split('/')[0] === 'threeD' && metaData?.media_url ? (
                                // <ThreeDRenderer
                                //     className={`w-4/5  rounded object-contain`}
                                //     src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split('/')[2]}`}
                                // />
                                <ThreeDRenderer
                                    className={` border rounded dark:border-gray-500 object-cover`}
                                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split('/')[2]}`}
                                    type={metaData?.mime_type?.split('/')[1]}
                                />
                            ) : (
                                <img
                                    className="w-4/5 object-contain"
                                    src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image?.split('/')[2]}`}
                                    alt="NFT"
                                />

                                // <ThreeDRenderer
                                //     className={`h-[350px] border rounded dark:border-gray-500 object-cover`}
                                //     src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url?.split("/")[2]}`}
                                //     type={metaData?.mime_type?.split("/")[1]}
                                // />
                                // <></>
                            )}
                        </div>
                        <div>
                            {content?.status === 'SEALED' ? (
                                <div className="text-[#1E893C]">Successfully Minted,ready for sale</div>
                            ) : (
                                <div className="text-[#89851E]">
                                    Your NFT minting is in progress,sale will go live after completion.
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="font-bold">Properties</div>
                        <div className="flex flex-wrap gap-4">
                            {/* {arrayOfObj && arrayOfObj?.map(() => {
                                return (
                                    <div className="border p-2 px-4 rounded-md flex flex-col items-center">
                                        <div className="font-bold text-sm">{{property} || <Skeleton />}</div>
                                        <div lassName="text-sm">{metaData?.properties[property]} </div>
                                    </div>
                                );
                            )}} */}
                            {arrayOfObj &&
                                arrayOfObj?.map((property) => {
                                    // return (
                                    //     <div className="border p-2 px-4 rounded-md flex flex-col items-center">
                                    //         <div className="font-bold text-sm">{property[0]}</div>
                                    //         <div lassName="text-sm">{property[1]} </div>
                                    //     </div>
                                    // );
                                    for (var i in property) {
                                        return (
                                            <div className="border p-2 px-4 rounded-md flex flex-col items-center">
                                                <div className="font-bold text-sm">{i}</div>
                                                <div className="text-sm">{property[i]} </div>
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="font-bold">Description</div>
                        <div className="text-sm"> {metaData?.description}</div>
                    </div>
                </div>
                <div className="flex flex-col w-1/2 gap-8">
                    <div className="flex gap-10">
                        <div className="font-bold">Name : </div>
                        <div className="text-sm">{metaData?.name}</div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="font-bold">On sale quantity</div>
                        <Input className="text-left" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="font-bold">Unit Price</div>
                        <div className="flex justify-between px-3 py-2 rounded-md">
                            <Input
                                className="text-left"
                                value={price}
                                onChange={(e) => {
                                    setPrice(e.target.value);
                                }}
                            />

                            <Select
                                className="bg-inherit dark:bg-gray-400 dark:outline-white "
                                value={priceUnit}
                                onChange={(e) => {
                                    setPriceUnit(e.target.value);
                                }}
                            >
                                <MenuItem classes="bg-inherit" value={nativeToken[appCtx?.blockchain]}>
                                    {nativeToken[appCtx?.blockchain]}
                                </MenuItem>
                                <MenuItem classes="bg-inherit" value="usd">
                                    USD
                                </MenuItem>
                                {/* <MenuItem classes="bg-inherit" value="inr">
                                    INR
                                </MenuItem> */}
                            </Select>
                        </div>
                    </div>
                    <div className="flex items-center gap-8">
                        <div className="font-bold text-lg">Service Fee :</div>
                        <div className="text-sm">Subsidized by NFTVerse</div>
                        {/* <div>2.5%</div> */}
                    </div>
                    <div className="flex justify-end">
                        {openSaleModal && (
                            <PutOnSaleModal
                                closeModal={setOpenSaleModal}
                                marketplaceDomain={marketplaceDomain}
                                nftId={nftId}
                            />
                        )}
                        {openFailedSaleModal && (
                            <SaleFailModal
                                closeModal={setOpenFailedSaleModal}
                                marketplaceId={saleResult?.marketplaceId}
                                nftId={saleResult?.nftId}
                            />
                        )}

                        <button className="px-16 bg-secBlue text-white py-3 rounded-md" onClick={saleHandler}>
                            Put On Sale
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SaleNft;
