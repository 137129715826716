import React, { useEffect, useState } from 'react';
import PremintDataContainer from './templates/PremintDataContainer';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useSelector } from 'react-redux';
import usePremint from '../../hooks/launchpad/usePremint';
import BlueGradientButton from '../BlueGradientButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useBulkMint from '../../hooks/launchpad/useBulkMint';

function Premint() {
    const { premintData, showMore, loadPremintAssets, allAssetsRef } = usePremint();

    const [options, setOptions] = useState([]);
    const dark = useSelector((state) => state.app.isDarkMode);
    const [allSelected, setAllSelected] = useState(false);
    const { bulkMint } = useBulkMint();
    const handleSelectAllChange = (event) => {
        setAllSelected(event.target.checked);
        setOptions(event.target.checked ? allAssetsRef.current : []);
    };
    return (
      <div className='flex flex-col'>
        <div className='flex justify-end sticky  '>
            <BlueGradientButton
            disabled={options.length===0}
            className={`${options.length === 0 && "bg-gray-600"} px-12`}
            onClick = {()=> bulkMint(options)}
            >
              Bulk mint
            </BlueGradientButton>
        </div>
        <Table className="flex flex-col gap-10">
            <TableHead style={{ color: 'blue' }} className="p-4 flex justify-between items-center font-bold ">
                <TableCell>
                    <div className="flex gap-2  items-center w-28">
                        <input className="w-5 h-5 cursor-pointer " type="checkbox" onChange={handleSelectAllChange} />
                        <div className="flex flex-col gap-1">
                            <span className="dark:text-white">
                                {options?.length < 1 ? (
                                    <span> No.</span>
                                ) : (
                                    <span className="text-xs">{options?.length} selected</span>
                                )}
                            </span>
                        </div>
                    </div>
                </TableCell>
                <TableCell sx={{ color: dark && 'white' }}> Asset Image</TableCell>
                <TableCell sx={{ color: dark && 'white' }}> Asset Name</TableCell>
                <TableCell sx={{ color: dark && 'white' }}> Asset Description</TableCell>
                <TableCell sx={{ color: dark && 'white' }}> Asset Property</TableCell>
                <TableCell sx={{ color: dark && 'white' }}> Asset Collection</TableCell>
                <TableCell sx={{ color: dark && 'white' }}> Actions</TableCell>
            </TableHead>
            {premintData.map((item, index) => {
                return (
                    <PremintDataContainer
                        allAssetsRef={allAssetsRef}
                        index={index}
                        options={options}
                        setOptions={setOptions}
                        key={item.id}
                        metaData={JSON.parse(item?.metaData)}
                        collection={item?.collection}
                        id={item.id}
                    />
                );
            })}
        </Table>
        </div>
    );
}

export default Premint;
