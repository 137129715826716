import React, { useEffect, useState } from 'react';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import { EventPageNavbarItem } from './EventPageNavbarItem';

function PreviewContainer(props) {
    const makeRequest = useAuthorizedHttp();
    const [marketplace, setMarketplace] = useState();
    const appCtx = useSelector((state) => state.app);
    // useEffect(() => {
    //     makeRequest(
    //         {
    //             url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
    //         },
    //         (data) => {
    //             setMarketplace(data[0]);
    //         },
    //         (data) => {
    //             console.log(data);
    //         },
    //         () => { }
    //     );
    // }, [makeRequest]);
    return (
        <div className="col-span-3 rounded-lg w-full  flex flex-col gap-10 items-start p-4 dark:bg-inherit bg-[#F8FAFC] shadow-prevBox dark:text-white border-white border-[20px] dark:border-darkBorder">
            <div
                className={`flex ${
                    appCtx.marketType !== 'DAO' ? 'justify-evenly' : 'justify-start'
                } w-full items-center `}
            >
                <div className="w-1/2 flex items-center">
                    <div className="w-10 h-10">
                        {appCtx?.marketplace?.logo && (
                            <img
                                src={`${appCtx?.marketplace?.logo}`}
                                className="w-full h-full rounded-full object-cover"
                                alt="logo"
                            />
                        )}
                    </div>
                    <button className="text-xl font-light pl-[5px]">{appCtx?.marketplace?.name}</button>
                </div>

                {appCtx.marketType !== 'DAO' ? (
                    <>
                        <input
                            className="outline-none dark:bg-darkBorder bg-[#D6D6D6] rounded-xl py-1 px-2 w-1/3"
                            type="text"
                        />
                        <button className="">Explore</button>

                        <button className="w-5 h-6">
                            <AccountBalanceWalletIcon />
                        </button>
                        <AccountCircleIcon />
                    </>
                ) : (
                    <EventPageNavbarItem />
                )}
            </div>
            <div className="text-2xl font-bold">{props.page}</div>
            {props.children}
        </div>
    );
}

export default PreviewContainer;
