import React, { useState } from 'react'
import LandingButton from '../../ui/buttons/LandingButton';
import CreateAccountModal from './CreateAccountModal';
import { appActions } from '../../context/app-slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function CarouselItem({ img, heading, detail, target }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div
            className="bg-cover bg-no-repeat py-5 h-[80vh] md:h-screen text-slate-200 flex justify-start"
            style={{ backgroundImage: `url(${img})` }}
        >
            <div className='w-4/5 lg:w-1/3 flex flex-col justify-center items-start gap-10 relative left-[8%] md:left-[5%]'>
                <span className='text-xl md:text-5xl font-semibold'>
                    {heading}
                </span>
                <span className=' md:text-2xl'>
                    {detail}
                </span>
                <div className='flex flex-col md:flex-row items-center gap-4 md:gap-10'>
                    <LandingButton onClick={() =>
                        navigate('/login')
                        // dispatch(appActions.setCreateAccountModal(true))
                    } >
                        Get Started
                    </LandingButton>
                    {target && <LandingButton onClick={() => navigate(target)} >Learn more</LandingButton>}
                </div>

            </div>

        </div>

    );
}

export default CarouselItem