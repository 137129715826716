import { useEffect, useState } from 'react';
import BlueGradientButton from '../ui/BlueGradientButton';
import { useNavigate } from 'react-router-dom';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { toast } from 'react-toastify';
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import PreviewContainer from '../ui/PreviewContainer';
import RichTextEditor from '../components/common/RichTextEditor';
import EditPagesContainer from '../components/marketplace-settings/EditPagesContainer';
import SuggestionTemplateContainer from '../ui/SuggestionTemplateContainer';
import React from "react"
import { useSelector } from 'react-redux';

const EditMarketplaceTOS = (props) => {
    const appCtx = useSelector(state => state.app)
    const [tos, setTOS] = useState('');
    const [uploading, setUploading] = useState(false);

    const marketplaceId = appCtx.marketplaceId;
    const domain = appCtx.marketplace.domain;

    const navigate = useNavigate();

    const makeRequest = useAuthorizedHttp();

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${domain}/page/tos/detail`
            },
            (data) => {
                setTOS(data.description ? data.description : '');
            },
            (data) => console.log(data),
            () => {}
        );
    }, [domain, makeRequest]);

    const handleSaveClick = () => {
        if (!tos) {
            toast.error("Terms of service can't be empty");
            return;
        }
        setUploading(true);

        const data = {
            description: tos,
            marketplaceId,
            page: 'tos'
        };

        toast.promise(
            () =>
                makeRequest(
                    {
                        data,
                        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                        method: 'POST'
                    },
                    (data) => {},
                    (data) => {
                        console.log(data);
                    },
                    () => {
                        setUploading(false);
                    }
                ),
            {
                pending: 'Updating your marketplace settings...',
                success: 'Settings updated successfully!',
                error: 'Uh-oh! Something went wrong'
            }
        );
    };

    return (
        <>
            {/* <div className="text-2xl font-bold mx-auto mb-10">Edit your marketplace Terms Of Service</div> */}
            <EditPagesContainer>
                <div className="grid grid-cols-5  gap-10">
                    <div className="col-span-2 flex flex-col gap-5 items-center">
                        {/* <button className="text-sky-500 text-left" onClick={() => navigate('/marketplace')}>
                        <KeyboardArrowLeftRounded />
                        Back
                    </button> */}
                        <div className={'text-gray-800 h-[300px] mb-10'}>
                            <RichTextEditor value={tos} onChange={(e) => setTOS(e)} />
                        </div>
                        <BlueGradientButton disabled={uploading} className="px-24" onClick={handleSaveClick}>
                            Save
                        </BlueGradientButton>
                        {/* <BlueGradientButton
                        disabled={uploading}
                        className="w-full"
                        onClick={() => navigate('/marketplace')}
                    >
                        Discard changes
                    </BlueGradientButton> */}
                    </div>
                    {/* <div className="col-span-2 rounded-lg  flex flex-col gap-10 items-start shadow-prevBox p-20 bg-prevBg text-white border-white border-[20px] dark:border-darkSecondary">
                    <div className="text-2xl font-bold">Terms Of Service</div>
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: tos }} />
                </div> */}
                    <PreviewContainer page="Terms Of Services">
                        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: tos }} />
                    </PreviewContainer>
                </div>
                <div className="mt-7 flex flex-col">
                    <div className="text-2xl font-bold">Terms Of Services template</div>
                    <SuggestionTemplateContainer setter={setTOS}>
                        {appCtx.marketType === 'DAO'
                            ? `
                        Welcome to "Organization Name" these terms of use describes the terms and conditions applicable to your access and use 
                        of this our services. This document is a legally binding agreement between you as the user of
                         the "Organization Name", the XYZ and its parent company. These terms and conditions govern your 
                         use of this site; by using this site, you accept these terms and conditions in full. If you disagree 
                         with these terms and conditions or any part of these terms and conditions, you must not use this website.
                          License to use website You must not: republish material from this site including republication on another 
                          website; sell, rent or sub-license material from the site; reproduce, duplicate, copy or otherwise exploit
                           material on this website for a commercial purpose; Acceptable use You must not use this website in any way
                            that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website;
                             or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, 
                             fraudulent or harmful purpose or activity Agreement These terms and conditions together constitute the entire
                              agreement between you and Marketplace name in relation to your use of this marketplace website, and supersede
                               all previous agreements in respect of your use of this website .Thanks .
                        `
                            : `Welcome to  www.(your marketplcae name).nftverse.com these terms of use describe the terms and conditions
                        applicable to your access and use of this marketplace services. This document is a legally
                        binding agreement between you as the user of the marketplace, the XYZ and its parent company.
                        These terms and conditions govern your use of this site; by using this site, you accept these
                        terms and conditions in full. If you disagree with these terms and conditions or any part of
                        these terms and conditions, you must not use this website License to use website You must not:
                        republish material from this site including republication on another website; sell, rent or
                        sub-license material from the site; reproduce, duplicate, copy or otherwise exploit material on
                        this website for a commercial purpose; Acceptable use You must not use this website in any way
                        that causes, or may cause, damage to the website or impairment of the availability or
                        accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful,
                        or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity Agreement
                        These terms and conditions together constitute the entire agreement between you and Marketplace
                        name in relation to your use of this marketplace website, and supersede all previous agreements
                        in respect of your use of this website Thanks .`}
                    </SuggestionTemplateContainer>
                </div>
            </EditPagesContainer>
        </>
    );
};

export default EditMarketplaceTOS;
