import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useCreateEvent from '../../hooks/events/useCreateEvent';
import EventDetailCard from '../../components/events/EventDetailCard';
import DivContainer from '../../ui/DivContainer';
import BlueGradientButton from '../../ui/BlueGradientButton';
import parse from 'html-react-parser';
import { Popover } from 'react-tiny-popover';
import useFetchEventTicket from '../../hooks/events/useFetchEventTicket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faContactBook, faLocation } from '@fortawesome/free-solid-svg-icons';
import useFetchClaimers from '../../hooks/events/useFetchClaimers';
import MinAttendeesList from '../../components/events/MinAttendeesList';
import useFetchOrgCertificates from '../../hooks/events/useFetchOrgCertificates';
import useFetchPremintData from '../../hooks/events/useFetchPremintData';
import { useSelector } from 'react-redux';
import useLoadWallet from '../../hooks/wallet/useLoadWallet';
import { RefreshOutlined } from '@mui/icons-material';
import NftCard from '../../components/events/NftCard';
import useRechargeWallet from '../../hooks/wallet/useRechargeWallet';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import AssetPulse from '../../components/common/AssetPulse';

function EventDetails() {
    const [selectedFilePreview, setSelectedFilePreview] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const { rechargeWallet } = useRechargeWallet();
    const appCtx = useSelector((state) => state.app);
    const navigate = useNavigate();
    const { eventTickets } = useFetchEventTicket();
    const { eventCert } = useFetchOrgCertificates();
    const { title, description, eventLocation, startTime, endTime, email, phone, eventVenue } = useCreateEvent({
        selectedFilePreview,
        setSelectedFilePreview,
        id
    });
    const { fetchClaimers, claimers } = useFetchClaimers();
    const [marketplaceDomain, setMarketplaceDomain] = useState('');

    const makeRequest = useAuthorizedHttp();
    useEffect(() => {
        if (eventCert) {
            fetchClaimers(eventCert?.certId);
        }
    }, [eventCert]);

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceDomain(data[0].domain);
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);

    const handleImageLoad = () => {
        setIsLoading(false);
    };
    return (
        <div className="flex flex-col gap-10">
            <DivContainer>
                <div className="flex justify-between items-center">
                    <span className="text-lg font-bold">Event Details</span>
                    <div className="flex gap-5 items-center">
                        <BlueGradientButton onClick={() => navigate(`/events/create-rsvp/${id}`)}>
                            Create RSVP Ticket
                        </BlueGradientButton>
                        {/* {eventTickets?.length > 0 && <BlueGradientButton>Upload Attendees List</BlueGradientButton>} */}
                        <BlueGradientButton onClick={() => navigate(`/events/create-poa/${id}`)}>
                            Create POA NFTs
                        </BlueGradientButton>
                        <BlueGradientButton onClick={() => navigate(`/events/edit-event/${id}`)}>
                            Edit Event
                        </BlueGradientButton>
                    </div>
                </div>
            </DivContainer>

            <div>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        window.open(`http://${marketplaceDomain}/event-details/${id}`, '_blank');
                    }}
                >
                    {`http://${marketplaceDomain}/event-details/${id}`}
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-10 gap-y-3">
                <div className="col-span-3 flex flex-col gap-10 dark-border light-border rounded-md p-4  ">
                    <div className=" h-[350px] w-full">
                        {isLoading && <AssetPulse />}
                        <img
                            src={selectedFilePreview}
                            alt="banner"
                            className={`max-h-[350px] min-h-[200px] object-contain w-full ${isLoading ? 'hidden' : ''}`}
                            onLoad={handleImageLoad}
                        />
                    </div>
                    <span className="font-bold text-lg">{title}</span>
                    <EventDetailCard title="">{parse(description || '')}</EventDetailCard>
                    <div className="grid grid-cols-3 gap-10 gap-y-3">
                        <div className="flex flex-col gap-2 col-span-2">
                            <span className="flex items-center gap-2  ">
                                <span>
                                    <FontAwesomeIcon icon={faLocation} />
                                </span>
                                <span className="font-semibold">Location</span>
                            </span>
                            <div className="flex flex-col gap-4">
                                <div className="flex gap-2 pr-5">
                                    <span className="break-words">
                                        <span className="font-bold">Venue: </span>
                                        {eventVenue}
                                    </span>
                                </div>
                                <div className="flex gap-2">
                                    <span className="font-bold">Map Location:</span>
                                    <span className="break-words">{eventLocation}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 col-span-1">
                            <span className="flex items-center gap-2 ">
                                <span>
                                    <FontAwesomeIcon icon={faContactBook} />
                                </span>
                                <span className="font-semibold">Contact</span>
                            </span>
                            <div className="flex flex-col">
                                <span className="font-bold break-words">{email}</span>
                                <span className="break-words">{phone}</span>
                            </div>
                        </div>
                        <div className="col-span-2">
                            <EventDetailCard title="Start Date">
                                {new Date(startTime || 1).toDateString()} T {startTime?.split('T')[1]}
                                {console.log(startTime, endTime, 'dhsdgg')}
                            </EventDetailCard>
                        </div>
                        <div className="col-span-1">
                            <EventDetailCard title="End Date">
                                {new Date(endTime || 1).toDateString()} T {endTime?.split('T')[1]}
                            </EventDetailCard>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-10  dark-border light-border rounded-md p-4 ">
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            window.open(`http://${marketplaceDomain}/event-details/${id}`, '_blank');
                        }}
                    >
                        {`http://${marketplaceDomain}/event-details/${id}`}
                        &nbsp;
                    </div>
                    <div className="text-sm text-yellow-300">
                        You need to have a minimum wallet balance in order to allow your guests to claim the NFTs
                        (RSVP/Certificates). Please{' '}
                        <button className="text-blue-500" onClick={rechargeWallet}>
                            recharge
                        </button>{' '}
                        your wallet now to avoid the service disruption.
                    </div>
                    <div className="flex flex-col gap-5">
                        {eventTickets?.length > 0 ? (
                            // TODO: add edit ticket by navigating below
                            <div className="flex flex-col gap-4 dark-border light-border p-3 rounded-md">
                                <div className="flex justify-between items-center">
                                    <span>Event Ticket NFT</span>{' '}
                                    <Link
                                        to={`/events/edit-ticket/${id}/${eventTickets[0]?.premintId}/${eventTickets[0]?.ticketId}`}
                                    ></Link>
                                </div>
                                <NftCard
                                    cert={eventTickets[0]}
                                    nftHeight="h-[200px]"
                                    gasFeeWaived={eventTickets[0]?.gasFeeWaived}
                                    price={eventTickets[0]?.price}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-3 items-center dark-border light-border p-3 rounded-md ">
                                <span>Create your RSVP ticket.</span>
                                <BlueGradientButton onClick={() => navigate(`/events/create-rsvp/${id}`)}>
                                    Create Ticket
                                </BlueGradientButton>
                            </div>
                        )}
                        {eventCert ? (
                            <div className="flex flex-col gap-4 dark-border light-border p-3 rounded-md">
                                <div className="flex justify-between items-center">
                                    <span>Event Certificate NFT</span>{' '}
                                    <BlueGradientButton
                                        className="p-1"
                                        onClick={() =>
                                            navigate(
                                                `/events/edit-certificate/${id}/${eventCert?.premintId}/${eventCert?.eventId}`
                                            )
                                        }
                                    >
                                        Edit
                                    </BlueGradientButton>
                                </div>
                                <NftCard cert={eventCert} nftHeight="h-[200px]" />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-3 items-center dark-border light-border p-3 rounded-md ">
                                <span>
                                    Create Proof of Attendance (POA) NFT certificate that you want to give your event
                                    attendees.
                                </span>
                                <BlueGradientButton onClick={() => navigate(`/events/create-poa/${id}`)}>
                                    Create Certificate
                                </BlueGradientButton>
                            </div>
                        )}
                    </div>
                    {eventCert && (
                        <MinAttendeesList
                            claimers={claimers}
                            certId={eventCert?.certId}
                            fetchClaimers={fetchClaimers}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default EventDetails;
