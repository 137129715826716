import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EventDetailCard from '../../components/events/EventDetailCard';
import DivContainer from '../../ui/DivContainer';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import useFetchCertCategory from '../../hooks/events/useFetchCertCategory';
import CreateCategoryModal from '../../components/events/CreateCategoryModal';
import useFetchPremintData from '../../hooks/events/useFetchPremintData';
import { CircularProgress } from '@mui/material';

function CreateCertificate() {
    const { id, premintId,certId} = useParams();

    const [selectedFee, setSelectedFee] = useState('subsidized');
    const [updating,setUpdating] = useState(false);
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);
    const { fetchCategories, categories } = useFetchCertCategory();

    const appCtx = useSelector((state) => state.app);
    const { premintContent, premintMetaData } = useFetchPremintData( premintId );

    const submitForm = useCallback(
        (e) => {
            e.preventDefault();
            console.log(selectedCategory);

            let flag = true;
            if (!selectedCategory) {
                flag = false;
                toast.warning('please create category to proceed');
            }

            if (flag) {
                setUpdating(true)
                const data = {
                    category: selectedCategory,
                    certId: certId ? +certId : null,
                    eventId: +id,
                    gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                    orgId: appCtx.marketplaceId,
                    premintId: +premintId
                };

                const config = {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/nft`,
                    method: certId ? "put":'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data
                };

                axios(config)
                    .then((res) => {
                        toast.success('Event Ticket created successfully');
                        navigate(`/events/event-details/${id}`);
                    })
                    .catch((rej) => toast.error('Colud not create event ticket'))
                    .finally(() => setUpdating(false))
            }
        },
        [selectedCategory, selectedFee, premintId,certId]
    );

    useEffect(()=>{
        if(categories?.length > 0){
            setSelectedCategory(categories[0]?.category)
        }
    },[categories])
    const fetchCertificateDetails = useCallback(() =>{
        const config ={
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/${certId}/nft/detail`,
            method:"get",
            headers:{
                "X-Auth-Token":appCtx.authToken
            }
        }

        axios(config)
        .then((res)=>{
                setSelectedCategory(res?.data?.category)
                setSelectedFee(() =>{
                    return res?.data?.gasFeeWaived ? "subsidized" : "charged"
                })
        })
        .catch(()=>{
            toast.error("Could not fetch the certificate details")
        })

    },[certId])

    useEffect(()=>{
        if(certId){
            fetchCertificateDetails()
        }
    },[certId])

    return (
        <div className="flex flex-col gap-8">
            <DivContainer>
                <span className="text-lg font-bold"> {certId ? "Edit" :"Create"} Certificate</span>
            </DivContainer>
            <div className="grid grid-cols-2 gap-10">
                <div className="flex flex-col gap-5">
                    <div className="flex justify-center">
                        <img
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                            className="max-w-full max-h-[400px] min-h-[300px] object-contain"
                        />
                    </div>
                    <EventDetailCard title="Name">
                        <DivContainer className="break-words">{premintMetaData?.name}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <DivContainer className="break-words">{premintMetaData?.description}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        <DivContainer className="flex gap-2 flex-wrap">
                            {premintMetaData?.properties &&
                                Object.entries(premintMetaData?.properties).map(([key, value]) => {
                                    return (
                                        <DivContainer className="flex flex-col" key={key}>
                                            <span>{key}</span>
                                            <span>{value}</span>
                                        </DivContainer>
                                    );
                                })}
                        </DivContainer>
                    </EventDetailCard>
                </div>
                <div className="flex flex-col gap-10">
                    <div className="flex gap-3 items-center">
                        {categories?.length > 0 ? (
                            <select
                                className="p-3 rounded-md text-gray-700"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                {categories?.map((category) => {
                                    return (
                                        <option value={category?.category} key={category.id}>
                                            {category?.category}
                                        </option>
                                    );
                                })}
                            </select>
                        ) : (
                            <div className='text-sm max-w-[70%]'>
                                Create a category for the certificate that you are giving to your attendees it can be a
                                participation certificate or anything else related to the event.
                            </div>
                        )}
                        <BlueGradientButton onClick={() => setOpenCreateCategoryModal(true)}>
                            Add category
                        </BlueGradientButton>
                    </div>
                    <EventDetailCard title="Gas Fees">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="subsidized"
                                    checked={selectedFee === 'subsidized'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Subsidized</span>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="charged"
                                    checked={selectedFee === 'charged'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Charge from the guest</span>
                            </div>
                        </div>
                    </EventDetailCard>
                    <BlueGradientButton disabled={updating} onClick={submitForm}>{updating ? <CircularProgress size={20}/> :"Submit"} </BlueGradientButton>
                </div>
            </div>
            {openCreateCategoryModal && (
                <CreateCategoryModal
                    closeModal={() => setOpenCreateCategoryModal(false)}
                    fetchCategories={fetchCategories}
                />
            )}
        </div>
    );
}

export default CreateCertificate;
