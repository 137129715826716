import { useEffect, useState } from 'react';
import BlueGradientButton from '../ui/BlueGradientButton';
import { useNavigate } from 'react-router-dom';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { toast } from 'react-toastify';
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import RichTextEditor from '../components/common/RichTextEditor';
import PreviewContainer from '../ui/PreviewContainer';
import SuggestionTemplateContainer from '../ui/SuggestionTemplateContainer';
import EditPagesContainer from '../components/marketplace-settings/EditPagesContainer';
import React from "react"
import { useSelector } from 'react-redux';

const EditMarketplaceFAQ = (props) => {
    const [faq, setFAQ] = useState('');
    const [uploading, setUploading] = useState(false);
    const appCtx = useSelector(state => state.app)

    const url = new URL(window.location.href);
    const marketplaceId = appCtx.marketplaceId;
    const domain = appCtx.marketplace.domain;

    const navigate = useNavigate();

    const makeRequest = useAuthorizedHttp();

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${domain}/page/faq/detail`
            },
            (data) => {
                setFAQ(data.description ? data.description : '');
            },
            (data) => console.log(data),
            () => {}
        );
    }, [domain, makeRequest]);

    const handleSaveClick = () => {
        if (!faq) {
            toast.error("FAQs can't be empty");
            return;
        }

        setUploading(true);

        const data = {
            description: faq,
            marketplaceId,
            page: 'faq'
        };

        toast.promise(
            () =>
                makeRequest(
                    {
                        data,
                        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                        method: 'POST'
                    },
                    (data) => {},
                    (data) => {
                        console.log(data);
                    },
                    () => {
                        setUploading(false);
                    }
                ),
            {
                pending: 'Updating your marketplace settings...',
                success: 'Settings updated successfully!',
                error: 'Uh-oh! Something went wrong'
            }
        );
    };

    return (
        <>
            {/* <div className="text-2xl font-bold mx-auto mb-10">Edit your marketplace Frequently Asked Questions</div> */}
            <EditPagesContainer>
                <div className="grid grid-cols-5  gap-10">
                    <div className="col-span-2 flex flex-col gap-5 items-center">
                        {/* <button className="text-sky-500 text-left" onClick={() => navigate('/marketplace')}>
                        <KeyboardArrowLeftRounded />
                        Back
                    </button> */}
                        <div className={'text-gray-800 h-[300px] mb-10'}>
                            <RichTextEditor value={faq} onChange={(e) => setFAQ(e)} />
                        </div>
                        <BlueGradientButton disabled={uploading} className=" px-24" onClick={handleSaveClick}>
                            Save
                        </BlueGradientButton>
                        {/* <BlueGradientButton
                        disabled={uploading}
                        className="w-full"
                        onClick={() => navigate('/marketplace')}
                    >
                        Discard changes
                    </BlueGradientButton> */}
                    </div>
                    {/* <div className="col-span-2 rounded-lg  flex flex-col gap-10 items-start p-20 bg-prevBg shadow-prevBox text-white border-white border-[20px] dark:border-darkSecondary">
                    <div className="text-2xl font-bold">Frequently Asked Questions</div>
                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: faq }} />
                </div> */}
                    <PreviewContainer page="Frequently Asked Questions">
                        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: faq }} />
                    </PreviewContainer>
                </div>
                <div className="mt-7 flex flex-col">
                    <div className="text-2xl font-bold">FAQ template</div>
                    <SuggestionTemplateContainer setter={setFAQ}>
                        1. What is the NFT Marketplace ? An NFT marketplace is a blockchain-based online platform to
                        sell and buy non-fungible tokens (NFTs). The popularity of NFT marketplaces consistently grows
                        fueled by skyrocketed prices for NFTs.
                    </SuggestionTemplateContainer>
                </div>
            </EditPagesContainer>
        </>
    );
};

export default EditMarketplaceFAQ;
