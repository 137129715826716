import React from 'react'

function AssetInfo({propName1,val1,propName2,val2}) {
  return (
    <div className='flex justify-between'>
            <div className='flex flex-col'>
                <span className='font-bold'>
                    {propName1}
                </span>
                <span>
                    {val1}
                </span>
            </div>
            <div className='flex flex-col'>
                <span className='font-bold'>
                    {propName2}
                </span>
                <span>
                    {val2}
                </span>
            </div>
    </div>
  )
}

export default AssetInfo