import React from 'react'

function Working() {
    const workingItems = [
        {
            title: 'Introductory Meeting',
            img: '/images/nlg/3a.svg',
            detail: "We must first talk about your company's needs. We will do everything in our power to help you find the best solution to reach your objectives."
        },
        {
            title: 'Prototype building',
            img: '/images/nlg/3b.svg',
            detail: 'Once the initial meetings are completed, we will start building the prototype for your product or service'
        },
        {
            title: 'Software creation',
            img: '/images/nlg/3c.svg',
            detail: 'After the prototyping state our developers will start working on the solution'
        },
        {
            title: 'QA Stage',
            img: '/images/nlg/3d.svg',
            detail: `We make sure that our project has been thoroughly examined, from beginning to end, and that all functionalities have been tested.`
        },

        {
            title: 'Deployment',
            img: '/images/nlg/3e.svg',
            detail: `We begin our deployment process once everything is fixed and working properly.`
        },
        {
            title: 'Post Release support',
            img: '/images/nlg/3d.svg',
            detail: 'We will continue to update your NFT launchpad to improve performance and satisfy users.'
        }
    ];
  return (
    <div className="flex flex-col gap-5 px-20">
                <div className="text-center text-semibold text-4xl">How it works</div>
                <div className="grid grid-cols-1 md:grid-cols-3 items-center px-10  gap-10">
                    {workingItems.map((item) => {
                        return (
                            <div className="bg-gradient-to-r py-10 px-5 h-full from-[#150d53] to-[#0c0536] flex flex-col gap-5 rounded-xl hover:scale-105 transition-all hover:shadow-md">
                                <div className="flex justify-between items-center">
                                    <span className="text-xl font-bold">{item.title}</span>
                                    <span>
                                        <img src={item.img} />
                                    </span>
                                </div>
                                <div>{item.detail}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
  )
}

export default Working