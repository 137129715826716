import MintAsset from '../components/mint-asset/MintAsset';
import AssetDisplay from '../components/common/AssetDisplay';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { useCallback, useEffect, useState } from 'react';
import CreateCollectionModal from '../components/mint-asset/CreateCollectionModal';
import { useLocation, useNavigate } from 'react-router-dom';
import BlueGradientButton from '../ui/BlueGradientButton';
import axios from 'axios';
import React from "react"
import { useSelector } from 'react-redux';

const MintNFT = () => {
    const [marketplaceId, setMarketplaceId] = useState();
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);
    const [collection, setCollection] = useState([]);
    const makeAuthorizedRequest = useAuthorizedHttp();
    const makeRequest = useAuthorizedHttp();
    const [showCreateNftOption,setShowCreateNftOption] = useState(false)
    const [type, setType] = useState('');
    const [s3url, setS3Url] = useState('');
    const [ipfsHash, setIpfsHash] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [extension,setExtension] = useState("");
    const appCtx = useSelector(state => state.app)
    const [assetId,setAssetId] = useState();
    const [nftId,setNftId] = useState(null);
    useEffect(() => {
        const url = new URL(window.location.href);
        setType(url.searchParams.get('type'));
        setS3Url(url.searchParams.get('s3url'));
        setIpfsHash(url.searchParams.get('ipfsHash'));
        setAssetId(url.searchParams.get('assetId'))
        setExtension(url.searchParams.get("extension"))
        setNftId(url.searchParams.get("nftId"))
        
    }, [location]);
    
    console.log(extension)

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);

    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    data.content.forEach((d) => {
                        setCollection((prev) => [
                            ...prev,
                            {
                                id: d.collectionId,
                                text: d.name
                            }
                        ]);
                    });
                }
            ),
        [makeAuthorizedRequest, marketplaceId]
    );

    useEffect(() => {
        marketplaceId && loadCollections();
    }, [loadCollections, makeAuthorizedRequest, marketplaceId]);

    useEffect(()=>{
        console.log(typeof ipfsHash)
     if((ipfsHash=="null" || ipfsHash === null) && (marketplaceId && assetId)){
            let config = {
                method: "POST",
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${marketplaceId}/ipfs/upload/retry`,
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": appCtx.authToken ,
                    "Cookie": "JSESSIONID=CDB365E31A223ED3EEE61B9527E264B4"
                },
                data:{
                    fileUrl:s3url,
                    assetId: +assetId

                }
            };
            axios(config)
            .then((res)=> setIpfsHash(res.data?.ipfsHash))
            .catch((rej)=>{
                
            })  
        }
    },[ipfsHash,marketplaceId,location,assetId])
    return (
        <>
            {marketplaceId && collectionModalOpen && (
                <CreateCollectionModal
                    marketplaceId={marketplaceId}
                    onCreateSuccesssful={() => loadCollections()}
                    onCloseCreateCollection={() => setCollectionModalOpen(false)}
                />
            )}
            <div>
                {marketplaceId && (
                    <div>
                        {(ipfsHash && s3url && type) || type === 'text' ? (
                            <MintAsset
                                ipfsHash={ipfsHash}
                                nftId = {nftId}
                                s3url={s3url}
                                type={type}
                                collection={collection}
                                onOpenCreateCollection={() => setCollectionModalOpen(true)}
                                marketplaceId={marketplaceId}
                                extension={extension}
                            />
                        ) : (
                            <div className='flex flex-col gap-8'>
                                <div
                                    className={`flex flex-col w-full gap-10 `}
                                >
                                    <div className="flex justify-between items-center">
                                        <div className='font-bold text-lg md:text-2xl'>Mint NFT</div>
                                        <div
                                            className={`flex flex-col gap-3 ${showCreateNftOption ? 'h-48' : 'h-12'
                                    } overflow-hidden absolute right-10 top-28 items-center text-white rounded-md shadow-md` }
                                        >
                                            <button
                                                className="py-3 px-12 bg-secBlue rounded-md"
                                                // onClick={() => setShowCreateNftOption(!showCreateNftOption)}
                                                onClick={() => navigate(`/asset?type=${"upload"}`)}
                                            >
                                                Create NFT
                                            </button>
                                            <div className="flex flex-col gap-2 shadow-md absolute top-14 py-3 rounded-md px-4">
                                                {/* <button
                                                    className={' bg-secBlue rounded-md py-2 px-4 text-sm'}
                                                    onClick={() => navigate('/mint?type=text')}
                                                >
                                                    Mint a text nft
                                                </button> */}
                                                <button
                                                    className={' bg-secBlue rounded-md py-2 px-4 text-sm'}
                                                    onClick={() => navigate(`/asset?type=${"upload"}`)}
                                                >
                                                    Upload an asset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <AssetDisplay marketplaceId={marketplaceId} showRedirect={true} onlyNonMinted={true} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default MintNFT;
