import React, { useState } from 'react'
import { toast } from 'react-toastify';

function Property({properties}) {
    // const [validJson,setValidJson] = useState(true)
    let validJson = true
    try{
        properties = JSON.parse(properties)
    }catch(error){
        
        validJson = false
    }
  return (
    <div className='flex flex-wrap gap-2'>
   { properties && validJson ?
      Object?.keys(properties).map(function (key) {
          return (
              <div  className="flex  gap-2 p-2 border dark:dark-border light-border rounded-md ">
                  <span>{key}</span>
                  <span>{properties[key]}</span>
              </div>
          );
      })
      :
      <div className='flex bg-red-400 text-semibold p-3 border rounded-md'>
            Valid JSON is expected. Please Enter valid JSON 
      </div>
    }
      </div>
  );
}

export default Property