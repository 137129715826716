import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuthorizedHttp from '../use-authorized-http';

function useCreateToken() {
    const [iconImage,setIconImage] = useState(null);
    const [iconImageUrl,setIconImageUrl] = useState(null);
    const appCtx = useSelector(state => state.app);
    const [tokenName,setTokeName] = useState("")
    const [tokwnSymbol,setTokenSymbol] = useState("");
    const [initialSupply,setInitialSupply] = useState(0);
    const [decimal,setDecimal] = useState(0);
    const navigate = useNavigate();
    const fileRef = useRef();
    const makeAuthorizedRequest = useAuthorizedHttp();
    // const blockchainRef = useRef("");
    // const propertiesMap = new Map()
    const [marketplaceAddress,setMarketplaceAddress] = useState('');
    const [reserveAccount,setReserveAccount] = useState(marketplaceAddress || '');
    const [selectedBlockChain,setSelectedBlockchain] = useState("ALGORAND")
    const selectIconImage = useCallback((file)=>{
        const objectUrl = URL.createObjectURL(file);

        setIconImage(file)
    },[iconImage])

    const blockchains = [{
        name:"Algorand",
        blockchain:"ALGORAND"
    }
    // ,{
    //     name:"Flow",
    //     blockchain:"FLOW"
    // },{
    //     name:"Ethereum",
    //     blockchain:"ETHEREUM"
    // },{
    //     name:"Solana",
    //     blockchain:"SOLANA"
    // }
]
useEffect(() => {
    if (appCtx.marketplaceId !== undefined) {
        makeAuthorizedRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplace?.marketplaceId}/wallet/balance?blockchain=${appCtx?.blockchain}`
            },
            (data) => {
                console.log(data);
                setMarketplaceAddress(data.address);
                setReserveAccount(data.address);
            },
            () => {}
        );
    }
}, [appCtx.marketplaceId, makeAuthorizedRequest])

const uploadTokenIcon = useCallback(()=>{
    
    const data = new FormData();
			data.append("file", iconImage);

			let config = {
				method: "post",
				url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=token-icon`,
				headers: {
					"Content-Type": "multipart/form-data",
					"X-Auth-Token": appCtx.authToken,
				},
				data: data,
			};

            axios(config)
            .then((response)=>{
            setIconImageUrl(response.data.fileUrl)
            })
            .catch((rej)=>{
                console.log(rej)
                toast.error("icon could not be updated")
            })
},[iconImage])

useEffect(()=>{
    if(iconImage){
        uploadTokenIcon();
    }
},[iconImage])

const initialSupplyHandler = useCallback((e)=>{

    if( !isNaN(+e.target.value )){
        setInitialSupply(+e.target.value)
    }

},[initialSupply])
const decimalHandler = useCallback((e)=>{
    if( !isNaN(+e.target.value )){
        setDecimal(+e.target.value)
    }
},[decimal])

const confirmTokenDetails = useCallback(()=>{
    
    let flag = true ;
    if(tokenName === ""){
        flag = false;
        toast.error("Token name can't be empty")
    }
    if(!iconImageUrl){
        flag = false;
        toast.error("Select token icon")
    }
    

    if(tokwnSymbol === ""){
        flag = false;
        toast.error("Token symbol can't be empty")
    }
    if(initialSupply===0){
        flag = false;
        toast.error("Token initial supply  should be more than 0")
    }
    if(decimal > 18 || decimal < 0){
        flag = false;
        toast.error("Token decimal should be between 0 to 18 ")
    }
    if(!reserveAccount){
        flag = false
        toast.error('reserve account details cannot be empty !!!')
    }
    if(flag){
        makeAuthorizedRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/fungibleToken/create`,
                method:"post",
                data:{
                    // metadata:JSON.stringify({       
                    //     tokenIcon:iconImageUrl,
                    //     properties:[]
                    // }),
                    metadata:{       
                        image:iconImageUrl,
                        properties:{}
                    },
                    icon:iconImageUrl,
                    marketplaceId:appCtx.marketplaceId,
                    tokenShortName:tokwnSymbol,
                    tokenName:tokenName,
                    totalSupply:initialSupply,
                    decimals:decimal,
                    blockchain:selectedBlockChain,
                    managerAccount: marketplaceAddress,
                    reserveAccount: reserveAccount
                }
            },
            (res)=>{
                toast.success("token details submitted ")
                navigate("/fungibletoken/manage")
                
            }
        )
    }
},[tokenName,tokwnSymbol,iconImageUrl,selectedBlockChain,decimal,initialSupply])
  return {
        iconImage,
        blockchains,
        selectIconImage,
        selectedBlockChain,
        setSelectedBlockchain,
        fileRef,
        iconImageUrl,
        setTokeName,
        tokwnSymbol,
        initialSupply,
        initialSupplyHandler,
        decimal,
        decimalHandler,
        confirmTokenDetails,
        tokenName,
        setTokenSymbol,
        marketplaceAddress,
        reserveAccount,
        setReserveAccount
  }
}

export default useCreateToken