import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AbiModal from './AbiModal';
import { useSelector } from 'react-redux';

function CollectionItem({ collection, domain, certificate }) {
    const [abimodal, setAbimodal] = useState(false);
    const appCtx = useSelector((state) => state.app);
    return (
        <div className="rounded-xl bg-white dark:bg-gray-700 shadow-lg  ">
            <div
                className=" flex-col p-6 relative gap-20 items-center"
                onClick={() => {
                    !certificate && window.open('http://' + domain + '/collections/' + collection?.collectionId);
                }}
            >
                <img
                    src={`${collection?.cover_image || '/images/defaultbanner.svg'}`}
                    alt="banner"
                    className="h-[140px] w-full rounded-lg overflow-hidden object-cover"
                />
                <img
                    src={`${collection?.logo || '/images/collogo.png'}`}
                    alt="logo"
                    className="w-20 md:w-[120px] aspect-square rounded-full overflow-hidden absolute top-[100px] left-1/2 translate-x-[-50%]  object-cover"
                />
                <div className="flex flex-col items-center mt-16">
                    <div className="font-bold">{collection?.name}</div>
                    <div className="font-bold" style={{ overflowWrap: 'anywhere' }}>
                        {collection?.description}
                    </div>
                </div>
            </div>
            {appCtx.blockchain !== 'ALGORAND' && (
                <>
                    <div
                        className="text-white cursor-pointer pt-2 pb-5"
                        onClick={() => {
                            const blockchain = collection?.blockchain;
                            const transactionId = collection?.contractAddress;
                            console.log('blockchain', blockchain);
                            console.log('transactionId', transactionId);

                            const blockchainLink =
                                blockchain === 'FLOW'
                                    ? `${process.env.REACT_APP_GATEWAY_FLOWSCAN}${transactionId}`
                                    : blockchain === 'ALGORAND'
                                    ? `${process.env.REACT_APP_GATEWAY_ALGO}/tx/${transactionId}`
                                    : blockchain === 'ETHEREUM'
                                    ? `${process.env.REACT_APP_GATEWAY_ETHEREUM}/address/${transactionId}`
                                    : blockchain === 'POLYGON'
                                    ? `${process.env.REACT_APP_GATEWAY_POLYGON}/address/${transactionId}`
                                    : blockchain === 'BSC'
                                    ? `${process.env.REACT_APP_GATEWAY_BSC}/address/${transactionId}`
                                    : '';

                            if (blockchainLink !== '') {
                                window.open(blockchainLink);
                            }
                        }}
                    >
                        <p className="w-[80%] mx-auto  overflow-hidden"> {collection?.contractAddress}</p>
                    </div>

                    <div className="flex justify-center w-[100%]">
                        <button
                            onClick={() => {
                                setAbimodal(true);
                            }}
                            className="bg-transparent hover:bg-blue-500 text-white font-semibold  py-1 px-4 border border-blue-500 hover:border-transparent rounded mb-5"
                        >
                            View abi
                        </button>
                    </div>
                </>
            )}
            <AbiModal openModal={abimodal} setOpenModal={setAbimodal} collection={collection} />
        </div>
    );
}

export default CollectionItem;
