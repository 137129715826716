import React, { useState, useEffect } from 'react';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { toast } from 'react-toastify';
import FacebookIcon from '@mui/icons-material/Facebook';
import Input from '../ui/Input';
import { AccountBalance, AccountBalanceRounded, AccountBalanceWallet, AccountCircle, LocationOn } from '@mui/icons-material';
import RadioButton from '../ui/radio-button/RadioButton';
import { useSelector } from 'react-redux';
import BlueGradientButton from '../ui/BlueGradientButton';
import axios from 'axios';
import { EventPageNavbarItem } from '../ui/EventPageNavbarItem';

function EditMarketPlaceFooter() {
    const appCtx = useSelector((state) => state.app);
    const [activButton, setActiveButton] = useState(appCtx.marketType === 'DAO' ? 'contact' : 'display');
    const [selctedOption, setSelectedOption] = useState('both');
    const [uploading, setUploading] = useState(false);
    const [marketplace, setMarketplace] = useState();
    const [display, setDisplay] = useState({
        logo: JSON.parse(localStorage.getItem('display'))?.logo || '',
        name: JSON.parse(localStorage.getItem('display'))?.name || '',
        tagline: JSON.parse(localStorage.getItem('display'))?.tagline || ''
    });
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState({
        email: JSON.parse(localStorage.getItem('contact'))?.email || '',
        phone: JSON.parse(localStorage.getItem('contact'))?.phone || '',
    });
    const [socialUrl, setSocialUrl] = useState({
        twitter: JSON.parse(localStorage.getItem('socialUrl'))?.twitter || '',
        facebook: JSON.parse(localStorage.getItem('socialUrl'))?.facebook || '',
        linkedIn: JSON.parse(localStorage.getItem('socialUrl'))?.linkedIn || '',
        discord: JSON.parse(localStorage.getItem('socialUrl'))?.discord || ''
    });
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    const url = new URL(window.location.href);
    const marketplaceId = appCtx.marketplaceId;
    const domain = appCtx.marketplace.domain;
    const makeRequest = useAuthorizedHttp();
    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                console.log(data);
                setMarketplace(data[0]);
            },
            () => { }
        );
    }, [makeRequest]);

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${marketplaceId}/footer`
            },
            (data) => {
                console.log(data);
                const socialUrlData = {
                    twitter: data?.twitter,
                    facebook: data?.facebook,
                    linkedIn: data?.linkedin,
                    discord: data?.discord
                }
                const locationObj = JSON.parse(data?.location);
                setLatitude(locationObj.lat)
                setLongitude(locationObj.long)
                setSocialUrl(socialUrlData)
                setAddress(data?.address)
                setContact({ email: data.email, phone: data?.mobile })
            },
            (data) => console.log(data),
            () => { }
        );
    }, [marketplaceId, makeRequest]);

    const updateFooter = (e) => {
        e.preventDefault();
        localStorage.removeItem('display');
        localStorage.removeItem('contact');
        localStorage.removeItem('socialUrl');
        localStorage.removeItem('location');

        const data = {
            address: address,
            backgroundColor: "",
            backgroundImage: "",
            discord: socialUrl.discord,
            email: contact.email,
            facebook: socialUrl.facebook,
            id: 0,
            linkedin: socialUrl.linkedIn,
            location: JSON.stringify({ lat: latitude, long: longitude }),
            marketplaceId: marketplace?.marketplaceId,
            mobile: contact.phone,
            twitter: socialUrl.twitter,
            userId: marketplace?.userId
        };



        setUploading(true);

        let config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${marketplaceId}/footer`,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
            // data: data
        };

        axios(config)
            .then(function (response) {
                console.log(response);
                if (response?.data!=="") {
                    toast.promise(
                        () =>
                            makeRequest(
                                {
                                    data,
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/footer`,
                                    method: 'put'
                                },
                                (data) => {
                                    // toast('Footer Details Updated successfully !!!');
                                },
                            ),
                        {
                            pending: 'Footer Details Updated successfully !!!',
                            success: 'Updating Footer Details',
                            error: 'Uh-oh! Something went wrong'
                        }
                    )
                }
                else {
                    toast.promise(
                        () =>
                            makeRequest(
                                {
                                    data,
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/footer`,
                                    method: 'post'
                                },
                                (data) => {
                                    // toast('Footer Details Updated successfully !!!');
                                },
                            ),
                        {
                            pending: 'Footer Details Updated successfully !!!',
                            success: 'Updating Footer Details',
                            error: 'Uh-oh! Something went wrong'
                        }
                    )
                }
                // toast('Footer Details Updated successfully !!!');
            })
            .catch(function (error) {
                // toast.promise(
                //     () =>
                //         makeRequest(
                //             {
                //                 data,
                //                 url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/footer`,
                //                 method: 'post'
                //             },
                //             (data) => {
                //                 // toast('Footer Details Updated successfully !!!');
                //             },
                //         ),
                //     {
                //         pending: 'Footer Details Updated successfully !!!',
                //         success: 'Updating Footer Details',
                //         error: 'Uh-oh! Something went wrong'
                //     }
                // )
            });
    }

    return (
        <div className="flex flex-col  gap-10">
            <div className="flex shadow-md rounded-lg dark:bg-darkSecondary">
                {appCtx.marketType !== 'DAO' ? <>
                    <button
                        className={` w-1/5 py-3 rounded-md ${activButton === 'display' && 'bg-secPurple text-white'}`}
                        onClick={() => setActiveButton('display')}
                    >
                        Display
                    </button>
                </>
                    :
                    ''
                }
                <button
                    className={` w-1/5 py-3 rounded-md ${activButton === 'contact' && 'bg-secPurple text-white'}`}
                    onClick={() => setActiveButton('contact')}
                >
                    Contact
                </button>

                <button
                    className={` w-1/5 py-3 rounded-md ${activButton === 'social' && 'bg-secPurple text-white'}`}
                    onClick={() => setActiveButton('social')}
                >
                    Social media
                </button>
                <button
                    className={` w-1/5 py-3 rounded-md ${activButton === 'location' && 'bg-secPurple text-white'}`}
                    onClick={() => setActiveButton('location')}
                >
                    Location
                </button>
            </div>

            <div className="flex justify-between  px-3 ">
                {activButton === 'display' && (
                    <form className="flex flex-col gap-5 border rounded-md p-3 px-6 dark:border-gray-700  border-gray-400 ">
                        {/* <div className="font-bold text-xl"> Display</div> */}
                        <div className="flex flex-col gap-3">
                            <div className="px-3 py-2 flex gap-2">
                                <input
                                    type="radio"
                                    value="marketplace-name"
                                    name="display"
                                    checked={selctedOption === 'marketplace-name'}
                                    onChange={() => {
                                        setSelectedOption('marketplace-name');
                                        setDisplay({ ...display, name: marketplace?.name });
                                    }}
                                />
                                <label htmlFor="marketplace-name">Marketplace Name</label>

                            </div>

                            <div className="px-3 py-2 flex gap-2">
                                <input
                                    type="radio"
                                    value="logo-image"
                                    name="display"
                                    checked={selctedOption === 'logo-image'}
                                    onChange={() => {
                                        setSelectedOption('logo-image');
                                        setDisplay({ ...display, logo: marketplace?.logo });
                                    }}
                                />
                                <label htmlFor="logo-image">Logo Image</label>
                            </div>
                            <div className="px-3 py-2 flex gap-2">
                                <input
                                    type="radio"
                                    value="both"
                                    name="display"
                                    checked={selctedOption === 'both'}
                                    onChange={() => {
                                        setSelectedOption('both');
                                        setDisplay({ ...display, logo: marketplace?.logo, name: marketplace?.name });
                                    }}
                                />
                                <label htmlFor="both">Both</label>
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            {/* <div className="font-bold text-xl">Tagline</div> */}
                            <Input
                                type="text"
                                className="px-7"
                                description="Tagline"
                                placeholder="Tagline"
                                onChange={(e) => setDisplay({ ...display, tagline: e.target.value })}
                                value={display.tagline}
                            />
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="px-24 py-3 bg-secBlue rounded-lg text-white"
                                type="submit"
                                onClick={() => {
                                    setActiveButton('contact');
                                    localStorage.setItem('display', JSON.stringify(display));
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </form>
                )}
                {activButton === 'contact' && (
                    <form className="flex flex-col gap-14 border rounded-md p-3 dark:border-gray-700  border-gray-400 " >
                        {/* <div className="font-bold text-xl"> Contact</div> */}
                        <div className="flex flex-col gap-4">
                            <div className="px-3 py-2 flex flex-col gap-2">
                                <label>Email</label>
                                <Input
                                    className="px-7"
                                    type="email"
                                    placeholder='Email'
                                    onChange={(e) => setContact({ ...contact, email: e.target.value })}
                                    value={contact.email}
                                />
                            </div>
                            <div className="px-3 py-2 flex flex-col gap-2">
                                <label>Phone number</label>
                                <Input
                                    className="px-7"
                                    type="text"
                                    placeholder='Phone number'
                                    onChange={(e) => setContact({ ...contact, phone: e.target.value })}
                                    value={contact.phone}
                                />
                            </div>
                            <div className="px-3 py-2 flex flex-col gap-2">
                                <label>Address</label>
                                <Input
                                    className="px-7"
                                    type="text"
                                    placeholder='Address'
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="px-24 py-3 bg-secBlue rounded-lg text-white"
                                type="submit"
                                onClick={() => {
                                    setActiveButton('social');
                                    localStorage.setItem('contact', JSON.stringify(contact));
                                }}
                            >
                                Next
                            </button>
                        </div>
                    </form>
                )}
                {activButton === 'social' && (
                    <form className="flex flex-col gap-4 border p-3 rounded-md dark:border-gray-700  border-gray-400">
                        {/* <div className="font-bold text-xl "> Social media</div> */}
                        <div className="flex flex-col gap-2 edit-scrollbar">
                            <div className="px-3 py-1 flex flex-col rounded-md">
                                <div>Twitter</div>
                                <div className="flex rounded-md overflow-hidden px-2 py-2 gap-3 border dark:border-gray-700  border-gray-400">
                                    <img src="/icons/twitter.svg" className="h-6 w-6" alt="twitter" />

                                    <input
                                        className="bg-none outline-none bg-inherit "
                                        type="text"
                                        placeholder="https://twitter.com"
                                        value={socialUrl.twitter}
                                        onChange={(e) => setSocialUrl({ ...socialUrl, twitter: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="px-3 py-1 flex flex-col rounded-md">
                                <div>Facebook</div>
                                <div className="flex rounded-md  overflow-hidden px-2 py-2 gap-3 border dark:border-gray-700  border-gray-400">
                                    <img src="/icons/facebook.svg" alt="facebook" />

                                    <input
                                        className=" outline-none  bg-inherit "
                                        type="text"
                                        placeholder="https://facebook.com"
                                        value={socialUrl.facebook}
                                        onChange={(e) => setSocialUrl({ ...socialUrl, facebook: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="px-3 py-1 flex flex-col rounded-md">
                                <div>LinkedIn</div>
                                <div className="flex rounded-md  overflow-hidden px-2 py-2 gap-3 border dark:border-gray-700  border-gray-400">
                                    <img src="/icons/linkedin.svg" alt="linkedIn" />

                                    <input
                                        className=" outline-none bg-inherit "
                                        type="text"
                                        placeholder="https://linkedin.com"
                                        value={socialUrl.linkedIn}
                                        onChange={(e) => setSocialUrl({ ...socialUrl, linkedIn: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="px-3 py-1 flex flex-col rounded-md">
                                <div>Discord</div>
                                <div className="flex rounded-md e overflow-hidden px-2 py-1 gap-3 border dark:border-gray-700  border-gray-400">
                                    <img src="/icons/discord.svg" alt="discord" />

                                    <input
                                        className=" outline-none bg-inherit "
                                        type="text"
                                        placeholder="https://discord.com"
                                        value={socialUrl.discord}
                                        onChange={(e) => setSocialUrl({ ...socialUrl, discord: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="px-24 py-3 bg-secBlue rounded-lg text-white"
                                type="submit"
                                onClick={() => {
                                    setActiveButton('location');
                                    // localStorage.setItem('contact', JSON.stringify(contact));
                                }}
                            // disabled={uploading}
                            >
                                Next
                            </button>
                        </div>
                    </form>
                )}
                {/* locaion */}
                {activButton === 'location' && <div className='w-[250px]'>
                    <div className="text-lg font-bold dark:text-gray-400 text-gray-700 mt-[20px]">Add Location <LocationOn /></div>
                    <input className=' my-[20px] w-[250px] h-[40px] rounded-lg px-[10px] text-black' value={latitude} placeholder='Enter Latitude' onChange={(e) => { setLatitude(e.target.value) }} />
                    <input className='w-[250px] h-[40px] rounded-lg px-[10px] text-black mb-[20px]' value={longitude} placeholder='Enter Longitude' onChange={(e) => { setLongitude(e.target.value) }} />
                    <div className="flex justify-center w-[250px]">
                        <button
                            className="px-24 py-3 bg-secBlue rounded-lg text-white"
                            type="submit"
                            onClick={(e) => {

                                updateFooter(e);

                            }}
                        // disabled={uploading}
                        >
                            Save
                        </button>
                    </div>
                </div>}

                <div className=" rounded-lg  flex flex-col shadow-prevBox dark:bg-inherit  h-[60vh] justify-between border-white dark:border-darkBorder border-[20px] dark:border-darkSecondary w-3/5">
                    <div className="flex justify-between px-10 py-4 gap-5 items-center">
                        {appCtx.marketType !== 'DAO' ? <>
                            {marketplace?.logo &&
                                <img
                                    src={marketplace?.logo}
                                    alt="LOGO"
                                    className="object-cover  w-[50px] h-[50px] z-10 rounded-full"
                                />
                            }
                            <button className="text-xl font-light">{marketplace?.name}</button>
                            <input
                                className="outline-none rounded-xl py-1 px-2 dark:bg-darkSecondary dark:bg-darkBorder bg-[#D6D6D6] "
                                type="text"
                            />
                            <div className="flex gap-5 items-center">
                                <button className="">Explore</button>
                                <AccountBalanceWallet />
                                <AccountCircle />
                            </div>
                        </>
                            :
                            <>
                                <div className='flex justify-start'>
                                    {marketplace?.logo &&
                                        <img
                                            src={marketplace?.logo}
                                            alt="LOGO"
                                            className="object-cover  w-[50px] h-[50px] z-10 rounded-full"
                                        />
                                    }
                                    <button className="text-xl font-light ml-[5px]">{marketplace?.name}</button>
                                </div>
                                <EventPageNavbarItem />
                            </>
                        }
                    </div>
                    <div className="flex dark:bg-gray-700 bg-[#eeeeee]   h-28 justify-between p-3 px-8 text-[10px]">
                        {appCtx.marketType !== 'DAO' ? <>
                            <div className="flex gap-2 flex-col ">
                                <div className="">
                                    {selctedOption === 'marketplace-name' && marketplace?.name}
                                    {selctedOption === 'logo-image' && (
                                        <div>
                                            {marketplace?.logo && <img
                                                className="h-8 w-8 rounded-full object-cover"
                                                src={marketplace?.logo}
                                                alt="logo"
                                            />}
                                        </div>
                                    )}
                                    {selctedOption === 'both' && (
                                        <div className="flex gap-2">
                                            {marketplace?.logo && <img
                                                className="h-8 w-8 rounded-full object-cover"
                                                src={marketplace?.logo}
                                                alt="logo"
                                            />}
                                            <div>{marketplace?.name}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="">{display.tagline}</div>
                            </div>
                            <div className="flex flex-col gap-3 items-start">
                                <button>About Us</button>
                                <button>Privacy</button>
                                <button>FAQ</button>
                                <button className="flex justify-start">Terms & Conditions</button>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div>Contact Us</div>
                                <div>{contact.email}</div>
                                <div>{contact.phone}</div>
                            </div>

                            <div className="flex flex-col gap-3">
                                <div>Join Our Community</div>
                                <div className="flex flex-wrap gap-2">
                                    {socialUrl.twitter && (
                                        <a href={socialUrl.twitter} target="_blank">
                                            <img src="/icons/twitter.svg" alt="twitter" />
                                        </a>
                                    )}
                                    {socialUrl.facebook && (
                                        <a href={socialUrl.facebook} target="_blank">
                                            <img src="/icons/facebook.svg" alt="facebook" />
                                        </a>
                                    )}
                                    {socialUrl.linkedIn && (
                                        <a href={socialUrl.linkedIn} target="_blank">
                                            <img src="/icons/linkedin.svg" alt="linkedin" />
                                        </a>
                                    )}
                                    {socialUrl.discord && (
                                        <a href={socialUrl.discord} target="_blank">
                                            <img src="/icons/discord.svg" alt="discord" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </>
                            :
                            <div className='flex gap-1 flex-col justify-center items-center w-[100%]'>
                                <div className="flex justify-center gap-2 w-[100%]">
                                    {marketplace?.logo && <img
                                        className="h-8 w-8 rounded-full object-cover"
                                        src={marketplace?.logo}
                                        alt="logo"
                                    />}
                                    <div>{marketplace?.name}</div>
                                </div>
                                <div className={`flex items-center justify-center`}>
                                    <Input
                                        placeholder="Enter number or email"
                                        border="left"
                                        className="sm:w-[200px] lg:w-[200px] h-[30px] rounded-r-none w-[100%] text-black pr-[15px]"
                                        // value={cred}
                                        disabled={true}
                                        type="text"
                                    // onChange={(e) => setCred(e.target.value)}
                                    />
                                    <BlueGradientButton
                                        className="flex justify-center items-center w-[100px] h-[30px] text-[10px] mt-2 relative right-[15px]"
                                        border="right"
                                        disabled={true}
                                    // disabled={notifyMeButtonDisabled}
                                    // onClick={handleNotifyMeClicked}
                                    >
                                        Notify Me!
                                    </BlueGradientButton>
                                </div>
                                <div className="flex justify-center flex-wrap gap-2 w-[100%]">
                                    {socialUrl.twitter && (
                                        <a href={socialUrl.twitter} target="_blank">
                                            <img src="/icons/twitter.svg" alt="twitter" />
                                        </a>
                                    )}
                                    {socialUrl.facebook && (
                                        <a href={socialUrl.facebook} target="_blank">
                                            <img src="/icons/facebook.svg" alt="facebook" />
                                        </a>
                                    )}
                                    {socialUrl.linkedIn && (
                                        <a href={socialUrl.linkedIn} target="_blank">
                                            <img src="/icons/linkedin.svg" alt="linkedin" />
                                        </a>
                                    )}
                                    {socialUrl.discord && (
                                        <a href={socialUrl.discord} target="_blank">
                                            <img src="/icons/discord.svg" alt="discord" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMarketPlaceFooter;
