import { CloseRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalOverlay from './ModalOverlay';

function PutOnSaleModal(props) {
    
    return (
        <ModalOverlay >
            <div className="flex flex-col gap-10 items-center">
                <div className={'flex w-full justify-end'}>
                    <Link to="/asset"  className={'text-rose-500'} >
                        <CloseRounded />
                    </Link>
                </div>
                <div className='flex flex-col items-center gap-3'>
                    <div className="font-bold">Congratulations</div>
                    <div>Your NFT is listed for sale</div>
                </div>
                <div className='flex gap-10'>
                    <div className='flex shadow-md flex-col rounded-md overflow-hidden gap-3'>
                        <img src="/images/sale1.png"  className='w-72 h-48 object-cover'/>
                        <div className='justify-center flex'>View your Listed NFT</div>
                        <button onClick={()=>window.open(`http://${props.marketplaceDomain}/nft/${props.nftId}`)} className='text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4'>Visit store</button>
                    </div>
                    <div className='flex  flex-col rounded-md overflow-hidden shadow-md gap-3'>
                        <img src="/images/sale2.png"  className='w-72 h-48 object-cover'/>
                        <div className='justify-center flex'>Mint Other Asset</div>
                        <Link to="/asset" className='text-white flex justify-center decoration-secBlue bg-secBlue font-bold py-4'>Mint</Link>
                    </div>
                </div>
            </div>
        </ModalOverlay>
    );
}

export default PutOnSaleModal;
