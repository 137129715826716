import React, { useCallback, useState, useEffect } from 'react';
import { ExpandMore, LightMode, Menu, Person, RemoveRedEye, Wallet } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NavbarDropdown from './NavbarDropdown';
import CreatePasswordModal from './CreatePasswordModal';
import UpdateProfileModal from './UpdateProfileModal';
import { persistor } from '../../context/store';
import logoWhite from '../../assets/logo-white.svg';
import logoBlack from '../../assets/logo-black.svg';
import ContactUsModal from './ContactUseModal';
import { appActions } from '../../context/app-slice';
import { useDispatch } from 'react-redux';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import NavWallet from '../common/NavWallet';
import Dropdown from 'react-dropdown';

import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import Network from '../../ui/navbar/Network';
import { getImageUrl } from '../../config';

const AuthenticatedNavbar = () => {
    const [isExpanded, setExpanded] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
    const [updateProfileVisible, setUpdateProfileVisible] = useState(false);
    const [contactUsVisible, setContactUsVisible] = useState(false);
    const [marketplaceDomain, setMarketplaceDomain] = useState('');
    const [marketplaceId, setMarketplaceId] = useState();
    const [wallet, setWallet] = useState(null);
    const [marketplace, setMarketplace] = useState(null);
    const [openNet, setOpenNet] = useState(false);
    // const [loading,setLoading] = useState(process.env.REACT_APP_ENVIRONMENT === "prod" && !appCtx.mainnet)

    const [activeTest, setActiveTest] = useState('algo-test');
    const [isWalletPopOpen, setIsWalletPopOpen] = useState(false);
    const dispatch = useDispatch();
    const handleLogoutClick = useCallback(() => {
        localStorage.removeItem('display');
        localStorage.removeItem('contact');
        localStorage.clear();
        persistor.purge().then(() => (window.location.href = '/'));
        // dispatch(appActions.logout())
    }, []);
    // const changeMode = useCallback(()=>{
    //     dispatch(appActions.toggleDarkMode());
    // },[]
    // )
    const makeRequest = useAuthorizedHttp();

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                //     if(!appCtx.mainnet && process.env.REACT_APP_ENVIRONMENT === "prod" ){
                //     if(!data[0]?.mainnet){
                //         const email = appCtx.marketplaceEmail
                //         persistor.purge().then(()=>window.location.replace(`https://testnet.onnftverse.com/?email=${email}&marketplace=${data[0]?.name}`))

                //     }else if(data[0].mainnet === true){
                //         dispatch(appActions.mainnet())
                //         setLoading(false)
                //     }
                // }
                if (data[0].mainnet) {
                    dispatch(appActions.mainnet(true));
                }
                setMarketplace(data[0]);
                setMarketplaceDomain(data[0].domain);
                setMarketplaceId(data[0].marketplaceId);
                dispatch(appActions.setMarketplace(data[0]));
                dispatch(appActions.setMarketplaceId(data[0]?.marketplaceId));
                dispatch(appActions.setDomain(data[0]?.domain));
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);
    useEffect(() => {
        console.log(appCtx.newSignup);
        if ((marketplaceId && appCtx.blockchain !== '') || !appCtx.newSignup) {
            if (marketplaceId) {
                makeRequest(
                    {
                        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/wallet/balance?blockchain=${appCtx?.blockchain}`
                    },
                    (data) => {
                        setWallet(data);
                        dispatch(appActions.setWalletDetails(data));
                        // dispatch(appActions.setBlockChain(data.blockchain))
                        return data;
                    },
                    (data) => {
                        console.log(data);
                    },
                    () => {}
                );
            }
        }
    }, [makeRequest, marketplaceId, appCtx.blockchain, appCtx.newSignup]);

    return (
        // loading ?<div className='w-screen text-black flex z-[100] justify-center items-center text-3xl font-bold bg-white h-screen fixed top-0'>
        //         Hi ,please wait for a while, as we are configuring your dashboard.
        // </div>
        // :

        <>
            <NavbarDropdown
                contactUsVisible={contactUsVisible}
                setContactUsVisible={setContactUsVisible}
                onLogoutClick={handleLogoutClick}
                showUpdateProfile={() => setUpdateProfileVisible(true)}
                showResetPassword={() => setResetPasswordVisible(true)}
                visible={isDropdownVisible}
            />
            {updateProfileVisible && <UpdateProfileModal hideUpdateProfile={() => setUpdateProfileVisible(false)} />}
            {resetPasswordVisible && (
                <CreatePasswordModal
                    header={'Reset your password'}
                    hideResetPassword={() => setResetPasswordVisible(false)}
                />
            )}
            {contactUsVisible && <ContactUsModal onClose={() => setContactUsVisible(false)} />}
            <div
                className={`${
                    isExpanded ? 'h-[250px]' : 'h-[80px]'
                } fixed z-30 md:h-[80px] w-screen  bg-white dark:bg-gray-700 shadow-xl text-black dark:text-white p-5 transition-all ease-out duration-300 overflow-y-hidden`}
            >
                <div className="flex flex-col md:flex-row justify-between w-[100%] mx-auto">
                    <div className="flex justify-between gap-3 items-center">
                        <Link to="/" className="font-extrabold text-2xl no-underline">
                            <img
                                src={appCtx.isDarkMode ? logoWhite : logoBlack}
                                alt={'NFTVerse'}
                                className={'w-[150px]'}
                            />
                        </Link>
                        {/* <div className="">
						<input
							type="text"
							className="h-[45px] lg:w-[500px] w-[230px] border-2 border-slate-400 rounded-xl bg-slate-50 p-4 outline-none text-gray-600"
							placeholder="Search for any NFT"
						/>
					</div> */}
                        <button className="visible md:invisible" onClick={() => setExpanded(!isExpanded)}>
                            <Menu fontSize="large" className="" />
                        </button>
                    </div>
                    <div className="flex gap-16">
                        {/* <Link
                            onClick={() => setContactUsVisible(!isDropdownVisible)}
                            to="#"
                            className="text-black dark:text-white transition-all ease-out duration-200 hover:text-gray-500 text-left my-auto no-underline"
                        >
                            Contact us
                        </Link> */}
                        <div
                            className={`flex flex-col md:flex-row md:items-center gap-3 mt-5 md:mt-0 md:gap-5 transition-all ease-out duration-300 `}
                        >
                            {/* <button onClick={changeMode}>
                                <LightMode />
                            </button> */}
                            <Network />

                            <Popover
                                isOpen={isWalletPopOpen}
                                className="z-10"
                                positions={['bottom', 'right']}
                                padding={20}
                                reposition={false}
                                onClickOutside={() => setIsWalletPopOpen(false)}
                                content={({ position }) => (
                                    <div
                                        // style={{ backgroundColor: "blue", opacity: 0.7 }}
                                        className={` z-20 relative right-32  rounded-md overflow-hidden bg-white dark:bg-gray-700 '
                                            } text-gray-600  shadow-md bg-inherit`}
                                    >
                                        <NavWallet marketplace={marketplace} wallet={wallet} />
                                    </div>
                                )}
                            >
                                <button className="mt-1" onClick={() => setIsWalletPopOpen(!isWalletPopOpen)}>
                                    <div className="flex gap-2  items-center">
                                        <Wallet />
                                        {wallet && (
                                            <div className="text-[10px] flex bg-slate-200 dark:bg-gray-600 rounded-3xl gap-1 p-1 items-center">
                                                <img src={getImageUrl(wallet?.blockchain)} className="w-4 h-4" />

                                                {appCtx.walletDetails.balance.toFixed(5)}
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </Popover>

                            <button
                                className="flex justify-start"
                                onClick={(e) => {
                                    window.open(`http://${marketplaceDomain}`);
                                }}
                            >
                                <RemoveRedEye />
                            </button>
                            <Link
                                onClick={() => setDropdownVisible(!isDropdownVisible)}
                                to="#"
                                className="no-underline border-sky-500 text-sky-500  rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"
                            >
                                <Person />
                                {/* {appCtx.userDetails.name.split(' ')[0]} */}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthenticatedNavbar;
