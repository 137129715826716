import React, { useEffect } from 'react'
import DivContainer from '../../ui/DivContainer'
import BlueGradientButton from '../../ui/BlueGradientButton';
import useVerifyAttendee from '../../hooks/events/useVerifyAttendee';

function AttendeeContainer({attendee,index,fetchAttendees}) {
    const { verifyAttendee, success,setSuccess } = useVerifyAttendee();
    useEffect(()=>{
        if(success){
            fetchAttendees()
            setSuccess(false)
        }
    },[])

  return (
      <DivContainer>
          <div className="grid grid-cols-12 items-center">
              <div className="col-span-1">{index+1}</div>
              <div className="col-span-3">{attendee?.name}</div>
              <div className="col-span-3">{attendee?.email}</div>
              <div className="col-span-3">{attendee?.claimed ? "Claimed" :"Unclaimed"} </div>
              <div className="col-span-2"><BlueGradientButton onClick={() => verifyAttendee(attendee?.eventId,attendee?.id)} >Verify</BlueGradientButton></div>
          </div>
      </DivContainer>
  );
}

export default AttendeeContainer