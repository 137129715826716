import React from "react"
import { Canvas } from '@react-three/fiber';
import { useTransition, useState, useEffect } from 'react';
import { OrbitControls, useFBX } from '@react-three/drei';
import { FBXLoader, GLTFLoader } from "three-stdlib";
import Forgltf from "./Forgltf";

const ThreeDRenderer = ({ src, className, type }) => {
    console.log(src, type);
    // const fbx = useFBX(src);
    const [isPending, startTransition] = useTransition();
    const [FBX, setFBX] = useState({});
    // const [scale,setScale] = useState();
    // let fbx = useFBX(src);
    useEffect(() => {
        if (type === "fbx") {
            startTransition(() =>
                void new FBXLoader().load(src, setFBX), [src]
            )
        }
    }, [src, type])

    return (
        type === 'fbx' ? <>
            <div className={`${className}`}>
                <Canvas camera={{ fov: 120, position: [300, 150, 300] }}>
                    <ambientLight intensity={0.5} />
                    <directionalLight position={[10, 10, 5]} intensity={1.5} />
                    <primitive object={FBX}/>
                    <OrbitControls enablePan={true} enableRotate={true} enableZoom={true} />
                </Canvas>
            </div>
        </>
            :
            (type === "gltf" || type === 'glb') ? <Forgltf src={src} />
                :
                <></>
    );
};

export default ThreeDRenderer;
