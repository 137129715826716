import React, { useCallback, useEffect } from 'react';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { DeleteRounded } from '@mui/icons-material';

function ArrayKeyValue({ kv, setKV, setObjectProperties }) {
    const handleRemoveClick = useCallback(
        (index) => {
            const copyArray = kv.filter((k) => k !== index);
            setKV(copyArray);
        },
        [kv]
    );

    const handleKVChange = useCallback(
        (e, index) => {
            const { name, value } = e.target;
            const list = [...kv];
            list[index][name] = value;
            setKV(list);
        },
        [kv]
    );
    useEffect(() => {
        let object = kv.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
        setObjectProperties(object);
    }, [kv]);
    return (
        <div>
            {kv.map((x, i) => (
                <div className="flex flex-row gap-5 items-between justify-between">
                    <Input
                        onChange={(e) => handleKVChange(e, i)}
                        name="name"
                        placeholder="Property"
                        type="text"
                        value={x.name}
                    />
                    <Input
                        onChange={(e) => handleKVChange(e, i)}
                        name="value"
                        placeholder="Value"
                        type="text"
                        value={x.value}
                    />
                    <button
                        onClick={() => handleRemoveClick(x)}
                        className="bg-gray-100 hover:bg-gray-200 transition-all dark:bg-gray-700 dark:hover:bg-gray-600 ease-out duration-300 p-3 rounded mt-2"
                    >
                        <DeleteRounded />
                    </button>
                </div>
            ))}

            <BlueGradientButton className="w-fit" onClick={() => setKV((prev) => [...prev, { name: '', value: '' }])}>
                Add property
            </BlueGradientButton>
        </div>
    );
}

export default ArrayKeyValue;
