import React from 'react';
import LandingButton from '../../ui/buttons/LandingButton';
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';
import Working from '../../components/landing/Working';

export default function GameDevelopers() {
    const dispatch = useDispatch();
   

    return (
        <div className="flex flex-col gap-24">
            <div
                className="grid bg-cover grid-cols-1 md:grid-cols-2  h-screen bg-no-repeat"
                style={{ backgroundImage: `url(/images/nlg/1.svg)` }}
            >
                <div className=" justify-center md:justify-end flex ml-20">
                    <div className="flex flex-col justify-center gap-5">
                        <div className="font-semibold text-4xl">NFT launchpad for Game Developers</div>
                        <div className="text-xl">
                            Launch and integrate the NFT marketplace into your game. Allow your users to purchase NFT
                            without the need to leave your game.
                        </div>
                        <div className="flex justify-start">
                            <LandingButton onClick={() => dispatch(appActions.setCreateAccountModal(true))}>
                                Get Started
                            </LandingButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-10 px-20">
                <div className="text-center text-semibold text-4xl">Features</div>
                <div className="grid grid-cols-1 md:grid-cols-3 items-center  gap-10">
                    <div className="flex justify-center">
                        <img src="/images/nlg/2a.svg" />
                    </div>
                    <div className="flex justify-center">
                        <img src="/images/nlg/2b.svg" />
                    </div>
                    <div className="flex justify-center">
                        <img src="/images/nlg/2b.svg" />
                    </div>
                </div>
            </div>
            <Working/>
        </div>
    );
}
