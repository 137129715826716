/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar';
import { appActions } from '../context/app-slice';
import useHttp from '../hooks/use-http';
import { isEmailValid, isPhoneValid } from '../misc/field-validation';
import LandingButton from '../ui/buttons/LandingButton';
import Input from '../ui/Input';
import login from '../assets/login/login-bg.jpg';
import BlueGradientButton from '../ui/BlueGradientButton';

const Web3Signup = () => {
    const [uploading, setUploading] = useState(false);
    const [marketplace, setMarketplace] = useState('');
    const [cred, setCred] = useState('');
    const marketplaceRef = useRef('');
    const emailRef = useRef('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [marketplaceSuccessMessage, setMarketplaceSuccessMessage] = useState('');
    // console.log(searchParams.get("email"))
    // console.log(searchParams.get("marketplace"))

    const navigate = useNavigate();
    const makeRequest = useHttp();

    const dispatch = useDispatch();

    const handleCreateClicked = useCallback(() => {
        let flag = true;
        let data = null;
        if (!marketplace && !marketplaceRef.current) {
            flag = false;
            toast.error("Marketplace name can't be empty!");
        }
        if (isEmailValid(cred || emailRef.current)) {
            data = {
                email: cred || emailRef.current,
                marketplace: marketplace || marketplaceRef.current
            };
        } else if (isPhoneValid(cred)) {
            data = {
                mobile: cred,
                marketplace
            };
        } else {
            flag = false;
            toast.error('Either mobile number or email needed');
        }
        if (flag) {
            dispatch(appActions.setMarketplaceEmail(cred));
            setUploading(true);
            toast.promise(
                () =>
                    makeRequest(
                        {
                            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/save`,
                            method: 'POST',
                            headers: {
                                'X-App-Token': process.env.REACT_APP_APP_TOKEN,
                                'Content-Type': 'application/json'
                            },
                            data
                        },
                        (data) => {
                            if (data.authToken) {
                                setMarketplaceSuccessMessage('Successfully created your marketplace!');
                                dispatch(appActions.updateUserDetails({ userId: data.userId }));
                                dispatch(appActions.updateAuthToken(data.authToken));
                                dispatch(appActions.toggleNewSignup());
                                dispatch(appActions.togglePasswordCreated());
                                dispatch(appActions.login());
                                navigate('/');
                            } else {
                                toast.error('Account already exists. Please login.');
                                navigate('/login');
                            }
                        },

                        (data) => {
                            console.log(data);
                        },
                        () => {
                            setUploading(false);
                        }
                    ),
                {
                    pending: 'Creating your marketplace',
                    success: marketplaceSuccessMessage,
                    error: 'Something went wrong!'
                }
            );
        }
    }, [cred, dispatch, makeRequest, marketplace, marketplaceSuccessMessage, navigate]);

    useEffect(() => {
        if (searchParams.get('email') && searchParams.get('marketplace')) {
            setCred(searchParams.get('email'));
            setMarketplace(searchParams.get('marketplace'));
            emailRef.current = searchParams.get('email');
            marketplaceRef.current = searchParams.get('marketplace');
            handleCreateClicked();
        }
    }, [searchParams]);

    return (
        <div className={'dark'}>
            <UnauthenticatedNavbar />
            <div
                className="w-[100%] h-fit bg-no-repeat bg-cover bg-fixed pt-10"
                style={{ backgroundImage: `url(${login})` }}
            >
                <div className="w-full min-h-screen bg-gradient-to-r from-zinc-800 via-zinc-800/80 to-zinc-600/0 p-0 flex justify-center items-center">
                    <div className="flex flex-col items-center w-[100%] gap-10">
                        <div className="text-slate-100 text-center w-[100%] text-2xl lg:text-3xl font-extrabold">
                            Create your Web 3.0 app
                        </div>
                        <div className="flex flex-col gap-5 lg:w-[620px] bg-[rgb(36,32,32)] p-4 lg:p-6 rounded-xl">
                            <Input
                                header="Web3 App Name"
                                description="Enter your Web3 App Name"
                                // disabled={uploading}
                                type="text"
                                className="w-[100%]"
                                // className="bg-gradient-to-t placeholder:text-white from-[#925BEC] to-[#5514DF]"
                                placeholder="Web3 app name"
                                value={marketplace}
                                onChange={(e) => setMarketplace(e.target.value)}
                            />
                            <Input
                                header="Email"
                                description="Enter your email address"
                                // disabled={uploading}
                                placeholder="Email"
                                className="w-[100%]"
                                // className="bg-gradient-to-t placeholder:text-white from-[#925BEC] to-[#5514DF]"
                                type="text"
                                value={cred}
                                onChange={(e) => setCred(e.target.value)}
                            />
                            <div className="flex justify-center">
                                <BlueGradientButton
                                    // disabled={uploading}
                                    className="w-[100%]"
                                    onClick={handleCreateClicked}
                                >
                                    Create
                                </BlueGradientButton>
                            </div>
                            <div className="flex justify-center text-white w-[100%]">
                                <span className="w-[100%] flex justify-between">
                                    Already have account ?{' '}
                                    <button
                                        className=""
                                        onClick={() => {
                                            dispatch(appActions.setCreateAccountModal(false));
                                            navigate('/');
                                        }}
                                    >
                                        Login here
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Web3Signup;
