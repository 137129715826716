import React, { useEffect, useState } from 'react';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import EditPages from './EditPages';

function EditPagesContainer(props) {
    return (
        <div className='flex flex-col gap-4 '>
            <EditPages  />
            {props.children}
        </div>
    );
}

export default EditPagesContainer;
