import { Add, PlusOne, Remove } from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Footer from '../components/common/Footer'
import UnauthenticatedNavbar from '../components/navbar/UnauthenticatedNavbar'

export const FAQ = () => {
    const [open, setOpen] = useState(false);
    const [faqData, setFaqData] = useState([
        {
            question: 'Q1. What is NFTVerse?',
            answer: 'NFTVerse is your one-stop Whitelabel SaaS-based web 3.0 platform where you can create your own Whitelabel NFT marketplace, web 3.0 apps, Non Fungible tokens, Fungible tokens & Whitelabel crypto wallets. NFTVerse also allows its users to customise their app and marketplaces and can use the platform to list and sell NFTs on marketplaces.            ',
            isOpen: false,
        },
        {
            question: 'Q2. How can I create my own whitelabel NFT Marketplace using NFTVerse?',
            answer: `You can use these simple, easy steps to create your own NFT marketplace - 
            Visit our Website stage.onnftverse.com
            Enter your Marketplace name, and email address and click on “Create Button”.  
            Once you click on the create option your marketplace is created now you can customise it according to your needs. 
            Mint NFTs and list them for sale on your platform. 
            `,
            isOpen: false,
        },
        {
            question: 'Q3. How can I create NFTs using NFTVerse? Can I list it for sale using NFTverse?',
            answer: `You can create NFTs by following these simple steps- 
            Create your marketplace 
            Customise it accordingly using our customise feature 
            Click on the “Mint NFTs” option on our dashboard, upload your asset, fill in information related to the asset and list it for sale on your marketplace.
            `,
            isOpen: false,
        },
        {
            question: 'Q4. What is NFTs SDK?            ',
            answer: 'SDKs stand for the software development kit. NFTs SDK helps you use all those features provided to create your own NFTs on your platform and list them for sale. (need to explain well).',
            isOpen: false,
        },
        {
            question: 'Q5. Can I List my NFTs for sale on the “NFTVerse” website as well?',
            answer: 'Currently we are not providing this feature on our website , but in  future, you can do that as well.',
            isOpen: false,
        },
        {
            question: 'Q6. Can I create NFTs on NFTVerse and can sell them on other marketplace platforms such as opensea, rarible etc?            ',
            answer: 'With our NFT distribution feature, anyone can easily sell their NFTs which are minted on NFTVerse.',
            isOpen: false,
        },
        {
            question: 'Q7. How can I use these SDKs in my existing web2 website?',
            answer: 'We provide end to end solutions to your problems. Our SDKs will be integrated with your existing sites and apps. You can use all the provided features of our SDKs in your existing websites as well. ',
            isOpen: false,
        },

    ])
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    return (
        <div className="dark landing-bg .bg-zinc-800 flex -col  h-full text-slate-50">
            <UnauthenticatedNavbar />
            <div className="dark landing-bg .bg-zinc-800  w-full h-full text-slate-50">
                <div className="flex flex-col mt-20 gap-10 ">
                    <div className=" flex text-3xl font-bold justify-center ">
                        <div className="border-b-[15px] border-secPurple text-xl md:text-3xl h-[80px] mt-[20px]">FAQ</div>
                    </div>
                    <div className='mx-4 sm:mx-8 lg:mx-32 mb-[80px] text-justify border-secPurple border-[15px] rounded-xl'>
                        <div className='m-3 md:m-6'>
                            {
                                faqData.map((faq, index) => {
                                    return (<>
                                        <div

                                            className="flex bg-white text-black flex-col gap-0 w-full rounded-lg border-none shadow-[0_15px_40px_#0C0C0C0D] overflow-hidden mb-4"
                                        >
                                            <button onClick={() => {
                                                let copyFaqData = [];
                                                copyFaqData = faqData.map((copyFaq, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...copyFaq,
                                                            isOpen: !copyFaq.isOpen
                                                        }
                                                    }
                                                    else {
                                                        return copyFaq
                                                    }

                                                })
                                                console.log(copyFaqData);
                                                setFaqData(copyFaqData)
                                            }}
                                                className="p-3 w-full  flex gap-3 items-center bg-details-heading-bg justify-between text-xl font-bold border-b "
                                            >
                                                <div className="flex gap-6">
                                                    <div className={"text-[12px] sm:text-[18px] text-justify flex-grow"}>{faq.question}</div>
                                                </div>
                                                <div>
                                                    <button>
                                                        {!faq.isOpen ? <Add /> : <Remove />}
                                                    </button>
                                                </div>
                                            </button>
                                            {faq.isOpen && (
                                                <div className={`${true ? 'bg-white' : ''} prop_container flex gap-4 p-4 flex-wrap`}>
                                                    <div className="font-medium">{faq.answer}</div>
                                                </div>
                                            )}
                                        </div>
                                    </>)
                                })
                            }

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    )
}
