import React, { useCallback, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import DivContainer from '../../ui/DivContainer';
import {
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import { useSelector } from 'react-redux';
import BlueGradientButton from '../../ui/BlueGradientButton';
import ModalOverlay from '../../components/common/ModalOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCloud, faCloudArrowDown, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function UploadAttendeesCSV({ closeModal, showUploadModal, fetchClaimers, certId }) {
    const appCtx = useSelector((state) => state.app);
    const [csvFile, setCsvFile] = useState(null);
    const [userArray, setUserArray] = useState([]);
    const [errorArray, setErrorArray] = useState([]);
    const csvInputRef = useRef(null);
    const validFileContent = ['Email', 'Name'];
    const dark = appCtx.isDarkMode;
    const { id } = useParams();
    console.log(certId);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: dark ? '#282c34' : 'white',
        border: '2px solid #000',
        boxShadow: 24,
        maxWidth:"80%",
        p: 4,
        color: dark ? appCtx.darkText : appCtx.lightText
    };
    const handleUploadCsv = useCallback((file) => {
        if (file) {
            let e = [];
            setCsvFile(file);
            setUserArray([]);
            const objectUrl = URL.createObjectURL(file);

            const reader = new FileReader();

            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });
                let parsedData = csv?.data;
                parsedData = parsedData?.filter((user) => !(user['Email'] === ''));
                let columns = Object.keys(parsedData[0]);
                console.log(columns);
                columns = columns.map((col) => col.trim());
                console.log(columns);
                if (JSON.stringify(columns) === JSON.stringify(validFileContent)) {
                    parsedData.map((user, i) => {
                        if (user['Name'] === '') {
                            e = [...e, { line: i + 1, issue: 'Name' }];
                        }
                        if (user['Email'] === '') {
                            e = [...e, { line: i + 1, issue: 'Email' }];
                        }
                    });
                    setUserArray(parsedData);
                    toast.success('Valid file format.');
                    setErrorArray(e);
                } else {
                    toast.error('invalid file, proper format is expected.');
                    setErrorArray([]);
                    setUserArray([]);
                }
            };
            reader.readAsText(file);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const uploadClaimers = useCallback(() => {
        const data = userArray.map((user) => {
            return {
                certId: certId,
                claimed: false,
                email: user['Email'],
                eventId: +id,
                gasFeeWaived: false,
                id: null,
                name: user['Name'],
                orgId: appCtx.marketplaceId
            };
        });

        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/cert/nft/claimers`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
            data: data
        };

        axios(config)
            .then((res) => {
                toast.success('List uploaded successfully');
                fetchClaimers(certId);
                closeModal();
            })
            .catch((rej) => {
                toast.error('List could not be uploaded');
            })
            .finally(() => {});
    }, [userArray, certId]);
    
    const handleDownload = (event) => {
        event.preventDefault();
    
        const a = document.createElement('a');
        a.href = "https://nftverse-asset.s3.ap-south-1.amazonaws.com/attendees-template.csv";
        a.download = 'csv-format.csv';
        document.body.appendChild(a);
        a.click();
      };

    return (
        <ModalOverlay
            open={showUploadModal}
            onClose={closeModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            
                <div className="flex flex-col gap-3">
                    <div className="flex justify-end">
                        <button onClick={closeModal}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                    <div className="flex  flex-col md:flex-row md:justify-evenly gap-3 min-h-[300px]">
                        <div className="flex  flex-col justify-start gap-10 max-w-sm ">
                            {/* <div className='flex flex-col gap-2'> */}
                            <div className="flex justify-between items-center">
                                <span className="font-bold text-lg">Upload CSV file </span>
                                <BlueGradientButton className="p-2 text-xs" onClick={handleDownload} >Download template</BlueGradientButton>
                            </div>
                            <div className=" text-sm">
                                Download the template file and fill in the required Information of your Guest List &
                                Upload it.
                            </div>
                            {/* </div> */}
                            <div className='flex flex-col gap-5'>
                                <input
                                    type="file"
                                    ref={csvInputRef}
                                    onInput={(e) => handleUploadCsv(e.target.files[0])}
                                    className="hidden"
                                    accept=".csv"
                                />
                                <div>
                                    <span className="font-semibold">Selected file : </span>{' '}
                                    <span className="font-bold">{csvFile?.name || 'no file selected'}</span>
                                </div>
                                <DivContainer
                                    className="flex gap-3 items-center cursor-pointer py-8  justify-center !border-2 !border-slate-300"
                                    onClick={() => csvInputRef.current.click()}
                                >
                                    <span><FontAwesomeIcon icon={faCloudArrowUp} fontSize={30}/></span>
                                    <span>Select a csv file</span>
                                    
                                </DivContainer>
                            </div>
                        </div>
                        <div className="flex items-center flex-col w-96 min-h-[400px] dark-border light-border  p-3">
                            {userArray?.length > 0 && (
                                <div className="flex flex-col gap-5">
                                    <span className="font-bold">
                                        Attendees List :{' '}
                                        <span className="font-semibold">Total {userArray.length} Attendees</span>
                                    </span>
                                    <DivContainer className="max-h-96 overflow-y-scroll ">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: dark && 'white' }}>Sr no.</TableCell>
                                                    <TableCell sx={{ color: dark && 'white' }}>Name</TableCell>
                                                    <TableCell sx={{ color: dark && 'white' }}>Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userArray?.map((user, index) => {
                                                    return (
                                                        <TableRow key={user['Email']}>
                                                            <TableCell sx={{ color: dark && 'white' }}>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell sx={{ color: dark && 'white' }}>
                                                                <span>{user.Name}</span>
                                                            </TableCell>
                                                            <TableCell sx={{ color: dark && 'white' , wordBreak:"break-word"}}>
                                                               {user.Email}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </DivContainer>
                                    <div className="flex justify-center">
                                        <BlueGradientButton className="px-14" onClick={uploadClaimers}>Upload</BlueGradientButton>
                                    </div>
                                </div>
                            )}  {
                                csvFile && userArray?.length === 0 && <span className='font-semibold'>No data to read from selected csv</span>
                            }
                        </div>
                    </div>
                </div>
            
        </ModalOverlay>
    );
}

export default UploadAttendeesCSV;
