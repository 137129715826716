import axios from 'axios';  
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';

function useFetchEventAttendees() {
    const appCtx = useSelector(state => state.app)
    const [attendees,setAttendees] = useState([]);
    const [loading,setLoading] = useState(true);
    const [isFound,setIsFound] = useState(true);

    const fetchAttendees = useCallback((eventId)=>{
        console.log(undefined)
        setLoading(true);

      const  config={
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx.marketplaceId}/event/${eventId}/attendees`,
            method:"get",
            headers:{
                "X-Auth-token":appCtx.authToken
            }
        }

        axios(config)
        .then((res) =>{
            setAttendees(res?.data?.content)
        })
        .catch((rej) =>{
            setIsFound(false);
        })
        .finally(()=>{
            setLoading(false);
        })
    },[attendees,loading,isFound,appCtx])

  return {
    loading,
    isFound,
    attendees,
    fetchAttendees
  }
}

export default useFetchEventAttendees