import React from 'react'

function LandingButton({onClick,className,disabled,...props}) {
    const border = {
        right: 'r-',
        left: 'l-',
        bottom: 'b-',
        top: 't-'
    };
  return (
    <button
    disabled={disabled}
    onClick={onClick}
    className={` rounded-${
        props.border != null && border[props.border] 
    } bg-gradient-to-r from-landingButtonGrad1 to-landingButtonGrad2 px-12 rounded-full py-2 text-white ${className}`}
    >
        {props.children}
    </button>
  )
}

export default LandingButton