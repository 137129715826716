import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from '../../Input';
import { useSelector } from 'react-redux';
import useFetchMarketplaceCollections from '../../../hooks/collection/useFetchMarketplaceCollections';
import BlueGradientButton from '../../BlueGradientButton';
import CreateCollectionModal from '../../../components/mint-asset/CreateCollectionModal';
import { RefreshOutlined } from '@mui/icons-material';
import Papa from "papaparse";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';

function LaunchPadUpload({setAssetsArray,setErrorArray,selectedCollection,setSelectedCollection}) {
    
    
    const appCtx = useSelector(state => state.app)
    const [openCollectionModal,setOpenCollectionModal ] = useState(false);
    const csvInputRef = useRef();
    const [nftPreview, setNftPreview] = useState();
    const [csvFile, setCsvFile] = useState(null);
    const errorMap = new Map();
    const [collections,setCollections] = useState([]);
    const makeAuthorizedRequest = useAuthorizedHttp();
    
    const fetchCollections = useCallback(()=>{
        if(appCtx.marketplaceId){
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/list`
                },
                (data) => {
                   setCollections(data.content)
                   setSelectedCollection(data?.content[0].name)
                })
        }
    },[makeAuthorizedRequest])

    useEffect(()=>{
        console.log("in use effect")
        fetchCollections();
    },[appCtx.marketplcaeId,fetchCollections])
    
    const validFileContent = ['Asset Image', 'Asset Name', 'Asset Descriptions', 'Asset Property', 'Display Image']



    function isValidHttpUrl(string) {
        let url;
        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      }


    const handleUploadCsv = useCallback((file) => {
        if (file) {
            let e =[];
            setCsvFile(file);
            setAssetsArray([]);
            const objectUrl = URL.createObjectURL(file);
            
            const reader = new FileReader();

            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });
                let parsedData = csv?.data;
                parsedData = parsedData?.filter(asset => !(asset["Asset Image"] ==="" && (asset["Asset Name"] === undefined || asset["Asset Name"] === "" )))
                let columns = Object.keys(parsedData[0]);
                console.log(columns)
                columns = columns.map(col => col.trim())
                console.log(columns)
                if(JSON.stringify(columns) === JSON.stringify(validFileContent))
                {
                    console.log(parsedData)
                    parsedData.map((asset,i) =>{
                        if(!isValidHttpUrl(asset['Asset Image'])){
                            errorMap.set(i+1,"url")
                            e = [...e,{line:i+1,issue:"Asset Image"}]
                        }

                        if(asset["Asset Name"] === "" ){
                            errorMap.set(i+1,"name")
                            // setErrorArray(prev => [...prev,{line:i+1,issue:"name"}])
                            e = [...e,{line:i+1,issue:"Asset Name"}]
                        }
                        try{
                            JSON.parse(asset["Asset Property"])
                        }catch(error){
                            e = [...e,{line:i+1,issue:"Asset Property"}]
                        }
                    })
                    setAssetsArray(parsedData)
                    toast.success("Valid file format.")
                    setErrorArray(e)
                    
                }
                else{
                    toast.error("invalid file, proper format is expected.")
                    setErrorArray([]);
                    setAssetsArray([])
                }
                // console.log(parsedData);
                // let dataArray = [];
                // parsedData.map((data) => {
                //     dataArray = [...dataArray, data.Email];
                // });
                
                // console.log(dataArray)
            };
            reader.readAsText(file);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleDownload = (event) => {
        event.preventDefault();
    
        const a = document.createElement('a');
        a.href = "https://nftverse-dashboard-assets.s3.ap-south-1.amazonaws.com/NFTVerse-Bulk-Mint-Template.csv";
        a.download = 'csv-format.csv';
        document.body.appendChild(a);
        a.click();
      };
    

    return (
        <div className="flex flex-col gap-8">
            <div className="flex justify-between items-center">
                <span className="font-bold">Upload CSV file</span>
                {/* <button className="text-blue-600">Download template</button>
                <a href='/csv-format.csv' download>Click to download</a> */}
                {/* <Link className='no-underline' to='/csv-format.csv' target="_blank" download>Download template</Link> */}
                <button className='text-blue-600' onClick={handleDownload}>Download template</button>

            </div>

            <div className="flex flex-col gap-3">
                <span className='flex gap-2 text-sm'>
                  <span className='font-bold'>Disclaimer: </span>  <span>Properties are expected to be filled in JSON </span>
                </span>
                <span className="text-sm">
                    Download the template file and fill in your NFT information before uploading. <br/>
                    Supported file type: CSV. 
                </span>
            </div>
            <div className="flex flex-col gap-4">
                <input
                    type="file"
                    accept=".csv"
                    ref={csvInputRef}
                    className="hidden"
                    onInput={(e) => handleUploadCsv(e.target.files[0])}
                />
                <div className='font-bold text-sm text-center'>{csvFile?.name}</div>
                <BlueGradientButton onClick={() => csvInputRef.current.click()}>Select csv file</BlueGradientButton>
                
            </div>
            <div className="flex flex-col gap-5 p-5  shadow-md rounded-md">
                <div className="flex flex-col items-start w-full ">
                    <span className="font-bold">Wallet address</span>
                    <div className="w-full">
                        <Input className="w-full" value={appCtx.walletDetails.address} disabled={true} />
                    </div>
                </div>
                <div className="flex flex-col items-start w-full ">
                    <span className="font-bold">Choose collection</span>
                    <div className="w-full flex items-center gap-3">
                        {collections?.length > 0 ? (
                            <select
                                value={selectedCollection}
                                onChange={(e) => setSelectedCollection(e.target.value)}
                                className="py-2 w-full rounded-md dark:text-black"
                            >
                                {collections?.map((collection) => {
                                    return (
                                        <option key={collection.name} value={collection.name}>
                                            {collection.name}
                                        </option>
                                    );
                                })}
                            </select>
                        ) : (
                            <div>You don't have collection .To proceed further create one</div>
                        )}
                        <button onClick={fetchCollections}>
                            <RefreshOutlined />
                        </button>
                        <BlueGradientButton className="py-1" onClick={() => setOpenCollectionModal(true)}>
                            Add
                        </BlueGradientButton>
                        {openCollectionModal && (
                            <CreateCollectionModal onCloseCreateCollection={() => setOpenCollectionModal(false)} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LaunchPadUpload;
