import { Cancel } from '@mui/icons-material';
import React, { useRef, useState, useCallback } from 'react';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { toast } from 'react-toastify';
import Input from '../../ui/Input';
import ModalOverlay from './ModalOverlay';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CloseRounded } from '@mui/icons-material';
import useAuthorizedHttp from '../../hooks/use-authorized-http';

function CreateCategoryModal({ setOpenCatModal, marketplaceId, loadCategories,searchCategory,searchKey,suggestedCats ,setSearchKey}) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const makeRequest = useAuthorizedHttp();
    const fileRef = useRef();
    const bannerFileRef = useRef();
    const [collectionLogo, setCollectionLogo] = useState();
    const [logoPreview, setLogoPreview] = useState();
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [collectionBanner, setCollectionBanner] = useState();
    const [bannerPreview, setBannerPreview] = useState();
    const [uploadingBanner, setUploadingBanner] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [logoUrl, setLogoUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [showUploadLogoButton, setShowUploadLogoButton] = useState(true);
    const [showUploadBannerButton, setShowUploadBannerButton] = useState(true);
    const [disableInputs,setDiasbleInputs] = useState(false);
    const [selectedSuggestionCategoryId,setSelectedSuggestionCategoryId] = useState();

    const handleSaveClick = useCallback(() => {
        if (!searchKey || searchKey.trim() === '') {
            toast.error("Category name can't be empty!");
            return;
        }
        setLoading(true);
        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/category/master/save`,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken,
                'X-App-Token': process.env.REACT_APP_APP_TOKEN
            },
            data: {
                category: searchKey,
                description: description,
                icon: logoUrl,
                banner: bannerUrl
            }
        };

        axios(config)
            .then((res) => {
               
                return res;
            })
            .then((response) => {
                
                let config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/category/save`,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken,
                        'X-App-Token': process.env.REACT_APP_APP_TOKEN
                    },
                    data: 
                        [{categoryId: response.data.categoryId,
                         marketplaceId:marketplaceId}]
                    
                };
                axios(config)
                    .then((res) => {
                        loadCategories()
                        setOpenCatModal(false)
                        toast.success("Category added successfully")

                    })
                    .catch((err) => {
                        toast.error("Could not be Completed");
                    });
            })
            .catch((err) => {
                
                toast.error("Could not Completed")
            });

    }, [makeRequest, searchKey, logoUrl, bannerUrl, description]);


     const populateSugg = useCallback((cat)=>{
        setSelectedSuggestionCategoryId(cat.categoryId)
        setDiasbleInputs(true);
        
        setBannerUrl(cat.banner);
        setDescription(cat.description)
        setBannerPreview(cat.banner);
        setLogoPreview(cat.icon);
        setSearchKey(cat.category);
    },[])
    const saveSuggestedCategory = useCallback(()=>{
        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/category/save`,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken,
                'X-App-Token': process.env.REACT_APP_APP_TOKEN
            },
            data: 
                {categoryId: selectedSuggestionCategoryId,
                 marketplaceId}
            
        };
        axios(config)
            .then((res) => {
                loadCategories()
                setOpenCatModal(false)
                toast.success("Category added successfully")

            })
            .catch((err) => {
                toast.error("Could not be Completed");
            });
    },[marketplaceId,selectedSuggestionCategoryId])

    

    const handleLogoChange = useCallback((file) => {
        if (file) {
            
            setCollectionLogo(file);
            const objectUrl = URL.createObjectURL(file);
            setLogoPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUpload = () => {
        
        let flag = true;
        if (!collectionLogo) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            setUploadingLogo(true);

            const data = new FormData();
            data.append('file', collectionLogo);

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${marketplaceId}/web2/file/upload?type=category-icon`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: data
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            setLogoUrl(response.data.fileUrl);
                            const marketplace = {
                                marketplaceId: marketplaceId,
                                collectionLogo: response.data.fileUrl
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                                    method: 'PUT',
                                    data: marketplace
                                },
                              
                                () => {
                                    setUploadingLogo(false);
                                    setShowUploadLogoButton(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error('Uploading icon failed!');
                            setUploadingLogo(false);
                        }),
                {
                    pending: 'Updating your marketplace settings...',
                    success: 'Settings updated successfully!',
                    error: 'Uh-oh! Something went wrong'
                }
            );
        }
    };
    const handleBannerChange = useCallback((file) => {
        if (file) {
            setCollectionBanner(file);
            const objectUrl = URL.createObjectURL(file);
            setBannerPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUploadBanner = () => {
       
        let flag = true;
        if (!collectionBanner) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            setUploadingLogo(true);

            const data = new FormData();
            data.append('file', collectionBanner);

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${marketplaceId}/web2/file/upload?type=category-banner`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: data
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            setBannerUrl(response.data.fileUrl);
                            const marketplace = {
                                marketplaceId: marketplaceId,
                                collectionBanner: response.data.fileUrl
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                                    method: 'PUT',
                                    data: marketplace
                                },
                                
                                () => {
                                    setUploadingBanner(false);
                                    setShowUploadBannerButton(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error('Uploading icon failed!');
                            setUploadingBanner(false);
                        }),
                {
                    pending: 'Updating your marketplace settings...',
                    success: 'Settings updated successfully!',
                    error: 'Uh-oh! Something went wrong'
                }
            );
        }
    };

    return (
        <ModalOverlay>
            <div className="flex flex-col ">
                <div className={'border-b border-b-gray-300 dark:border-b-gray-600 flex justify-end'}>
                    {/* <div className="dark:text-gray-300 text-2xl font-bold text-gray-600">Create a collection</div> */}
                    <button onClick={() => setOpenCatModal(false)} className={'text-rose-500'}>
                        <CloseRounded />
                    </button>
                </div>
                <div className="flex gap-10 ">
                    <div className="flex flex-col gap-1">
                        <Input
                            description={'Display Name'}
                            disabled={disableInputs}
                            placeholder={'Enter category name'}
                            value={searchKey}
                            onChange={(e) => searchCategory(e)}
                            type={'text'}
                        /><div className='flex gap-2 flex-wrap max-w-xs'>{
                           
                            searchKey.length >2 && suggestedCats?.length >0 && suggestedCats?.map((cat)=>{
                                return(<button className='flex px-4 py-2 bg-gray-400 rounded-sm' key={cat.categoryId} onClick ={()=>populateSugg(cat)}>
                                    {cat.category}
                                    
                                </button>
                            )})
                            
                        }
                        </div>
                        <Input
                            disabled={disableInputs}
                            description={'Description'}
                            placeholder={'Enter Description'}
                            value={description}
                            type={'text'}
                            onChange={(e) => setDescription(e.target.value)}
                        />

                        <div className="flex flex-col gap-3 p-3">
                            <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Logo image</div>
                            <div className="flex  gap-3 items-center ">
                                <div
                                    className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700 rounded-full w-[120px] h-[120px] cursor-pointer"
                                    onClick={() => fileRef.current.click()}
                                >
                                    <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center w-[120px] h-[120px] rounded-full transition-all ease-out duration-300">
                                        Browse for icon
                                    </div>
                                    {logoPreview && (
                                        <img
                                            src={`${logoPreview} `}
                                            alt="upload"
                                            className="w-[120px] h-[120px] rounded-full relative bottom-[120px]  overflow-hidden object-cover cursor-pointer"
                                        />
                                    )}
                                </div>

                                <div className="flex  flex-col gap-2 text-[8px]">
                                    <span>Maximum Size : 3MB </span>
                                    <span>Supported Format : JPG,PNG,SVG,GIF</span>
                                    <span>Recommended Size : 400 X 400 px</span>
                                </div>
                                <div className="flex flex-col gap-5 justify-center">
                                    {logoPreview && showUploadLogoButton && !disableInputs && (
                                        <button
                                            disabled={!collectionLogo || uploadingLogo}
                                            onClick={handleUpload}
                                            // onClick={() => fileRef.current.click()}
                                            className={`group bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12 px-8 text-white ${
                                                uploadingLogo && 'bg-gray-700'
                                            }`}
                                        >
                                            Confirm
                                        </button>
                                    )}
                                </div>
                            </div>
                            <input
                            disabled={disableInputs}
                                hidden={true}
                                type="file"
                                ref={fileRef}
                                onChange={(e) => {
                                    handleLogoChange(e.target.files[0]);
                                    setShowUploadLogoButton(true);
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Banner image</div>
                            <button
                                onClick={() => bannerFileRef.current.click()}
                                className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700  w-[350px] h-[100px] rounded-lg mx-auto"
                            >
                                <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center h-full rounded-lg transition-all ease-out duration-300">
                                    Browse for banner
                                </div>
                                {bannerPreview && (
                                    <img
                                        src={bannerPreview}
                                        alt="Banner"
                                        className="object-cover relative bottom-[100px]  w-[350px] h-[100px] z-10  overflow-hidden rounded-lg"
                                    />
                                )}
                            </button>
                            {bannerPreview && (
                                <div className="flex justify-center">
                                    {!uploadingBanner && showUploadBannerButton &&  !disableInputs && (
                                        <button
                                            disabled={!collectionBanner || uploadingBanner}
                                            onClick={handleUploadBanner}
                                            // onClick={() => fileRef.current.click()}
                                            className="group px-20 bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12  text-white"
                                        >
                                            Confirm
                                        </button>
                                    )}
                                </div>
                            )}
                            <input
                                disabled={disableInputs}
                                hidden={true}
                                type="file"
                                ref={bannerFileRef}
                                onChange={(e) => {
                                    handleBannerChange(e.target.files[0]);
                                    setShowUploadBannerButton(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className=" flex flex-col items-center gap-10 mt-10">
                        <div className="relative border-[20px] dark:border-darkBorder border-gray-200 rounded-lg w-[500px]">
                            <div className="w-full h-64 overflow-hidden rounded-lg">
                                <img src={bannerPreview} className="w-full h-full object-cover" />
                            </div>
                            <div className="w-[150px] h-[150px] overflow-hidden rounded-full absolute left-1/2 translate-x-[-50%] translate-y-[-70%] object-cover z-10">
                                <img src={logoPreview} className="w-full h-full object-cover" />
                            </div>
                            <div className="font-bold mt-12 flex flex-col items-center text-2xl pb-3 px-3">
                                <div>{searchKey}</div>
                                <div className="text-center text-base">{description}</div>
                            </div>
                        </div>
                        {!disableInputs ?<BlueGradientButton onClick={handleSaveClick} disabled={loading} className={'w-[60%]'}>
                            Create category
                        </BlueGradientButton> :<BlueGradientButton onClick={saveSuggestedCategory} >Save</BlueGradientButton> }
                    </div>
                </div>
            </div>
        </ModalOverlay>
    );
}
export default CreateCategoryModal;
