import React, { useEffect, useRef, useState } from 'react';
import KeyValuePair from '../../../components/common/KeyValuePair';
import DivContainer from '../../../ui/DivContainer';
import EventDetailCard from '../../../components/events/EventDetailCard';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Input from '../../../ui/Input';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    AddCircle,
    CloudCircle,
    CloudOutlined,
    FileCopyOutlined,
    InsertDriveFile,
    InsertDriveFileOutlined,
    SelectAll,
    Upload
} from '@mui/icons-material';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import ArrayKeyValue from '../../../components/common/ArrayKeyValue';
import useAuthorizedHttp from '../../../hooks/use-authorized-http';
import CreateCollectionModal from '../../../components/mint-asset/CreateCollectionModal';

function CreateNewMembershipCertificate() {
    // const [keyValue, setKeyValue] = useState({});
    const [objectProperties, setObjectProperties] = useState({});
    const [kv, setKV] = useState([]);

    const appCtx = useSelector((state) => state.app);
    const fileRef = useRef(null);
    const inputFileRef = useRef(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [cid, setCid] = useState('');
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [collection, setCollection] = useState([]);
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);
    const extensionRef = useRef('');
    const navigate = useNavigate();

    const uploadNft = useCallback(() => {
        const formData = new FormData();
        formData.append('file', fileRef.current);
        setUploading(true);
        setImgLoading(true);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/file/upload?type=image`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            data: formData
        };
        axios(config)
            .then((res) => {
                console.log(res?.data?.cid);
                setCid(res?.data?.cid);
            })
            .catch((rej) => {
                if (rej?.response?.data?.status === 500) {
                    if (
                        rej?.response?.data?.message ===
                        'Request processing failed; nested exception is java.lang.Exception: Duplicate file from other marketplace'
                    ) {
                        setOpenWarningModal(true);
                        toast.error('Duplicate file from other marketplace');
                    } else {
                        toast.error('Having trouble uploading your asset');
                    }
                } else {
                    toast.error('Having trouble uploading your asset');
                }
            })
            .finally(() => {
                setUploading(false);
                setImgLoading(false);
            });
    }, []);
    const fileChange = useCallback((file) => {
        fileRef.current = file;
        setSelectedFile(file);

        let extension = file.name.split('.');
        extensionRef.current = extension[extension.length - 1];
    }, []);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let flag = true;
            if (!cid) {
                flag = false;
                toast.error('Upload Asset in order to proceed');
                return;
            }
            if (!name) {
                flag = false;
                toast.error('Please enter the name');
            }

            if (flag) {
                setUpdating(true);
                // const collectionConfig = {
                //     url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/save`,
                //     method: 'post',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'X-Auth-Token': appCtx.authToken
                //     },
                //     data: {
                //         name: name?.substring(0, 49)
                //     }
                // };
                // axios(collectionConfig)
                //     .then((response) => {
                const data = {
                    standard:
                        appCtx.blockchain === 'ALGORAND' ? 'ARC69' : appCtx.blockchain === 'BSC' ? 'BEP721' : 'ERC721',
                    name,
                    collection: 'Membership NFT',
                    marketplaceId: appCtx.marketplaceId,
                    description,
                    properties: objectProperties,
                    mimeType: `image/${extensionRef.current}`,
                    image: `ipfs://${cid}`,
                    cid: cid,
                    media_url: `ipfs://${cid}`
                };
                const config = {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data
                };

                axios(config)
                    .then((res) => {
                        navigate(`/membership/submit-membership/${res?.data?.id}?collectionName=Membership NFT`);
                        toast.success('Certificate created successfully');
                    })
                    .catch((rej) => {
                        toast.error(rej?.response?.data?.message?.split(':')[1]);
                    })
                    .finally(() => {
                        setUpdating(false);
                    });
                // })
                // .catch((rej) => {
                //     toast.error('Could not initiate the process.');
                // });
            }
        },
        [cid, name, appCtx.marketplaceId, appCtx.authToken, appCtx.blockchain, description, objectProperties, navigate]
    );
    const handleSaveCollection = useCallback(() => {
        makeAuthorizedRequest({
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/save`,
            method: 'post',
            data: {
                name: 'Membership NFT',
                description: '',
                logo: '',
                cover_image: '',
                symbol: 'Membership NFT',
                categoryId: 1
            }
        });
    }, [makeAuthorizedRequest]);
    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    let collectionPresent = false;
                    data.content.forEach((d) => {
                        if (d.name === 'Membership NFT') {
                            collectionPresent = true;
                        }
                    });
                    if (!collectionPresent) {
                        handleSaveCollection();
                    }
                }
            ),
        [appCtx.marketplaceId, makeAuthorizedRequest]
    );

    useEffect(() => {
        appCtx.marketplaceId && loadCollections();
    }, [appCtx.marketplaceId, loadCollections]);
    return (
        <div className="flex flex-col gap-5">
            {appCtx.marketplaceId && collectionModalOpen && (
                <CreateCollectionModal
                    marketplaceId={appCtx.marketplaceId}
                    onCreateSuccesssful={() => loadCollections()}
                    onCloseCreateCollection={() => setCollectionModalOpen(false)}
                />
            )}
            <DivContainer>
                <span className="font-bold text-lg">Membership NFT Details</span>
            </DivContainer>

            <div className="grid grid-cols-5 gap-5">
                <div className="flex flex-col gap-5 col-span-5 md:col-span-3">
                    <EventDetailCard title="Upload Asset">
                        <div className="flex flex-col gap-3 items-center">
                            <input
                                className="hidden"
                                ref={inputFileRef}
                                type="file"
                                accept="image/*"
                                onChange={(e) => fileChange(e.target.files[0])}
                            />
                            <div
                                onClick={() => inputFileRef.current.click()}
                                className="
                                border-slate-400 w-full
                                flex justify-center
                                gap-2
                                items-center
                                py-5
                                !border-2
                                rounded-md
                            cursor-pointer
                            "
                            >
                                <span className="">
                                    <FontAwesomeIcon icon={faCloudUpload} fontSize={30} />
                                </span>
                                <span>Select File</span>
                            </div>
                            {selectedFile && <span>{selectedFile?.name}</span>}
                            {selectedFile && (
                                <BlueGradientButton disabled={uploading} className="px-14" onClick={uploadNft}>
                                    {uploading ? 'Uploading .....' : 'Upload'}
                                </BlueGradientButton>
                            )}
                        </div>
                    </EventDetailCard>
                    <EventDetailCard title="NFT Name">
                        <Input required value={name} placeholder="NFT name" onChange={(e) => setName(e.target.value)} />
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <Input
                            value={description}
                            placeholder="Description"
                            type="textarea"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        {/* <KeyValuePair keyValue={keyValue} setKeyValue={setKeyValue} /> */}
                        <ArrayKeyValue kv={kv} setKV={setKV} setObjectProperties={setObjectProperties} />
                    </EventDetailCard>
                    <div>
                        <EventDetailCard title="Collection">
                            <div className="flex gap-5 w-[100%]">
                                <Input value="Membership NFT" disabled={true} />
                            </div>
                        </EventDetailCard>
                    </div>
                </div>

                <div className="flex flex-col gap-5 col-span-5 md:col-span-2">
                    {
                        <div className="flex flex-col gap-5 items-center">
                            <div className="flex h-[300px] rounded-md shadow-md w-96 justify-between items-center">
                                {imgLoading ? (
                                    <div className="flex justify-center items-center w-[100%]">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    cid && (
                                        <img
                                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${cid}`}
                                            className="max-h-[300px] min-h-[250px] max-w-[350px] object-contain "
                                            onLoad={() => setImgLoading(false)}
                                            alt="premintnft"
                                        />
                                    )
                                )}
                            </div>
                            <BlueGradientButton
                                disabled={updating}
                                className="px-12"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {updating ? 'Submitting' : 'Submit'}
                            </BlueGradientButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CreateNewMembershipCertificate;
