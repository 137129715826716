import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function useFetchEvents() {
    const appCtx = useSelector(state => state.app)
    const [eventList,setEventList] = useState([]);
    const [loading,setLoading] = useState(true);
    const [isFound,setIsFound] = useState(true);

    const fetchEvents = useCallback(()=>{
        setLoading(true);

      const  config={
            url:`${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/${appCtx?.marketplaceId}/event/list`,
            method:"get",
            headers:{
                "X-Auth-token":appCtx.authToken
            }
        }

        axios(config)
        .then((res) =>{
            setEventList(res?.data?.content)
        })
        .catch((rej) =>{
            setIsFound(false);
        })
        .finally(()=>{
            setLoading(false);
        })

    },[])

    useEffect(()=>{
        fetchEvents();
    },[])
  return {
    eventList,
    isFound,
    loading
  }
}

export default useFetchEvents