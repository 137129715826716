import ModalOverlay from '../common/ModalOverlay';
import axios from 'axios';
import Input from '../../ui/Input';
import { useCallback, useState, useRef, useEffect } from 'react';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { CloseRounded, RefreshSharp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { toast } from 'react-toastify';
import React from 'react';
import Editor from '@monaco-editor/react';
import { nativeToken } from '../../config';

const CreateCollectionModal = (props) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const makeRequest = useAuthorizedHttp();
    const fileRef = useRef();
    const bannerFileRef = useRef();
    const [collectionLogo, setCollectionLogo] = useState();
    const [logoPreview, setLogoPreview] = useState();
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [collectionBanner, setCollectionBanner] = useState();
    const [bannerPreview, setBannerPreview] = useState();
    const [uploadingBanner, setUploadingBanner] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [logoUrl, setLogoUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [symbol, setSymbol] = useState('');
    const [selectedCategory, setSelectedCtaegory] = useState(null);
    const [categories, setCatrgories] = useState([]);
    const [refreshCat, setRefreshCat] = useState(true);
    const makeAuthorizedRequest = useAuthorizedHttp();
    const [showUploadLogoButton, setShowUploadLogoButton] = useState(true);
    const [showUploadBannerButton, setShowUploadBannerButton] = useState(true);

    const [code, setCode] = useState('');
    useEffect(() => {
        setCode(`// SPDX-License-Identifier: MIT
                pragma solidity ^0.8.9;

                import "@openzeppelin/contracts/token/ERC721/ERC721.sol";
                import "@openzeppelin/contracts/token/ERC721/extensions/ERC721URIStorage.sol";
                import "@openzeppelin/contracts/token/ERC721/extensions/ERC721Burnable.sol";
                import "@openzeppelin/contracts/access/Ownable.sol";
                import "@openzeppelin/contracts/utils/Counters.sol";

                contract ${name} is ERC721, ERC721URIStorage, ERC721Burnable, Ownable {
                    using Counters for Counters.Counter;

                    Counters.Counter private _tokenIdCounter;

                    constructor() ERC721(${name}, ${symbol}) {}

                    function safeMint(address to, string memory uri) public onlyOwner {
                        uint256 tokenId = _tokenIdCounter.current();
                        _tokenIdCounter.increment();
                        _safeMint(to, tokenId);
                        _setTokenURI(tokenId, uri);
                    }

                    // The following functions are overrides required by Solidity.

                    function _burn(uint256 tokenId) internal override(ERC721, ERC721URIStorage) {
                        super._burn(tokenId);
                    }

                    function tokenURI(uint256 tokenId)
                    public
                    view
                    override(ERC721, ERC721URIStorage)
                    returns (string memory)
                    {
                        return super.tokenURI(tokenId);
                    }
                }`);
    }, [name, symbol]);

    const handleSaveClick = useCallback(
        (code) => {
            if (!name || name.trim() === '') {
                toast.error("Collection name can't be empty!");
                return;
            }
            if (appCtx?.walletDetails?.balance < 0.1) {
                toast.error(
                    `You don't have enough balance to create collection. Minimum 0.1 ${
                        nativeToken[appCtx.blockchain]
                    } required!`
                );
                return;
            }
            setLoading(true);
            const formattedCode = code?.replace(/\r?\n/g, ' ');
            toast.promise(
                () =>
                    makeRequest(
                        {
                            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/save`,
                            method: 'post',
                            data: {
                                name,
                                description,
                                logo: logoUrl,
                                cover_image: bannerUrl,
                                symbol,
                                categoryId: selectedCategory,
                                contract: appCtx.blockchain === 'ALGORAND' ? '' : formattedCode,
                                blockchain: appCtx.blockchain
                            }
                        },
                        (msg) => {
                            props.onCreateSuccesssful && props.onCreateSuccesssful();
                            props.onCloseCreateCollection && props.onCloseCreateCollection();
                        },
                        () => {
                            props.onCreateSuccesssful && props.onCreateSuccesssful();
                            props.onCloseCreateCollection && props.onCloseCreateCollection();
                            // window.location.reload();
                        },
                        () => {},
                        () => setLoading(false)
                    ),
                {
                    pending: 'Saving your collection...',
                    error: 'There was an error saving your collection',
                    success: 'Your collection was saved successfully!'
                }
            );
        },
        [makeRequest, name, props, logoUrl, bannerUrl, symbol, selectedCategory]
    );

    useEffect(() => {
        if (appCtx?.walletDetails?.balance < 0.1) {
            toast.error(
                `You don't have enough balance to create collection. Minimum 0.1 ${
                    nativeToken[appCtx.blockchain]
                } required!`
            );
        }
    }, []);

    useEffect(() => {
        appCtx.marketplaceId &&
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${appCtx.marketplaceId}/category/list`
                },
                (data) => {
                    // setCollection([{ collectionId: 0.5, name: 'All' }]);
                    setCatrgories([]);
                    setCatrgories((prev) => [...prev, ...data.content]);
                    setSelectedCtaegory(data?.content[0].categoryId);
                },
                () => {}
            );
    }, [makeAuthorizedRequest, appCtx.marketplaceId, refreshCat]);
    const handleLogoChange = useCallback((file) => {
        if (file) {
            setCollectionLogo(file);
            const objectUrl = URL.createObjectURL(file);
            setLogoPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUpload = () => {
        console.log(collectionLogo);
        let flag = true;
        if (!collectionLogo) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            setUploadingLogo(true);

            const data = new FormData();
            data.append('file', collectionLogo);

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-logo`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: data
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            setLogoUrl(response.data.fileUrl);
                            const marketplace = {
                                marketplaceId: appCtx.marketplaceId,
                                collectionLogo: response.data.fileUrl
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                                    method: 'PUT',
                                    data: marketplace
                                },
                                (data) => console.log(data),
                                (data) => console.log(data),
                                () => {
                                    setUploadingLogo(false);
                                    setShowUploadLogoButton(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error('Uploading logo failed!');
                            setUploadingLogo(false);
                        }),
                {
                    pending: 'Updating your marketplace settings...',
                    success: 'Settings updated successfully!',
                    error: 'Uh-oh! Something went wrong'
                }
            );
        }
    };
    const handleBannerChange = useCallback((file) => {
        if (file) {
            setCollectionBanner(file);
            const objectUrl = URL.createObjectURL(file);
            setBannerPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUploadBanner = () => {
        console.log(collectionBanner);
        let flag = true;
        if (!collectionBanner) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            setUploadingLogo(true);

            const data = new FormData();
            data.append('file', collectionBanner);

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=collection-banner`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: data
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            setBannerUrl(response.data.fileUrl);
                            const marketplace = {
                                marketplaceId: appCtx.marketplaceId,
                                collectionBanner: response.data.fileUrl
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
                                    method: 'PUT',
                                    data: marketplace
                                },
                                (data) => console.log(data),
                                (data) => console.log(data),
                                () => {
                                    setUploadingBanner(false);
                                    setShowUploadBannerButton(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error('Uploading logo failed!');
                            setUploadingBanner(false);
                        }),
                {
                    pending: 'Updating your marketplace settings...',
                    success: 'Settings updated successfully!',
                    error: 'Uh-oh! Something went wrong'
                }
            );
        }
    };
    useEffect(() => {
        if (collectionLogo) {
            handleUpload();
        }
    }, [collectionLogo]);
    useEffect(() => {
        if (collectionBanner) {
            handleUploadBanner();
        }
    }, [collectionBanner]);
    return (
        <ModalOverlay>
            <div className="flex flex-col ">
                <div className={'border-b border-b-gray-300 dark:border-b-gray-600 flex justify-end'}>
                    {/* <div className="dark:text-gray-300 text-2xl font-bold text-gray-600">Create a collection</div> */}
                    <button onClick={props.onCloseCreateCollection} className={'text-rose-500'}>
                        <CloseRounded />
                    </button>
                </div>
                <div className="flex gap-10 ">
                    <div className="flex flex-col gap-1">
                        <Input
                            description={'Display Name'}
                            placeholder={'Enter collection name'}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type={'text'}
                        />
                        <Input
                            description={'Symbol'}
                            placeholder={'Enter symbol'}
                            maxLength={8}
                            value={symbol}
                            onChange={(e) => setSymbol(e.target.value.toUpperCase())}
                            type={'text'}
                        />
                        <div className={`flex justify-end text-xs ${symbol.length === 8 && 'text-red-700'}`}>
                            Character limit 8
                        </div>
                        <Input
                            description={'Description'}
                            placeholder={'Enter Description'}
                            value={description}
                            type={'text'}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        {categories?.length > 0 ? (
                            <div className="flex flex-col gap-2">
                                <div className="font-bold">Select category</div>
                                <select
                                    className="bg-inherit cursor-pointer dark:bg-darkPrimary border rounded-md bg-white py-2"
                                    value={selectedCategory}
                                    onChange={(e) => selectedCategory(e.target.value)}
                                >
                                    {categories.map((category) => {
                                        return (
                                            <option
                                                className="bg-inherit  cursor-pointer "
                                                value={category?.categoryId}
                                                key={category?.categoryId}
                                            >
                                                {category?.categoryMaster?.category}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        ) : (
                            <div className="flex gap-3 mt-3">
                                <span>You dont have any category.</span>{' '}
                                <button className="text-blue-400" onClick={() => window.open('/categories')}>
                                    {' '}
                                    Add category.
                                </button>{' '}
                                <button onClick={() => setRefreshCat(!refreshCat)}>
                                    <RefreshSharp />{' '}
                                </button>{' '}
                            </div>
                        )}

                        <div className="flex flex-col gap-3 p-3">
                            <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Logo image</div>
                            <div className="flex  gap-3 items-center ">
                                <div
                                    className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700 rounded-full w-[120px] h-[120px] cursor-pointer"
                                    onClick={() => fileRef.current.click()}
                                >
                                    <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center w-[120px] h-[120px] rounded-full transition-all ease-out duration-300">
                                        Browse for logo
                                    </div>
                                    {logoPreview && (
                                        <img
                                            src={`${logoPreview} `}
                                            alt="upload"
                                            className="w-[120px] h-[120px] rounded-full relative bottom-[120px]  overflow-hidden object-cover cursor-pointer"
                                        />
                                    )}
                                </div>

                                <div className="flex  flex-col gap-2 text-[8px]">
                                    <span>Maximum Size : 3MB </span>
                                    <span>Supported Format : JPG,PNG,SVG,GIF</span>
                                    <span>Recommended Size : 400 X 400 px</span>
                                </div>
                                <div className="flex flex-col gap-5 justify-center">
                                    {logoPreview && showUploadLogoButton && (
                                        <button
                                            disabled={!collectionLogo || uploadingLogo}
                                            onClick={handleUpload}
                                            // onClick={() => fileRef.current.click()}
                                            className={`group bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12 px-8 text-white ${
                                                uploadingLogo && 'bg-gray-700'
                                            }`}
                                        >
                                            Confirm
                                        </button>
                                    )}
                                </div>
                            </div>
                            <input
                                hidden={true}
                                type="file"
                                ref={fileRef}
                                onChange={(e) => {
                                    handleLogoChange(e.target.files[0]);
                                    setShowUploadLogoButton(true);
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Banner image</div>
                            <button
                                onClick={() => bannerFileRef.current.click()}
                                className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700  w-[350px] h-[100px] rounded-lg mx-auto"
                            >
                                <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center h-full rounded-lg transition-all ease-out duration-300">
                                    Browse for banner
                                </div>
                                {bannerPreview && (
                                    <img
                                        src={bannerPreview}
                                        alt="Banner"
                                        className="object-cover relative bottom-[100px]  w-[350px] h-[100px] z-10  overflow-hidden rounded-lg"
                                    />
                                )}
                            </button>
                            {bannerPreview && (
                                <div className="flex justify-center">
                                    {!uploadingBanner && showUploadBannerButton && (
                                        <button
                                            disabled={!collectionBanner || uploadingBanner}
                                            onClick={handleUploadBanner}
                                            // onClick={() => fileRef.current.click()}
                                            className="group px-20 bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12  text-white"
                                        >
                                            Confirm
                                        </button>
                                    )}
                                </div>
                            )}
                            <input
                                hidden={true}
                                type="file"
                                ref={bannerFileRef}
                                onChange={(e) => {
                                    handleBannerChange(e.target.files[0]);
                                    setShowUploadBannerButton(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className=" flex flex-col items-center gap-10 mt-10">
                        <div className="relative border-[20px] dark:border-darkBorder border-gray-200 rounded-lg w-[500px] h-[450px] ">
                            {appCtx?.blockchain === 'ALGORAND' ? (
                                <div>
                                    <div className="w-full h-64 overflow-hidden rounded-lg">
                                        <img src={bannerPreview} className="w-full h-full object-cover" />
                                    </div>
                                    <div className="w-[150px] h-[150px] overflow-hidden rounded-full absolute left-1/2 translate-x-[-50%] translate-y-[-70%] object-cover z-10">
                                        <img src={logoPreview} className="w-full h-full object-cover" />
                                    </div>
                                    <div className="font-bold mt-12 flex flex-col items-center text-2xl pb-3 px-3">
                                        <div>{name}</div>
                                        <div className="text-center text-base">{description}</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-black">
                                    <Editor
                                        height="410px"
                                        width="100%"
                                        theme="vs-dark"
                                        defaultLanguage="javascript"
                                        value={code}
                                        options={{
                                            minimap: {
                                                enabled: false
                                            }
                                        }}
                                        onChange={(value) => setCode(value)}
                                    />
                                </div>
                            )}
                        </div>
                        <BlueGradientButton
                            onClick={() => {
                                handleSaveClick(code);
                            }}
                            disabled={loading}
                            className={'w-[60%]'}
                        >
                            Create collection
                        </BlueGradientButton>
                    </div>
                </div>
            </div>
        </ModalOverlay>
    );
};

export default CreateCollectionModal;
