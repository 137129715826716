import React, { useCallback, useEffect, useState } from 'react';

import ErrorDisplay from '../../components/common/ErrorDisplay';
import NftCard from '../../components/events/NftCard';

import usePremint from '../../hooks/launchpad/usePremint';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useFetchEvents from '../../hooks/events/useFetchEvents';
import useFetchOrgCertificates from '../../hooks/events/useFetchOrgCertificates';
import AssetPulse from '../../components/common/AssetPulse';
import DivContainer from '../../ui/DivContainer';
import EmptyContent from '../../ui/EmptyContent';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useSelector } from 'react-redux';

function POANft() {
    const appCtx = useSelector((state) => state.app);
    const { eventList, isFound, loading } = useFetchEvents();
    const navigate = useNavigate();
    const { eventCert, fetchOrgCets, certLoading, eventCerts } = useFetchOrgCertificates();
    const [selectedEvent, setSelectedEvent] = useState(0);
    const [qrCode, setQrCode] = useState('load');
    // const [nft, setNft] = useState('');
    useEffect(() => {
        setQrCode(`https://api.qrserver.com/v1/create-qr-code/?data=${appCtx.domain}/claim/${selectedEvent}`);
    }, [appCtx.domain, selectedEvent]);
    const handleEventChange = useCallback(
        (e) => {
            console.log(typeof e.target.value);
            setSelectedEvent(+e.target.value);
            if (+e.target.value === 0) {
                navigate('/events/claimers-list');
            } else {
                fetchOrgCets(e.target.value);
            }
        },
        [selectedEvent]
    );
 

    useEffect(() => {
        if (eventList?.length > 0) {
            setSelectedEvent(eventList[0]?.eventId);
            fetchOrgCets(eventList[0]?.eventId);
        }
    }, [eventList]);
    const handleDownload = (event,url) => {
        event.preventDefault();
    
        const a = document.createElement('a');
        a.href = url;
        a.download = "certificate-qrcode";
        document.body.appendChild(a);
        a.click();
      };
    return !loading ? (
        isFound ? (
            eventList?.length > 0 ? (
                <div className="flex flex-col gap-10">
                    <div>
                        <select
                            className="p-3 rounded-md shadow-md text-gray-700"
                            value={selectedEvent}
                            onChange={handleEventChange}
                        >
                            {/* <option value={0}>Select Event </option> */}
                            {eventList?.map((event) => {
                                return (
                                    <option key={event?.eventId} value={event?.eventId}>
                                        {event?.eventName}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {certLoading ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                            <AssetPulse />
                            <AssetPulse />
                            <AssetPulse />
                        </div>
                    ) : eventCert !== null ? (
                        eventCerts?.length > 0 ? (
                            <div className="grid grid-cols-2 md:grid-cols-2 items-center gap-10 content-center">
                                <div className='md:col-span-1'>
                                    <NftCard cert={eventCerts[0]} />
                                </div>
                                
                                <div className='md:col-span-1 flex flex-col items-center gap-5'>
                                    <img src={qrCode} alt="cert-qr" />
                                    
                                        <BlueGradientButton onClick={(event) =>handleDownload(event,qrCode)}  >
                                            Download
                                        </BlueGradientButton>
                                    
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-3 justify-center items-center text-3xl font-semibold">
                                <EmptyContent message="No certificates to show" />
                                <BlueGradientButton className="text-base" onClick={() => navigate(`/events/create-poa/${selectedEvent}`)}> Create certifiacte</BlueGradientButton>
                            </div>
                        )
                    ) : (
                        <ErrorDisplay />
                    )}
                </div>
            ) : (
                <DivContainer>
                    <div className="flex flex-col items-center gap-5">
                        <EmptyContent
                            message="You haven't created any event or uploaded
                        the attendee list create one first to get it appear here."
                        />
                        <BlueGradientButton onClick={() => navigate('/events/create-event')}>
                            Create event
                        </BlueGradientButton>
                    </div>
                </DivContainer>
            )
        ) : (
            <ErrorDisplay />
        )
    ) : (
        <AssetPulse />
    );
    // const { orgCerts } = useFetchOrgAllCerts();
    // const { premintData: orgCerts } = usePremint();
    // return orgCerts ? (
    //     orgCerts?.length > 0 ? (
    //         <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
    //             {orgCerts?.map((cert) => {
    //                 return (
    //                     <div key={cert.certId}>
    //                         <NftCard cert={cert} />
    //                     </div>
    //                 );
    //             })}
    //         </div>
    //     ) : (
    //         <div className='flex justify-center items-center text-3xl font-semibold'>No certificates to show</div>
    //     )
    // ) : (
    //     <ErrorDisplay />
    // );
}

export default POANft;
