import React, { useEffect, useState } from 'react'
import useAuthorizedHttp from '../hooks/use-authorized-http';

function RetryMint(props) {
    const makeRequest = useAuthorizedHttp();
    const [content,setContent] = useState(null);
    const [metaData,setMetData] = useState(null)
    useEffect(()=>{
        console.log()
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${props.marketplaceId}/nft/${props.nftId}/detail`
            },
            (res) => {
                console.log(res)
                setContent(res);
                setMetData(JSON.parse(res?.metaData));
            }
        );
        
    },[])
  return (
    <div >

    </div>
  )
}

export default RetryMint