import React from "react"

const NewsItem = (props) => {
	const n = props.news;

	return (
		<button
			onClick={() => window.open(n.url)}
			className="group h-[330px] bg-slate-300 dark:bg-gray-700 rounded-lg flex flex-col hover:-translate-y-2 transition-all ease-out duration-300 hover:shadow-xl"
		>
			<div
				className={`h-[200px] rounded-t-lg ${
					n.image ? "bg-cover bg-no-repeat" : "bg-gradient-to-r from-cyan-500 to-blue-500"
				}`}
				style={{ backgroundImage: `url(${n.image ? n.image.thumbnail.contentUrl : ""})` }}
			>
				<div className="opacity-0 group-hover:opacity-100 transition-all ease-out duration-300 rounded-t-lg bg-slate-50/70 h-[100%] text-gray-800 text-lg text-left p-2">
					{n.description}
				</div>
			</div>
			<div className="px-5 py-2 grid grow">
				<div className="text-xl dark:text-gray-100 font-light text-left">{n.name}</div>
				<div className="flex justify-between items-center">
					<div className="flex flex-row gap-3 max-w-[80%] overflow-hidden">
						{n.provider[0].image && (
							<img width={25} src={n.provider[0].image.thumbnail.contentUrl} alt="P" />
						)}
						<div className="dark:text-gray-400">{n.provider[0].name}</div>
					</div>
					<div className="text-right">
						{new Date(n.datePublished).toLocaleDateString("en-IN", {
							weekday: "long",
							month: "short",
							day: "numeric",
						})}
					</div>
				</div>
			</div>
		</button>
	);
};

export default NewsItem;
