import React from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../../context/app-slice';
import Working from '../../components/landing/Working';
import LandingButton from '../../ui/buttons/LandingButton';

function MarketplaceOrStorefront() {
    const dispatch = useDispatch();
    return (
        <div className="flex flex-col gap-24">
            <div
                className="grid bg-cover grid-cols-1 md:grid-cols-2  h-screen bg-no-repeat"
                style={{ backgroundImage: `url(/images/nftsf/1.svg)` }}
            >
                <div className=" justify-center md:justify-end flex">
                    <div className="flex flex-col justify-center gap-5 pl-20">
                        <div className="font-semibold text-4xl">Personal Nft storefornt for influencers and celebreties</div>
                        <div className="text-xl">
                            Create your own custom Web 3.0 app, NFT store, or Marketplace. Turn your digital assets into
                            a Non-Fungible Token(NFT)
                        </div>
                        <div className="flex justify-start">
                            <LandingButton onClick={() => dispatch(appActions.setCreateAccountModal(true))}>
                                Get Started
                            </LandingButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-10 px-20">
                <div className="text-center text-semibold text-4xl">Features</div>
                <div className=" px-10">
                    <img src="/images/nftsf/2.svg" />
                </div>
            </div>
            <Working />
        </div>
    );
}

export default MarketplaceOrStorefront;
