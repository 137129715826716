
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from '../../context/app-slice';
import BlueGradientButton from '../../ui/BlueGradientButton';
import './QrCodeGenerator.css';

function QrCodeGenerator() {
  const [qrCode, setQrCode] = useState("load");
  const [nft, setNft] = useState('');
  const dispatch = useDispatch();
  const appCtx = useSelector((state) => state.app)
  const nftList = appCtx.nftList
  // Changing the URL only when the user
  // changes the input
  useEffect(() => {
    setQrCode
      (`http://api.qrserver.com/v1/create-qr-code/?data=${appCtx.domain + "/claim?cid=" + nft}`);
  }, [appCtx.domain, nft]);

  return (
    <div className="App flex flex-col justify-center items-center text-black dark:text-white">
      <h1 className='mb-[20px]'>QR Code</h1>
      {/* <div className='w-[66%] text-right text-black dark:text-white'>NFT List</div> */}

      <div className='w-[80%] flex  justify-end'>
        <div className='flex flex-col gap-2'>
          <div className='font-bold text-left'>
            Select NFT
          </div>
          <select className='bg-inherit cursor-pointer dark:bg-darkPrimary border rounded-md bg-white p-[10px] w-[150px] ' value={nft}
            onChange={(e) => {
              console.log(e.target.value);
              setQrCode('');
              setNft(e.target.value);
              dispatch(appActions.setNftId(nft))
            }}>
            {
              appCtx.nftList?.length === 0 ?
                <option className='bg-inherit  cursor-pointer ' value={''} key={''}>
                  No NFT uploaded
                </option>
                :
                <>
                  <option className='bg-inherit  cursor-pointer ' value={''} key={''}>
                    Select NFT
                  </option>
                  {nftList?.map((nft, index) => {
                    if (index < nftList?.length && nftList[index]?.cid !== nftList[index + 1]?.cid) {
                      // if (index < nftList?.length) {
                        console.log(index,nftList.length);
                        const metaData = JSON.parse(nft?.metaData);

                        return (
                          <option className='bg-inherit cursor-pointer' value={nft?.cid} key={nft?.cid}>
                            {metaData?.name}
                          </option>
                        );
                      // }
                    }
                  })}
                </>
            }
          </select>
        </div>
      </div>
      <div className="output-box  gap-20">
        {/* {qrCode !== 'load' ?
          <> */}
        {nft !== '' ?
          <>
            <img src={qrCode} alt="" />
            <a href={qrCode} download="QRCode ">
              <BlueGradientButton className="mt-[20px]" type="button">Download</BlueGradientButton>
            </a>
          </>
          :
          <div>Select NFT to generate Qr Code</div>
        }

        {/* </>
          :
          <CircularProgress />
        } */}
      </div>
    </div>
  );
}

export default QrCodeGenerator;