import React from "react"

const CoinPulse = (props) => {
	return (
		<div className="flex items-center gap-5 border border-gray-400/30 rounded p-3 animate-pulse">
			<div className="h-[10px] w-[20px] bg-gray-400/30 rounded-xl"></div>
			<div className="h-[50px] w-[50px] bg-gray-400/30 rounded-full"></div>
			<div className="flex-grow flex justify-between">
				<div className="grid w-full gap-5">
					<div className="w-[80%] h-[10px] bg-gray-400/30 rounded-xl"></div>
					<div className="w-[50%] h-[10px] bg-gray-400/30 rounded-xl"></div>
				</div>
			</div>
		</div>
	);
};

export default CoinPulse;
