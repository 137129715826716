import React, { useCallback, useRef, useState } from 'react';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import useCreateEvent from '../../hooks/events/useCreateEvent';
import { title } from 'process';
import RichTextEditor from '../common/RichTextEditor';
import { CircularProgress } from '@mui/material';

function CreateEventForm({setSelectedFilePreview,selectedFilePreview,hideCreateElements,id}) {
    const {
        submitForm,
        formRef,
        selectedEventType,
        setSelectedEventType,
        inputBannerRef,
        handleFileChange,
        selectedFile,
        setTitle,
        setDescription,
        setEventLocation,
        setEmail,
        setStartTime,
        setEndTime,
        setPhone,
        setEventVenue,
        title,
        description,
        eventLocation,
        startTime,
        endTime,
        email,phone,
        eventVenue,
        updating
        
      } = useCreateEvent({setSelectedFilePreview,selectedFilePreview,hideCreateElements,id});

    //   const date = new Date();
    //   console.log(new Date(startTime))
    return (
        <form className="flex flex-col gap-5 " onSubmit={submitForm} ref={formRef}>
            {/* <select
                className="p-3 rounded-md text-gray-700 border outline-none focus:border-secPurple dark:border-gray-700 dark:focus:border-white outline-[0.5px]"
                required
                value={selectedEventType}
                onChange={(e) => setSelectedEventType(e.target.value)}
            >
                <option>Please select event type</option>
                <option>Business</option>
                <option>Reunion</option>
                <option>Educational Purpose.</option>
                <option>Reunion</option>
                <option>Wedding</option>
                <option>Holiday Part.</option>
                <option>Show/Performance</option>
                <option>Any other</option>
            </select> */}
            <Input
                description="Enter Event Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                name="eventTitle"
                placeholder="Event Title"
            />
            <div className='flex flex-col gap-1'>
            <span className='text-base dark:text-gray-400 font-bold'>
                 Event Description
            </span>

            <RichTextEditor value={description} onChange={(e) => setDescription(e)} />
            </div>
            
            {/* <Input
                required
                type="textarea"
                description="Enter Event Description"
                name="eventDescription"
                placeholder="Event Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            /> */}
            <input ref={inputBannerRef} onInput={handleFileChange} className="hidden" type="file" accept="image/*" />
            <div className="flex flex-col justify-start gap-2">
                <div className="flex gap-2">
                    {' '}
                    <span className="font-bold">Selected File: </span> <span>{selectedFile?.name || 'none'}</span>
                </div>
                <div
                    className=" border border-gray-300 dark:border-gray-700 rounded-md cursor-pointer p-4 flex justify-center items-center gap-5"
                    onClick={(e) => {
                        e.preventDefault();
                        inputBannerRef.current.click();
                    }}
                >
                    <FontAwesomeIcon icon={faCloudUpload} fontSize={30} />
                    <span>Select Banner image</span>
                </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between">
                <div className="flex flex-col gap-3">
                    {/* <label className="font-bold">Start time</label> */}
                    <Input
                        className="text-black p-2 rounded-md"
                        description="Start time"
                        name="startDate"
                        required
                        value={startTime}
                        type="datetime-local"
                        onChange={(e) => setStartTime(e.target.value)}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {/* <label className="font-bold">End time</label> */}
                    <Input
                        className="text-black p-2 rounded-md"
                        description="End time"
                        required
                        name="endtDate"
                        type="datetime-local"
                        value={endTime}
                        onChange={(e) => {
                            console.log( typeof e.target.value,e.target.value)
                            setEndTime(e.target.value)}}
                    />
                </div>
            </div>
            <div className="flex justify-between">
                <Input
                    description="Enter Event Venue"
                    type="textarea"
                    name="eventLocation"
                    placeholder="Event venue"
                    value={eventVenue}
                    onChange={(e) => setEventVenue(e.target.value)}
                />
                <Input
                    description="Enter Event Location"
                    name="eventLocation"
                    placeholder="longitude,latitude"
                    value={eventLocation}
                    onChange={(e) => setEventLocation(e.target.value)}
                />
            </div>

            <div className="flex flex-col md:flex-row justify-between items-center w-full md:items-end">
                <Input
                    className=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    description="Contact Details"
                    placeholder="E-mail"
                    name="eventMail"
                />
                <Input
                    className=""
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    description=""
                    required
                    placeholder="Contact"
                    name="eventContact"
                />
            </div>
           
            <BlueGradientButton
            disabled={updating}
            type="submit"
            >
            {updating ? <CircularProgress size={16} /> : hideCreateElements ? 'Update Event' : 'Create Event'}
            </BlueGradientButton>
        </form>
    );
}

export default CreateEventForm;
