import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'

function useFetchAllBlogs() {
  const [posts,setPosts] = useState([]);
  const [isFound,setIsFound] = useState(true);
  const [loading,setLoading] = useState(true);
  const allPostRef = useRef([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const titleUrlRef = useRef("");

    const fetchAllPosts = useCallback(()=>{
      setLoading(true);
        let config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/0/blog/post/list?type=POST`,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              'X-App-Token': process.env.REACT_APP_APP_TOKEN
    
          }
        };
        axios(config)
            .then((response) => {
               setPosts(response.data?.content);
               allPostRef.current = response.data?.content
              
              setIsFound(true)
            })
            .catch(()=>{
                setIsFound(false)
            })
            .finally(() => setLoading(false));
    },[loading,posts,isFound])

    useEffect(()=>{
      fetchAllPosts();
    },[])

    const loadSelectedCategoryPost = useCallback((cat)=>{
      setSelectedCategory(cat);

      if(cat==="All"){
          setPosts(allPostRef.current)
      }
      else{
        setPosts(() => allPostRef.current.filter(post =>post.category ===cat))
      }

},[selectedCategory,posts])

  return {
      loading,
      posts,
      isFound,
      titleUrlRef,
      selectedCategory,
      loadSelectedCategoryPost
  };
}

export default useFetchAllBlogs