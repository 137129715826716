import React, { useCallback, useState } from 'react';
import Footer from '../../components/common/Footer';
import UnauthenticatedNavbar from '../navbar/UnauthenticatedNavbar';
import { useSelector } from 'react-redux';
import useFetchAllBlogs from '../../hooks/blogpost/useFetchAllBlogs';
import AssetPulse from '../../components/common/AssetPulse';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import FeaturedBlog from './FeaturedBlog';
import urlForTheString from '../../misc/urlForTheString';

function BlogPosts() {
    const appCtx = useSelector((state) => state.app);
    const { loading, posts, isFound, titleUrlRef ,loadSelectedCategoryPost,selectedCategory} = useFetchAllBlogs();
    const categories = ['All', 'Announcements', 'Community space', 'Insights', 'Features released', 'Success stories'];
    

    

    return !loading ? (
        isFound ? (
            <div className="flex flex-col gap-4 items-center">
                <div className="w-full h-44 flex items-center flex-col gap-3 justify-center bg-[#16022f]">
                    <span className="font-semibold text-3xl">NFTVerse Blog</span>
                    <span className="text-center font-medium">
                        Browse through our blogs about NFTs, partnerships, hot topics, best practices, and actionable
                        insights. Learn more today!
                    </span>
                </div>
                <div className="flex flex-col gap-4 w-11/12 md:w-4/5 ">
                    <div className=" flex gap-6  md:justify-between  border-b border-slate-500  flex-wrap">
                        {categories.map((cat, i) => (
                            <button
                                className={`${cat === selectedCategory && 'border-b-2'} py-2 font-semibold`}
                                key={i}
                                onClick={() => loadSelectedCategoryPost(cat)}
                            >
                                {cat}
                            </button>
                        ))}
                    </div>
                    {/* {
                     posts.length > 0  &&  selectedCategory ==="All" && <FeaturedBlog post={posts[posts.length-1]} />
                    } */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-10 ">
                        {posts.length > 0 ? 
                        
                        (
                            posts.map((post) => {
                                return (
                                    <Link
                                        key={post.postId}
                                        to={`post/${post.postId}/${urlForTheString(post?.title)}`}
                                        className="flex flex-col gap-3 no-underline text-gray-900 bg-slate-200 hover:scale-105  transition-all rounded-md overflow-hidden"
                                    >
                                        <div className="w-full h-56">
                                            <img
                                                src={post.coverImage || '/logo.png'}
                                                className="w-full h-full object-contain"
                                            />
                                        </div>
                                        <div className="px-3 py-1 font-bold overflow-hidden">
                                            <span>{post.title}</span>
                                        </div>
                                        <div className="px-3 py-3">
                                            <span className="overflow-hidden block h-12 w-full text-ellipsis">
                                                {parse(post?.summary || '')}
                                            </span>
                                        </div>
                                        {/* <div className='px-3 text-sm font-light'>
                              Nov 22 , 2022
                            </div> */}
                                    </Link>
                                );
                            })
                        ) : (
                            <div className="flex flex-col">
                                <span className='font-bold text-ce'>Stay tuned we are comming with more exciting blog very soon!!</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <div className="text-center text-3xl py-14">Some error occured !!!!!</div>
        )
    ) : (
        <div className="grid grid-cols-3 gap-6">
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
            <AssetPulse />
        </div>
    );
}

export default BlogPosts;
