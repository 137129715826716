/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Menu } from '@mui/icons-material';
import logoWhite from '../../assets/logo-white.svg';
import NavbarDropdown from './NavbarDropdown';
import BlueButton from '../../ui/BlueButton';
import { useNavigate ,Link as RouterLink } from 'react-router-dom';
import CreateAccountModal from '../landing/CreateAccountModal';
import { useSelector } from 'react-redux';

const   UnauthenticatedNavbar = (props) => {
    const appCtx = useSelector(state => state.app)
    const [isExpanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const openInNewTab = url => {
        window.open(url);
    };

    return (
        <div
            className={`${
                isExpanded ? '' : 'h-[80px]'
            } w-full landing-bg fixed z-20 py-5 top-0  transition-all ease-out duration-300 overflow-y-hidden px-10`}
        >
            <div className="flex flex-col md:flex-row justify-between  ">
                <div className=" flex flex-row justify-between gap-3">
                    <div onClick={() => navigate('/')} className="font-extrabold text-2xl no-underline cursor-pointer">
                        <img src={logoWhite} alt={'NFTVerse'} className={'w-[150px]'} />
                    </div>
                    {/* <div className="">
						<input
							type="text"
							className="h-[45px] lg:w-[500px] w-[230px] border-2 border-slate-400 rounded-xl bg-slate-50 p-4 outline-none text-gray-600"
							placeholder="Search for any NFT"
						/>
					</div> */}
                    <button className="visible md:invisible text-black" onClick={() => setExpanded(!isExpanded)}>
                        <Menu fontSize="large" className="text-neutral-800 dark:text-slate-200" />
                    </button>
                </div>
                <div
                    className={`flex items-center flex-col md:flex-row gap-3 mt-5 md:mt-0 md:gap-10 md:font-semibold dark:text-slate-200 text-gray-900 transition-all ease-out duration-300 `}
                >
                    {/* <Link
                        activeClass="navbar-active-item"
                        to="aboutus"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className={
                            'no-underline cursor-pointer transition-all ease-out duration-200 hover:text-sky-500 text-gray-300'
                        }
                    >
                        About Us
                    </Link>
                    <Link
                        activeClass="navbar-active-item"
                        to="pricing"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className={
                            'no-underline cursor-pointer transition-all ease-out duration-200 hover:text-sky-500 text-gray-300'
                        }
                    >
                        Pricing
                    </Link>
                    <Link
                        activeClass="navbar-active-item"
                        to="#"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className={
                            'no-underline cursor-pointer transition-all ease-out duration-200 hover:text-sky-500 text-gray-300'
                        }
                    >
                        Contact Us
                    </Link> */}
                    {/* <BlueButton onClick={() => navigate('/login')}>Login</BlueButton> */}
                    <button onClick={() => openInNewTab('https://www.onnftverse.com/blog')}>Blog</button>
                    <button onClick={() => window.open('/tutorial')}>Tutorial</button>
                    <button onClick={() => openInNewTab('https://developer.onnftverse.com/')}>API Docs</button>
                    {/* <button onClick={() => navigate('/login')}>Login</button> */}

                    
                </div>
            </div>
        </div>
    );
};

export default UnauthenticatedNavbar;
