import React, { useRef, useState } from 'react';
import KeyValuePair from '../../components/common/KeyValuePair';
import DivContainer from '../../ui/DivContainer';
import EventDetailCard from '../../components/events/EventDetailCard';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Input from '../../ui/Input';
import BlueGradientButton from '../../ui/BlueGradientButton';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Select, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import ArrayKeyValue from '../../components/common/ArrayKeyValue';
import { AddCircle } from '@mui/icons-material';
import CreateCollectionModal from '../../components/mint-asset/CreateCollectionModal';
import { useEffect } from 'react';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { nativeToken } from '../../config';

function CreateRSVP() {
    const { id } = useParams();
    const location = useLocation();
    // const [keyValue, setKeyValue] = useState({});
    const [objectProperties, setObjectProperties] = useState({});
    const [kv, setKV] = useState([]);
    const [uploadbutton, setUploadbutton] = useState(true);
    const [selectedCollection, setSelectedCollection] = useState('select');
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);
    const [collection, setCollection] = useState([]);
    const [ticktName, setTicketName] = useState('');
    const [selectedFee, setSelectedFee] = useState('subsidized');
    const [price, setPrice] = useState(0);
    const appCtx = useSelector((state) => state.app);
    const [priceUnit, setPriceUnit] = useState(nativeToken[appCtx?.blockchain]);

    const fileRef = useRef(null);
    const inputFileRef = useRef(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [cid, setCid] = useState('');
    const [uploading, setUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const extensionRef = useRef('');
    const navigate = useNavigate();
    const makeAuthorizedRequest = useAuthorizedHttp();

    const [selectedItem, setSelectedItem] = useState(null);

    const handleCheckboxChange = (event) => {
        setSelectedItem(event.target.value);
    };

    const uploadNft = useCallback(() => {
        const formData = new FormData();
        formData.append('file', fileRef.current);
        setUploading(true);

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/file/upload?type=image`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            data: formData
        };
        axios(config)
            .then((res) => {
                console.log(res?.data?.cid);
                setCid(res?.data?.cid);
                setUploadbutton(false);
            })
            .catch((rej) => {
                if (rej?.response?.data?.status === 500) {
                    if (
                        rej?.response?.data?.message ===
                        'Request processing failed; nested exception is java.lang.Exception: Duplicate file from other marketplace'
                    ) {
                        setOpenWarningModal(true);
                        toast.error('Duplicate file from other marketplace');
                    } else {
                        toast.error('Having trouble uploading your asset');
                    }
                } else {
                    toast.error('Having trouble uploading your asset');
                }
            })
            .finally(() => {
                setUploading(false);
            });
    }, []);
    const fileChange = useCallback((file) => {
        fileRef.current = file;
        setSelectedFile(file);

        let extension = file.name.split('.');
        extensionRef.current = extension[extension.length - 1];
    }, []);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            let flag = true;
            if (!cid) {
                flag = false;
                toast.error('Upload Asset in order to proceed');
                return;
            }
            if (!name) {
                flag = false;
                toast.error('Please enter the name');
            }

            if (flag && selectedItem !== 'offchain') {
                setUpdating(true);
                const collectionConfig = {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/save`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: {
                        name: name?.substring(0, 49)
                    }
                };
                axios(collectionConfig)
                    .then((response) => {
                        const data = {
                            standard:
                                appCtx.blockchain === 'ALGORAND'
                                    ? 'ARC69'
                                    : appCtx.blockchain === 'BSC'
                                    ? 'BEP721'
                                    : 'ERC721',
                            name,
                            collection: selectedCollection,
                            marketplaceId: appCtx.marketplaceId,
                            description,
                            properties: objectProperties,
                            mimeType: `image/${extensionRef.current}`,
                            image: `ipfs://${cid}`,
                            cid: cid,
                            media_url: `ipfs://${cid}`
                        };
                        const config = {
                            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint`,
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-Auth-Token': appCtx.authToken
                            },
                            data: data
                        };

                        axios(config)
                            .then((res) => {
                                if (location.pathname.includes('create-rsvp')) {
                                    toast.success('RSVP created successfully');
                                    navigate(`/events/create-ticket/${id}/${res?.data?.id}`);
                                } else {
                                    toast.success('Certificate created successfully');
                                    navigate(`/events/create-certificate/${id}/${res?.data?.id}`);
                                }
                            })
                            .catch((rej) => {
                                toast.error(rej?.response?.data?.message?.split(':')[1]);
                            })
                            .finally(() => {
                                setUpdating(false);
                            });
                    })
                    .catch((rej) => {
                        toast.error('Could not initiate the process.');
                    });
            } else {
                if (location.pathname.includes('create-rsvp')) {
                    setUpdating(true);
                    const data = {
                        cid: cid,
                        claimed: false,
                        eventId: +id,
                        premintId: 0,
                        gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                        orgId: appCtx.marketplaceId,
                        price: +price,
                        priceUnit: priceUnit,
                        ticketId: null,
                        ticketName: name
                    };
                    const config = {
                        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/event/nft/ticket`,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Auth-Token': appCtx.authToken
                        },
                        data: data
                    };

                    axios(config)
                        .then((res) => {
                            toast.success('Event Ticket created successfully');
                            navigate(`/events/event-details/${id}`);
                        })
                        .catch((rej) => toast.error('Colud not create event ticket'))
                        .finally(() => setUpdating(false));
                }
            }
        },
        [name, description, cid, objectProperties]
    );

    const loadCollections = useCallback(
        () =>
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([]);
                    data.content.forEach((d) => {
                        setCollection((prev) => [
                            ...prev,
                            {
                                id: d.collectionId,
                                text: d.name
                            }
                        ]);
                    });
                }
            ),
        [makeAuthorizedRequest, appCtx.marketplaceId]
    );

    useEffect(() => {
        appCtx.marketplaceId && loadCollections();
    }, [appCtx.marketplaceId, loadCollections]);
    return (
        <div className="flex flex-col gap-5">
            {location.pathname.includes('create-rsvp') ? (
                <DivContainer>
                    <span className="font-bold text-lg">Event NFT Ticket Details</span>
                </DivContainer>
            ) : (
                <DivContainer>
                    <span className="font-bold text-lg">Certificate NFT Details</span>
                </DivContainer>
            )}
            <div className="grid grid-cols-5 gap-5">
                <div className="flex flex-col gap-5 col-span-5 md:col-span-3">
                    <EventDetailCard title="Upload Asset">
                        <div className="flex flex-col gap-3 items-center">
                            <input
                                className="hidden"
                                ref={inputFileRef}
                                type="file"
                                accept="image/*"
                                onChange={(e) => fileChange(e.target.files[0])}
                            />
                            <div
                                onClick={() => inputFileRef.current.click()}
                                className="
                                border-slate-400 w-full
                                flex justify-center
                                gap-2
                                items-center
                                py-5
                                !border-2
                                rounded-md
                            cursor-pointer
                            "
                            >
                                <span className="">
                                    <FontAwesomeIcon icon={faCloudUpload} fontSize={30} />
                                </span>
                                <span>Select File</span>
                            </div>
                            {selectedFile && <span>{selectedFile?.name}</span>}
                            {selectedFile && uploadbutton && (
                                <BlueGradientButton disabled={uploading} className="px-14" onClick={uploadNft}>
                                    {uploading ? 'Uploading .....' : 'Upload'}
                                </BlueGradientButton>
                            )}
                        </div>
                    </EventDetailCard>
                    <EventDetailCard title="NFT Name">
                        <Input required value={name} placeholder="NFT name" onChange={(e) => setName(e.target.value)} />
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <Input
                            value={description}
                            placeholder="Description"
                            type="textarea"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </EventDetailCard>

                    {location.pathname.includes('create-rsvp') && (
                        <div>
                            <div className="font-bold mb-3">Mint As</div>

                            <label className="ml-3">
                                <input
                                    type="checkbox"
                                    value="nft"
                                    checked={selectedItem === 'nft'}
                                    onChange={handleCheckboxChange}
                                    className="mr-3"
                                />
                                NFT
                            </label>
                            <br />
                            <label className="ml-3">
                                <input
                                    type="checkbox"
                                    value="offchain"
                                    checked={selectedItem === 'offchain'}
                                    onChange={handleCheckboxChange}
                                    className="mr-3"
                                />
                                OFF-CHAIN
                            </label>
                        </div>
                    )}

                    {selectedItem === 'nft' || location.pathname.includes('create-poa') ? (
                        <div>
                            <EventDetailCard title="Collection">
                                <div className="flex gap-5 w-[100%]">
                                    <Select
                                        className="bg-gray-50 outline-none rounded-lg w-[200px]"
                                        sx={{
                                            borderRadius: 2,
                                            height: 40
                                        }}
                                        value={selectedCollection}
                                        onChange={(e) => {
                                            setSelectedCollection(e.target.value);
                                            // sortNfts(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="select" key="">
                                            select
                                        </MenuItem>
                                        {collection?.map((collection) => {
                                            return (
                                                <MenuItem value={collection?.text} key={collection?.id}>
                                                    {collection?.text}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <button
                                        onClick={() => {
                                            setCollectionModalOpen(true);
                                        }}
                                        className={
                                            'items-center flex px-5 gap-1 rounded-md py-1 border bg-secBlue text-white'
                                        }
                                        filled
                                    >
                                        <AddCircle fontSize="small" /> <div>Create new collection </div>
                                    </button>
                                </div>
                            </EventDetailCard>
                        </div>
                    ) : (
                        ''
                    )}

                    {location.pathname.includes('create-rsvp') && selectedItem === 'offchain' && (
                        <>
                            <EventDetailCard title="Ticket Tier">
                                <Input
                                    required
                                    placeholder="Ticket tier"
                                    value={ticktName}
                                    onChange={(e) => setTicketName(e.target.value)}
                                />
                            </EventDetailCard>
                            <EventDetailCard title="Gas Fees">
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-2 items-center">
                                        <input
                                            className="w-5 h-5"
                                            type="radio"
                                            value="subsidized"
                                            checked={selectedFee === 'subsidized'}
                                            onChange={(e) => setSelectedFee(e.target.value)}
                                        />
                                        <span>Subsidized</span>
                                    </div>
                                    {/* <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="charged"
                                    checked={selectedFee === 'charged'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Charge from the guest</span>
                            </div> */}
                                </div>
                            </EventDetailCard>
                            <EventDetailCard title="Price">
                                <div className="flex items-center gap-10">
                                    <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
                                    <select
                                        className="px-4 py-2 rounded-md text-gray-700"
                                        value={priceUnit}
                                        onChange={(e) => setPriceUnit(e.target.value)}
                                    >
                                        <option value={nativeToken[appCtx?.blockchain]}>
                                            {nativeToken[appCtx?.blockchain]}
                                        </option>
                                        <option value="usd">USD</option>
                                    </select>
                                </div>
                            </EventDetailCard>
                        </>
                    )}

                    <EventDetailCard title="Property">
                        {/* <KeyValuePair keyValue={keyValue} setKeyValue={setKeyValue} /> */}
                        <ArrayKeyValue kv={kv} setKV={setKV} setObjectProperties={setObjectProperties} />
                    </EventDetailCard>
                </div>

                <div className=" col-span-5 md:col-span-2">
                    {
                        <div className="flex flex-col gap-5 items-center">
                            <div className="flex h-[300px] rounded-md shadow-md w-96 justify-between items-center">
                                {cid && (
                                    <img
                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${cid}`}
                                        className="max-h-[300px] min-h-[250px] max-w-[350px] object-contain "
                                    />
                                )}
                            </div>
                            <BlueGradientButton
                                disabled={updating}
                                className="px-12"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {updating ? 'Submitting' : 'Submit'}
                            </BlueGradientButton>
                        </div>
                    }
                </div>
            </div>
            {appCtx.marketplaceId && collectionModalOpen && (
                <CreateCollectionModal
                    marketplaceId={appCtx.marketplaceId}
                    onCreateSuccesssful={() => loadCollections()}
                    onCloseCreateCollection={() => setCollectionModalOpen(false)}
                />
            )}
        </div>
    );
}

export default CreateRSVP;
