import BlueButton from '../../ui/BlueButton';
import ThreeDRenderer from './ThreeDRenderer';
import ModalOverlay from './ModalOverlay';

const ThreeDViewOverlay = (props) => {
    return (
        <ModalOverlay>
            <div className={' w-[700px] h-[500px] flex flex-col gap-1 items-end rounded-lg '}>
                <BlueButton onClick={props.onClose} className={'w-fit place-right'} filled>
                    Close
                </BlueButton>
                <ThreeDRenderer className={`w-full h-full`} src={props.url} type={props.type} />
            </div>
        </ModalOverlay>
    );
};

export default ThreeDViewOverlay;
