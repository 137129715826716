import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useHttp from "../../hooks/use-http";
import CoinItem from "./CoinItem";
import CoinPulse from "./CoinPulse";
import React from "react"

const CoinDisplay = (props) => {
	const [coins, setCoins] = useState([]);
	const [offset, setOffset] = useState(0);
	const [maxCoins, setMaxCoins] = useState(20000);
	const makeRequest = useHttp();

	useEffect(() => {
		makeRequest(
			{
				url: `https://coinranking1.p.rapidapi.com/coins?referenceCurrencyUuid=yhjMzLPhuIDl&timePeriod=24h&tiers%5B0%5D=1&orderBy=marketCap&orderDirection=desc&limit=12&offset=${offset}`,
				headers: {
					"X-RapidAPI-Key": "3281daaa4dmsh25e95de531eee53p151389jsn13bf426b2653",
					"X-RapidAPI-Host": "coinranking1.p.rapidapi.com",
				},
			},
			(data) => {
				setCoins(data.data.coins);
				setMaxCoins(data.data.stats.total);
			},
			(data) => {
				toast.error("something went wrong");
			},
			() => {}
		);
	}, [makeRequest, offset]);

	const handleLeftClicked = useCallback(() => {
		setCoins([]);
		setOffset((prev) => prev - 10);
	}, []);

	const handleRightClicked = useCallback(() => {
		setCoins([]);
		setOffset((prev) => prev + 10);
	}, []);

	return (
		<div
			className={`${props.className} dark:text-gray-200 text-gray-600 grid grid-cols-1 gap-y-10`}
		>
			<div className="text-2xl font-semibold">Trending cryptocurrencies</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
				{coins.length === 0 && (
					<div className="grid grid-cols-3 lg:col-span-3 md:col-span-2 col-span-1 gap-10">
						<CoinPulse />
						<CoinPulse />
						<CoinPulse />
						<CoinPulse />
						<CoinPulse />
					</div>
				)}
				{coins.length !== 0 &&
					coins.map((coin) => <CoinItem key={coin.uuid} coin={coin} />)}
			</div>
			{coins.length !== 0 && (
				<div className="flex flex-row mx-auto">
					<button
						disabled={offset === 0}
						onClick={handleLeftClicked}
						className="disabled:bg-gray-500 dark:disabled:bg-zinc-600 rounded-l-xl w-[130px] bg-sky-500 text-slate-50 flex items-center gap-3 p-3 justify-center"
					>
						<KeyboardArrowLeftRounded />
						Previous
					</button>
					<div className="bg-gray-100 dark:bg-zinc-700 w-[60px] h-[50px] flex justify-center items-center text-xl">
						{offset / 10 + 1}
					</div>
					<button
						disabled={offset === maxCoins}
						onClick={handleRightClicked}
						className="disabled:bg-gray-500 dark:disabled:bg-zinc-600 rounded-r-xl bg-sky-500 text-slate-50 flex items-center gap-3 p-3 w-[130px] justify-center"
					>
						Next
						<KeyboardArrowRightRounded />
					</button>
				</div>
			)}
		</div>
	);
};

export default CoinDisplay;
