import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EventDetailCard from '../../../components/events/EventDetailCard';
import DivContainer from '../../../ui/DivContainer';
import Input from '../../../ui/Input';
import BlueGradientButton from '../../../ui/BlueGradientButton';
import useFetchCertCategory from '../../../hooks/events/useFetchCertCategory';
import CreateCategoryModal from '../../../components/events/CreateCategoryModal';
import useFetchPremintData from '../../../hooks/events/useFetchPremintData';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import UploadAttendeesCSV from '../UploadAttendeesCSV';
import useFetchClaimers from '../../../hooks/events/useFetchClaimers';

function SubmitMembership() {
    const { id, premintId } = useParams();

    const [selectedFee, setSelectedFee] = useState('subsidized');
    const [updating, setUpdating] = useState(false);
    const [price, setPrice] = useState();
    const [priceUnit, setPriceUnit] = useState('');

    const navigate = useNavigate();
    const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);
    const { fetchCategories, categories } = useFetchCertCategory();
    const params = new URLSearchParams(window.location.search);
    const [selectedCategory, setSelectedCategory] = useState(params.get('collectionName'));
    const appCtx = useSelector((state) => state.app);
    const { premintContent, premintMetaData } = useFetchPremintData(premintId);
    let certId = premintMetaData?.certId;


    const createCategory = useCallback(() => {
        let flag = false;
        categories?.map((item) => {
            if (item?.category === 'Membership NFT') {
                flag = true;
            }
        })
        const data = {
            category: params.get('collectionName'),
            id: null,
            orgId: appCtx.marketplaceId
        };

        const config = {
            url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/category`,
            method: id ? 'put' : 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': appCtx.authToken
            },
            data: data
        };
        if (!flag) {
            axios(config)
                .then((res) => {

                })
                .catch((rej) => {
                    toast.error('category creation failed')
                })
                .finally(() => {

                });
        }
    }, [appCtx.authToken, appCtx.marketplaceId, id, params]);

    // const handleSubmit = useCallback((e) => {

    //     e.preventDefault();


    // },
    //     [price, priceUnit, selectedFee]
    // );

    const submitForm = useCallback(
        (e) => {
            e.preventDefault();

            let flag = true;
            // if (!selectedCategory) {
            //     flag = false;
            //     toast.warning('please create category to proceed');
            // }

            if (flag) {
                setUpdating(true)
                const data = {
                    category: params.get('collectionName'),
                    certId: certId ? +certId : null,
                    // eventId: +id,
                    gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                    orgId: appCtx.marketplaceId,
                    premintId: +premintId,
                };

                const config = {
                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/org/cert/nft`,
                    method: certId ? "put" : 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': appCtx.authToken
                    },
                    data: data
                };

                axios(config)
                    .then((res1) => {
                        createCategory();
                        // handleSubmit();
                        let flag = true;
                        console.log('====================================');
                        console.log(price, priceUnit);
                        console.log('====================================');
                        if (price || priceUnit !== '') {

                            if (price && priceUnit !== '') {

                            }
                            else {
                                toast.error('You need to enter both price and price unit or its optional')
                                flag = false
                            }
                        }
                        if (flag) {
                            setUpdating(true)

                            const data = {
                                gasFeeWaived: selectedFee === 'subsidized' ? true : false,
                                id: +premintId,
                                price: price,
                                priceUnit: priceUnit
                            };
                            const config = {
                                url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${appCtx.marketplaceId}/blockchain/${appCtx.blockchain}/nft/premint`,
                                method: 'put',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'X-Auth-Token': appCtx.authToken
                                },
                                data: data
                            };

                            axios(config)
                                .then((res) => {
                                    toast.success('Details submitted successfully !!');
                                    navigate(`/membership/details/${premintId}/${params.get('collectionName')}?certId=${res1.data?.certId}`);
                                })
                                .catch((rej) => {
                                    toast('Details submission failed !!!')
                                })
                                .finally(() => {
                                    setUpdating(false)
                                })
                        }
                    })
                    .catch((rej) => toast.error('Could submit details'))
                    .finally(() => setUpdating(false))
            }
        },
        [params, certId, selectedFee, appCtx.marketplaceId, appCtx.authToken, appCtx.blockchain, premintId, createCategory, price, priceUnit]
    );

    return (
        <div className="flex flex-col gap-8">
            <DivContainer>
                <span className="text-lg font-bold"> {certId ? "Edit" : "Create"} Membership Certificate</span>
            </DivContainer>
            <div className="grid grid-cols-2 gap-10">
                <div className="flex flex-col gap-5">
                    <div className="flex justify-center">
                        <img
                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${premintContent?.cid}`}
                            className="max-w-full max-h-[400px] min-h-[300px] object-contain"
                            alt="premint nft"
                        />
                    </div>
                    <EventDetailCard title="Name">
                        <DivContainer className="break-words">{premintMetaData?.name}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Description">
                        <DivContainer className="break-words">{premintMetaData?.description}</DivContainer>
                    </EventDetailCard>
                    <EventDetailCard title="Property">
                        <DivContainer className="flex gap-2 flex-wrap">
                            {Object.keys(premintMetaData?.properties || {})?.length ? <>
                                {
                                    Object.entries(premintMetaData?.properties)?.map(([key, value]) => {
                                        return (
                                            <DivContainer className="flex flex-col" key={key}>
                                                <span>{key}</span>
                                                <span>{value}</span>
                                            </DivContainer>
                                        );
                                    })}
                            </>
                                :
                                <div>No properties to display</div>
                            }

                        </DivContainer>
                    </EventDetailCard>
                </div>
                <div className="flex flex-col gap-10">
                    <div className='flex gap-5'>
                        <EventDetailCard title="NFT Price (optional)">
                            <div className="flex gap-3 items-center">
                                <Input value={price} placeholder="Enter NFT Price" onChange={(e) => { setPrice(e.target.value) }} />
                            </div>
                        </EventDetailCard>
                        <EventDetailCard title="Price unit (optional)">
                            <Select
                                className="bg-inherit dark:bg-gray-400 dark:outline-white w-[100%] h-[35px] mt-[10px]"
                                value={priceUnit}
                                onChange={(e) => {
                                    console.log('====================================');
                                    console.log(e.target.value);
                                    console.log('====================================');
                                    setPriceUnit(e.target.value);
                                }}
                            >
                                <MenuItem classes="bg-inherit" value="">
                                    NA
                                </MenuItem>
                                {appCtx.blockchain === 'ALGORAND' ? (
                                    <MenuItem classes="bg-inherit" value="algo">
                                        Algorand
                                    </MenuItem>
                                ) : (
                                    <MenuItem value="flow">Flow</MenuItem>
                                )}
                                <MenuItem classes="bg-inherit" value="usd">
                                    USD
                                </MenuItem>
                            </Select>
                        </EventDetailCard>
                    </div>
                    <EventDetailCard title="Category">
                        <div className="flex gap-3 items-center">
                            <Input value={`${params.get('collectionName')}`} />
                        </div>
                    </EventDetailCard>
                    <EventDetailCard title="Gas Fees">
                        <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="subsidized"
                                    checked={selectedFee === 'subsidized'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Subsidized by Organisation</span>
                            </div>
                            <div className="flex gap-2 items-center">
                                <input
                                    className="w-5 h-5"
                                    type="radio"
                                    value="charged"
                                    checked={selectedFee === 'charged'}
                                    onChange={(e) => setSelectedFee(e.target.value)}
                                />
                                <span>Charge from the members</span>
                            </div>
                        </div>
                    </EventDetailCard>
                    <BlueGradientButton disabled={updating} onClick={submitForm} className='w-[300px]'>{updating ? <CircularProgress size={20} /> : "Submit"} </BlueGradientButton>

                </div>
            </div>
            {/* {openCreateCategoryModal && (
                <CreateCategoryModal
                    closeModal={() => setOpenCreateCategoryModal(false)}
                    fetchCategories={fetchCategories}
                />
            )} */}

        </div>
    );
}

export default SubmitMembership;
