import { LocationOn } from '@mui/icons-material';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import Input from '../../ui/Input';

export const ConfigureEvent = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const makeRequest = useAuthorizedHttp();
    const fileRef = useRef();
    const bannerFileRef = useRef();
    const [collectionLogo, setCollectionLogo] = useState();
    const [logoPreview, setLogoPreview] = useState(null);
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [collectionBanner, setCollectionBanner] = useState();
    const [bannerPreview, setBannerPreview] = useState();
    const [uploadingBanner, setUploadingBanner] = useState(false);
    const appCtx = useSelector((state) => state.app);
    const [logoUrl, setLogoUrl] = useState("");
    const [bannerUrl, setBannerUrl] = useState("");
    const [confirm, setConfirm] = useState(false)
    const makeAuthorizedRequest = useAuthorizedHttp()
    const [showUploadLogoButton, setShowUploadLogoButton] = useState(true);
    const [showUploadBannerButton, setShowUploadBannerButton] = useState(true);

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/${appCtx.domain}/page/home/detail`
            },
            (data) => {
                
                data.bannerImage && setBannerPreview(data.bannerImage);
                data.description && setDescription(data.description);
                data.title && setTitle(data.title);
                // data.logo && setLogo(data.logo)
                // data.description && setDescription(data.description);
                // data.buttonLabel && setButtonLabel(data.buttonLabel);
                // data.buttonLink && setButtonURL(data.buttonLink);
            },
            (data) => console.log(data),
            () => {}
        );
    }, [ makeRequest]);

    const handleBannerChange = useCallback((file) => {

        if (file) {
            // if (file.size > 4 * 1024 * 1024) {
            //     toast.error('File size exceeds 3mb!');
            //     return false;
            // }
            setCollectionBanner(file);
            const objectUrl = URL.createObjectURL(file);
            setBannerPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const handleUploadBanner = () => {

        console.log(collectionBanner);
        let flag = true;
        if (!collectionBanner) {
            toast.error("Logo can't be empty");
            flag = false;
        }

        if (flag) {
            setUploadingLogo(true);

            const data = new FormData();
            data.append("file", collectionBanner);
            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/web2/file/upload?type=banner`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Auth-Token': appCtx.authToken
                },
                data: data
            };

            toast.promise(
                () =>
                    axios(config)
                        .then(function (response) {
                            const marketplace = {
                                // description,
                                // title,
                                // buttonLabel,
                                // buttonLink: buttonURL,
                                marketplaceId:appCtx.marketplaceId,
                                page: 'home',
                                bannerImage: response.data?.fileUrl
                            };
                            makeRequest(
                                {
                                    url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                                    method: 'POST',
                                    data: marketplace
                                },
                                (data) => console.log(data),
                                (data) => console.log(data),
                                () => {
                                    // setUploading(false);
                                }
                            );
                        })
                        .catch(function (error) {
                            toast.error('Uploading banner failed!');
                            // setUploading(false);
                        }),
                {
                    pending: 'Updating your marketplace settings...',
                    success: 'Settings updated successfully!',
                    error: 'Uh-oh! Something went wrong'
                }
            );
           
            //     method: "post",
            //     url: `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${appCtx.marketplaceId}/file/upload?type=collection-banner`,
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //         "X-Auth-Token": appCtx.authToken,
            //     },
            //     data: data,
            // };

            // toast.promise(
            //     () =>
            //         axios(config)
            //             .then(function (response) {
            //                 setBannerUrl(response.data.fileUrl)
            //                 const marketplace = {
            //                     marketplaceId: appCtx.marketplaceId,
            //                     collectionBanner: response.data?.fileUrl,
            //                 };
            //                 makeRequest(
            //                     {
            //                         url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace`,
            //                         method: "PUT",
            //                         data: marketplace,
            //                     },
            //                     (data) => {
            //                         setConfirm(true);
            //                     },
            //                     () => {
            //                         setUploadingBanner(false);
            //                         setShowUploadBannerButton(false);
            //                     }
            //                 );
            //             })
            //             .catch(function (error) {
            //                 toast.error("Uploading logo failed!");
            //                 setUploadingBanner(false);
            //             }),
            //     {
            //         pending: "Updating your marketplace settings...",
            //         success: "Settings updated successfully!",
            //         error: "Uh-oh! Something went wrong",
            //     }
            // );
        }
    }
    const setDescriptionData = (description) => {
        const marketplace = {
            description,
            title,
            // buttonLabel,
            // buttonLink: buttonURL,
            // marketplaceId,
            marketplaceId:appCtx.marketplaceId,
            page: 'home',
        };
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/marketplace/page`,
                method: 'POST',
                data: marketplace
            },
            (data) => console.log(data),
            (data) => console.log(data),
            () => {
                // setUploading(false);
            }
        );
    }

    return (
        <div>
            <div className='flex flex-col md:flex-row'>
                <div className="flex flex-col gap-3">
                    <div className="text-lg font-bold dark:text-gray-400 text-gray-700">Banner image</div>
                    <button
                        onClick={() => bannerFileRef.current.click()}
                        className="group bg-[#EAEAEA] dark:bg-gray-400 dark:bg-zing-700  w-[350px] h-[100px] rounded-lg"
                    >
                        <div className="z-20  opacity-80 group-hover:opacity-100 bg-gray-400/50 items-center flex justify-center h-full rounded-lg transition-all ease-out duration-300">
                            Browse for banner
                        </div>
                        {bannerPreview && (
                            <img
                                src={bannerPreview}
                                alt="Banner"
                                className="object-cover relative bottom-[100px]  w-[350px] h-[100px] z-10  overflow-hidden rounded-lg"
                            />
                        )}
                    </button>
                    {bannerPreview && <div className='flex justify-center'>{!uploadingBanner && showUploadBannerButton && <button
                        disabled={!collectionBanner || uploadingBanner}
                        onClick={handleUploadBanner}
                        // onClick={() => fileRef.current.click()}
                        className="group px-20 bg-secBlue  dark:bg-zing-700 rounded-md py-2 h-12  text-white"
                    >
                        Confirm
                    </button>}</div>}
                    <input
                        hidden={true}
                        type="file"
                        ref={bannerFileRef}
                        onChange={(e) => {
                            handleBannerChange(e.target.files[0])
                            setShowUploadBannerButton(true)
                        }}
                    />
                    <div className="text-lg font-bold dark:text-gray-400 text-gray-700 mt-[20px]">Event Title</div>
                    <Input className='w-[100%] h-[40px] rounded-lg px-[10px] text-black' value={title} placeholder='Enter button label name' onChange={(e) => { setTitle(e.target.value) }} />
                    
                    <div className="text-lg font-bold dark:text-gray-400 text-gray-700 mt-[20px]">Banner Description</div>
                    <Input className='rounded-lg w-[350px] px-[10px] text-white' placeholder='Enter Event Description' value={description?.substring(0, 250)} onChange={(e) => { setDescription(e.target.value) }}></Input>
                    <div className='text-right'>{description?.length > 250 ? 250 : description.length}/{250}</div>
                </div>
                <div className="lg:ml-[50px] mt-[50px] sm:mt-[0px] rounded-lg w-[306%] sm:w-[121%] mr-[10px] flex flex-col items-center shadow-prevBox dark:bg-inherit dark:border-darkBorder  h-[500px] xl:w-3/4  border-white border-[20px] dark:border-darkSecondary ">
                    {/* <div>
                        logoPreview
                    </div> */}
                    <div className="w-[100%] h-[100%] flex justify-between items-center pl-[1rem] pr-[1rem] xl:px-10 py-4 gap-5">
                        <div className='w-[360px] h-[250px]' style={{ overflowWrap: 'break-word' }}>
                            {description !== '' ? (
                                <div className="w-[360px] h-[100%] flex justify-start items-center text-[15px]" style={{ overflowWrap: 'anywhere' }}>
                                    {description?.substring(0, 250)}
                                </div>
                            ) :
                                <div className="w-[360px] h-[100%] flex justify-center items-center text-[20px] bg-gray-500">
                                    Banner Description Preview
                                </div>
                            }
                        </div>
                        <div className='w-[80%] flex items-center justify-center'>
                            {bannerPreview ? (
                                <img
                                    src={bannerPreview}
                                    alt="BANNERLOGO"
                                    className="w-[100%] h-[100%]"
                                />
                            )
                                :
                                <div className="w-[100%] h-[220px] flex justify-center items-center text-[20px] bg-gray-500">
                                    Banner Logo Preview
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-32 flex-wrap items-center mt-[20px]">
                {/* {confirm && <div className="text-green-500">Marketplace header details updated successfully </div>} */}
                {description && title && (
                    <Link to='/uploadnft'
                        className=" no-underline bg-secBlue py-3 px-12 rounded-md text-white"
                    onClick={() => setDescriptionData(description)}
                    >
                        Next : Upload NFTs Page
                    </Link>
                )}
            </div>
        </div>
    )
}
