const regexName = /^[a-zA-Z]{2,40}$/;
const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!_@#$%^])[0-9a-zA-_Z!@#$%^]{8,40}/;
const regexEmail = /^[a-zA-Z0-9.-]{2,30}@[a-z]{2,10}.[a-z-]{2,10}(.[a-z-]{2,10})?$/;
const regexPhone = /^[\d]{10}$/;

export const isNameValid = (name) => {
	return regexName.test(name);
};

export const isPasswordValid = (password) => {
	return regexPassword.test(password);
};

export const isEmailValid = (email) => {
	return regexEmail.test(email);
};

export const isPhoneValid = (phone) => {
	return regexPhone.test(phone);
};

export const passwordMatches = (password, confirmPassword) => {
	return password === confirmPassword;
};

export const isFieldNonEmpty = (text) => {
	return text !== "" && text.trim() !== "";
};
