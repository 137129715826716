import React from 'react';

function CryptoWallet() {
    return (
        <div className="flex flex-col border-gray-400  py-5 sm:px-8 rounded-lg gap-4">
            <div className="text-md sm:text-2xl font-bold w-full text-left">
                Crypto wallet is not available for everyone, if you want you can request early access.
            </div>
            <div className="flex flex-col border border-gray-700 p-4 rounded-lg gap-5">
                <div className='font-semibold'>Why should you chose us.</div>
                <div className="flex flex-col sm:flex-row w-[100%] justify-evenly flex-wrap">
                    <div className=" flex flex-col justify-start  gap-16 items-center w-[100%] sm:w-2/5 shadow-md p-5 rounded-md">
                        <div className='font-bold'>Multichain Support</div>
                        <div className="flex  gap-6 justify-center">
                            <img src="/images/flow.png" alt="flow" className="w-1/3 object-cover" />
                            <img src="/images/algorand.png" alt="algorand" className="w-1/3 object-cover" />
                        </div>
                    </div>

                    <div className=" flex flex-col justify-start gap-10 items-center w-[100%] sm:w-2/5 shadow-md p-5 rounded-md">
                        <div className='font-bold'> Dedicated team for your all queries & complaints</div>
                        <div className="flex justify-center">
                            <img className="w-2/3 object-cover" src="/images/team.png" alt='team'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CryptoWallet;
