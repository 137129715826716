import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AssetPulse from '../components/common/AssetPulse';
import NFTItem from '../components/all-nft/NFTItem';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import BlueGradientButton from '../ui/BlueGradientButton';
import Select from '../ui/Select';
import CreateNftTutorial from './CreateNftTutorial';
import React from "react"

const AllNFTs = () => {
    const makeRequest = useAuthorizedHttp();
    const [marketplaceId, setMarketplaceId] = useState();
    const [nft, setNfts] = useState([]);
    const [totalPages,setTotalPages] = useState(1)
    const [currentPage,setCurrentPage] = useState(0);
    const [currentCollectionPage,setCurrentCollectionPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [marketplaceDomain, setMarketplaceDomain] = useState();
    const [collection, setCollection] = useState([{ id: 0, name: 'All' }]);
    const [selectedCollection, setSelectedCollection] = useState({ id: 0, name: 'All' });
    const makeAuthorizedRequest = useAuthorizedHttp();
    const loaction  = useLocation();
    console.log(loaction)

    const navigate = useNavigate();

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
                setMarketplaceDomain(data[0].domain);
            },
            () => {},
            () => {
                setLoading(false);
            }
        );
    }, [makeRequest]);

    useEffect(() => {
        marketplaceId &&
            makeAuthorizedRequest(
                {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/list`
                },
                (data) => {
                    setCollection([{ id: 0, name: 'All' }]);
                     setCollection((prev) => [...prev, ...data.content]);
                    // setCollection((prev) => [new Set([...prev,data?.content])]);
                }
            );
    }, [makeAuthorizedRequest, marketplaceId]);

    useEffect(() => {
        console.log(selectedCollection.collectionId)
        console.log(selectedCollection.id)
        // setLoading(true);
        marketplaceId &&
            makeRequest(
               
                {   
                    url:
                         selectedCollection.id === 0 || selectedCollection.id ==="All" 
                            ?`${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/nft/list?page=${currentPage}` 
                            : `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/marketplace/${marketplaceId}/collection/${selectedCollection.id}/nft/list?page=${currentCollectionPage}`
                },
                (data) => {
                    
                    // setNfts(prev => [...prev,...data.content]);
                    // setNfts(prev =>{ [...new Set([...prev,...data.content].map((nft)=>{
                    //     return
                    // }))]
                    // }
                    //     );
                    if(selectedCollection.id === 0 || selectedCollection.id ==="All" ){
                        setNfts(prev => [...new Map([...prev,...data?.content].map(item =>
                            [item.nftId, item])).values()])
                    }
                    else{  
                        if(currentCollectionPage === 0){
                        setNfts(data?.content)
                        }
                        else{
                            setNfts(prev => [...new Map([...prev,...data?.content].map(item =>
                                [item.nftId, item])).values()]) 
                        }
                    }
                    setTotalPages(data?.totalPages)
                },
                (data) => {
                    console.log(data);
                },
                () => {
                    setLoading(false);
                }
            );
    }, [makeRequest, marketplaceId, selectedCollection,currentPage,currentCollectionPage]);

    return (
        <>
            <div className="text-xl font-semibold mb-10 flex items-center justify-between">
                <div className="font-bold text-2xl">My NFTs</div>
                <button
                    className="px-12 bg-secBlue py-3 rounded-md text-base text-white"
                    onClick={() => navigate(loaction.pathname ==="/nft-launch-pad/nft" ? "/nft-launch-pad":`/asset?type=${'upload'}`)}
                >
                    Create NFT
                </button>
            </div>
            {loading && (
                <div className="grid grid-cols-4 gap-5">
                    <AssetPulse />
                    <AssetPulse />
                    <AssetPulse />
                    <AssetPulse />
                    <AssetPulse />
                </div>
            )}
            {!loading && nft && collection?.length !== 0 && (
                <div className={'flex flex-col gap-10'}>
                    <div className={'flex flex-col'}>
                        <div className={'text-lg w-[100%]'}>Filter from collection</div>
                        <Select
                            className={'w-[300px]'}
                            data={collection?.map((c) => {
                                return { id: c.collectionId, text: c.name };
                            })}
                            value={selectedCollection}
                            onChange={(d) => setSelectedCollection(d)}
                        />
                    </div>
                    <div className={'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-10'}>
                        {nft?.map((n) => (
                            <NFTItem
                                key={n.nftId}
                                collection={collection?.filter((c) => c.collectionId === n.collectionId)[0]}
                                domain={marketplaceDomain}
                                nft={n}
                            />
                        ))}
                    </div>
                   { (selectedCollection?.id === 0 || selectedCollection?.id ==="All") ?   (totalPages - currentPage > 1) && <div className='flex mb-16 justify-center text-blue-600'>
                        <button
                        onClick={() => setCurrentPage(prev => prev +1)} 
                        >Load more ...</button>
                    </div>
                   : 
                        ( (totalPages - currentCollectionPage >1 )) && <div className='flex mb-16 justify-center text-blue-600'>
                        <button
                        onClick={() => setCurrentCollectionPage(prev => prev +1)} 
                        >Load more ...</button>

                        </div>
                    
                }
                    {/* {
                        <div className='flex   justify-center'>
                            {
                                totalPages?.map((page,index)=>{
                                    return <button
                                    onClick={()=>setCurrentPage(index)}
                                    key={index}
                                    className="p-2 px-4 border"
                                    > {index+1}</button>
                                })
                            }
                        </div>
                    } */}
                </div>
            )}
            {!loading && (!nft || nft?.length === 0) && (
                <div className="flex flex-col gap-5">
                    {/* <div>No NFT found. Upload your asset to mint NFT</div>
                    <BlueGradientButton className={'w-[300px]'} onClick={() => navigate('/asset')}>
                        Upload your asset
                    </BlueGradientButton> */}
                    <CreateNftTutorial />
                </div>
            )}
        </>
    );
};

export default AllNFTs;
