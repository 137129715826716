import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetType } from '../misc/asset-type';
import { toast } from 'react-toastify';
import BlueGradientButton from '../ui/BlueGradientButton';
import AssetDisplay from '../components/common/AssetDisplay';
import React from 'react';
import useAuthorizedHttp from '../hooks/use-authorized-http';
import { Outlet, useLocation } from 'react-router-dom';
import ThreeDRenderer from '../components/common/ThreeDRenderer';
import { Cancel } from '@mui/icons-material';
import ModalOverlay from '../components/common/ModalOverlay';

const imageExtensions = ['jpg', 'jpeg', 'gif', 'tiff', 'psd', 'raw', 'png', 'svg'];
const videoExtensions = ['mp4', 'mov', 'wmv', 'avi', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'mpeg-2', 'webm'];
const threeDExtensions = ['fbx', 'obj', 'gltf', 'glb'];
const maxFileSize = 500 * 1024 * 1024;

const MyAssets = () => {
    const assetType = getAssetType();
    const [showModal, setShowModal] = useState(false);
    const [uploadFile, setUploadFile] = useState();
    const [uploaded, setUploaded] = useState('0%');
    const [uploadFinished, setUploadFinished] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [fileType, setFileType] = useState('');
    const fileRef = useRef();
    const appCtx = useSelector((state) => state.app);
    const [asset, setAsset] = useState(null);
    const [marketplaceId, setMarketplaceId] = useState();
    const location = useLocation();
    const [extension, setExtension] = useState('');
    const makeRequest = useAuthorizedHttp();
    const [showUploadSection, setShowUploadSection] = useState(false);
    const navigate = useNavigate();

    const type = new URL(window.location.href).searchParams.get('type');

    useEffect(() => {
        makeRequest(
            {
                url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/user/marketplace/list`
            },
            (data) => {
                setMarketplaceId(data[0].marketplaceId);
            },
            (data) => {
                console.log(data);
            },
            () => {}
        );
    }, [makeRequest]);

    const submitForm = async () => {
        var data = new FormData();
        data.append('file', uploadFile);
        setUploading(true);
        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_NFT_FILE_UPLOAD}/marketplace/${marketplaceId}/file/upload?type=${fileType}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Auth-Token': appCtx.authToken
            },
            onUploadProgress: (progressEvent) => {
                setUploaded(`${Number((progressEvent.loaded / uploadFile.size) * 100).toFixed(0)}%`);
            },
            data: data
        };
        if (uploadFile) {
            // axios(config)
            //     .then((res) => {
            //         console.log(res)
            //         setUploading(false);
            //         setAsset(res.data);
            //         setUploadFinished(true);
            //         uploadFinished()
            //         // toast.success('File Uploaded SuccessFully')
            //     })
            //     .catch((rej) => {
            //         if (rej?.response?.data?.message ===
            //             "Request processing failed; nested exception is java.lang.Exception: Duplicate file from other marketplace") {
            //             setUploading(false);
            //             setUploadFinished(true);
            //             setShowModal(true)
            //         }
            //         setUploading(false);
            //         setUploadFinished(true);
            //         // toast.error('File Upload Unsuccessfull')
            //     })

            toast.promise(
                () =>
                    // new Promise((resolve, reject) =>
                    axios(config)
                        .then(function (response) {
                            // setUploading(false);
                            // setAsset(response.data);
                            // setUploadFinished(true);
                            console.log(response);
                            setUploading(false);
                            setAsset(response.data);
                            setUploadFinished(true);
                            uploadFinished();
                            toast.success('Your asset was uploaded successfully!');
                            // resolve();
                        })
                        .catch(function (rej) {
                            if (
                                rej?.response?.data?.message ===
                                'Request processing failed; nested exception is java.lang.Exception: Duplicate file from other marketplace'
                            ) {
                                setUploading(false);
                                setUploadFinished(true);
                                setShowModal(true);
                                toast.error('There was an error while uploading your asset!');
                            }
                            setUploading(false);
                            setUploadFinished(true);
                            // reject();
                        }),
                {
                    pending: 'Started upload...'
                    // success: 'Your asset was uploaded successfully!',
                    // error: 'There was an error while uploading your asset!'
                }
            );
        }
    };
    const unselectFile = () => {
        setAsset(null);
        setUploadFile(null);
    };

    const stageForUpload = useCallback((file) => {
        setAsset(null);
        if (!uploading) {
            setUploaded('0%');
            setUploadFile(file);
            setUploadFinished(false);
        } else {
            toast.error('Another upload still in progress!');
        }
    }, []);
    const isFileValid = useCallback((file) => {
        if (file.size > maxFileSize) {
            toast.error('File size exceeds 500mb!');
            return false;
        }
        let extension = file.name.split('.');
        extension = extension[extension.length - 1];
        setExtension(extension);
        if (imageExtensions.includes(extension)) {
            setFileType('image');
            return true;
        } else if (videoExtensions.includes(extension)) {
            setFileType('video');
            return true;
        } else if (threeDExtensions.includes(extension)) {
            setFileType('threeD');
            console.log('threeD');
            return true;
        } else {
            return null;
        }
    }, []);

    const handleFileDropped = useCallback(
        (e) => {
            e.preventDefault();
            const f = e.dataTransfer.files[0];

            if (isFileValid(f)) stageForUpload(f);
        },
        [isFileValid, stageForUpload]
    );

    const handleFileInputChange = useCallback(
        (e) => {
            const f = e.target.files[0];
            if (isFileValid(f)) stageForUpload(f);
        },
        [isFileValid, stageForUpload]
    );
    return (
        <>
            <div className="text-gray-700 dark:text-slate-100 flex flex-col gap-10">
                {location.pathname === '/asset' ? (
                    <>
                        {/* <div className="text-2xl font-semibold">Upload your assets</div> */}
                        {(showUploadSection || type === 'upload') && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
                                <div className="w-full flex flex-col gap-5">
                                    <button
                                        draggable={true}
                                        onDragEnter={(e) => e.preventDefault()}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={handleFileDropped}
                                        onClick={() => fileRef.current.click()}
                                        onDrag={() => fileRef.current.drag()}
                                        className="w-full border-2 border-dashed rounded h-[200px] items-center justify-center flex dark:bg-zinc-700/70 bg-gray-100/70 dark:hover:bg-sky-700/50 hover:bg-sky-100/50 transition-all ease-out duration-300"
                                    >
                                        <div>Drag and drop images here or browse...</div>
                                        <input
                                            draggable={true}
                                            type="file"
                                            className="hidden"
                                            onChange={handleFileInputChange}
                                            ref={fileRef}
                                        />
                                    </button>
                                    {uploadFile && (
                                        <div className="flex flex-col w-full">
                                            <div className="flex justify-between rounded-t dark:bg-gray-700 bg-slate-200 p-4">
                                                <div className="">{uploadFile.name}</div>
                                                <button onClick={unselectFile}>
                                                    <Cancel />
                                                </button>
                                            </div>
                                            <div className="flex flex-row">
                                                <div
                                                    className="h-2 bg-green-500 rounded-bl"
                                                    style={{ width: uploaded }}
                                                ></div>
                                                <div className="h-2 bg-gray-200 dark:bg-gray-700 grow rounded-br"></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex justify-center ">
                                        <BlueGradientButton
                                            disabled={!uploadFile || uploading || !marketplaceId}
                                            className={`px-28`}
                                            onClick={() => submitForm()}
                                        >
                                            Upload
                                        </BlueGradientButton>
                                    </div>
                                    {showModal && (
                                        <ModalOverlay>
                                            <div className="flex flex-col gap-5 py-5 ">
                                                <button
                                                    className="flex justify-end"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    X
                                                </button>
                                                <div className="flex w-96 flex-col">
                                                    This uploaded asset is owned & listed on marketplace for sale by
                                                    someone else if you think its not you please do Raise a Dispute, we
                                                    will help you out finding the appropriate solution.
                                                    <span className="font-bold">
                                                        Send an email to info@onnftverse.com
                                                    </span>
                                                </div>
                                            </div>
                                        </ModalOverlay>
                                    )}
                                </div>
                                <div className="flex justify-center">
                                    {asset ? (
                                        <div className="flex flex-col gap-[20vh] ">
                                            {asset?.type === 'image' ? (
                                                <div className="shadow-md p-4 rounded-md">
                                                    <img
                                                        src={
                                                            asset?.s3url
                                                                ? `${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`
                                                                : `${process.env.REACT_APP_GATEWAY_IPFS}/${asset?.cid}`
                                                        }
                                                        className="h-[250px] w-[350px] object-contain"
                                                        alt="img"
                                                    />
                                                </div>
                                            ) : asset?.type === 'video' ? (
                                                <video
                                                    controls
                                                    autoPlay
                                                    className="w-full shadow-lg rounded-lg object-contain h-full"
                                                    src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                                />
                                            ) : (
                                                <ThreeDRenderer
                                                    className={`w-full shadow-lg rounded-lg object-contain h-full`}
                                                    src={`${process.env.REACT_APP_URL_NFTVERSE_ASSETS}/${asset?.s3url}`}
                                                    type={extension}
                                                />
                                            )}
                                            <button
                                                className="bg-secBlue text-slate-50 p-3 w-full rounded"
                                                onClick={() =>
                                                    navigate(
                                                        `/mint?ipfsHash=${asset?.ipfsHash}&s3url=${asset?.s3url}&type=${fileType}&assetId=${asset?.assetId}&extension=${extension}`
                                                    )
                                                }
                                            >
                                                Mint NFT
                                            </button>
                                        </div>
                                    ) : (
                                        //    {/* <div className="w-full">
                                        //         <AssetItem asset={asset} />
                                        // </div>*/}

                                        <div className="text-xl font-light h-[250px] w-[350px] dark:bg-inherit rounded-lg border-[20px] border-white dark:border-darkBorder shadow-md"></div>
                                    )}
                                </div>
                            </div>
                        )}

                        {marketplaceId && type !== 'upload' && (
                            <AssetDisplay
                                marketplaceId={marketplaceId}
                                showUploadSection={showUploadSection}
                                setShowUploadSection={setShowUploadSection}
                                type={extension}
                            />
                        )}
                    </>
                ) : (
                    <Outlet />
                )}
            </div>
        </>
    );
};

export default MyAssets;
